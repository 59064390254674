// style
import styles from "./QualityAssuranceFilter.module.scss";

import { Checkbox } from "@trussworks/react-uswds";

// store
import { useFilterStore } from "../../../store/useFilterStore";

const qualityAssurances = [
  "ANSI/ASQC Z1.4",
  "ISO-9000 Series",
  "ISO 10012-1",
  "MIL-Q-9858",
  "MIL-STD-45662A",
];

export default function QualityAssuranceFilter() {
  const store = useFilterStore();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    const newList = store.filters.qualityAssuranceStandards.standards;

    if (checked) {
      store.setFilter("qualityAssuranceStandards", {
        ...store.filters.qualityAssuranceStandards,
        standards: [...newList, { value: name, isApplied: false }],
      });
    } else {
      store.setFilter("qualityAssuranceStandards", {
        ...store.filters.qualityAssuranceStandards,
        standards: newList.filter((item) => item.value !== name),
      });
    }
  };

  return (
    <div className={styles["quality-assurance-filter-root"]}>
      <div className={styles.checkboxes}>
        {qualityAssurances.map((item, i) => (
          <Checkbox
            key={i}
            id={item}
            name={item}
            label={item}
            checked={store.filters.qualityAssuranceStandards.standards
              .map((item) => item.value)
              .includes(item)}
            onChange={handleChange}
          />
        ))}
      </div>

      <hr />
      <p>
        Results can match <b>any</b> of these items.
      </p>
    </div>
  );
}
