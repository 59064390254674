import "./PerformanceHistorySection.scss";

// types
import { PerformanceHistory } from "../../../types/performance-history-.types";
import { useSearchStore } from "../../../store/searchStore";
import DisplayValue from "../DisplayValue";


export default function PerformanceHistorySection() {

  const { business_profile: { performance_history } } = useSearchStore();
  return (
    <div className="font-family-sans performance-history-root">
      <h1 className="title">Performance history</h1>
      <h2 className="sub-title">References</h2>
      <DisplayValue value={performance_history} message={`This firm does not have any references listed`} renderer={() => (<>
        <div className="margin-top-2 space-y-4">
          {performance_history && performance_history.map((item: any, i) => (
            <PerformanceCard key={i} data={item} />
          ))}
        </div>
      </>)} />
    </div>
  );
}

function PerformanceCard({ data }: { data: PerformanceHistory }) {
  return (
    <div className="performance-card padding-2 border border-ink">
      <h4 className="title">{data.name}</h4>

      <div className="margin-top-2 grid-row">
        <div className="grid-col-12 desktop:grid-col-6 space-y-2">
          <div className="card-detail">
            <h5>Contract</h5>
            <p>{data.contract}</p>
          </div>
          <div className="card-detail">
            <h5>Start</h5>
            <p>{data.start_date}</p>
          </div>
          <div className="card-detail">
            <h5>End</h5>
            <p>{data.end_date}</p>
          </div>
        </div>

        <div className="grid-col-12 desktop:grid-col-6 margin-top-1 desktop:margin-top-0 space-y-2">
          <div className="card-detail">
            <h5>Value</h5>
            <p>{data.value}</p>
          </div>
          <div className="card-detail">
            <h5>Contact</h5>
            <p>{data.contact}</p>
          </div>
          <div className="card-detail">
            <h5>Phone</h5>
            <p>{data.phone}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
