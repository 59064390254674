import styles from "./NoResultFound.module.scss";

import classNames from "classnames";

// components
import Blob from "components/icons/Blob";

type NoResultFoundProps = {
  content: string;
  className?: string;
} & JSX.IntrinsicElements["div"];

export default function NoResultFound(props: NoResultFoundProps) {
  const { content, className, ...rest } = props;
  const classes = classNames(styles["no-result-root"], className);

  return (
    <div className={classes} {...rest}>
      <Blob />
      <span>{content}</span>
    </div>
  );
}
