import { useState, useEffect, useMemo } from "react";
import { Icon } from "@trussworks/react-uswds";
import cn from "classnames";

// component
import {
  FilterFlyout,
  MultiSelectCheckbox,
  RoundedButton,
  SearchInput,
  ToggleBar,
} from "components/LibraryComponents/Components";
import { PopoutArrow } from "components/icons";

// types
import { Generic, Location } from "types/filters-store.types";

// utils
import { getHighlightedText, formattedStatesList } from "utils/advanced-search";

// constants
import { FilterKeys, FilterTitles } from "constants/filters";

// store
import useFiltersStore from "store/useFiltersStoreNew";

// hooks
import useDebounce from "hooks/useDebounce";

// styles
import styles from "./LocationFilterButton.module.scss";

// query
import useZipCodes, { FilterTerm } from "./query";

export default function LocationFilterButton() {
  const { location, setFilter } = useFiltersStore((state) => ({
    location: state.filters.location,
    setFilter: state.setFilter,
  }));
  console.log(location.counties);

  const { zipCodes, counties } = useZipCodes();

  const isDefaultState =
    location.states.length === 0 &&
    location.zipCodes.length === 0 &&
    location.counties.length === 0;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(isDefaultState);
  const [isSearchState, setIsSearchState] = useState<boolean>(false);
  const [stateList, setStateList] = useState<Generic[]>(formattedStatesList);
  // This state only control if filter is open,
  // and has more than one value apply max-width to filter button.
  const [isHalt, setIsHalt] = useState<boolean>(true);

  const debouncedSearchTerm = useDebounce(value, 150);

  const selectedLocationValues = locationOptionBuilder(location);
  const isDisabled = selectedLocationValues.length === 0;

  // filter zip codes
  const filteredZipCodes = useMemo(() => {
    if (!debouncedSearchTerm.trim()) return [];

    // check if search term contains only numbers
    const onlyNumbers = /^[0-9]+$/.test(debouncedSearchTerm);

    if (onlyNumbers) {
      // Filter state list based on the search term
      const searchLower = debouncedSearchTerm.toLowerCase();
      const filtered = zipCodes.filter((item) =>
        item.label.toLowerCase().startsWith(searchLower)
      );

      // Limit the number of results to 5
      const limitList = filtered.slice(0, 5);

      const highlightedList = getHighlightedText(
        limitList,
        debouncedSearchTerm
      );

      return highlightedList.map((item) => ({
        ...item,
        label: `Zip code: ${item.label}`,
      }));
    }

    return [];
  }, [zipCodes, debouncedSearchTerm]);

  // filter counties
  const filteredCounties = useMemo(() => {
    if (!debouncedSearchTerm.trim()) return [];

    // check if search term contains alphabets, and dot
    const onlyAlphabets = /^[a-zA-Z.\s]+$/.test(debouncedSearchTerm);

    if (onlyAlphabets) {
      // Filter state list based on the search term
      const searchLower = debouncedSearchTerm.toLowerCase();
      const filtered = counties.filter((item) =>
        item.label.toLowerCase().startsWith(searchLower)
      );

      // Limit the number of results to 10
      // const limitList = filtered.slice(0, 10);

      const highlightedList = getHighlightedText(filtered, debouncedSearchTerm);

      return highlightedList.map((item: FilterTerm) => ({
        ...item,
        label: countyLabelFormatter(item.label, item.stateName),
      }));
    }

    return [];
  }, [counties, debouncedSearchTerm]);

  useEffect(() => {
    if (selectedLocationValues.length === 0) {
      if (!isHalt) setIsHalt(true);
    }
  }, [selectedLocationValues]);

  const handleChange = (items: Generic[]) => {
    const allSelected = items.length === formattedStatesList.length;

    setChecked(allSelected);

    setFilter(FilterKeys.LOCATION, {
      ...location,
      states: !allSelected ? items : [],
    });
  };

  const handleZipCodesChange = (items: FilterTerm[]) => {
    setFilter(FilterKeys.LOCATION, {
      ...location,
      zipCodes: items.map((item) => {
        if (item?.originalLabel) {
          return { label: item.originalLabel, value: item.originalValue };
        }
        return item;
      }),
    });

    if (checked) setChecked(false);
  };

  const handleCountiesChange = (items: FilterTerm[]) => {
    setFilter(FilterKeys.LOCATION, {
      ...location,
      counties: items.map((item) => {
        if (item?.originalLabel) {
          // Value contins {county_name}-{state_name}-{fips_code}
          return { label: item.originalLabel, value: item.value };
        }
        return item;
      }),
    });

    if (checked) setChecked(false);
  };

  const handleClear = () => {
    setFilter(FilterKeys.LOCATION, {
      ...location,
      states: [],
      zipCodes: [],
      counties: [],
    });

    setChecked(true);
    setIsHalt(true);
  };

  // if true/false either case set the filter to Default State.
  const handleAnywhereChecked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChecked(event.target.checked);

    if (selectedLocationValues.length > 0) {
      setFilter(FilterKeys.LOCATION, {
        ...location,
        states: [],
        zipCodes: [],
        counties: [],
      });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const trimValue = value.trim();

    setValue(value);

    if (trimValue && !isSearchState) {
      setIsSearchState(true);
    } else if (!trimValue && isSearchState) {
      setIsSearchState(false);
    }

    // filter state list based on the search term
    if (trimValue) {
      const filterList = formattedStatesList.filter((item) =>
        item.label.toLowerCase().includes(trimValue.toLowerCase())
      );

      setStateList(getHighlightedText(filterList, trimValue));
    } else {
      setStateList(formattedStatesList);
    }
  };

  const handleSearchClear = () => {
    setValue("");
    setStateList(formattedStatesList);
    setIsSearchState(false);
  };

  // reset local states
  const handleCallback = (open: boolean) => {
    if (!open) {
      setChecked(isDefaultState);

      if (isSearchState) {
        setValue("");
        setIsSearchState(false);
        setStateList(formattedStatesList);
      }

      if (location.states.length > 0) setIsHalt(false);
    }
  };

  return (
    <FilterFlyout
      id={FilterKeys.LOCATION}
      filterName={FilterTitles[FilterKeys.LOCATION]}
      title={FilterTitles[FilterKeys.LOCATION]}
      FilterIcon={<Icon.Map />}
      defaultValue="Anywhere"
      isApplied={false}
      options={selectedLocationValues}
      isOpen={isOpen}
      isHalt={isHalt}
      setIsOpen={setIsOpen}
      callback={handleCallback}
    >
      <div className={styles["location-filter-content"]}>
        <p className={styles["description"]}>
          Narrow your search down to one or more places.
        </p>

        <div className={styles["search-input-wrapper"]}>
          <SearchInput
            id="location-search"
            name="location-search"
            placeholder="Type to search for a state, territory, zip code and more"
            inputSize="small"
            value={value}
            onChange={handleSearch}
            handleClear={handleSearchClear}
          />
        </div>

        {/* Show selected zip codes and counties */}
        {!isSearchState &&
          (location.zipCodes.length > 0 || location.counties.length > 0) && (
            <div className={styles["list"]}>
              <p className={styles["selected-label"]}>Selected</p>

              <div
                className={cn(
                  styles["list-wrapper"],
                  styles["selected-list-wrapper"]
                )}
              >
                {/* selected zip codes */}
                {location.zipCodes.length > 0 && (
                  <MultiSelectCheckbox
                    items={location.zipCodes.map((item) => ({
                      ...item,
                      label: `Zip code: ${item.label}`,
                    }))}
                    checkedItems={location.zipCodes}
                    handleChange={handleZipCodesChange}
                    isInnerHtml
                    hideOnly
                  />
                )}

                {/* selected counties */}
                {location.counties.length > 0 && (
                  <MultiSelectCheckbox
                    items={location.counties.map((item) => ({
                      ...item,
                      label: countyLabelFormatter(
                        item.value.split("-")[0],
                        item.value.split("-")[1]
                      ),
                    }))}
                    checkedItems={location.counties}
                    handleChange={handleCountiesChange}
                    isInnerHtml
                    hideOnly
                  />
                )}
              </div>
            </div>
          )}

        <div hidden={isSearchState} className={styles["toggle-bar-wrapper"]}>
          <ToggleBar
            id="bar-anywhere"
            label="Search for businesses anywhere"
            inputProps={{ "aria-label": "controlled" }}
            checked={checked}
            onChange={handleAnywhereChecked}
          />
        </div>

        <div className={styles["list"]}>
          {/* Show zip codes list on search state */}
          {isSearchState && filteredZipCodes.length > 0 && (
            <>
              <div style={{ marginTop: 16 }} />
              <MultiSelectCheckbox
                className={styles["list-wrapper"]}
                items={filteredZipCodes}
                checkedItems={location.zipCodes}
                handleChange={handleZipCodesChange}
                isInnerHtml
                hideOnly
              />
            </>
          )}

          {/* Show counties list on search state */}
          {isSearchState && filteredCounties.length > 0 && (
            <>
              <div style={{ marginTop: 16 }} />
              <MultiSelectCheckbox
                className={styles["list-wrapper"]}
                items={filteredCounties}
                checkedItems={location.counties}
                handleChange={handleCountiesChange}
                isInnerHtml
                hideOnly
              />
              <div style={{ marginBottom: 16 }} />
            </>
          )}

          {/* Show states list */}
          {stateList.length > 0 && (
            <>
              <p>STATES AND TERRITORIES</p>
              <MultiSelectCheckbox
                className={styles["list-wrapper"]}
                items={stateList}
                checkedItems={
                  !isDefaultState || !checked
                    ? location.states
                    : formattedStatesList
                }
                handleChange={handleChange}
                isInnerHtml
              />
            </>
          )}
        </div>

        {/* <div style={{ marginTop: "16px" }} /> */}

        <div hidden className={styles["narrow-section"]}>
          <p>Or, or narrow your search further by governing area:</p>
          <div className={styles["group"]}>
            <RoundedButton variant="secondary">
              Metropolitan statistical area
              <PopoutArrow />
            </RoundedButton>
            <RoundedButton variant="secondary">
              Congressional District
              <PopoutArrow />
            </RoundedButton>
          </div>
        </div>

        <div hidden={!isSearchState} style={{ marginTop: "16px" }} />

        <div className={styles["clear-filter"]}>
          <RoundedButton
            variant="ghost"
            onClick={handleClear}
            disabled={isDisabled}
          >
            Clear filter selections
          </RoundedButton>
        </div>
      </div>
    </FilterFlyout>
  );
}

function locationOptionBuilder(location: Location) {
  const options: string[] = [];

  if (location.states.length > 0) {
    location.states.forEach((state) => {
      options.push(state.value);
    });
  }

  if (location.zipCodes.length > 0) {
    location.zipCodes.forEach((zip) => {
      options.push(zip.label);
    });
  }

  if (location.counties.length > 0) {
    location.counties.forEach((county) => {
      options.push(county.label);
    });
  }

  return options;
}

function countyLabelFormatter(county: string, stateName: string) {
  if (stateName === "Louisiana") {
    return `Parish: ${county}, ${stateName}`;
  }

  return `County: ${county}, ${stateName}`;
}
