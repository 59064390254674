import {
  useQuery,
  // keepPreviousData,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";

// types
import { AppliedFilter } from "../../types/applied-filter.types";
import { BusinessItem } from "../../types/results-panel.types";

// fetch
import { getResults } from "../../data/fetch";

// utils
import { refactorAppliedFilterOptions } from "../../utils/helper";

export default function useSBSResultsQuery(appliedfilters: AppliedFilter[]) {
  const [businessList, setBusinessList] = useState<BusinessItem[]>([]);

  const refactorFiltersOptions = refactorAppliedFilterOptions(appliedfilters);

  // TODO: Remove the log onece all filters are sweep
  console.log("data to be send to api: ", { refactorFiltersOptions });

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["sbs-results", refactorFiltersOptions],

    // queryKey and signal are reduntant, but as it's required in api destructuring had to be added.
    queryFn: () =>
      getResults({ queryKey: [refactorFiltersOptions], signal: {} }),

    enabled: refactorFiltersOptions.length > 0,
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
    // placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (data) {
      const resultsData: BusinessItem[] =
        data?.results?.map((result) => ({
          pronetID: result.pronet_id,
          businessName: result.business_trade_name,
          capabilitiesNarrative: result.capabilities,
          capabilitiesStatement: result.capabilities_link,
          activeCertifications: result.active_certifications || [],
          contact: result.contact,
          email: result.email,
          address: result.address,
          ein: result.ein,
          uei: result.uei,
        })) ?? [];

      setBusinessList(resultsData);
    }
  }, [data]);

  return {
    businessList,
    totalResultsCount: businessList?.length ?? 0,
    total:data?.total ?? 0,
    isLoading,
    isError: Boolean(error),
    refetch,
    resetData: () => setBusinessList([]),
  };
}
