import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// styles
import styles from "./SearchBarNew.module.scss";

// components
import { SearchInput } from "components/LibraryComponents/Components";

// store
import useFiltersStore from "store/useFiltersStoreNew";

// constant
import { FilterKeys } from "constants/filters";

// fetch
import { getbusinessByUEI } from "data/fetch";

export default function SearchBarNew() {
  const { searchProfiles, setFilter } = useFiltersStore((state) => ({
    searchProfiles: state.filters.searchProfiles,
    setFilter: state.setFilter,
  }));

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // set search input value if user is in advanced search and enters search term from home
  useEffect(() => {
    if (pathname.includes("/advanced")) {
      setValue(searchProfiles.searchTerm);
    }
  }, [pathname, searchProfiles]);

  const handleSearchTerm = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // handle enter key
    if (event.key === "Enter") {
      const trimValue = value.trim();

      // check if value is not empty
      if (trimValue !== "") {
        try {
          // set search term
          setSearchTerm(trimValue, false);

          // pattern to identify UEI
          // F.Y.I with current search input component this does the job, but it will be far more
          // better to include selection/dropwdown in search input component and specifically choose UEI as search filter.
          const pattern = /^[A-Za-z0-9]{12}$/;

          if (pattern.test(trimValue)) {
            setIsLoading(true);

            // get business by UEI
            const response = await getbusinessByUEI({ id: trimValue });

            setIsLoading(false);

            // check if business exists
            if (response.data && response.data?.USER_ID) {
              const userId = response.data.USER_ID;
              // navigate to profile page
              navigate(`/profile/${userId}`);

              // // reset search term
              // setSearchTerm("", false);
              return;
            }
          }

          // if user is in home, navigate to advanced search page
          if (pathname === "/") {
            navigate("/advanced");
          }
        } catch (error: any) {
          console.error(`Error Search Input: ${error?.message ?? error}`);

          if (trimValue.length > 0) {
            setSearchTerm(trimValue);
          }

          if (isLoading) {
            setIsLoading(false);
          }
        }
      } else {
        // if value is empty and search term is not empty, clear search term
        if (searchProfiles.searchTerm.length > 0) {
          setFilter(FilterKeys.SEARCH_TERM, {
            ...searchProfiles,
            searchTerm: "",
          });
        }
      }
    }
  };

  const setSearchTerm = (value: string, canNavigate: boolean = true) => {
    setFilter(FilterKeys.SEARCH_TERM, {
      ...searchProfiles,
      searchTerm: value,
    });

    // if user is in home, navigate to advanced search page
    if (pathname === "/" && canNavigate) {
      navigate("/advanced");
    }
  };

  return (
    <div className={styles["search-bar-root"]}>
      <SearchInput
        id="search-input"
        name="search-input"
        placeholder="Type to search for small businesses by name, UEI, CAGE code, and more"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        handleClear={() => {
          setFilter(FilterKeys.SEARCH_TERM, {
            ...searchProfiles,
            searchTerm: "",
          });
          setValue("");
        }}
        onKeyDown={handleSearchTerm}
        disabled={isLoading}
        isLoading={isLoading}
      />
    </div>
  );
}
