// style
import "./ResultsPane.scss";

import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import Masonry from "react-masonry-css";

// components
import { Button, Icon, Modal, Checkbox, ModalRef, Textarea, Radio, TextInput, Label } from "@trussworks/react-uswds";
import AppliedCard from "./AppliedCard";
import ResultTable from "components/ResultTable/ResultTable";
// import AppliedFilterSentence from "./AppliedFilterSentence";

// utils
import { exportDataToCSV } from "../../utils/export-csv";
import {
  formatDateToYYYYMMDD,
  formatFeedbackData,
  getAppliedFilterOptions,
  getTotalAppliedFilterOptionsCount,
  selectedEditTableResultsData,
  selectedexportTableResultsData,
  transformedResultsData,
} from "../../utils/helper";

// data
import {
  resultsPanelData,
} from "../../data/result-panel.data";

// types
import DownArrow from "../icons/DownArrow";

// store
import { useFilterStore } from "../../store/useFilterStore";
import useColumnsStore from "store/useColumnsStore";

// query
import useSBSResultsQuery from "./queries";
import ControlAccordion from "../ControlAccordion";
import { modalAccordionProperty } from "../../constants/SearchProperties";
import { unparse } from "papaparse";
import { sendFeedback } from "../../data/fetch";
import { useExportTableColumnStore } from "../../store/exportTableColumnStore";

interface IProps {
  handleOpen: () => void;
  openExpand: boolean;
}

const breakpoint = {
  default: 4,
  1024: 2,
  680: 1,
};

const ResultsPaneNew = forwardRef(({ handleOpen, openExpand }: IProps, ref) => {
  const store = useFilterStore();
  const exportColumnStore = useExportTableColumnStore()
  const {columns, setColumns}= useColumnsStore((state) => ({
    columns: state.columns,
    setColumns: state.setColumns
  }));

  const{exportTablecolumnData} = exportColumnStore;

  const [sortType, setSortType] = useState("alphabetical");
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(0);
  const [showResultModal, setShowResultModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [selectOption, setSelectOption] = useState(false)
  const [selectOption1, setSelectOption1] = useState(true)
  const [resultModelChecked, setResultModelChecked] = useState<any>(columns && columns.length > 0 ? columns : [
    {
    id: "businessName1",
    name: "businessName",
    label: "Business name (Required)",
  }
])
  
  
  const [exportTableChecked, setExportTableChecked] = useState<any>(exportTablecolumnData && exportTablecolumnData.length >0 ? exportTablecolumnData :[])
  const [feedFormValue, setFeedBackFormValue] = useState({
    name: "",
    email: "",
    feedback: "",
    selectOption: "" || "send-information"
  })
  const [isFeedbackLoading, setIsFeedbackLoading] = useState<boolean>(false);

  const totalAppliedCount = getTotalAppliedFilterOptionsCount(
    store.appliedFilters
  );

  const appliedfilters = getAppliedFilterOptions(store.appliedFilters);
  const findSearchKeyword: any = appliedfilters && appliedfilters.find(item => item?.category === "searchBusiness");
  const findSearchKeywordShow = findSearchKeyword && findSearchKeyword?.options && findSearchKeyword?.options?.length > 0 ? findSearchKeyword?.options[0] : ''
  const { businessList, totalResultsCount, isLoading, resetData,total } =
    useSBSResultsQuery(appliedfilters);

  useEffect(() => {
    totalAppliedCount ? setIsExpand(openExpand) : setIsExpand(false);
  }, [openExpand, totalAppliedCount]);

  // ====Edit result table columns====

  const resultModelCheckBoxData = [
    {
      id: "capabilitiesNarrative1",
      name: "capabilitiesNarrative",
      label: "Capabilities narrative and capabilities statement link",
      labelDescription: "In a spreadsheet export, the capabilities narrative and capabilities statement will be separated into 2 columns.",
    },
    {
      id: "activeCertifications1",
      name: "activeCertifications",
      label: "Active SBA certifications",
      labelDescription: "This column will list each active SBA certification in a single cell. To separate them, scroll to the SBA certification settings section below."
    },
    {
      id: "contact1",
      name: "contact",
      label: "Contact person's name and email address",
      labelDescription: "In a spreadsheet export, the contact person's name and their email address will be separated into 2 columns."
    },
    {
      id: "address1",
      name: "address",
      label: "Address",
      labelDescription: "In a spreadsheet export, each part of the business address will be separated into columns: street address line 1, street address line 2, city, state, and zip code."
    },

  ]
  const resultModelRef = useRef<ModalRef>({
    modalId: "result-table-columns",
    modalIsOpen: false,
    toggleModal: (event?: React.MouseEvent, open?: boolean, close?: boolean) => {
      if (open) {
        const currentState = resultModelRef.current.modalIsOpen;
        const newState = open ?? !currentState;
        resultModelRef.current.modalIsOpen = newState;
        setShowResultModal(newState);
        return newState;
      }
      if (close) {
        resultModelRef.current.modalIsOpen = false;
        setShowResultModal(false);
        return false;
      }
    }
  })
  const openResultModel = () => {
    setResultModelChecked(columns && columns.length > 0 ? [...columns] : [
      {
      id: "businessName1",
      name: "businessName",
      label: "Business name (Required)",
    },
    ...resultModelCheckBoxData
  ]);
    resultModelRef.current?.toggleModal(undefined, true,false);
  }
  const closeResultModel = () => {
    setResultModelChecked([{
      id: "businessName1",
      name: "businessName",
      label: "Business name (Required)",
    }])
    setColumns([]);
    resultModelRef.current?.toggleModal(undefined, false, true);
  }
  const handleResultCheckBoxChange = (name: string, label: string,isChecked: Boolean, id) => {

    if (isChecked) {
      setResultModelChecked(prevChecked => [...prevChecked, { name, label,id }])
    } else {
      setResultModelChecked(prevChecked => prevChecked.filter(item => item.name !== name))
    }

  }
  
  const handleResultSelectedCheckBoxChange = (name: string, label: string,id) => {
    const isChecked = resultModelChecked.find(item => item.name === name && item.label === label);
    
    if (isChecked.name === name) {
      setResultModelChecked(prevChecked => prevChecked.filter(item => item.name !== name))
    }
    else {
      setResultModelChecked(prevChecked => [...prevChecked, { name, label ,id  }])
    }
  }

  const handleApplyChanges=()=>{
    setColumns(resultModelChecked);
    exportColumnStore.setFilter(resultModelChecked);
    resultModelRef.current?.toggleModal(undefined, false,false);
  }

  // ====Edit Export table columns====

  const exportModelCheckBoxData = [
    {
      id: "businessName",
      name: "businessName",
      label: "Business names",
      labelDescription: "Business name will be hyperlinked to the business’s Small Business Search profile page."
    }
    ,
    {
      id: "capabilitiesNarrative",
      name: "capabilitiesNarrative",
      label: "Capabilities narrative and capabilities statement link",
      labelDescription: "In a spreadsheet export, the capabilities narrative and capabilities statement will be separated into 2 columns.",
    },
    {
      id: "active_certifications",
      name: "active_certifications",
      label: "Active SBA certifications",
      labelDescription: "This column will list each active SBA certification in a single cell. To separate them, scroll to the SBA certification settings section below."
    },
    {
      id: "contact",
      name: "contact",
      label: "Contact person's name and email address",
      labelDescription: "In a spreadsheet export, the contact person's name and their email address will be separated into 2 columns."
    },
    {
      id: "address",
      name: "address",
      label: "Address",
      labelDescription: "In a spreadsheet export, each part of the business address will be separated into columns: street address line 1, street address line 2, city, state, and zip code."
    },

  ]
  const exportModelRef = useRef<ModalRef>({
    modalId: "export-table-columns",
    modalIsOpen: false,
    toggleModal: (event?: React.MouseEvent, open?: boolean, close?: boolean) => {
      if (open) {
        const currentState = exportModelRef.current.modalIsOpen;
        const newState = open ?? !currentState;
        exportModelRef.current.modalIsOpen = newState;
        setShowExportModal(newState);
        return newState;
      }
      if (close) {
        exportModelRef.current.modalIsOpen = false;
        setShowExportModal(false);
        return false;
      }
    }
  })
  const openExportModel = () => {
    setExportTableChecked(exportTablecolumnData && exportTablecolumnData.length > 0 ? [...exportTablecolumnData] : [...exportModelCheckBoxData]);
    exportModelRef.current?.toggleModal(undefined, true);
  }
  const closeExportModel = () => {
    setExportTableChecked([]);
    exportModelRef.current?.toggleModal(undefined, false, true);
  }
 
  const exportData = (data, fileName, type) => {
    const csv = unparse(data);
    const blob = new Blob([csv], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const handleExportTableCSV = () => {
    const fileName = `SBSexportTableResults_[${formatDateToYYYYMMDD(new Date())}].csv`;
    exportData(selectedexportTableResultsData(exportTableChecked,businessList), fileName,'text/csv;');
  };
  const handleExportTableCheckBoxChange = (name: string, label: string, isChecked: Boolean,id) => {

    if (isChecked) {
      setExportTableChecked(prevChecked => [...prevChecked, { name, label,id }])
    } else {
      setExportTableChecked(prevChecked => prevChecked.filter(item => item.name !== name))
    }

  }
  const handleExportTableSelectedCheckBoxChange = (name: string, label: string,id) => {
    const isChecked = exportTableChecked.find(item => item.name === name && label === label);
    if (isChecked) {
      setExportTableChecked(prevChecked => prevChecked.filter(item => item.name !== name))
    }
    else {
      setExportTableChecked(prevChecked => [...prevChecked, { name, label ,id}])
    }
  }

  //===Feedback Model===
  const [nameErrors, setNameErrors] = useState(false)
  const [emailErrors, setEmailErrors] = useState(false)
  const [isvalid, setIsvalid] = useState(false)
  const [feedBackErrors, setFeedBackErrors] = useState(false)
  
  const feedBackModelRef = useRef<ModalRef>({
    modalId: "feed-back",
    modalIsOpen: false,
    toggleModal: (event?: React.MouseEvent, open?: boolean, close?: boolean) => {
      if (open) {
        const currentState = feedBackModelRef.current.modalIsOpen;
        const newState = open ?? !currentState;
        feedBackModelRef.current.modalIsOpen = newState;
        setShowFeedbackModal(newState);
        return newState;
      }
      if (close) {
        feedBackModelRef.current.modalIsOpen = false;
        setShowFeedbackModal(false);
        return false;
      }
    }
  })
  const handleOptionChange = (e: any) => {
    if (e.target.id === "send-anonymously") {
      setFeedBackFormValue(prevState => ({ ...prevState, selectOption: "send-anonymously" }))
      setSelectOption(true)
      setSelectOption1(false)

    } else if (e.target.id === "send-information") {
      setFeedBackFormValue(prevState => ({ ...prevState, selectOption: "send-information" }))
      setSelectOption1(true)
      setSelectOption(false)
      setEmailErrors(false)
      setNameErrors(false)

    }
  }
  const openFeedbackModel = () => {
    setFeedBackFormValue({
      name: "",
      email: "",
      feedback: "",
      selectOption: "" || "send-information"
    })
    
    feedBackModelRef.current?.toggleModal(undefined, true);
  }
  const CloseFeedbackModel = () => {
    feedBackModelRef.current?.toggleModal(undefined, false, true);
  }
  const validEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }
  const SendFeedback = async () => {
    if (!feedFormValue.feedback) {
      setFeedBackErrors(true)
      return;
    } else {
      setFeedBackErrors(false)
    }

    if (feedFormValue.selectOption === "send-information") {
      if (!feedFormValue.name) {
        setNameErrors(true);
        return;
      } else {
        setNameErrors(false);
      }

      if (!feedFormValue.email) {
        setEmailErrors(true);
        return;
      } else {
        setEmailErrors(false);
      }
      if (!validEmail(feedFormValue.email)) {
        setIsvalid(true);
        return;
      } else {
        setIsvalid(false);
      }
    }

      setIsFeedbackLoading(true);

      const formattedData = formatFeedbackData(feedFormValue);

      await sendFeedback(formattedData);

      setIsFeedbackLoading(false);

      feedBackModelRef.current?.toggleModal(undefined, false, true);
  }
  const handleFeedbackchange = (event) => {
    const { name, value } = event.target
    setFeedBackFormValue((prevdata) => ({ ...prevdata, [name]: value }))
  }
  const handleExpandOpen = () => setIsExpand(true);

  const handleExpandClose = () => setIsExpand(false)

  const handleClearFilter = () => {
    resetData();
    store.clearFilter();
    setResultModelChecked([{
      id: "businessName1",
      name: "businessName",
      label: "Business name (Required)",
    }])
  };

  // expose handleClearFilter to parent (AdvancedSearch)
  useImperativeHandle(ref, () => ({
    handleClearFilter,
  }));

  // console.log("====HERE APPLIED FILTERS====", {
  //   appliedfilters,
  //   filters: store.filters,
  // });

  return (
    <div className="results-pane-root">
      {isLoading && (
        <div className="loading-section">
          <div className="loader" />
        </div>
      )}

      <div className="pane-content">
        <div className="result-content">
          <h3>Search results</h3>

          <Button type="button" onClick={handleOpen}>
            <Icon.FilterAlt /> Filters
          </Button>
        </div>

        <div className="export-content">
          <Button type="button" onClick={handleOpen}>
            <Icon.FilterAlt /> Filters
          </Button>


          <Button className="usa-button--outline" type="button" onClick={openResultModel} >
            Edit table columns
          </Button>

          <Button type="button" onClick={openExportModel}>
            Export as CSV
          </Button>
          <Button type="button" onClick={openFeedbackModel}>
            Give feedback
          </Button>
        </div>
      </div>
      <div>
        {showResultModal && (
          <Modal placeholder="" isLarge id={resultModelRef.current.modalId} ref={resultModelRef} isInitiallyOpen={resultModelRef.current.modalIsOpen} className={`padding-0 position-relative`}  onPointerEnterCapture={()=>{}} onPointerLeaveCapture={()=>{}}>
            <div className={`margin-bottom-1 overflow-auto resultpane-model-content`}>
              <p className="font-sans-xl text-bold" >
                Edit result table columns
              </p>
              <div className="margin-top-2 text-ink">
                <p>
                  Choose up to 10 table columns that will appear in your result list and spreadsheet export.
                </p>
                <p className="font-sans-lg text-bold margin-top-1">
                  Default columns
                </p>
                <Checkbox
                  id={"businessName1"}
                  name={"businessName"}
                  label="Business name (Required)"
                  className="margin-y-2"
                  checked={resultModelChecked.some(item => item.name === "businessName")}
                  disabled
                />
                {
                  resultModelCheckBoxData?.map((checkbox: any) => {
                    return (
                      <Checkbox
                        id={checkbox.id}
                        name={checkbox.name}
                        label={checkbox.label}
                        className="margin-y-2"
                        onChange={(e) => handleResultCheckBoxChange(checkbox.name, checkbox.label, e.target.checked,checkbox.id)}
                        checked={resultModelChecked.some(item => item.name === checkbox.name)}
                        disabled={resultModelChecked.length == 10 && resultModelChecked.some(item => item.name !== checkbox.name && !resultModelChecked.some(item => item.name === checkbox.name))}
                        labelDescription={checkbox?.labelDescription}
                      />
                    )
                  })
                }
              </div>

              <div className="padding-y-3">
                {resultModelChecked.length > 1 && <p className="font-sans-xs">SELECTED COLUMNS</p>}

                {resultModelChecked.length > 1 && resultModelChecked?.map((i, index) => {

                  return (
                    <>
                     { index !== 0 && <Checkbox
                        key={index}
                        id={"edit"+'_'+i.id}
                        name={i.name}
                        label={i.label}
                        className="margin-1"
                        checked={resultModelChecked.find(item => item.name === i.name)}
                        onChange={() => handleResultSelectedCheckBoxChange(i.name, i.label ,i.id)}

                      />}
                    </>
                  )
                })}
              </div>
              {modalAccordionProperty.map((sProp, index) => {
                const PropertyComponent = sProp.component || null;
                return (
                  <ControlAccordion
                    key={sProp.name}
                    title={sProp.display}
                    name={sProp.name}
                  >
                    <PropertyComponent handleCheckBoxChange={handleResultCheckBoxChange} checked={resultModelChecked} length={10} name="Result"/>
                  </ControlAccordion>
                );
              })}

            </div>
            <div className="usa-modal__footer bg-accent-cool-lighter position-sticky margin-top-0 padding-0">
              <ul className="usa-button-group flex-justify-end padding-1 padding-x-2">
                <li className="usa-button-group__item">
                  <button
                    type="button"
                    className="usa-button usa-button--unstyled padding-105 text-center"
                    onClick={closeResultModel}
                    data-close-modal
                  >
                    Cancel and close
                  </button>
                </li>
                <li className="usa-button-group__item">
                  <button type="button" className="usa-button"  onClick={handleApplyChanges} >
                    Apply changes
                  </button>
                </li>
              </ul>
            </div>
          </Modal>
        )}
      </div>

      {/* TODO: remove this section when filter flyout buttons are ready. */}
      <div hidden className="filter-preview-section">
      </div>

      <div>
        {showExportModal && (
          <Modal placeholder="" isLarge id={exportModelRef.current.modalId} ref={exportModelRef} className={`padding-0 position-relative`} isInitiallyOpen={exportModelRef.current.modalIsOpen}  onPointerEnterCapture={()=>{}} onPointerLeaveCapture={()=>{}}>
            <div className={`margin-bottom-1 overflow-auto resultpane-model-content`}>
              <p className="font-sans-xl text-bold" >
                Edit export columns
              </p>
              <div className="margin-top-2 text-ink">
                <p>
                  Choose up to 20 table columns that will appear in your spreadsheet export.
                </p>
                <p className="font-sans-lg text-bold margin-top-1">
                  Default columns
                </p>
                {
                  exportModelCheckBoxData?.map((checkbox: any) => {
                    return (
                      <Checkbox
                        id={checkbox.id}
                        name={checkbox.name}
                        label={checkbox.label}
                        className="margin-y-2"
                        onChange={(e) => handleExportTableCheckBoxChange(checkbox.name, checkbox.label, e.target.checked,checkbox.id)}
                        checked={exportTableChecked.some(item => item.name === checkbox.name)}
                        disabled={exportTableChecked.length == 20 && exportTableChecked.some(item => item.name !== checkbox.name && !exportTableChecked.some(item => item.name === checkbox.name))}
                        labelDescription={checkbox?.labelDescription}
                      />
                    )
                  })
                }
              </div>

              <div className="padding-y-3">
                {exportTableChecked.length > 0 && <p className="font-sans-xs">SELECTED COLUMNS</p>}

                {exportTableChecked?.map((i, index) => {
                  return (
                    <>
                      <Checkbox
                        key={index}
                        id={"export"+'_'+i.id}
                        name={i.name}
                        label={i.label}
                        className="margin-1"
                        checked={exportTableChecked.find(item => item.name === i.name)}
                        onChange={() => handleExportTableSelectedCheckBoxChange(i.name, i.label,i.id)}

                      />
                    </>
                  )
                })}
              </div>
              {modalAccordionProperty.map((sProp, index) => {
                const PropertyComponent = sProp.component || null;
                const fields = sProp.display || null;

                return (
                  <ControlAccordion
                    key={sProp.name}
                    title={sProp.display}
                    name={sProp.name}
                  >
                    <PropertyComponent handleCheckBoxChange={handleExportTableCheckBoxChange} checked={exportTableChecked} length={20} name="Export" />
                  </ControlAccordion>
                );
              })}

            </div>
            <div className="usa-modal__footer bg-accent-cool-lighter position-sticky margin-top-0 padding-0">
              <ul className="usa-button-group flex-justify-end padding-1 padding-x-2">
                <li className="usa-button-group__item">
                  <button
                    type="button"
                    className="usa-button usa-button--unstyled padding-105 text-center"
                    onClick={closeExportModel}
                    data-close-modal
                  >
                    Cancel and close
                  </button>
                </li>
                <li className="usa-button-group__item">
                  <button type="button" className="usa-button" onClick={handleExportTableCSV} >
                    Export results to .csv
                  </button>
                </li>

              </ul>
            </div>
          </Modal>
        )}

      </div>
      {/* feedbackmodel */}
      <div>
        {showFeedbackModal && (
          <Modal placeholder="" isLarge id={feedBackModelRef.current.modalId} ref={feedBackModelRef} isInitiallyOpen={feedBackModelRef.current.modalIsOpen} className={`padding-0 position-relative`}  onPointerEnterCapture={()=>{}} onPointerLeaveCapture={()=>{}}>
            <form onSubmit={SendFeedback}>
              <div className={`margin-bottom-1 overflow-auto resultpane-model-content padding-x-4 `}>
                <p className="font-sans-xl text-bold" >
                  Give feedback
                </p>
                <div className="usa-prose margin-top-2 text-ink">
                <p style={{ maxWidth: '100%' }}>
                  Report a bug, request a feature, ask a question, or share your thoughts about Small Business Search. If you provide your contact information, our team may follow up for more details.
                  </p>
                  <i style={{ maxWidth: '100%', marginTop :"10px",display : "block"}}>
                    A red asterisk(<span className="text-secondary">*</span>) indicates a required field
                  </i>
                  <div>

                    <p >
                      What would you like us to know? <span className="text-secondary">*</span>
                    </p>
                    <Textarea id="feedback" name="feedback" value={feedFormValue.feedback} placeholder="Type your feedback here." className="maxw-full radius-lg" style={{backgroundColor: "#f8f8ee"}} onChange={handleFeedbackchange} />
                    {feedBackErrors && <p className="text-secondary-vivid">This field is required</p>}

                  </div>
                  <div>
                    <Radio id="send-anonymously" name="send-anonymously" label="Send anonymously" className="padding-left-1" checked={selectOption} onChange={handleOptionChange} />
                    <Radio id="send-information" name="send-information" label="Send with contact information" className="padding-left-1" checked={selectOption1} onChange={handleOptionChange} />
                    {!selectOption && (<div className="display-flex flex-row flex-justify-center maxw-full margin-bottom-2">
                      <div className="width-full margin-right-1 margin-left-5">
                        <Label htmlFor="vehicle">Your name <span className="text-secondary">*</span></Label>
                        <TextInput id="name" name="name" type="text" value={feedFormValue.name}  style={{backgroundColor: "#f8f8ee"}} className="maxw-full radius-lg" onChange={handleFeedbackchange}/>
                        {nameErrors && <p className="text-secondary-vivid">Name is required</p>}
                      </div>
                      <div className="width-full margin-left-1">
                        <Label htmlFor="vehicle">Your email address <span className="text-secondary">*</span></Label>
                        <TextInput id="email" name="email" type="email" value={feedFormValue.email}  style={{backgroundColor: "#f8f8ee"}} className="radius-lg" aria-required onChange={handleFeedbackchange} />
                        {emailErrors && <p className="text-secondary-vivid">Email is required</p>}
                        {isvalid && <p className="text-secondary-vivid">Please enter valid email</p>}

                        {/* <p>enter valid email</p> */}

                      </div>
                    </div>)}
                     {/* <p className="margin-y-2"> <span className="text-secondary">*</span> Required fields</p> */}
                  </div>
                </div>


              </div>
              <div className="usa-modal__footer bg-accent-cool-lighter position-sticky margin-top-0 padding-0">
                <ul className="usa-button-group flex-justify-end padding-1 padding-x-2">
                  <li className="usa-button-group__item">
                    <button
                      type="button"
                      className="usa-button usa-button--unstyled padding-105 text-center"
                      onClick={CloseFeedbackModel}
                      data-close-modal
                      disabled={isFeedbackLoading}
                    >
                      Cancel
                    </button>
                  </li>
                  <li className="usa-button-group__item">
                    <button type="button" className="usa-button" onClick={SendFeedback} disabled={isFeedbackLoading} >
                      {!isFeedbackLoading ? "Send feedback" : "Sending..."}
                    </button>
                  </li>

                </ul>
              </div>
            </form>
          </Modal>
        )}

      </div>
      {/* <div className="filter-preview-section">
        <div className="header-content">
          {totalAppliedCount === 0 ? (
            <h4>No applied filters</h4>
          ) : (
            <h4>Applied filters {`(${totalAppliedCount})`}</h4>
          )}
          {!isExpand ? (
            <Icon.Add size={3} onClick={handleExpandOpen} />
          ) : (
            <Icon.Remove size={3} onClick={handleExpandClose} />
          )}
        </div>

        {isExpand && (
          <div className="preview-section">
            {totalAppliedCount === 0 && (
              <div className="no-filter">
                <Icon.ArrowBack size={3} />
                <p>Choose and apply filters to search for small businesses.</p>
              </div>
            )}

            {totalAppliedCount > 0 && (
              <div>
                <div className="clear">
                  <span>
                    Results match <b>all</b> of these.
                  </span>

                  <Button type="button" unstyled onClick={handleClearFilter}>
                    Clear all filters
                  </Button>
                </div>

                <Masonry
                  breakpointCols={breakpoint}
                  className="masonry-grid"
                  columnClassName="masonry-grid_column"
                >
                  {appliedfilters.map((filter) => (
                    <AppliedCard key={filter.category} optionData={filter} />
                  ))}
                </Masonry>
              </div>
            )}
          </div>
        )}
      </div> */}

      {totalResultsCount > 0 && (
        <>
          <div className={"result-total"}>
            <h3>
              There are <strong>{total}</strong> small businesses
            </h3>
          </div>

          {/* <AppliedFilterSentence /> */}
        </>
      )}

      <div className="sort-section">
        { totalResultsCount > 0 &&  (
          <div className="left-section-listing">
            <h6>
              Listing {itemsPerPage} of <b>{total}</b> results
              {findSearchKeywordShow ? (' containing ' + `${findSearchKeywordShow}`) : ''}
            </h6>
            {totalAppliedCount > 0 && <button onClick={handleClearFilter}>Clear search</button>}
          </div>
        )}
        <div className="right-section-dropdown">
          <h6>Sort by</h6>
          <div className="select-box">
            <select
              value={sortType}
              onChange={(e) => setSortType(e.target.value)}
            >
              <option value="random_order">Random order</option>
              <option value="alphabetical">
                Alphabetical by small business name
              </option>
              <option value="recently_updated">Recently updated</option>
            </select>
            <div className="down-arrow">
              <DownArrow />
            </div>
          </div>
        </div>
      </div>
      { totalResultsCount > 0 ? (
        <ResultTable
          data={businessList}
          sortType={sortType}
          setItemsPerPage={setItemsPerPage}
        />
      ) : (
        <div className="left-section-noresult">
          <h3>Try removing some filters or changing your search</h3>
          <h6>No results found</h6>
          <button onClick={handleClearFilter}>
            Clear all filters sections
          </button>
        </div>
      )}
    </div>
  );
});

export default ResultsPaneNew;
