import { useState } from "react";

// components
import {
  Heading,
  RoundedButton,
  DefaultDialog,
  Dialog,
  DialogTrigger,
  DialogHeading,
  DialogDescription,
  DialogClose,
  DialogContent,
} from "components/LibraryComponents/Components";

// styles
import styles from "./DialogBlock.module.scss";

export default function DialogBlock() {
  return (
    <div className={styles["dialog-block-root"]}>
      <Heading title="Dialog" />

      <div style={{ marginTop: 48 }} />

      <div className={styles["group"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default</span>
          <DefaultDialogDemo />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>UnControlled</span>
          <UnControlledDialog />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Controlled</span>
          <ControlledDialog />
        </div>
      </div>
    </div>
  );
}

function DefaultDialogDemo() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <RoundedButton variant="secondary" onClick={() => setOpen(true)}>
        Default Dialog
      </RoundedButton>

      <DefaultDialog
        open={open}
        heading="Default Dialog"
        setOpen={setOpen}
        handleConfirm={() => setOpen(false)}
      >
        Dialog content
      </DefaultDialog>
    </div>
  );
}

function UnControlledDialog() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <RoundedButton variant="secondary">Trigger Dialog</RoundedButton>
      </DialogTrigger>
      <DialogContent hasBackdrop className={styles["dialog-content-style"]}>
        <DialogHeading>Uncontrolled Dialog</DialogHeading>
        <DialogDescription>Description</DialogDescription>
        <DialogClose>Close</DialogClose>
      </DialogContent>
    </Dialog>
  );
}

function ControlledDialog() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <RoundedButton variant="secondary" onClick={() => setOpen(true)}>
        Trigger Dialog
      </RoundedButton>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent hasBackdrop className={styles["dialog-content-style"]}>
          <DialogHeading>controlled Dialog</DialogHeading>
          <DialogDescription>Description</DialogDescription>
          <DialogClose>Close</DialogClose>
        </DialogContent>
      </Dialog>
    </>
  );
}
