import { forwardRef } from "react";
import classNames from "classnames";

import styles from "./RoundedButton.module.scss";

type RoundedButtonProps = {
  variant?: "primary" | "secondary" | "ghost";
} & JSX.IntrinsicElements["button"];

/**
 * `RoundedButton` component is used to display button with rounded corners.
 *
 * @param {RoundedButtonProps} props - The props for the `RoundedButton` component.
 * @param {React.ReactElement} props.variant - The variant of the button can be (primary, secondary, ghost) (defult: primary).
 * @param {string} props.className - The class name for the root element (Optional).
 */
const RoundedButton = forwardRef<HTMLButtonElement, RoundedButtonProps>(
  ({ variant = "primary", className, ...props }, ref) => {
    const buttonClasses = classNames(styles["rounded-button-root"], {
      [styles["primary"]]: variant === "primary",
      [styles["secondary"]]: variant === "secondary",
      [styles["ghost"]]: variant === "ghost",
      [className]: className,
    });

    return <button ref={ref} className={buttonClasses} {...props} />;
  }
);

export default RoundedButton;
