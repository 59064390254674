import styles from "./ToggleBlock.module.scss";

// components
import { Heading, Subheading, Toggle } from "../../Components";

// simulation components
import { AutoFocusToggle, AutoHoverToggle } from "../../Components/Toggle";

export default function ToggleBlock() {
  return (
    <div className={styles["toggle-block-root"]}>
      <Heading title="Toggle" />

      <div style={{ marginTop: "24px" }} />

      {/* Selected Toggle */}
      <Subheading title="Selected" />

      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default</span>
          <Toggle defaultChecked />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Hover</span>
          <AutoHoverToggle checked />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Focus</span>
          <AutoFocusToggle checked />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Disabled</span>
          <Toggle disabled />
        </div>
      </div>

      {/* Unselected Toggle */}
      <div style={{ marginTop: "24px" }} />

      <Subheading title="Unselected" />

      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default</span>
          <Toggle />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Hover</span>
          <AutoHoverToggle checked={false} />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Focus</span>
          <AutoFocusToggle checked={false} />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Disabled</span>
          <Toggle disabled />
        </div>
      </div>
    </div>
  );
}
