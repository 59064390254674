// styles
import styles from "./NaicsCodes.module.scss";

import { Icon } from "@trussworks/react-uswds";
import classNames from "classnames";

// store
import { useSearchStore } from "store/searchStore";

// types
import { NaicsProfile } from "types/profile.types";

// components
import DisplayValue from "../DisplayValue";
import { SimpleToopTip } from "components/Tooltip";

const naicsTestData: NaicsProfile[] = [
  {
    code: "237990",
    description: "Other Heavy and Civil Engineering Construction",
    is_primary: true,
    small: true,
    buy_green: false,
    exception_number: 0,
  },
  {
    code: "237990",
    description: "Other Heavy and Civil Engineering Construction",
    exception: "Dredging and Surface Cleanup Activities",
    is_primary: true,
    small: true,
    buy_green: false,
    exception_number: 1,
  },
  {
    code: "113310",
    description: "Logging",
    is_primary: false,
    small: true,
    buy_green: false,
    exception_number: 0,
  },
  {
    code: "115112",
    description: "Soil Preparation, Planting, and Cultivating",
    is_primary: false,
    small: false,
    buy_green: false,
    exception_number: 0,
  },
  {
    code: "115310",
    description: "Support Activities for Forestry",
    is_primary: false,
    small: false,
    buy_green: false,
    exception_number: 0,
  },
  {
    code: "115310",
    description: "Support Activities for Forestry",
    exception: "Forest Fire Suppression - for one, two, thre",
    is_primary: false,
    small: true,
    buy_green: false,
    exception_number: 1,
  },
  {
    code: "115310",
    description: "Support Activities for Forestry",
    exception: "Fuels Management Services",
    is_primary: false,
    small: true,
    buy_green: true,
    exception_number: 1,
  },
];

export default function NaicsCodes() {
  const {
    business_profile: { naics },
  } = useSearchStore();

  const tableClasses = classNames(
    "usa-table",
    "usa-table--striped",
    styles["naics-table"]
  );

  const primaryTagClasses = classNames("usa-tag", styles["primary"]);

  return (
    <>
      <h1>NAICS Codes</h1>

      <DisplayValue
        value={naics}
        message="This firm does not have any NAICS codes listed"
        renderer={(_value) => (
          <>
            <table className={tableClasses}>
              <thead>
                <tr>
                  <th scope="col">Code</th>
                  <th scope="col">NAICS Code Description</th>
                  <th scope="col">
                    <SimpleToopTip
                      title={`If Yes, the firm's revenues/number of employees do not exceed the NAICS code's small business size standard.`}
                      placement="top"
                    >
                      <div className={styles["col-group"]}>
                        <span>Small</span>
                        <Icon.InfoOutline />
                      </div>
                    </SimpleToopTip>
                  </th>
                </tr>
              </thead>
              <tbody>
                {naics &&
                  naics.map((naics: NaicsProfile, index) => (
                    <tr key={index}>
                      <td scope="row" style={{ verticalAlign: "text-top" }}>
                        {naics?.code}
                      </td>

                      <td>
                        {naics?.is_primary && (
                          <span className={primaryTagClasses}>Primary</span>
                        )}
                        <p className={styles["heading"]}>
                          {naics.description}
                        </p>
                        {naics?.exception_number > 0 &&
                          <p className={styles["description"]}>
                            <span>{naics?.exception}</span>
                            <SimpleToopTip
                              title={
                                <span>
                                  The size standards can depend on
                                  subcategories within a NAICS code.
                                  For more information, see{" "}
                                  <a
                                    href="https://www.sba.gov/document/support-table-size-standards"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    the SBA’s size standards table
                                    here.
                                  </a>
                                </span>
                              }
                              placement="top"
                              className={styles["tooltip-wrapper"]}
                            >
                              <div
                                className={styles["size-exception"]}
                              >
                                <span className={"usa-tag"}>
                                  Size standard exception
                                </span>
                                <Icon.InfoOutline />
                              </div>
                            </SimpleToopTip>
                          </p>
                        }
                        {naics?.buy_green && (
                          <SimpleToopTip
                            title={`By entering Yes for "Buy Green", the firm asserts that it obeys EPA guidelines for environmental friendliness for this NAICS code. Note, EPA guidelines do not exist for every NAICS code.`}
                            placement="top"
                          >
                            <div className={styles["is-buy"]}>
                              <Icon.Check />
                              <span>Buy Green</span>
                              <Icon.InfoOutline />
                            </div>
                          </SimpleToopTip>
                        )}
                      </td>

                      <td>
                        <div
                          className={styles["check-group"]}
                          style={{
                            color: naics?.small ? "#538200" : "#565C65",
                          }}
                        >
                          {naics?.small ? <Icon.Check /> : <Icon.Cancel />}
                          <span>{naics?.small ? "Yes" : "No"}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
      />
    </>
  );
}
