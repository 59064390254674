import moment from "moment";

// types
import {
  AccordionBusinessItem,
  BusinessItem,
  ResultPanel,
} from "../types/results-panel.types";
import { AppliedFilter } from "../types/applied-filter.types";
import { BusinessProfile } from "store/searchStore";

// stores
import { FilterState } from "../store/useFilterStore";

// constants
import { FilterKeys, FilterTitles } from "../constants/SearchProperties";

// data
import { headerMapping, resultsPanelData } from "../data/result-panel.data";

export function formatDateToYYYYMMDD(date: Date) {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}${month}${day}`;
}

const serviceInformationFields = [
  "cbona",
  "cbonc",
  "sbonc",
  "sbona",
  "special_equipment",
  "keywords",
];

const certificationStatuses = {
  certStatus: "cert_active",
  certDateStart: "start_date",
  certDateExit: "exit_date",
};

const certificationTypes = {
  "8a": "8(a) Certification",
  "8aJV": "8(a) Joint Venture",
  WOSB: "Women-Owned Small Business (WOSB)",
  EDWOSB: "Ecnonomically Disadvantaged Women-Owned Small Business (EDWOSB)",
  VOSB: "Veteran-Owned Small Business",
  SDVOSB: "Service-Disabled Veteran-Owned Small Business",
  HUBZone: "HUBZone Certification",
  VOSBJV: "Veteran-Owned Small Business Joint Venture",
  SDVOSBJV: "Service-Disabled Veteran-Owned Small Business Joint Venture",
};

const certStatusTypes = Object.keys(certificationStatuses).flatMap((status) =>
  Object.keys(certificationTypes).map((type) => `${status}_${type}`)
);

export function transformedResultsData(results: ResultPanel[]) {
  return results.map((entry) => {
    const newEntry = {};

    for (const key in entry) {
      if (headerMapping[key]) {
        newEntry[headerMapping[key]] = entry[key];
      } else {
        newEntry[key] = entry[key];
      }
    }

    return newEntry;
  });
}

export function selectedEditTableResultsData(results: ResultPanel[]) {
  return results.map((entry) => {
    const newEntry = {};

    for (const key in entry) {
      if (headerMapping[key]) {
        newEntry[headerMapping[key]] = entry[key];
      } else {
        newEntry[key] = entry[key];
      }
    }

    return newEntry;
  });
}
export function selectedexportTableResultsData(
  exportableCols,
  businessList: BusinessItem[]
) {
  const colNames = exportableCols.reduce((acc, cur) => {
    if (cur.name === "businessName") {
      acc[cur.name] = "Business name";
    } else if (cur.name === "capabilitiesNarrative") {
      acc[cur.name] = "Capabilities narrative";
      acc["capabilitiesStatement"] = "Capabilities statement link";
    } else if (cur.name === "contact") {
      acc[cur.name] = "Contact person's name";
      acc["email"] = "Contact person's email";
    } else if (cur.name === "address") {
      if (!acc[cur.name]) {
        acc[cur.name] = {};
      }

      acc[cur.name]["line1"] = "Street address line 1";
      acc[cur.name]["line2"] = "Street address line 2";
      acc[cur.name]["city"] = "City";
      acc[cur.name]["state"] = "State";
      acc[cur.name]["zip"] = "Zip code";
    } else {
      acc[cur.name] = cur.label;
    }

    return acc;
  }, {});

  const exportData = businessList.map((item: BusinessItem) => {
    const colData = {};

    for (const key in colNames) {
      if (
        item[key] ||
        key === "capabilitiesStatement" ||
        serviceInformationFields.includes(key) ||
        certStatusTypes.includes(key)
      ) {
        if (key === "active_certifications") {
          colData[colNames[key]] = item[key]
            .filter((cert) => cert?.cert_active === "A")
            .map((cert) => refineCertificationTags(cert.name, false).name)
            .join(", ");
        } else if (key === "address") {
          colData[colNames[key]["line1"]] = item[key].line1;
          colData[colNames[key]["line2"]] = item[key].line2;
          colData[colNames[key]["city"]] = item[key].city;
          colData[colNames[key]["state"]] = item[key].state;
          colData[colNames[key]["zip"]] = item[key].zip;
        } else if (key === "current_principals") {
          colData[colNames[key]] = item[key].map(
            (principal) => `${principal.name}, ${principal.title}`
          );
        } else if (key === "capabilitiesStatement") {
          colData[colNames[key]] = getCapabilitiesNarrativeLink(item);
        } else if (serviceInformationFields.includes(key)) {
          colData[colNames[key]] = item.service_information[key];
        } else if (certStatusTypes.includes(key)) {
          const certInfo = key.split("_")[0];
          const certType = key.split("_")[1];
          let certStatus = [certificationStatuses[certInfo]];
          let value = item.active_certifications.find(
            (cert) => cert.name === certificationTypes[certType]
          )?.[certificationStatuses[certInfo]];
          if (value === "A") value = "Active";
          else if (value === "X") value = "Expired";
          else if (value === "I") value = "Expired";

          colData[colNames[key]] = value;
        } else {
          colData[colNames[key]] = item[key];
        }
      } else {
        colData[colNames[key]] = null;
      }
    }

    return colData;
  });

  return exportData;
}

export function getUnAppliedFilterOptionsCount(
  filter: FilterState["filters"],
  name: string
) {
  switch (name) {
    case FilterKeys.QUALITY_ASSURANCE_STANDARDS: {
      return filter[name].standards.filter((item: any) => !item.isApplied)
        .length;
    }

    case FilterKeys.NATURE_OF_BUSINESS: {
      return filter[name].business.filter((item: any) => !item.isApplied)
        .length;
    }

    case FilterKeys.BONDING_LEVELS: {
      return Object.keys(filter[name].level).filter(
        (key) =>
          filter[name].level[key].value !== "" &&
          !filter[name].level[key].isApplied
      ).length;
    }

    case FilterKeys.PROFILE_STATUS: {
      const lastUpdatedOnCount =
        filter[name].lastUpdatedOn?.value &&
        !filter[name].lastUpdatedOn.isApplied
          ? 1
          : 0;

      const statusCount =
        filter[name].status?.value && !filter[name].status.isApplied ? 1 : 0;

      return lastUpdatedOnCount + statusCount;
    }

    case FilterKeys.PRIVILEGED_SEARCH: {
      return Object.keys(filter[name]).filter(
        (key) => filter[name][key].value !== "" && !filter[name][key].isApplied
      ).length;
    }

    case FilterKeys.CAPABILITY_KEYWORDS: {
      const keywordsCount = filter[name].keywords.filter(
        (keyword: any) => !keyword.isApplied
      ).length;

      const keywordRelatedCount =
        filter[name].keywordRelated?.value &&
        !filter[name].keywordRelated.isApplied
          ? 1
          : 0;

      return keywordsCount;
    }

    case FilterKeys.SMALL_BUSINESS_PROFILE: {
      return filter[name]
        ? [filter[name]].filter((item: any) => !item.isApplied).length
        : 0;
    }

    case FilterKeys.LOCATION: {
      return filter[name].states.filter((item: any) => !item.isApplied).length;
    }

    case FilterKeys.SBA_CERTIFICATIONS: {
      const activeCount = filter[name].activeCertification.filter(
        (item: any) => !item.isApplied
      );
      const previousCount = filter[name].previousCertification.filter(
        (item: any) => !item.isApplied
      );

      return activeCount.length + previousCount.length;
    }

    case FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS: {
      const selfCertificationsCount = filter[name].selfCertifications.filter(
        (item) => !item.isApplied
      );

      const womanOwnedCount = filter[name].selfCertifiedWomanOwned.filter(
        (item) => !item.isApplied
      );

      const nativeCount = filter[
        name
      ].anyMinorityOwned.anyNativeAmericanOwner.filter(
        (item) => !item.isApplied
      );

      const otherCount =
        filter[name].anyMinorityOwned.otherMinorityOwned?.value &&
        !filter[name].anyMinorityOwned.otherMinorityOwned.isApplied
          ? 1
          : 0;

      return (
        selfCertificationsCount.length +
        womanOwnedCount.length +
        nativeCount.length +
        otherCount
      );
    }

    case FilterKeys.CAPABILITIES: {
      let count = 0;

      filter[name].naics.forEach((item) => {
        if (!item.isApplied) {
          count += item.ids.length;
        }
      });

      return count;
    }
    case FilterKeys.SEARCH_BUSINESS: {
      return filter[name].filter((item: any) => item.isApplied).length;
    }

    default:
      return 0;
  }
}

export function getAppliedFilterOptionsCount(
  filter: FilterState["filters"],
  name: string
) {
  switch (name) {
    case FilterKeys.QUALITY_ASSURANCE_STANDARDS: {
      return filter[name].standards.filter((item: any) => item.isApplied)
        .length;
    }

    case FilterKeys.NATURE_OF_BUSINESS: {
      return filter[name].business.filter((item: any) => item.isApplied).length;
    }

    case FilterKeys.BONDING_LEVELS: {
      return Object.keys(filter[name].level).filter(
        (key) =>
          filter[name].level[key].value !== "" &&
          filter[name].level[key].isApplied
      ).length;
    }

    case FilterKeys.PROFILE_STATUS: {
      const lastUpdatedOnCount =
        filter[name].lastUpdatedOn?.value &&
        filter[name].lastUpdatedOn.isApplied
          ? 1
          : 0;

      const statusCount =
        filter[name].status?.value && filter[name].status.isApplied ? 1 : 0;

      return lastUpdatedOnCount + statusCount;
    }

    case FilterKeys.PRIVILEGED_SEARCH: {
      return Object.keys(filter[name]).filter(
        (key) => filter[name][key].value !== "" && filter[name][key].isApplied
      ).length;
    }

    case FilterKeys.CAPABILITY_KEYWORDS: {
      const keywordsCount = filter[name].keywords.filter(
        (keyword: any) => keyword.isApplied
      ).length;

      const keywordRelatedCount =
        filter[name].keywordRelated?.value &&
        filter[name].keywordRelated.isApplied
          ? 1
          : 0;

      return keywordsCount;
    }

    case FilterKeys.SMALL_BUSINESS_PROFILE: {
      return filter[name].filter((item: any) => item.isApplied).length;
    }

    case FilterKeys.LOCATION: {
      return filter[name].states.filter((item: any) => item.isApplied).length;
    }

    case FilterKeys.SBA_CERTIFICATIONS: {
      const activeCount = filter[name].activeCertification.filter(
        (item: any) => item.isApplied
      );
      const previousCount = filter[name].previousCertification.filter(
        (item: any) => item.isApplied
      );

      return activeCount.length + previousCount.length;
    }

    case FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS: {
      const selfCertificationsCount = filter[name].selfCertifications.filter(
        (item) => item.isApplied
      );

      const womanOwnedCount = filter[name].selfCertifiedWomanOwned.filter(
        (item) => item.isApplied
      );

      const nativeCount = filter[
        name
      ].anyMinorityOwned.anyNativeAmericanOwner.filter(
        (item) => item.isApplied
      );

      const otherCount =
        filter[name].anyMinorityOwned.otherMinorityOwned?.value &&
        filter[name].anyMinorityOwned.otherMinorityOwned.isApplied
          ? 1
          : 0;

      return (
        selfCertificationsCount.length +
        womanOwnedCount.length +
        nativeCount.length +
        otherCount
      );
    }

    case FilterKeys.CAPABILITIES: {
      let count = 0;

      filter[name].naics?.forEach((item) => {
        if (item.isApplied) {
          count += item.ids.length;
        }
      });

      return count;
    }
    case FilterKeys.SEARCH_BUSINESS: {
      return filter[name]
        ? [filter[name]].filter((item: any) => item.isApplied).length
        : 0;
    }

    default:
      return 0;
  }
}

export function getTotalAppliedFilterOptionsCount(
  filter: FilterState["filters"]
) {
  let totalAppliedCount = 0;

  const filterCategories = Object.keys(filter) as Array<
    keyof FilterState["filters"]
  >;

  filterCategories.forEach((category) => {
    totalAppliedCount += getAppliedFilterOptionsCount(filter, category);
  });

  return totalAppliedCount;
}

export function getTotalFilterOptionsCount(
  filter: FilterState["filters"],
  name: string
) {
  switch (name) {
    case FilterKeys.QUALITY_ASSURANCE_STANDARDS: {
      return filter[name].standards.length;
    }

    case FilterKeys.NATURE_OF_BUSINESS: {
      return filter[name].business.length;
    }

    case FilterKeys.BONDING_LEVELS: {
      return Object.keys(filter[name].level).filter(
        (key) => filter[name].level[key].value !== ""
      ).length;
    }

    case FilterKeys.PROFILE_STATUS: {
      const lastUpdatedOnCount = filter[name].lastUpdatedOn?.value ? 1 : 0;

      const statusCount = filter[name].status?.value ? 1 : 0;

      return lastUpdatedOnCount + statusCount;
    }

    case FilterKeys.PRIVILEGED_SEARCH: {
      return Object.keys(filter[name]).filter(
        (key) => filter[name][key].value !== ""
      ).length;
    }

    case FilterKeys.CAPABILITY_KEYWORDS: {
      const keywordsCount = filter[name].keywords.length;
      return keywordsCount;
    }

    case FilterKeys.SMALL_BUSINESS_PROFILE: {
      return filter[name].length;
    }

    case FilterKeys.LOCATION: {
      return filter[name].states.length;
    }

    case FilterKeys.SBA_CERTIFICATIONS: {
      const activeCount = filter[name].activeCertification;
      const previousCount = filter[name].previousCertification;

      return activeCount.length + previousCount.length;
    }

    case FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS: {
      const selfCertificationsCount = filter[name].selfCertifications;

      const womanOwnedCount = filter[name].selfCertifiedWomanOwned;

      const nativeCount = filter[name].anyMinorityOwned.anyNativeAmericanOwner;

      const otherCount =
        filter[name].anyMinorityOwned.otherMinorityOwned?.value &&
        filter[name].anyMinorityOwned.otherMinorityOwned.isApplied
          ? 1
          : 0;

      return (
        selfCertificationsCount.length +
        womanOwnedCount.length +
        nativeCount.length +
        otherCount
      );
    }

    case FilterKeys.CAPABILITIES: {
      let count = 0;

      filter[name]?.naics?.forEach((item) => {
        count += item.ids.length;
      });

      return count;
    }
    case FilterKeys.SEARCH_BUSINESS: {
      return filter[name].filter((item: any) => item.isApplied).length;
    }

    default:
      return 0;
  }
}

export function getAppliedFilterOptions(
  filter: FilterState["filters"]
): AppliedFilter[] {
  const filterCategories = Object.keys(filter) as Array<
    keyof FilterState["filters"]
  >;

  const appliedFilters: AppliedFilter[] = filterCategories
    .filter((category) => getAppliedFilterOptionsCount(filter, category) > 0)
    .map((category) => ({
      label: "",
      category: category,
      name: FilterTitles[category],
      options: [] as string[],
      resultStatus: "",
      values: [] as string[],
      matchType: "",
      matchLimit: null,
    }));

  const appliedFilterOptions: AppliedFilter[] = appliedFilters.map(
    (appFilter: AppliedFilter) => {
      switch (appFilter.category) {
        case FilterKeys.QUALITY_ASSURANCE_STANDARDS: {
          const options: string[] = filter.qualityAssuranceStandards.standards
            .filter((item: any) => item.isApplied)
            .map((item: any) => item.value);

          return {
            ...appFilter,
            options,
            resultStatus: `Results can match <b>any</b> of these items.`,
          };
        }

        case FilterKeys.BONDING_LEVELS: {
          const options: string[] = Object.keys(filter.bondingLevels.level)
            .filter((key) => filter.bondingLevels.level[key].isApplied)
            .map((key) => {
              switch (key) {
                case "construction_individual":
                  return `Minimum construction bonding level (individual): $${filter.bondingLevels.level[key].value}`;
                case "construction_aggregate":
                  return `Minimum construction bonding level (aggregate): $${filter.bondingLevels.level[key].value}`;
                case "service_individual":
                  return `Minimum service bonding level (individual): $${filter.bondingLevels.level[key].value}`;
                case "service_aggregate":
                  return `Minimum service bonding level (aggregate): $${filter.bondingLevels.level[key].value}`;
                default:
                  return "";
              }
            });

          const values: any = [
            {
              construction: {
                individual:
                  filter.bondingLevels.level?.construction_individual?.value ??
                  null,
                aggregate:
                  filter.bondingLevels.level?.construction_aggregate?.value ??
                  null,
              },
              service: {
                individual:
                  filter.bondingLevels.level?.service_individual?.value ?? null,
                aggregate:
                  filter.bondingLevels.level?.service_aggregate?.value ?? null,
              },
            },
          ];

          return {
            ...appFilter,
            options,
            values,
            resultStatus: `Results can match <b>any</b> of these amounts.`,
            matchType: "Or",
          };
        }

        case FilterKeys.NATURE_OF_BUSINESS: {
          const options: string[] = filter.natureOfBusiness.business
            .filter((item: any) => item.isApplied)
            .map((item: any) => item.value);

          return {
            ...appFilter,
            options,
            values: options,
            resultStatus: `Results can match <b>any</b> of these items.`,
            matchType: "Or",
          };
        }

        case FilterKeys.PROFILE_STATUS: {
          const options: string[] = [];
          let lastUpdatedValue: string | null = null;
          let statusValue = "";

          if (filter.profileStatus.status?.isApplied) {
            const statusValue =
              filter.profileStatus.status.value === "profile-active"
                ? "Active"
                : filter.profileStatus.status.value === "profile-expired"
                ? "Expired"
                : filter.profileStatus.status.value === null
                ? "null"
                : "Active or Expired";

            options.push(statusValue);
          }

          if (filter.profileStatus.lastUpdatedOn?.isApplied) {
            const dateValue = filter.profileStatus.lastUpdatedOn.value || null;
            options.unshift(dateValue);
          }
          const optionObj = {
            date: filter.profileStatus.lastUpdatedOn.value,
            status: !filter.profileStatus.status.value
              ? "null"
              : filter.profileStatus.status.value === "profile-expired"
              ? "X"
              : filter.profileStatus.status.value === "profile-either"
              ? "A,X"
              : "null",
          };

          const values = [
            {
              date: lastUpdatedValue,
              status: statusValue,
            },
          ];

          return {
            ...appFilter,
            options: optionObj,
            resultStatus: `Or`,
          };
        }

        case FilterKeys.CAPABILITY_KEYWORDS: {
          const options: string[] = filter.capabilityKeywords.keywords
            .filter((item: any) => item.isApplied)
            .map((item: any) => item.value);

          let resultStatus =
            filter.capabilityKeywords.keywordRelated.isApplied &&
            filter.capabilityKeywords.keywordRelated?.value ===
              "keyword-relate-and"
              ? `Results must include <b>all</b> of these.`
              : `Results can include <b>any</b> of these.`;

          const matchType =
            filter.capabilityKeywords.keywordRelated.isApplied &&
            filter.capabilityKeywords.keywordRelated?.value ===
              "keyword-relate-and"
              ? "And"
              : "Or";

          return {
            ...appFilter,
            options,
            values: options,
            resultStatus,
            matchType,
          };
        }

        case FilterKeys.PRIVILEGED_SEARCH: {
          const options: string[] = Object.keys(filter.privilegedSearch)
            .filter((key) => filter.privilegedSearch[key].isApplied)
            .map((key) => {
              switch (key) {
                case "min_employee":
                  return `Minimum number of employees: ${filter.privilegedSearch[key].value}`;
                case "max_employee":
                  return `Maximum number of employees: ${filter.privilegedSearch[key].value}`;
                case "min_revenue":
                  return `Minimum revenue: $${filter.privilegedSearch[key].value}`;
                case "max_revenue":
                  return `Maximum revenue: $${filter.privilegedSearch[key].value}`;
                case "ein":
                  return `EIN: ${filter.privilegedSearch[key].value}`;
                default:
                  return "";
              }
            });

          return {
            ...appFilter,
            options,
            resultStatus: `Results can match <b>any</b> of these criteria.`,
          };
        }

        case FilterKeys.SMALL_BUSINESS_PROFILE: {
          const options: string[] = filter.smallBusinessProfile
            .filter((item: any) => item.isApplied)
            .map((item: any) => item.value);

          return {
            ...appFilter,
            options,
            resultStatus: `Results can include <b>any</b> of these.`,
          };
        }

        case FilterKeys.LOCATION: {
          const options: string[] = filter.location.states
            .filter((item: any) => item.isApplied)
            .map((item: any) => `State: ${item.value.slice(0, 2)}`);

          const values: string[] = filter.location.states
            .filter((item: any) => item.isApplied)
            .map((item: any) => item.value.slice(0, 2));

          return {
            ...appFilter,
            options,
            values,
            resultStatus: `Results can match <b>any</b> of these locations.`,
            matchType: "Or",
          };
        }

        case FilterKeys.SBA_CERTIFICATIONS: {
          const active: string[] = [];
          const Previous: string[] = [];

          if (filter.sbaCertifications.activeCertification.length <= 6) {
            filter.sbaCertifications.activeCertification
              .filter((item: any) => item.isApplied)
              .forEach((item: any) => active.push(item.value));
          } else {
            active.push("Any active SBA Certification (6)");
          }

          if (filter.sbaCertifications.previousCertification.length <= 6) {
            filter.sbaCertifications.previousCertification
              .filter((item: any) => item.isApplied)
              .forEach((item: any) => Previous.push(item.value));
          } else {
            Previous.push("Any previous SBA Certification (6)");
          }

          let matchType =
            filter.sbaCertifications.certificationRelate.isApplied &&
            filter.sbaCertifications.certificationRelate?.value ===
              "certifications-and"
              ? `AND`
              : `Or`;
          let resultStatus =
            filter.sbaCertifications.certificationRelate.isApplied &&
            filter.sbaCertifications.certificationRelate?.value ===
              "certifications-and"
              ? `Results must match <b>all</b> of these.`
              : `Results can match <b>any</b> of these.`;

          const options = [
            {
              active: active,
              previous: Previous,
            },
          ];
          const label: string[] = [];
          if (filter.sbaCertifications.activeCertification.length <= 6) {
            filter.sbaCertifications.activeCertification.forEach((item: any) =>
              label.push(item.label)
            );
          } else {
            label.push("Any active SBA Certification (6)");
          }

          if (filter.sbaCertifications.previousCertification.length <= 6) {
            filter.sbaCertifications.previousCertification.forEach(
              (item: any) => label.push(item.label)
            );
          } else {
            label.push("Any previous SBA Certification (6)");
          }

          return {
            ...appFilter,
            options,
            values: options as any,
            label: label,
            resultStatus,
            matchType,
          };
        }

        case FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS: {
          const options: string[] = [];

          filter.ownershipSelfCerts.selfCertifications
            .filter((item) => item.isApplied)
            .forEach((item: any) => options.push(item.value));

          if (filter.ownershipSelfCerts.selfCertifiedWomanOwned.length < 3) {
            filter.ownershipSelfCerts.selfCertifiedWomanOwned
              .filter((item) => item.isApplied)
              .forEach((item: any) => options.push(item.value));
          } else {
            options.push("Any Self-Certified Woman-Owned Small Business (3)");
          }

          if (
            filter.ownershipSelfCerts.anyMinorityOwned.otherMinorityOwned
              .isApplied &&
            filter.ownershipSelfCerts.anyMinorityOwned.otherMinorityOwned?.value
          ) {
            if (
              filter.ownershipSelfCerts.anyMinorityOwned.anyNativeAmericanOwner
                .length === 3
            ) {
              options.push("Any minority-owned (4)");
            } else {
              options.push(
                filter.ownershipSelfCerts.anyMinorityOwned.otherMinorityOwned
                  ?.value
              );

              filter.ownershipSelfCerts.anyMinorityOwned.anyNativeAmericanOwner
                .filter((item) => item.isApplied)
                .forEach((item: any) => options.push(item.value));
            }
          } else {
            if (
              filter.ownershipSelfCerts.anyMinorityOwned.anyNativeAmericanOwner
                .length < 3
            ) {
              filter.ownershipSelfCerts.anyMinorityOwned.anyNativeAmericanOwner
                .filter((item) => item.isApplied)
                .forEach((item: any) => options.push(item.value));
            } else {
              options.push("Any Native American-Owned (3)");
            }
          }

          const resultStatus =
            filter.ownershipSelfCerts.matchType.isApplied &&
            filter.ownershipSelfCerts.matchType?.value === "ownership-and"
              ? `Results must match <b>all</b> of these.`
              : `Results can match <b>any</b> of these.`;

          return {
            ...appFilter,
            options,
            resultStatus,
          };
        }

        case FilterKeys.CAPABILITIES: {
          const options: string[] = [];
          const label = [];

          let values =
            // options.push(
            filter.capabilities.matchlimit?.value === "primary"
              ? `primary`
              : filter.capabilities.matchlimit?.value === "small"
              ? `small`
              : null;
          filter.capabilities.naics.forEach((item) => {
            if (item.code.length === 6) {
              options.push(item.codeNumber?.[0]);
            } else {
              options.push(
                item.codeNumber.map((codes) => {
                  return codes;
                })
              );
            }
          });

          filter.capabilities.naics.forEach((item) => {
            label.push(item.name);
          });

          let resultStatus =
            filter.capabilities.matchType.isApplied &&
            filter.capabilities.matchType?.value === "naics-and"
              ? `AND.`
              : `Or`;

          return {
            ...appFilter,
            options,
            label: label,
            resultStatus,
            matchLimit: values,
          };
        }
        case FilterKeys.SEARCH_BUSINESS: {
          const options: string[] = [];
          if (filter.searchBusiness.value !== null) {
            options.push(filter.searchBusiness.value);
          }
          return {
            ...appFilter,
            options,
            name: "Search Business",
            resultStatus: `Or`,
          };
        }

        default:
          return appFilter;
      }
    }
  );

  return appliedFilterOptions;
}

export function refineCertificationTags(tag: string, isPending: boolean) {
  switch (tag?.toLowerCase()) {
    case "hubzone certification":
      return { name: "HUBZone", isPending: false };

    case "wosb certification":
    case "women-owned small business (wosb)":
      return { name: "WOSB", isPending: isPending };

    case "8(a) certification":
      return { name: "8(a)", isPending: false };

    case "8(a) joint venture":
      return { name: "8(a) JV", isPending: false };

    case "edwosb certification":
    case "economically disadvantaged women-owned small business (edwosb)":
      return { name: "EDWOSB", isPending: isPending };

    case "veteran":
    case "veteran-owned small business":
      return { name: "VOSB", isPending: false };

    case "veteran joint venture":
      return { name: "VOSB JV", isPending: false };

    case "veteran service disabled":
    case "service-disabled veteran-owned small business":
      return { name: "SDVOSB", isPending: false };

    case "veteran service disabled joint venture":
      return { name: "SDVOSB JV", isPending: false };

    default:
      return { name: tag, isPending: false };
  }
}

export function refactorAppliedFilterOptions(appliedFilters: AppliedFilter[]) {
  const filterOptions = appliedFilters.map((filter) => {
    // if values has been set for filter, change options to values
    if (filter.values.length > 0) {
      const options =
        filter.category !== "bondingLevels" &&
        filter.category !== "profileStatus" &&
        filter.category !== "sbaCertifications"
          ? filter.values
          : filter.values[0];

      return {
        category: filter.category,
        name: filter.name,
        options,
        values:
          filter.category === "sbaCertifications"
            ? filter.label
            : filter.label[0],
        resultStatus: filter.matchType,
        matchLimit: filter.matchLimit,
      };
    }
    // else use previous format.
    // TODO: Just to note the else part should be delete once all filters are sweep.
    else {
      return {
        category: filter.category,
        name: filter.name,
        options: filter.options,
        values: filter.label,
        resultStatus: filter.resultStatus,
        matchLimit: filter.matchLimit,
      };
    }
  });

  return filterOptions;
}

function getDateRangePastMonths(pastMonths: number) {
  const startDate = moment()
    .subtract(pastMonths, "months")
    .format("MM/DD/YYYY");

  const endDate = moment().format("MM/DD/YYYY");

  return `${startDate}-${endDate}`;
}

export function formatFeedbackData(feedback: {
  name: string;
  email: string;
  feedback: string;
  selectOption: string;
}) {
  let content = [];

  if (feedback.selectOption === "send-anonymously") {
    content = [
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: feedback.feedback,
          },
        ],
      },
    ];
  } else if (feedback.selectOption === "send-information") {
    content = [
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "Name: ",
          },
          {
            type: "text",
            text: feedback.name,
            marks: [
              {
                type: "strong",
              },
            ],
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "Email: ",
          },
          {
            type: "text",
            text: feedback.email,
            marks: [
              {
                type: "link",
                attrs: {
                  href: `mailto:${feedback.email}`,
                },
              },
            ],
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "Feedback: ",
            marks: [
              {
                type: "strong",
              },
            ],
          },
          {
            type: "text",
            text: feedback.feedback,
          },
        ],
      },
    ];
  }

  const summary = "Feedback about SBS ";
  const description = {
    type: "doc",
    version: 1,
    content: content,
  };

  return {
    summary,
    description,
  };
}

export function formatLastUpdatedDate(date: string) {
  if (!date || date === "custom") return "";

  return moment(new Date(date)).format("YYYY-MM-DD");
}

export function getMaxDate() {
  return moment(new Date()).format("YYYY-MM-DD");
}

export function getCapabilitiesNarrativeLink(
  data: BusinessItem | BusinessProfile
) {
  if (!data) return null;

  const profileData = data as BusinessProfile;
  const itemData = data as BusinessItem;

  // Business profile
  if (profileData?.profile) {
    if (profileData?.profile?.capabilities_narrative_link) {
      return profileData?.profile?.capabilities_narrative_link;
    }

    if (
      profileData?.certifications?.sba_certifications &&
      profileData?.certifications?.sba_certifications.length > 0
    ) {
      const sbac = profileData?.certifications?.sba_certifications?.find(
        (cert) => cert?.name === "8(a) Certification"
      );

      if (sbac) {
        return sbac?.status === "Active"
          ? `https://certify.sba.gov/capabilities/${profileData?.profile?.uei}`
          : null;
      }
    }
  }

  // Business item
  if (itemData.capabilitiesStatement) return itemData.capabilitiesStatement;

  if (
    itemData.active_certifications &&
    itemData.active_certifications.length > 0
  ) {
    const sbac = itemData.active_certifications?.find(
      (cert) => cert?.name === "8(a) Certification"
    );

    if (sbac) {
      return sbac.cert_active === "A"
        ? `https://certify.sba.gov/capabilities/${itemData.uei}`
        : null;
    }
  }
}

export function formatUpdateAtDate(timestamp: number) {
  if (!timestamp) return "";

  const formattedDate = moment(timestamp).format("MMMM D, YYYY h:mm A [EST]");

  return formattedDate;
}

export function returnSBACertsInsights(data: BusinessItem[]) {
  if (!data) return [];

  const sbac = new Map<string, { name: string; count: number }>();

  data?.forEach((item) => {
    item?.active_certifications?.forEach((cert) => {
      const label = getSBACertsLabel(cert?.name);

      const isActive = cert?.cert_active === "A";

      if (sbac.has(label)) {
        sbac.set(label, {
          name: label,
          count: isActive ? sbac.get(label).count + 1 : sbac.get(label).count,
        });
      } else {
        if (label) {
          sbac.set(label, {
            name: label,
            count: 1,
          });
        }
      }
    });
  });

  const certsInsights = Array.from(sbac.values());

  const order = [
    "8(a) or 8(a) Joint Venture",
    "HUBZone",
    "Women-Owned Small Business (WOSB)",
    "Economically-Disadvantaged Women-Owned Small Business (EDWOSB)",
    "Veteran-Owned Small Business or Joint Venture (VOSB, VOSB JV)",
    "Service-Disabled Veteran-Owned Small Business or Joint Venture (SDVOSB, SDVOSB JV)",
  ];

  certsInsights.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));

  return certsInsights;
}

export function getSBACertsLabel(cert: string) {
  switch (cert?.toLowerCase()) {
    case "8(a) certification":
    case "8(a) joint venture":
      return "8(a) or 8(a) Joint Venture";

    case "hubzone certification":
      return "HUBZone";

    case "wosb certification":
    case "women-owned small business (wosb)":
      return "Women-Owned Small Business (WOSB)";

    case "edwosb certification":
    case "economically disadvantaged women-owned small business (edwosb)":
      return "Economically-Disadvantaged Women-Owned Small Business (EDWOSB)";

    case "veteran":
    case "veteran-owned small business":
    case "veteran joint venture":
      return "Veteran-Owned Small Business or Joint Venture (VOSB, VOSB JV)";

    case "veteran service disabled":
    case "service-disabled veteran-owned small business":
    case "veteran service disabled joint venture":
      return "Service-Disabled Veteran-Owned Small Business or Joint Venture (SDVOSB, SDVOSB JV)";
  }
}
