import styles from "./ResultTable.module.scss";

import type { Table as ReactTableType } from "@tanstack/react-table";
import { Button, Icon } from "@trussworks/react-uswds";

interface IProps<T> {
  table: ReactTableType<T>;
  totalPages?: number;
}

export default function Pagniation<T extends unknown>({
  table,
  totalPages,
}: IProps<T>) {
  const pageCount = totalPages;
  const pageIndex = table.getState().pagination.pageIndex;
  const canPreviousPage = table.getCanPreviousPage();
  const canNextPage = table.getCanNextPage();
  const maxGap = 5;

  const pagesBuffer = pageCount > maxGap ? 2 : 0; // Pages shown around the current page
  let pages = [];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Helper function to add page buttons
  const addPageButton = (page: number, isCurrent: boolean) => {
    pages.push(
      <li key={page} className={`usa-pagination__item usa-pagination__page-no`}>
        <Button
          type="button"
          unstyled
          className={`${styles["btn-page"]} usa-pagination__button ${
            isCurrent ? "usa-current" : ""
          }`}
          onClick={() => {
            table.setPageIndex(page);
            scrollToTop();
          }}
          aria-current={isCurrent ? "page" : undefined}
        >
          {page + 1}
        </Button>
      </li>
    );
  };

  // Add Previous Button
  pages.push(
    <li key="prev" className="usa-pagination__item usa-pagination__arrow">
      <Button
        type="button"
        unstyled
        className="usa-pagination__link usa-pagination__previous-page"
        aria-label="Previous page"
        onClick={() => {
          table.previousPage();
          scrollToTop();
        }}
        disabled={!canPreviousPage}
      >
        <Icon.NavigateBefore />
        <span className="usa-pagination__link-text">Previous</span>
      </Button>
    </li>
  );

  if (pageCount <= maxGap) {
    for (let i = 0; i < pageCount; i++) {
      addPageButton(i, pageIndex === i);
    }
  } else {
    // Add first page and ellipsis if needed
    addPageButton(0, pageIndex === 0);

    if (pageIndex > pagesBuffer + 1) {
      pages.push(
        <li
          key="start-ellipsis"
          className="usa-pagination__item usa-pagination__overflow"
        >
          …
        </li>
      );
    }

    // Add page numbers around current page
    for (
      let i = Math.max(1, pageIndex - pagesBuffer);
      i <= Math.min(pageIndex + pagesBuffer, pageCount - 2);
      i++
    ) {
      addPageButton(i, pageIndex === i);
    }

    // Add ellipsis and last page if needed
    if (pageIndex < pageCount - pagesBuffer - 2) {
      pages.push(
        <li
          key="end-ellipsis"
          className="usa-pagination__item usa-pagination__overflow"
        >
          …
        </li>
      );
    }

    // last page
    addPageButton(pageCount - 1, pageIndex === pageCount - 1);
  }

  // Add Next Button
  pages.push(
    <li key="next" className="usa-pagination__item usa-pagination__arrow">
      <Button
        type="button"
        unstyled
        className="usa-pagination__link usa-pagination__next-page"
        aria-label="Next page"
        onClick={() => {
          table.nextPage();
          scrollToTop();
        }}
        disabled={!canNextPage}
      >
        <span className="usa-pagination__link-text">Next</span>
        <Icon.NavigateNext />
      </Button>
    </li>
  );

  return (
    <div className={styles["pagination-section"]}>
      <nav aria-label="Pagination" className="usa-pagination">
        <ul className="usa-pagination__list">{pages}</ul>
      </nav>
    </div>
  );
}
