import { useState, useRef } from "react";

// components
import { RoundedButton } from "components/LibraryComponents/Components";
// icons
import { Modal, Checkbox, ModalRef } from "@trussworks/react-uswds";
import ControlAccordion from "components/ControlAccordion";
import { modalAccordionProperty } from "constants/SearchProperties";
import { useExportTableColumnStore } from "store/exportTableColumnStore";
import { EditTable } from "components/icons";
import useColumnsStore from "store/useColumnsStore";

export default function ExportTableModal() {
  const exportColumnStore = useExportTableColumnStore();
  const [showResultModal, setShowResultModal] = useState(false);
  const { columns, setColumns } = useColumnsStore((state) => ({
    columns: state.columns,
    setColumns: state.setColumns,
  }));
  const [resultModelChecked, setResultModelChecked] = useState<any>(
    columns && columns.length > 0
      ? columns
      : [
          {
            id: "businessName1",
            name: "businessName",
            label: "Business name (Required)",
          },
        ]
  );
  const resultModelCheckBoxData = [
    {
      id: "capabilitiesNarrative1",
      name: "capabilitiesNarrative",
      label: "Capabilities narrative and capabilities statement link",
      labelDescription:
        "In a spreadsheet export, the capabilities narrative and capabilities statement will be separated into 2 columns.",
    },
    {
      id: "activeCertifications1",
      name: "activeCertifications",
      label: "Active SBA certifications",
      labelDescription:
        "This column will list each active SBA certification in a single cell. To separate them, scroll to the SBA certification settings section below.",
    },
    {
      id: "contact1",
      name: "contact",
      label: "Contact person name and email",
      labelDescription:
        "In a spreadsheet export, the contact person name their email will be separated into 2 columns.",
    },
    {
      id: "address1",
      name: "address",
      label: "Address",
      labelDescription:
        "In a spreadsheet export, each part of the business address will be separated into columns: street address line 1, street address line 2, city, state, and zip code.",
    },
  ];

  /**
   * This function checks if the column is not a default column
   * @param id The Id of the column object
   * @returns true if the id is not represented in the resultModelChecked array
   */
  const isNonDefaultColumn = ({id}): boolean => {
    return !resultModelCheckBoxData.some((item) => item.id === id);
  }

  const resultModelRef = useRef<ModalRef>({
    modalId: "result-table-columns",
    modalIsOpen: false,
    toggleModal: (
      event?: React.MouseEvent,
      open?: boolean,
      close?: boolean
    ) => {
      if (open) {
        const currentState = resultModelRef.current.modalIsOpen;
        const newState = open ?? !currentState;
        resultModelRef.current.modalIsOpen = newState;
        setShowResultModal(newState);
        return newState;
      }
      if (close) {
        resultModelRef.current.modalIsOpen = false;
        setShowResultModal(false);
        return false;
      }
    },
  });
  const openResultModel = () => {
    setResultModelChecked(
      columns && columns.length > 0
        ? [...columns]
        : [
            {
              id: "businessName1",
              name: "businessName",
              label: "Business name (Required)",
            },
            ...resultModelCheckBoxData,
          ]
    );
    resultModelRef.current?.toggleModal(undefined, true, false);
  };
  const closeResultModel = () => {
    setResultModelChecked([
      {
        id: "businessName1",
        name: "businessName",
        label: "Business name (Required)",
      },
    ]);
    setColumns([]);
    resultModelRef.current?.toggleModal(undefined, false, true);
  };
  const handleResultCheckBoxChange = (
    name: string,
    label: string,
    isChecked: Boolean,
    id
  ) => {
    if (isChecked) {
      setResultModelChecked((prevChecked) => [
        ...prevChecked,
        { name, label, id },
      ]);
    } else {
      setResultModelChecked((prevChecked) =>
        prevChecked.filter((item) => item.name !== name)
      );
    }
  };

  const handleResultSelectedCheckBoxChange = (
    name: string,
    label: string,
    id
  ) => {
    const isChecked = resultModelChecked.find(
      (item) => item.name === name && item.label === label
    );

    if (isChecked.name === name) {
      setResultModelChecked((prevChecked) =>
        prevChecked.filter((item) => item.name !== name)
      );
    } else {
      setResultModelChecked((prevChecked) => [
        ...prevChecked,
        { name, label, id },
      ]);
    }
  };

  const handleApplyChanges = () => {
    setColumns(resultModelChecked);
    exportColumnStore.setFilter(resultModelChecked);
    resultModelRef.current?.toggleModal(undefined, false, false);
  };

  return (
    <>
      <RoundedButton variant="ghost" onClick={openResultModel}>
        <EditTable />
        Edit table
      </RoundedButton>
      {showResultModal && (
        <Modal
          placeholder=""
          isLarge
          id={resultModelRef.current.modalId}
          ref={resultModelRef}
          isInitiallyOpen={resultModelRef.current.modalIsOpen}
          className={`padding-0 position-relative`}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        >
          <div
            className={`margin-bottom-1 overflow-auto resultpane-model-content`}
          >
            <p className="font-sans-xl text-bold">Edit result table columns</p>
            <div className="margin-top-2 text-ink">
              <p>
                Choose up to 10 table columns that will appear in your result list.
              </p>
              <p className="font-sans-lg text-bold margin-top-1">
                Default columns
              </p>
              <Checkbox
                id={"businessName1"}
                name={"businessName"}
                label="Business name (Required)"
                className="margin-y-2"
                checked={resultModelChecked.some(
                  (item) => item.name === "businessName"
                )}
                disabled
              />
              {resultModelCheckBoxData?.map((checkbox: any) => {
                return (
                  <Checkbox
                    id={checkbox.id}
                    name={checkbox.name}
                    label={checkbox.label}
                    className="margin-y-2"
                    onChange={(e) =>
                      handleResultCheckBoxChange(
                        checkbox.name,
                        checkbox.label,
                        e.target.checked,
                        checkbox.id
                      )
                    }
                    checked={resultModelChecked.some(
                      (item) => item.name === checkbox.name
                    )}
                    disabled={
                      resultModelChecked.length == 10 &&
                      resultModelChecked.some(
                        (item) =>
                          item.name !== checkbox.name &&
                          !resultModelChecked.some(
                            (item) => item.name === checkbox.name
                          )
                      )
                    }
                    labelDescription={checkbox?.labelDescription}
                  />
                );
              })}
            </div>

            <div className="padding-y-3">
              {resultModelChecked?.filter(isNonDefaultColumn)?.length > 1 && (
                <p className="font-sans-xs">SELECTED COLUMNS</p>
              )}

              {resultModelChecked?.filter(isNonDefaultColumn)?.length > 1 &&
                resultModelChecked?.filter(isNonDefaultColumn).map((i, index) => {
                  return (
                    <>
                      {index !== 0 && (
                        <Checkbox
                          key={index}
                          id={"edit" + "_" + i.id}
                          name={i.name}
                          label={i.label}
                          className="margin-1"
                          checked={resultModelChecked.find(
                            (item) => item.name === i.name
                          )}
                          onChange={() =>
                            handleResultSelectedCheckBoxChange(
                              i.name,
                              i.label,
                              i.id
                            )
                          }
                        />
                      )}
                    </>
                  );
                })}
            </div>
            {modalAccordionProperty.map((sProp, index) => {
              const PropertyComponent = sProp.component || null;
              return (
                <ControlAccordion
                  key={sProp.name}
                  title={sProp.display}
                  name={sProp.name}
                >
                  <PropertyComponent
                    handleCheckBoxChange={handleResultCheckBoxChange}
                    checked={resultModelChecked}
                    length={10}
                    name="Result"
                  />
                </ControlAccordion>
              );
            })}
          </div>
          <div className="usa-modal__footer bg-accent-cool-lighter position-sticky margin-top-0 padding-0">
            <ul className="usa-button-group flex-justify-end padding-1 padding-x-2">
              <li className="usa-button-group__item">
                <button
                  type="button"
                  className="usa-button usa-button--unstyled padding-105 text-center"
                  onClick={closeResultModel}
                  data-close-modal
                >
                  Cancel and close
                </button>
              </li>
              <li className="usa-button-group__item">
                <button
                  type="button"
                  className="usa-button"
                  onClick={handleApplyChanges}
                >
                  Apply changes
                </button>
              </li>
            </ul>
          </div>
        </Modal>
      )}
    </>
  );
}
