import { useState, useEffect } from "react";

// component
import {
  FilterFlyout,
  FilterRelationBox,
  MultiSelectCheckbox,
  RoundedButton,
} from "components/LibraryComponents/Components";
import { SelfCert } from "components/icons";

// types
import { Generic } from "types/filters-store.types";

// constants
import {
  OperatorType,
  SelfCertifications,
  FilterKeys,
  FilterTitles,
} from "constants/filters";

// store
import useFiltersStore from "store/useFiltersStoreNew";

// styles
import styles from "./SelfFilterButton.module.scss";

export default function SelfFilterButton() {
  const { selfCertifications, setFilter } = useFiltersStore((state) => ({
    selfCertifications: state.filters.selfCertifications,
    setFilter: state.setFilter,
  }));

  const [isOpen, setIsOpen] = useState<boolean>(false);
  // This state only control if filter is open,
  // and has more than one value apply max-width to filter button.
  const [isHalt, setIsHalt] = useState<boolean>(true);

  const selectedSelfValues = selfCertifications.certifications.map(
    (item) => item.label
  );
  const isDisabled = selectedSelfValues.length === 0;

  useEffect(() => {
    if (selfCertifications.certifications.length === 0) {
      if (!isHalt) setIsHalt(true);
    }
  }, [selfCertifications.certifications]);

  const handleChange = (items: Generic[]) => {
    setFilter(FilterKeys.SELF_CERTIFICATIONS, {
      ...selfCertifications,
      certifications: items,
    });
  };

  const handleOperatorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setFilter(FilterKeys.SELF_CERTIFICATIONS, {
      ...selfCertifications,
      operatorType: value,
    });
  };

  const handleClear = () => {
    setFilter(FilterKeys.SELF_CERTIFICATIONS, {
      certifications: [],
      operatorType: OperatorType.OR,
    });

    setIsHalt(true);
  };

  const handleCallback = (open: boolean) => {
    if (!open && selectedSelfValues.length > 0) {
      setIsHalt(false);
    }
  };

  return (
    <FilterFlyout
      id={FilterKeys.SELF_CERTIFICATIONS}
      filterName={FilterTitles[FilterKeys.SELF_CERTIFICATIONS]}
      title={FilterTitles[FilterKeys.SELF_CERTIFICATIONS]}
      FilterIcon={<SelfCert />}
      options={selectedSelfValues}
      operator={selfCertifications.operatorType}
      isOpen={isOpen}
      isHalt={isHalt}
      setIsOpen={setIsOpen}
      callback={handleCallback}
    >
      <div className={styles["self-filter-content"]}>
        <p className={styles["description"]}>
          Small businesses with self-certifications attested to these traits
          when registering with SAM.gov. These assessments are not vetted by
          SBA.{" "}
          <a hidden href="https://usa.gov/" target="_blank" rel="noreferrer">
            Learn more about self-certification here.
          </a>
        </p>

        <div className={styles["self-certs-list"]}>
          <MultiSelectCheckbox
            items={SelfCertifications}
            checkedItems={selfCertifications.certifications}
            handleChange={handleChange}
            hideOnly
          />
        </div>

        <div
          hidden={selectedSelfValues.length <= 1}
          className={styles["relation-box"]}
        >
          {/* <FilterRelationBox
            filterName={"self-certifications"}
            value={`${"self-certifications"}-${
              selfCertifications.operatorType
            }`}
            onChange={handleOperatorChange}
          /> */}
        </div>

        <div className={styles["clear-filter"]}>
          <RoundedButton
            variant="ghost"
            onClick={handleClear}
            disabled={isDisabled}
          >
            Clear filter selections
          </RoundedButton>
        </div>
      </div>
    </FilterFlyout>
  );
}
