import styles from "./SearchInput.module.scss";

import { TextInput, Icon } from "@trussworks/react-uswds";
import { TextInputProps } from "@trussworks/react-uswds/lib/components/forms/TextInput/TextInput";
import classNames from "classnames";
import { Spinner } from "components/icons";

// Adjusting TextInputProps for SearchInputProps:
type AdjustedTextInputProps = Omit<TextInputProps, "type"> & {
  type?: TextInputProps["type"];
  inputSize?: "small";
};

type SearchInputProps = {
  PrefixIcon?: React.ReactElement;
  SufixIcon?: React.ReactElement;
  isLoading?: boolean;
  handleClear: () => void;
} & AdjustedTextInputProps;

/**
 * `SearchInput` component allows users to search for specific content
 *
 * @param {SearchInputProps} props - The props for the `SearchInput` component.
 * @param props.PrefixIcon - If provided will replace defualt search icon.
 * @param props.SufixIcon - If provided will replace defualt clear icon.
 * @param props.className - The class name for the root element (Optional).
 * @param {function} props.handleClear - callback function from sufix icon onClick.
 *
 * @see https://designsystem.digital.gov/components/text-input/
 */
export default function SearchInput(props: SearchInputProps) {
  const {
    inputSize,
    PrefixIcon,
    SufixIcon,
    className,
    isLoading,
    handleClear,
    ...rest
  } = props;

  const inputClasses = classNames(styles["input-root"], {
    [styles["input-small"]]: inputSize === "small",
    [className]: className,
  });

  const Prefix = PrefixIcon ? PrefixIcon : <Icon.Search />;
  const Sufix = SufixIcon ? SufixIcon : <Icon.Cancel />;

  return (
    <div className={styles["input-wrapper"]}>
      {inputSize !== "small" && (
        <span className={styles["prefix-icon"]}>{Prefix}</span>
      )}

      <TextInput className={inputClasses} type="search" {...rest} />

      {!isLoading && (
        <span className={styles["sufix-icon"]} onClick={handleClear}>
          {Sufix}
        </span>
      )}

      {isLoading && (
        <span className={styles["spinner-wrapper"]}>
          <Spinner />
        </span>
      )}
    </div>
  );
}
