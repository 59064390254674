import styles from "./ToggleBar.module.scss";

// component
import { SwitchProps } from "@mui/material";
import Toggle from "../Toggle";

type ToggleBarProps = {
  id: string;
  label: string;
} & SwitchProps;

/**
 * `ToggleBar` allows the user to toggle the state of a single setting on or off.
 *
 * @param {ToggleBarProps} props - The props for the `ToggleBar` component.
 * @param {string} props.id - The id for the root element.
 * @param {string} props.label - The label for the root element.
 *
 * @param {SwitchProps} props - mui switch props
 *
 * @see https://mui.com/material-ui/react-switch/
 */
export default function ToggleBar(props: ToggleBarProps) {
  const { id, label, ...rest } = props;

  return (
    <div className={styles["toggle-bar-root"]}>
      <label htmlFor={id}>{label}</label>
      <Toggle id={id} {...rest} />
    </div>
  );
}
