import styles from "./IconBlock.module.scss";

// components
import { Heading } from "../../Components";
import { Icon } from "@trussworks/react-uswds";
import {
  PopoutArrow,
  Feedback,
  EditTable,
  SbaCertify,
  SelfCert,
} from "../../../icons";

export default function IconBlock() {
  return (
    <div className={styles["icon-block-root"]}>
      <Heading title="Icons" />

      <div className={styles["group"]}>
        <div className={styles["block"]}>
          <PopoutArrow />
          <span>pop out arrow</span>
        </div>
        <div className={styles["block"]}>
          <Icon.ArrowDropDown />
          <span>caret down</span>
        </div>
        <div className={styles["block"]}>
          <Icon.ArrowDropUp />
          <span>caret up</span>
        </div>
        <div className={styles["block"]}>
          <Icon.SortArrow />
          <span>sort</span>
        </div>
        <div className={styles["block"]}>
          <Icon.Check />
          <span>check</span>
        </div>
        <div className={styles["block"]}>
          <Icon.Cancel />
          <span>clear x</span>
        </div>
        <div className={styles["block"]}>
          <Icon.Close />
          <span>close x</span>
        </div>
        <div className={styles["block"]}>
          <Feedback />
          <span>give feedback</span>
        </div>
        <div className={styles["block"]}>
          <EditTable />
          <span>edit table</span>
        </div>
        <div className={styles["block"]}>
          <Icon.SaveAlt />
          <span>export</span>
        </div>
        <div className={styles["block"]}>
          <Icon.Map />
          <span>location</span>
        </div>
        <div className={styles["block"]}>
          <SbaCertify />
          <span>sba certify</span>
        </div>
        <div className={styles["block"]}>
          <Icon.Topic />
          <span>NAICS</span>
        </div>
        <div className={styles["block"]}>
          <Icon.List />
          <span>keywords</span>
        </div>
        <div className={styles["block"]}>
          <SelfCert />
          <span>self cert</span>
        </div>
        <div className={styles["block"]}>
          <Icon.Schedule />
          <span>last update</span>
        </div>
        <div className={styles["block"]}>
          <Icon.Add />
          <span>more</span>
        </div>
        <div className={styles["block"]}>
          <Icon.Search />
          <span>search</span>
        </div>
      </div>
    </div>
  );
}
