import styles from "./ToggleBarBlock.module.scss";

// components
import { Heading, ToggleBar } from "../../Components";

export default function ToggleBarBlock() {
  return (
    <div className={styles["toggle-bar-block-root"]}>
      <Heading title="Toggle bar" />

      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default checked</span>
          <ToggleBar
            id="default-toggle-bar"
            defaultChecked
            label="Search for businesses anywhere"
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default unchecked</span>
          <ToggleBar
            id="default-un-toggle-bar"
            label="Search for businesses anywhere"
          />
        </div>
      </div>
    </div>
  );
}
