import { Link } from "react-router-dom";
import { useState } from "react";

// icons
import { SBSLogo } from "components/icons";

// styles
import styles from "./Header.module.scss";

// compoennts
import { RoundedButton } from "components/LibraryComponents/Components";
import { Feedback } from "components/icons";

// assets
import SBALogoImage from "assets/images/sba-logo-new.png";
import { FeedbackDialog } from "components/Dialogs";

export default function Header() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div className={styles["header-root"]}>
        <div className={styles["container"]}>
          <div className={styles["left-section"]}>
            <a href="https://www.sba.gov/" target="_blank" rel="noreferrer">
              <img
                src={SBALogoImage}
                alt="Small Business Administration Logo"
              />
            </a>

            <hr className={styles["divider"]} />

            <Link to="/" className={styles["link-wrapper"]}>
              <SBSLogo />
              <h2>Small Business Search</h2>
              <span>Beta</span>
            </Link>
          </div>

          <div className={styles["right-section"]}>
            <RoundedButton
              variant="ghost"
              className={styles["feedback-btn"]}
              onClick={() => setOpen(true)}
            >
              <Feedback />
              Give feedback
            </RoundedButton>

            {open && <FeedbackDialog open={open} setOpen={setOpen} />}
          </div>
        </div>
      </div>
    </>
  );
}
