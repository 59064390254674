import "./SBACertifications.scss";

import React, { useState, useEffect } from "react";

import { Radio, Icon } from "@trussworks/react-uswds";

// store
import { useFilterStore } from "../../../store/useFilterStore";

// constants
import { FilterKeys } from "../../../constants/SearchProperties";


let SBACertificationsOptions = [
  {
    label: '8(a) or 8(a) Joint Venture',
    value: '1,4'
  },
  {
    label: 'HUBZone',
    value: '3'
  },
  {
    label: 'Women-Owned Small Business (WOSB)',
    value: '5'
  },
  {
    label: 'Economically-Disadvantaged Women-Owned Small Business (EDWOSB)',
    value: '6'
  },
  {
    label: 'Veteran Owned Small Business or Joint Venture (VOSB,VOSB JV)',
    value: '7,8'
  },
  {
    label: 'Service Disabled Veteran Owned Small Business or Joint Venture (SDVOSB,SDVOSB JV)',
    value: '9,10'
  }
];

export default function SBACertifications() {
  const store = useFilterStore();

  const [isActiveOpen, setIsActiveOpen] = useState<boolean>(true);
  const [isPreviousOpen, setIsPriviousOpen] = useState<boolean>(false);
  const [toggleActive, setToggleActive] = useState<boolean>(false);
  const [togglePrevious, setTogglePrevious] = useState<boolean>(false);

  useEffect(() => {
    if (store.filters.sbaCertifications.activeCertification.length > 0) {
      setIsActiveOpen(true);
    }

    if (store.filters.sbaCertifications.previousCertification.length > 0) {
      setIsPriviousOpen(true);
    }

    if (
      store.filters.sbaCertifications.activeCertification.length ===
      SBACertificationsOptions.length
    ) {
      setToggleActive(true);
    }

    if (
      store.filters.sbaCertifications.previousCertification.length ===
      SBACertificationsOptions.length
    ) {
      setTogglePrevious(true);
    }
  }, []);

  const handleChange = (
    type: string,
    e: React.ChangeEvent<HTMLInputElement>, index ,label
  ) => {
    const { name, checked } = e.target;

    const key =
      type === "active" ? "activeCertification" : "previousCertification";

    const list = store.filters.sbaCertifications[key];

    if (checked) {
      const newList = [...list, { key : index, value: name,label :label, isApplied: false }];

      store.setFilter(FilterKeys.SBA_CERTIFICATIONS, {
        ...store.filters.sbaCertifications,
        [key]: newList,
      });

      if (
        type === "active" &&
        newList.length === SBACertificationsOptions.length
      ) {
        setToggleActive(true);
      }

      if (
        type === "previous" &&
        newList.length === SBACertificationsOptions.length
      ) {
        setTogglePrevious(true);
      }
    } else {
      const filterList = list.filter((item) => item.value !== name);

      store.setFilter(FilterKeys.SBA_CERTIFICATIONS, {
        ...store.filters.sbaCertifications,
        [key]: filterList,
      });

      if (
        type === "active" &&
        filterList.length < SBACertificationsOptions.length
      ) {
        setToggleActive(false);
      }

      if (
        type === "previous" &&
        filterList.length < SBACertificationsOptions.length
      ) {
        setTogglePrevious(false);
      }
    }
  };

  const handleToggle = (type: string, toggle: boolean) => {
    const key =
      type === "active" ? "activeCertification" : "previousCertification";

    store.setFilter(FilterKeys.SBA_CERTIFICATIONS, {
      ...store.filters.sbaCertifications,
      [key]: toggle
        ? SBACertificationsOptions.map((item) => ({
            value: item.value,
            isApplied: false,
            label:item.label
          }))
        : [],
    });

    if (type === "active") {
      setToggleActive(!toggleActive);
    } else {
      setTogglePrevious(!togglePrevious);
    }
  };

  const handleRelate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;

    store.setFilter(FilterKeys.SBA_CERTIFICATIONS, {
      ...store.filters.sbaCertifications,
      certificationRelate: {
        value: id,
        isApplied: false,
      },
    });
  };

  return (
    <>
      <div className="sba-certification-root">
        <fieldset className="usa-fieldset">
          <div className="usa-checkbox parent">
            <span
              className="icon"
              onClick={() => setIsActiveOpen(!isActiveOpen)}
            >
              {isActiveOpen ? <Icon.ExpandLess /> : <Icon.ExpandMore />}
            </span>

            <p className="label">Any SBA certification</p>

            <span
              onClick={() => handleToggle("active", !toggleActive)}
              className="toggle-on-off"
            >
              {toggleActive ? (
                <Icon.ToggleOn color="#005ea2" />
              ) : (
                <Icon.ToggleOff />
              )}
            </span>
          </div>

          <div className={`children-box ${isActiveOpen ? "active" : ""}`}>
            {SBACertificationsOptions.map((item: { label: string; value: string; }, index) => (
              <div className="usa-checkbox" key={`active-${item.value}`}>
                <input
                  className="usa-checkbox__input"
                  id={`active-${item.value}`}
                  name={item.value}
                  type="checkbox"
                  checked={store.filters.sbaCertifications.activeCertification
                    .map((act) => act.value)
                    .includes(item.value)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange("active", e, item.value , item.label)
                  }
                />
                <label
                  className="usa-checkbox__label"
                  htmlFor={`active-${item.value}`}
                >
                  {item.label}
                </label>
              </div>
            ))}
          </div>
        </fieldset>

        <fieldset className="usa-fieldset">
          <div className="usa-checkbox parent">
            <span
              className="icon"
              onClick={() => setIsPriviousOpen(!isPreviousOpen)}
            >
              {isPreviousOpen ? <Icon.ExpandLess /> : <Icon.ExpandMore />}
            </span>

            <p className="label">Any previous SBA certification</p>

            <span
              onClick={() => handleToggle("previous", !togglePrevious)}
              className="toggle-on-off"
            >
              {togglePrevious ? (
                <Icon.ToggleOn color="#005ea2" />
              ) : (
                <Icon.ToggleOff />
              )}
            </span>
          </div>
          <div className={`children-box ${isPreviousOpen ? "active" : ""}`}>
            {SBACertificationsOptions.map((item: { label: string; value: string; }, index) => (
              <div className="usa-checkbox" key={`previous-${item.value}`}>
                <input
                  className="usa-checkbox__input"
                  id={`previous-${item.value}`}
                  name={item.value}
                  type="checkbox"
                  checked={store.filters.sbaCertifications.previousCertification
                    .map((act) => act.value)
                    .includes(item.value)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange("previous", e,item.value , item.label);
                  }}
                />
                <label
                  className="usa-checkbox__label"
                  htmlFor={`previous-${item.value}`}
                >
                  {item.label}
                </label>
              </div>
            ))}
          </div>
        </fieldset>

        <hr className="divider" />

        <h5>How should these certifications relate?</h5>

        <div className="radio-group">
          <Radio
            id="certifications-or"
            name="certifications-or"
            label={
              <>
                OR: results can match <b>any</b> of these.
              </>
            }
            checked={
              store.filters.sbaCertifications.certificationRelate?.value ===
              "certifications-or"
            }
            onChange={handleRelate}
          />
          <Radio
            id="certifications-and"
            name="certifications-and"
            label={
              <>
                AND: results must match <b>all</b> of these.
              </>
            }
            checked={
              store.filters.sbaCertifications.certificationRelate?.value ===
              "certifications-and"
            }
            onChange={handleRelate}
          />
        </div>
      </div>
    </>
  );
}
