import styles from "./AdvandedFooter.module.scss";

import { Icon } from "@trussworks/react-uswds";

const sbaLogo = require("../../../assets/images/sba_logo_horizontal.png");

const footerLinks = [
  {
    url: "https://www.sba.gov/contracting",
    label: "SBA.gov/contracting",
  },
  {
    url: "https://whitehouse.gov",
    label: "WhiteHouse.gov",
  },
  {
    url: "https://regulations.gov",
    label: "Regulations.gov",
  },
  {
    url: "https://business.usa.gov",
    label: "BusinessUSA.gov",
  },
  {
    url: "https://usa.gov",
    label: "USA.gov",
  },
];

const socialLinks = [
  {
    href: "https://www.facebook.com/SBAgov",
    name: <Icon.Facebook />,
  },
  {
    href: "https://www.twitter.com/SBAgov",
    name: <Icon.Twitter />,
  },
  {
    href: "https://youtube.com/c/sbagov",
    name: <Icon.Youtube />,
  },
];

const DynamicIcon: React.FC<{ iconName: string }> = ({ iconName }) => {
  const IconComponent = Icon[iconName];
  return <IconComponent />;
};

export default function AdvancedFooter() {
  return (
    <div className={styles["advanced-footer-root"]}>
      <div className={styles["links-section"]}>
        {footerLinks.map((item, i) => (
          <a
            key={i}
            href={item.url}
            rel="noreferrer"
            target="_blank"
            className={styles["link"]}
          >
            {item.label}
          </a>
        ))}
      </div>

      <div className={styles["content-section"]}>
        <a
          href="https://www.sba.gov"
          rel="noreferrer"
          target="_blank"
          className={styles["img-wrapper"]}
        >
          <img src={sbaLogo} alt="SBA Logo" />
        </a>

        <div className={styles["social-content"]}>
          <div className={styles["social-links"]}>
            {socialLinks.map((item, i) => (
              <a key={i} href={item.href} rel="noreferrer" target="_blank">
                {item.name}
              </a>
            ))}
          </div>

          <h3>Contact SBA</h3>
          <a href="#">SBS Help Desk</a>
        </div>
      </div>
    </div>
  );
}
