// styles
import styles from "./ResultTable.module.scss";

import { useEffect, useMemo, useState } from "react";
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  flexRender,
  SortingState,
  ColumnDef,
} from "@tanstack/react-table";
import classNames from "classnames";

// components
import { Table } from "@trussworks/react-uswds";
import Pagination from "./Pagination";

// types
import { BusinessItem } from "types/results-panel.types";

// columns
import { columns, optionalColumns } from "./Columns";

// store
import useColumnsStore from "store/useColumnsStore";

type ResultTableProps = {
  data: BusinessItem[];
  sortType: string;
  setItemsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  rootClassname?: string;
  tableClassname?: string;
  totalPages?: number;
};

export default function ResultTable(props: ResultTableProps) {
  const { data, sortType, setItemsPerPage, rootClassname, tableClassname, totalPages } =
    props;

  const { storeColumns } = useColumnsStore((state) => ({
    storeColumns: state.columns,
  }));

  const [sorting, setSorting] = useState<SortingState>([]);

  const rootClass = classNames(styles["table-root"], {
    [rootClassname]: rootClassname,
  });

  const tableClass = classNames(styles["table"], {
    [tableClassname]: tableClassname,
  });

  const dynamicColumns = useMemo(() => {
    const columnMap = new Map<string, ColumnDef<BusinessItem>>(
      columns.map((column) => [column.id, column])
    );

    if (storeColumns.length === 0) {
      return Array.from(columnMap.values());
    }
    // Apply modifications from store columns
    storeColumns.forEach((item) => {
      if (columnMap.has(item.name)) {
        // Update existing column
        const column = columnMap.get(item.name);
        columnMap.set(item.name, column);
      } else {
        // Add new column if not found
        columnMap.set(item.name,
          optionalColumns.find((col) => col?.id === item.name))
      }
    });

    // Remove columns that are no longer in editTablecolumnData but were added previously
    columnMap.forEach((_, key) => {
      if (!storeColumns.find((item) => item.name === key)) {
        columnMap.delete(key);
      }
    });

    return Array.from(columnMap.values());
  }, [storeColumns, columns]);

  const table = useReactTable({
    initialState: {
      pagination: {
        pageSize: 25,
      },
      sorting: [{ id: "businessName", desc: false }],
    },
    data,
    columns: dynamicColumns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // sort table
  useEffect(() => {
    if (sortType === "alphabetical") {
      setSorting([{ id: "businessName", desc: false }]);
    } else {
      setSorting([]);
    }
  }, [sortType]);

  // set items per page
  useEffect(() => {
    if (setItemsPerPage)
      setItemsPerPage(table.getPaginationRowModel().rows.length);
  }, [table.getPaginationRowModel()]);

  return (
    <>
      <div className={rootClass}>
        <Table bordered className={tableClass} stackedStyle="headers">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} scope="col">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Pagination table={table} totalPages={totalPages} />
    </>
  );
}
