import * as React from "react";

function EditTable(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 19V5c0-.55.196-1.02.587-1.413A1.926 1.926 0 015 3h14c.55 0 1.02.196 1.413.587C20.803 3.98 21 4.45 21 5v6.025a3.56 3.56 0 00-2 .342V11h-3.325v3h.438l-2.438 2.427V16h-3.35v3H12v2H5c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 013 19zM5 9h14V5H5v4zm8.675 5v-3h-3.35v3h3.35zM5 14h3.325v-3H5v3zm0 5h3.325v-3H5v3zm9-.075V22h3.075l5.5-5.5c.15-.15.258-.32.325-.512s.1-.38.1-.563c0-.183-.038-.367-.113-.55a1.74 1.74 0 00-.312-.5l-.925-.925a1.504 1.504 0 00-1.075-.45 1.6 1.6 0 00-.55.1c-.183.067-.35.175-.5.325L14 18.925zm2.45 1.575h-.95v-.95l3.05-3.025.925.925-3.025 3.05z"
        fill="currentColor"
      />
    </svg>
  );
}

export default EditTable;
