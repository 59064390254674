import styles from "./ButtonBlock.module.scss";

import { Icon } from "@trussworks/react-uswds";

// components
import {
  Heading,
  Button,
  Subheading,
  IconButton,
  RoundedButton,
} from "../../Components";

// simultation components
import { AutoFocusButton, AutoHoverButton } from "../../Components/Button";

export default function ButtonBlock() {
  return (
    <div className={styles["button-block-root"]}>
      <Heading title="Buttons" />

      <div style={{ marginTop: "24px" }} />

      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default</span>
          <Button type="button">Button</Button>
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Hover</span>
          <AutoHoverButton type="button">Button</AutoHoverButton>
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Focus</span>
          <AutoFocusButton type="button">Button</AutoFocusButton>
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Disabled</span>
          <Button type="button" disabled>
            Button
          </Button>
        </div>
      </div>

      {/* Icon buttons */}
      <div style={{ marginTop: "42px" }} />
      <Subheading title="Icon Buttons" />

      <div style={{ marginTop: "24px" }} />

      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]} style={{ alignItems: "center" }}>
          <span className={styles["label"]}>Small size</span>
          <IconButton type="button" Icon={<Icon.Close />} size="sm" />
        </div>

        <div className={styles["toggle-col"]} style={{ alignItems: "center" }}>
          <span className={styles["label"]}>Medium size</span>
          <IconButton type="button" Icon={<Icon.Close />} size="md" />
        </div>

        <div className={styles["toggle-col"]} style={{ alignItems: "center" }}>
          <span className={styles["label"]}>Large size</span>
          <IconButton type="button" Icon={<Icon.Close />} size="lg" />
        </div>
      </div>

      {/* Rounded buttons */}
      <div style={{ marginTop: "42px" }} />
      <Subheading title="Rounded Buttons" />

      <div style={{ marginTop: "24px" }} />

      <div
        className={styles["grid-toggle-cols"]}
        style={{ gridTemplateColumns: "repeat(2, 240px)" }}
      >
        <div className={styles["toggle-col"]} style={{ alignItems: "center" }}>
          <span className={styles["label"]}>Primary button</span>
          <RoundedButton>Sort: Random</RoundedButton>
        </div>

        <div className={styles["toggle-col"]} style={{ alignItems: "center" }}>
          <span className={styles["label"]}>Primary button with icon</span>
          <RoundedButton>
            <Icon.SortArrow />
            Sort: Random
          </RoundedButton>
        </div>
      </div>

      <div style={{ marginTop: "24px" }} />

      <div
        className={styles["grid-toggle-cols"]}
        style={{ gridTemplateColumns: "repeat(2, 240px)" }}
      >
        <div className={styles["toggle-col"]} style={{ alignItems: "center" }}>
          <span className={styles["label"]}>Secondary button</span>
          <RoundedButton variant="secondary">Pop out button</RoundedButton>
        </div>

        <div className={styles["toggle-col"]} style={{ alignItems: "center" }}>
          <span className={styles["label"]}>Secondary button with icon</span>
          <RoundedButton variant="secondary">
            Pop out button
            <Icon.NearMe />
          </RoundedButton>
        </div>
      </div>

      <div style={{ marginTop: "24px" }} />

      <div
        className={styles["grid-toggle-cols"]}
        style={{ gridTemplateColumns: "repeat(2, 240px)" }}
      >
        <div className={styles["toggle-col"]} style={{ alignItems: "center" }}>
          <span className={styles["label"]}>Ghost button</span>
          <RoundedButton variant="ghost">Sort: Random</RoundedButton>
        </div>

        <div className={styles["toggle-col"]} style={{ alignItems: "center" }}>
          <span className={styles["label"]}>Ghost button with icon</span>
          <RoundedButton variant="ghost">
            <Icon.SortArrow />
            Sort: Random
          </RoundedButton>
        </div>
      </div>
    </div>
  );
}
