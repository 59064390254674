// styles
import styles from "./Library.module.scss";

// blocks
import {
  ToggleBlock,
  CheckboxBlock,
  MultiSelectCheckboxBlock,
  NaicsCheckboxBlock,
  ButtonBlock,
  SelectListBlock,
  SearchInputBlock,
  ToggleBarBlock,
  FilterRelationBoxBlock,
  SBATagBlock,
  FilterFlyoutBlock,
  IconBlock,
  DialogBlock,
} from "components/LibraryComponents/Blocks";

export default function LibraryPage() {
  return (
    <div className={styles["library-root"]}>
      <div className={styles["container"]}>
        <h1 className={styles["heading"]}>SBS Component Library</h1>
        <ToggleBlock />
        <CheckboxBlock />
        <MultiSelectCheckboxBlock />
        <NaicsCheckboxBlock />
        <SelectListBlock />
        <ButtonBlock />
        <FilterFlyoutBlock />
        <SearchInputBlock />
        <ToggleBarBlock />
        <FilterRelationBoxBlock />
        <SBATagBlock />
        <IconBlock />
        <DialogBlock />
      </div>
    </div>
  );
}
