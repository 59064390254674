// style
import "./ProfileStatusFilter.scss";

import { useState, useEffect } from "react";
import { DatePicker, Label, Radio } from "@trussworks/react-uswds";

import { useFilterStore } from "../../../store/useFilterStore";
import { FilterKeys } from "../../../constants/SearchProperties";

export default function ProfileStatusFilter() {
  const store = useFilterStore();

  const [isShow, setIsShow] = useState<boolean>(true);

  // useEffect(() => {
  //   if (store.filters.profileStatus.lastUpdatedOn?.value) {
  //     const inputEl = document.getElementById(
  //       "lastUpdatedDate"
  //     ) as HTMLInputElement;
  //     inputEl.value = store.filters.profileStatus.lastUpdatedOn.value;
  //   }
  // }, [store.filters.profileStatus]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;

    store.setFilter(FilterKeys.PROFILE_STATUS, {
      ...store.filters.profileStatus,
      status: {
        value: id,
        isApplied: false,
      },
    });
  };

  const handleChangeLastUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;

    if (id !== "anytime") {
      store.setFilter(FilterKeys.PROFILE_STATUS, {
        ...store.filters.profileStatus,
        lastUpdatedOn: {
          value: id,
          isApplied: false,
        },
      });
    } else {
      store.setFilter(FilterKeys.PROFILE_STATUS, {
        ...store.filters.profileStatus,
        lastUpdatedOn: {} as any,
      });
    }
  };
  const handleDateChange = (date: string | null) => {
    if (typeof date === "undefined") return;
    store.setFilter("profileStatus", {
      ...store.filters.profileStatus,
      lastUpdatedOn: {
        value: date || "",
        isApplied: false,
      },
      status: {
        value: "null",
        isApplied: false,
      },
    });
  };

  // const handleDateChange = (date: string | null) => {
  //   if (typeof date === "undefined") return;
  //   store.setFilter("profileStatus", {
  //     ...store.filters.profileStatus,
  //     lastUpdatedOn: {
  //       value: date || "",
  //       isApplied: false,
  //     },
  //     status: {
  //       value: "profile-active",
  //       isApplied: false,
  //     },
  //   });
  // };

  const toggleShow = () => setIsShow(!isShow);

  const today = new Date();

  const formattedToday = `${today.getFullYear()}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

  return (
    <div className="profile-status-filter">
      {/* <div className="date-wrapper">
        <Label htmlFor="lastUpdatedDate" className="label">
          Last updated on or after
        </Label>
        <DatePicker
          name="lastUpdatedDate"
          id="lastUpdatedDate"
          placeholder="mm/dd/yyyy"
          maxDate={formattedToday}
          defaultValue={store.filters.profileStatus.lastUpdatedOn?.value || ""}
          // value={store.filters.profileStatus?.lastUpdatedOn?.value || ""}
          onChange={handleDateChange}
        />
      </div> */}

      <span className="last-update-label">
        <strong>Last update within</strong>
      </span>

      <div className="checkbox-wrapper">
        <Radio
          id="anytime"
          name="anytime"
          label="Anytime"
          checked={
            store.filters.profileStatus.lastUpdatedOn?.value !==
              "past-3-months" &&
            store.filters.profileStatus.lastUpdatedOn?.value !==
              "past-6-months" &&
            store.filters.profileStatus.lastUpdatedOn?.value !== "past-year"
          }
          onChange={handleChangeLastUpdate}
        />
        <Radio
          id="past-3-months"
          name="past-3-months"
          label="Past 3 months"
          checked={
            store.filters.profileStatus.lastUpdatedOn?.value === "past-3-months"
          }
          onChange={handleChangeLastUpdate}
        />
        <Radio
          id="past-6-months"
          name="past-6-months"
          label="Past 6 months"
          checked={
            store.filters.profileStatus.lastUpdatedOn?.value === "past-6-months"
          }
          onChange={handleChangeLastUpdate}
        />
        <Radio
          id="past-year"
          name="past-year"
          label="Past year"
          checked={
            store.filters.profileStatus.lastUpdatedOn?.value === "past-year"
          }
          onChange={handleChangeLastUpdate}
        />
      </div>

      <div className="status-wrapper" onClick={toggleShow}>
        <span>
          <strong>SAM.gov status</strong>
        </span>
      </div>

      {isShow && (
        <div className="checkbox-wrapper">
          <Radio
            id="profile-active"
            name="profile-active"
            label="Active Profiles Only"
            checked={true  &&
             store.filters.profileStatus.status?.value !== "profile-expired" &&
            store.filters.profileStatus.status?.value !== "profile-either"
          }
            onChange={handleChange}
          />

          <Radio
            id="profile-either"
            name="profile-either"
            label="Include Expired Profiles"
            checked={
              store.filters.profileStatus.status?.value !== "profile-active" &&
              store.filters.profileStatus.status?.value !== "profile-expired" &&
              store.filters.profileStatus.status?.value === "profile-either"
            }
            onChange={handleChange}
          />

          <Radio
            id="profile-expired"
            name="profile-expired"
            label="Expired profiles only"
            checked={
              store.filters.profileStatus.status?.value === "profile-expired"
            }
            onChange={handleChange}
          />
        </div>
      )}

      <hr className="break-line" />
      <p className="bottom-text">
        Results can match <b>any</b> of these items.
      </p>
    </div>
  );
}
