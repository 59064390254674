import { useSearchStore } from "../../../store/searchStore";
import DisplayValue from "../DisplayValue";
import "./OrganizationOwnershipSection.scss";

export default function OrganizationOwnershipSection() {
  const { business_profile: { organization_ownership } } = useSearchStore();

  return (
    <div className="font-family-sans organization-ownership-root">
      <h1 className="title">Organization & ownership</h1>

      <div className="space-y-4">
        <div className="content-group">
          <h5>Legal structure</h5>
          <DisplayValue value={organization_ownership?.legal_structure} />
        </div>

        <div className="content-group">
          <h5>Current principals</h5>
          <DisplayValue value={organization_ownership?.current_principals} renderer={(_value) => (<>
            <ol type="1">
              {organization_ownership?.current_principals.map((principal, i) => (<li key={i}>{principal?.name}, {principal?.title}</li>))}
            </ol>
          </>)} />
        </div>

        <div className="content-group">
          <h5>Business development servicing office (for certifications)</h5>
          <DisplayValue value={organization_ownership?.bd_servicing_office} message="Unknown" />
        </div>
      </div>
    </div>
  );
}
