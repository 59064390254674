import * as React from "react";

function PopoutArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.343 6.343v1.994l7.899.007-8.606 8.606 1.414 1.414 8.606-8.605.007 7.898h1.994V6.343H6.343z"
        fill="currentColor"
      />
    </svg>
  );
}

export default PopoutArrow;
