import styles from "./SearchInputBlock.module.scss";

import { useState } from "react";
import classNames from "classnames";

// components
import { Heading, SearchInput, Subheading } from "../../Components";

export default function SearchInputBlock() {
  const [value, setValue] = useState<string>("");
  const [valueSmall, setValueSmall] = useState<string>("");

  return (
    <div className={styles["input-block-root"]}>
      <Heading title="Search inputs" />

      <div style={{ marginTop: "32px" }} />

      {/* Search input big */}
      <Subheading title="Big" />

      <div className={styles["block"]}>
        <span className={styles["label"]}>Default</span>
        <SearchInput
          id="search-input-big-default"
          name="search-input-big-default"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Placeholder text"
          handleClear={() => setValue("")}
        />
      </div>

      <div className={styles["block"]}>
        <span className={styles["label"]}>Hover</span>
        <SearchInput
          id="search-input-big-hover"
          name="search-input-big-hover"
          placeholder="Placeholder text"
          handleClear={() => {}}
          // NOTE: simualating Hover effect
          // In real scenario there's no need to do that, it's just for simulation in library page.
          className={styles["search-input-hover-simulation"]}
          readOnly
        />
      </div>

      <div className={styles["block"]}>
        <span className={styles["label"]}>Entered Text</span>
        <SearchInput
          id="search-input-big-entered"
          name="search-input-big-entered"
          placeholder="Placeholder text"
          handleClear={() => {}}
          value="Typed Text"
          readOnly
        />
      </div>

      <div className={styles["block"]}>
        <span className={styles["label"]}>Focus</span>
        <SearchInput
          id="search-input-big-focus"
          name="search-input-big-focus"
          placeholder="Placeholder text"
          handleClear={() => {}}
          value="Typed Text"
          // NOTE: simualating Focus effect
          // In real scenario there's no need to do that, it's just for simulation in library page.
          className={styles["search-input-focus-simulation"]}
          readOnly
        />
      </div>

      {/* Search input small */}
      <div style={{ marginTop: "32px" }} />
      <Subheading title="Little" />

      <div className={classNames([styles["block"], styles["sm"]])}>
        <span className={styles["label"]}>Default</span>
        <SearchInput
          id="search-input-small-default"
          name="search-input-small-default"
          value={valueSmall}
          placeholder="Placeholder text"
          inputSize="small"
          onChange={(e) => setValueSmall(e.target.value)}
          handleClear={() => setValueSmall("")}
        />
      </div>

      <div className={classNames([styles["block"], styles["sm"]])}>
        <span className={styles["label"]}>Hover</span>
        <SearchInput
          id="search-input-small-hover"
          name="search-input-small-hover"
          placeholder="Placeholder text"
          inputSize="small"
          handleClear={() => {}}
          // NOTE: simualating Hover effect
          // In real scenario there's no need to do that, it's just for simulation in library page.
          className={styles["search-input-hover-simulation"]}
          readOnly
        />
      </div>

      <div className={classNames([styles["block"], styles["sm"]])}>
        <span className={styles["label"]}>Entered</span>
        <SearchInput
          id="search-input-small-entered"
          name="search-input-small-entered"
          placeholder="Placeholder text"
          inputSize="small"
          handleClear={() => {}}
          value="Typed Text"
          readOnly
        />
      </div>

      <div className={classNames([styles["block"], styles["sm"]])}>
        <span className={styles["label"]}>Focus</span>
        <SearchInput
          id="search-input-small-focus"
          name="search-input-small-focus"
          placeholder="Placeholder text"
          inputSize="small"
          handleClear={() => {}}
          value="Typed Text"
          // NOTE: simualating Focus effect
          // In real scenario there's no need to do that, it's just for simulation in library page.
          className={styles["search-input-focus-simulation"]}
          readOnly
        />
      </div>
    </div>
  );
}
