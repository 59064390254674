import { useSearchStore } from "../../../store/searchStore";
import DisplayValue from "../DisplayValue";
import "./Certifications.scss";

export default function Certifications() {
    let { business_profile } = useSearchStore();
    return (
        <>
            <div id="certifications">
                <h1>Certifications</h1>
                <h2 className="sba-certifications-icon">SBA certifications</h2>
                <DisplayValue value={business_profile?.certifications?.sba_certifications} message="This firm does not have any active, pending, or former SBA Certifications" renderer={(_value) => (<>
                    {business_profile?.certifications?.sba_certifications?.map((cert, index) => {
                        return (
                            <div className="grid-row border-section" key={index}>
                                <div className="tablet:grid-col-6 inner">
                                    <div className="inner-wrap">
                                        <h4>{cert?.name}</h4>
                                        <div className="single-row">
                                            <span>{cert?.name} certified</span>
                                            <span className="right-side">{cert?.status === 'Active' ? 'Yes' : 'No'}</span>
                                        </div>
                                        <div className="single-row">
                                            <span>Exit date</span>
                                            <span >{cert?.graduation_date}</span>
                                        </div>
                                        <div className="single-row">
                                            <span>Entrance date</span>
                                            <span>{cert?.date}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="tablet:grid-col-6 inner">
                                    <div className="inner-wrap">
                                        <h4>8(a) Joint Venture - SBA certified</h4>
                                        <div className="single-row">
                                            <span>Not certified</span>
                                            <span></span>
                                        </div>
                                        <small>NOTE: Notify your servicing SBA Business Opportunity Specialist to have your 8(a) joint venture approval date reflected in
                                            DSBS.</small>
                                    </div>
                                </div> */}
                            </div>
                        )
                    })}
                </>)} />
                <h2>Ownership & self certifications</h2>
                <DisplayValue value={business_profile?.certifications?.ownership_certifications} renderer={(_value) => (<>
                    <div className="grid-row border-section">
                        <div className="tablet:grid-col-6 inner">
                            <ul className="usa-list">
                                {business_profile?.certifications?.ownership_certifications?.map((cert, index) => {
                                    return (
                                        <>
                                            <li key={index}>{cert.description}</li>
                                        </>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </>)} />
                <h2>Non-Federal government certifications</h2>
                <DisplayValue value={business_profile?.certifications?.non_federal_certifications} renderer={(_value) => (<>
                    <div className="grid-row border-section">
                        <div className="tablet:grid-col-6 inner">
                            <ul className="usa-list usa-list--unstyled">
                                {business_profile?.certifications?.non_federal_certifications?.map((cert, index) => {
                                    return (
                                        <>
                                            <li key={index}>{cert}</li>
                                        </>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </>)} />
            </div>
        </>
    )
}