import "./BusinessProfile.scss";

import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { FaArrowLeft } from "react-icons/fa";
import BusinessProfileComponent from "../../components/BusinessProfileControls";
import { useSearchStore } from "../../store/searchStore";
import { useQuery } from "@tanstack/react-query";
import { ProfileParam, getProfile } from "../../data/fetch";
import { Loader } from "components/icons";
import NotFoundPage from "pages/NotFoundPage/NotFoundPage";
import Banner from "layouts/MainLayout/Header/Banner";

const BusinessProfile = () => {
  const [elementScroll, setElementScroll] = useState("profileOverview");
  const sections = useRef<any>([]);

  const navigate = useNavigate();
  const backToResultHandler = () => {
    navigate("/advanced");
  };

  const { pronetId } = useParams();
  const { business_profile, setProfile } = useSearchStore();

  const { data: q_profile, isLoading, isError } = useQuery({
    queryKey: ["profile", pronetId],

    queryFn: () => getProfile(pronetId),

    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!pronetId,
  });

  const scrollIntoElementHandler = (e: any) => {
    const element = document.getElementById(e);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    setElementScroll(e);
  };

  const handleScroll = (event: any) => {
    const pageYOffset = window.pageYOffset;

    let newActiveSection = null;

    sections.current.forEach((section: any) => {
      const sectionOffsetTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop - 30 &&
        pageYOffset < sectionOffsetTop + sectionHeight + 80
      ) {
        newActiveSection = section.id;
      }
    });
    setElementScroll(newActiveSection);
  };

  useEffect(() => {
    sections.current = document.querySelectorAll(
      ".business-profile-single-card"
    );

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (q_profile) {
      setProfile(q_profile);
    }
  }, [q_profile]);

  // Use the userId to fetch the profile data from the API anytime the userId changes
  // useEffect(() => {
  //   console.log('userId', userId);

  // }, [userId]);

  const InPageMenuItems = [
    "Profile overview",
    "Organization & ownership",
    "Certifications",
    "NAICS Codes",
    "Service information",
    "Export profile",
    "Performance history",
  ];

  const InPageCase = (_word, _index) => {
    if (_index === 0) {
      return _word.toLowerCase();
    } else {
      return _word.charAt(0).toUpperCase() + _word.slice(1).toLowerCase();
    }
  };

  const InPageNavMenuItem = (_itemName) => {
    let camelCaseItem = _itemName
      .split(" ")
      .map(InPageCase)
      .filter((_w) => _w !== "&")
      .join("");
    // console.log(camelCaseItem);
    return (
      <>
        <li className="usa-in-page-nav__item">
          <a
            onClick={() => scrollIntoElementHandler(`${camelCaseItem}`)}
            className={`usa-in-page-nav__link ${
              elementScroll === `${camelCaseItem}` ? "usa-current" : ""
            }`}
          >
            {_itemName}
          </a>
        </li>
      </>
    );
  };

  if (isLoading) {
    return (
      <>
        <Helmet>
          <title>
            Loading...
          </title>
        </Helmet>
        <div className="loader-root">
          <Loader />
        </div>
      </>
    );
  }

  if (!isLoading && isError) {
    return (
      <>
        <Helmet>
          <title>Page not found</title>
        </Helmet>
        <NotFoundPage hideLayout />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{q_profile?.profile?.name ?? ""} | SBA Small Business Search</title>
      </Helmet>

      <Banner />

      <div id="business-profile-page">
        <div className="return-to-back">
          <FaArrowLeft color="#005ea2" onClick={backToResultHandler} />
          <Link to="/advanced" className="back-link">
            Back to results
          </Link>
        </div>
        <div className="usa-in-page-nav-container ">
          <aside
            className="usa-in-page-nav"
            data-title-text="On this page"
            data-title-heading-level="h4"
            data-scroll-offset="0"
            data-root-margin="0px 0px 0px 0px"
            data-threshold="1"
          >
            <nav aria-label="On this page" className="usa-in-page-nav__nav">
              <h4 className="usa-in-page-nav__heading">On this page</h4>
              <ul className="usa-in-page-nav__list">
                {InPageMenuItems.map((item) => InPageNavMenuItem(item))}
              </ul>
            </nav>
          </aside>
          <div id="main-content" className="main-content usa-prose">
            <BusinessProfileComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessProfile;
