import "./ExportProfileSection.scss";

import { useState } from "react";

import { useSearchStore } from "../../../store/searchStore";

// icons
import { CheckIcon } from "../../icons";
import DisplayValue from "../DisplayValue";

enum ExportStatus {
  YES = "yes",
  NO = "no",
  WANTS_TO_BE = "wants_to_be",
}

export default function ExportProfileSection() {
  const { business_profile: profile, business_profile: {export_profile: export_profile} } = useSearchStore();

  const [exportStatus, setExportStatus] = useState<ExportStatus>(
    ExportStatus.WANTS_TO_BE
  );

  const handleExportStatusChange = (value: ExportStatus) => {
    setExportStatus(value);
  };

  return (
    <div className="font-family-sans export-profile-root">
      <h1 className="title">Export profile</h1>
      <h2 className="sub-title">Trade mission online</h2>
      <div className="margin-top-3 space-y-4">
        <div className="content-group">
          <h5>Exporter status</h5>
          <DisplayValue value={export_profile?.status} />
          {/* <div
            className="status"
            onClick={() => handleExportStatusChange(ExportStatus.YES)}
          >
            <CheckIcon
              className={exportStatus === ExportStatus.YES ? "active" : ""}
            />
            <span className={exportStatus === ExportStatus.YES ? "active" : ""}>
              Yes
            </span>
          </div>
          <div
            className="status"
            onClick={() => handleExportStatusChange(ExportStatus.NO)}
          >
            <CheckIcon
              className={exportStatus === ExportStatus.NO ? "active" : ""}
            />
            <span className={exportStatus === ExportStatus.NO ? "active" : ""}>
              No
            </span>
          </div>
          <div
            className="status"
            onClick={() => handleExportStatusChange(ExportStatus.WANTS_TO_BE)}
          >
            <CheckIcon
              className={
                exportStatus === ExportStatus.WANTS_TO_BE ? "active" : ""
              }
            />
            <span
              className={
                exportStatus === ExportStatus.WANTS_TO_BE ? "active" : ""
              }
            >
              Wants to be
            </span>
          </div> */}
        </div>

        <div className="content-group">
          <h5>Export business activities</h5>
          <DisplayValue value={export_profile?.business_activities.join(', ')} />
        </div>

        <div className="content-group">
          <h5>Exporting to</h5>
          <DisplayValue value={export_profile?.exporting_to.join(', ')} />
        </div>

        <div className="content-group">
          <h5>Desired export business relationships</h5>
          <DisplayValue value={export_profile?.desired_export} />
        </div>

        <div className="content-group">
          <h5>Description of export objective(s)</h5>
          <DisplayValue value={export_profile?.description_export} />
        </div>
      </div>
    </div>
  );
}
