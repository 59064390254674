import "./Input.scss";

interface IProps {
  id: string;
  label?: string;
  error? : string;
  [x: string]: any;
}

export default function Input({ label, error, id, ...rest }: IProps) {
  return (
    <div className="input-root">
      {label && <label htmlFor={id}>{label}</label>}
      <input id={id} name={id} className="input" {...rest} />
      { error && <p className="error">{error}</p> }
    </div>
  );
}
