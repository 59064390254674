import { ButtonProps as TrussButtonProps } from "@trussworks/react-uswds/lib/components/Button/Button";
import { Button as TrussButton } from "@trussworks/react-uswds";
import classNames from "classnames";

import styles from "./Button.module.scss";

export type ButtonProps = {
  children: React.ReactNode;
} & TrussButtonProps &
  JSX.IntrinsicElements["button"];

/**
 * `Button` component draws attention to important actions with a large selectable surface.
 *
 * @param {ButtonProps} props - `Button` component props
 *
 * @see https://designsystem.digital.gov/components/button
 */
export default function Button(props: ButtonProps) {
  const { children, size, ...rest } = props;

  const buttonClasses = classNames(styles["button-root"], {
    [styles["button-big"]]: size === "big",
    [props.className]: props.className,
  });

  return (
    <TrussButton className={buttonClasses} {...rest}>
      {props.children}
    </TrussButton>
  );
}

// *********** SIMULATION COMPONENTS *****************

/**
 * `Button` component draws attention to important actions with a large selectable surface.
 *
 * @param {ButtonProps} props - `Button` component props
 *
 * @see https://designsystem.digital.gov/components/button
 *
 * * @summary The component shouldn't be used in real scenario and is just for Simulation purpose.
 */
export function AutoHoverButton(props: ButtonProps) {
  const { children, size, ...rest } = props;

  const buttonClasses = classNames(
    styles["button-root"],
    styles["button-hover-simultation"],
    {
      [styles["button-big"]]: size === "big",
      [props.className]: props.className,
    }
  );

  return (
    <TrussButton className={buttonClasses} {...rest}>
      {props.children}
    </TrussButton>
  );
}

/**
 * `Button` component draws attention to important actions with a large selectable surface.
 *
 * @param {ButtonProps} props - `Button` component props
 *
 * @see https://designsystem.digital.gov/components/button
 *
 * * @summary The component shouldn't be used in real scenario and is just for Simulation purpose.
 */
export function AutoFocusButton(props: ButtonProps) {
  const { children, size, ...rest } = props;

  const buttonClasses = classNames(
    styles["button-root"],
    styles["button-focus-simultation"],
    {
      [styles["button-big"]]: size === "big",
      [props.className]: props.className,
    }
  );

  return (
    <TrussButton className={buttonClasses} {...rest}>
      {props.children}
    </TrussButton>
  );
}
