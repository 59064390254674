import classNames from "classnames";

import styles from "./Heading.module.scss";

interface IProps {
  title: string;
  className?: string;
  [x: string]: any;
}

/**
 * Heading component
 * Show the text in Heading.
 *
 * @param {string} title - title text
 * @param {string} className - optional class name, used for styling
 */
export default function Heading({ title, className, ...rest }: IProps) {
  const classes = classNames(styles["heading-root"], className);

  return (
    <h2 className={classes} {...rest}>
      {title}
    </h2>
  );
}
