import { create } from "zustand";
import { persist } from "zustand/middleware";

// types
import { Column } from "types/common.types";

type ColumnsStore = {
  columns: Column[];

  setColumns: (columns: Column[]) => void;
  clear: () => void;
};

const useColumnsStore = create(
  persist<ColumnsStore>(
    (set) => ({
      columns: [],

      setColumns: (columns) => {
        set(() => ({
          columns,
        }));
      },

      clear: () => {
        set(() => ({
          columns: [],
        }));
      },
    }),

    // cache data to (default) localStorage
    {
      name: "columns-store",
    }
  )
);

export default useColumnsStore;
