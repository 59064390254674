import classNames from "classnames";

// stylses
import styles from "./IconButton.module.scss";

type IconButtonProps = {
  Icon: React.ReactElement;
  size: "sm" | "md" | "lg";
  className?: string;
} & JSX.IntrinsicElements["button"];

/**
 * `IconButton` component is used to display an icon button.
 *
 * @param {IconButtonProps} props - The props for the `IconButton` component.
 * @param {React.ReactElement} props.Icon - Icon to be displayed.
 * @param {Item[]} props.size - size of the button can be (sm, md, lg).
 * @param {string} props.className - The class name for the root element (Optional).
 */
export default function IconButton(props: IconButtonProps) {
  const { Icon, size, className, ...rest } = props;

  const buttonClasses = classNames(styles["icon-button-root"], {
    [styles["sm"]]: size === "sm",
    [styles["md"]]: size === "md",
    [styles["lg"]]: size === "lg",
    [className]: className,
  });

  return (
    <button className={buttonClasses} {...rest}>
      {Icon}
    </button>
  );
}
