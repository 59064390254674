import "./header.scss";

import { useRef, useState } from "react";
import {
  Alert,
  Banner,
  GovBanner,
  Icon,
  Label,
  Modal,
  ModalRef,
  Radio,
  TextInput,
  Textarea,
  Title,
  Header as USHeader,
} from "@trussworks/react-uswds";
import { Link } from "react-router-dom";

import { useFilterStore } from "../../../store/useFilterStore";
import SearchBar from "../../../components/SearchBar";

// icons
import { SBSLogo } from "../../../components/icons";
import { formatFeedbackData } from "utils/helper";
import { sendFeedback } from "data/fetch";

const sbaLogo = require("../../../assets/images/sba_logo.png");

interface HeaderProps {
  showHeader: Boolean;
}

const Header: React.FC<HeaderProps> = ({ showHeader }) => {
  const store = useFilterStore();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleDrawerOpen = () => setIsDrawerOpen(true);

  const handleDrawerClose = () => setIsDrawerOpen(false);
  //===Feedback Model===
  const [nameErrors, setNameErrors] = useState(false)
  const [emailErrors, setEmailErrors] = useState(false)
  const [isvalid, setIsvalid] = useState(false)
  const [feedBackErrors, setFeedBackErrors] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [selectOption, setSelectOption] = useState(false)
  const [selectOption1, setSelectOption1] = useState(true)
  const [feedFormValue, setFeedBackFormValue] = useState({
    name: "",
    email: "",
    feedback: "",
    selectOption: "" || "send-information1"
  })
  const [isFeedbackLoading, setIsFeedbackLoading] = useState<boolean>(false);


  const feedBackModelRef = useRef<ModalRef>({
    modalId: "feed-back",
    modalIsOpen: false,
    toggleModal: (event?: React.MouseEvent, open?: boolean, close?: boolean) => {
      if (open) {
        const currentState = feedBackModelRef.current.modalIsOpen;
        const newState = open ?? !currentState;
        feedBackModelRef.current.modalIsOpen = newState;
        setShowFeedbackModal(newState);
        return newState;
      }
      if (close) {
        feedBackModelRef.current.modalIsOpen = false;
        setShowFeedbackModal(false);
        return false;
      }
    }
  })
  const handleOptionChange = (e: any) => {
    if (e.target.id === "send-anonymously1") {
      setFeedBackFormValue(prevState => ({ ...prevState, selectOption: "send-anonymously1" }))
      setSelectOption(true)
      setSelectOption1(false)

    } else if (e.target.id === "send-information1") {
      setFeedBackFormValue(prevState => ({ ...prevState, selectOption: "send-information1" }))
      setSelectOption1(true)
      setSelectOption(false)
      setEmailErrors(false)
      setNameErrors(false)

    }
  }
  const openFeedbackModel = () => {
    setFeedBackFormValue({
      name: "",
      email: "",
      feedback: "",
      selectOption: "" || "send-information"
    })

    feedBackModelRef.current?.toggleModal(undefined, true);
  }
  const CloseFeedbackModel = () => {
    feedBackModelRef.current?.toggleModal(undefined, false, true);
  }
  const validEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }
  const SendFeedback = async () => {
    if (!feedFormValue.feedback) {
      setFeedBackErrors(true)
      return;
    } else {
      setFeedBackErrors(false)
    }

    if (feedFormValue.selectOption === "send-information") {
      if (!feedFormValue.name) {
        setNameErrors(true);
        return;
      } else {
        setNameErrors(false);
      }

      if (!feedFormValue.email) {
        setEmailErrors(true);
        return;
      } else {
        setEmailErrors(false);
      }
      if (!validEmail(feedFormValue.email)) {
        setIsvalid(true);
        return;
      } else {
        setIsvalid(false);
      }
    }

    setIsFeedbackLoading(true);

    const formattedData = formatFeedbackData(feedFormValue);

    await sendFeedback(formattedData);

    setIsFeedbackLoading(false);

    feedBackModelRef.current?.toggleModal(undefined, false, true);
  }
  const handleFeedbackchange = (event) => {
    const { name, value } = event.target
    setFeedBackFormValue((prevdata) => ({ ...prevdata, [name]: value }))
  }
  return (
    <>
      <div id="sbs-header">
        <Banner>
          <GovBanner
            id="header-gov-banner-container"
            aria-label="Official Government Website"
          />
        </Banner>

        {/* feedbackmodel */}
        {showFeedbackModal && (
          <Modal placeholder="" isLarge id={feedBackModelRef.current.modalId} ref={feedBackModelRef} isInitiallyOpen={feedBackModelRef.current.modalIsOpen} className={`padding-0 position-relative`} onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}>
            <form onSubmit={SendFeedback}>
              <div className={`margin-bottom-1 overflow-auto resultpane-model-content padding-x-4 `}>
                <p className="font-sans-xl text-bold" >
                  Give feedback
                </p>
                <div className="usa-prose margin-top-2 text-ink">
                  <p style={{ maxWidth: '100%' }}>
                    Report a bug, request a feature, ask a question, or share your thoughts about Small Business Search. If you provide your contact information, our team may follow up for more details.
                  </p>
                  <i style={{ maxWidth: '100%', marginTop: "10px", display: "block" }}>
                    A red asterisk(<span className="text-secondary">*</span>) indicates a required field
                  </i>
                  <div>

                    <p >
                      What would you like us to know? <span className="text-secondary">*</span>
                    </p>
                    <Textarea id="feedback" name="feedback" value={feedFormValue.feedback} placeholder="Type your feedback here." className="maxw-full radius-lg" style={{ backgroundColor: "#f8f8ee" }} onChange={handleFeedbackchange} />
                    {feedBackErrors && <p className="text-secondary-vivid">This field is required</p>}

                  </div>
                  <div>
                    <Radio id="send-anonymously1" name="send-anonymously" label="Send anonymously" className="padding-left-1" checked={selectOption} onChange={handleOptionChange} />
                    <Radio id="send-information1" name="send-information" label="Send with contact information" className="padding-left-1" checked={selectOption1} onChange={handleOptionChange} />
                    {!selectOption && (<div className="display-flex flex-row flex-justify-center maxw-full margin-bottom-2">
                      <div className="width-full margin-right-1 margin-left-5">
                        <Label htmlFor="vehicle">Your name <span className="text-secondary">*</span></Label>
                        <TextInput id="name" name="name" type="text" value={feedFormValue.name} style={{ backgroundColor: "#f8f8ee" }} className="maxw-full radius-lg" onChange={handleFeedbackchange} />
                        {nameErrors && <p className="text-secondary-vivid">Name is required</p>}
                      </div>
                      <div className="width-full margin-left-1">
                        <Label htmlFor="vehicle">Your email address <span className="text-secondary">*</span></Label>
                        <TextInput id="email" name="email" type="email" value={feedFormValue.email} style={{ backgroundColor: "#f8f8ee" }} className="radius-lg" aria-required onChange={handleFeedbackchange} />
                        {emailErrors && <p className="text-secondary-vivid">Email is required</p>}
                        {isvalid && <p className="text-secondary-vivid">Please enter valid email</p>}

                      </div>
                    </div>)}

                  </div>
                </div>


              </div>
              <div className="usa-modal__footer bg-accent-cool-lighter position-sticky margin-top-0 padding-0">
                <ul className="usa-button-group flex-justify-end padding-1 padding-x-2">
                  <li className="usa-button-group__item">
                    <button
                      type="button"
                      className="usa-button usa-button--unstyled padding-105 text-center"
                      onClick={CloseFeedbackModel}
                      data-close-modal
                      disabled={isFeedbackLoading}
                    >
                      Cancel
                    </button>
                  </li>
                  <li className="usa-button-group__item">
                    <button type="button" className="usa-button" onClick={SendFeedback} disabled={isFeedbackLoading} >
                      {!isFeedbackLoading ? "Send feedback" : "Sending..."}
                    </button>
                  </li>

                </ul>
              </div>
            </form>
          </Modal>
        )}
        {!showHeader ? (
          <div className={"font-family-sans header"}>
            <div className={"header-content"}>
              <a href="https://www.sba.gov/" target="_blank" rel="noreferrer">
                <img src={sbaLogo} alt="Small Business Administration Logo" />
              </a>

              <hr className="divider" />

              <Link to="/" className="link-wrapper">
                <SBSLogo />
                <h2>Small Business Search</h2>
                <span>Beta</span>
              </Link>
            </div>
          </div>
        ) : (
          <div className={"font-family-sans header"}>
            <div className={"header-content"}>
              <a href="https://www.sba.gov/" target="_blank" rel="noreferrer">
                <img src={sbaLogo} alt="Small Business Administration Logo" />
              </a>

              <hr className="divider" />

              <Link to="/" className="link-wrapper">
                <SBSLogo />
                <h2>Small Business Search</h2>
                <span>Beta</span>
              </Link>

              <div className={"search-bar-wrapper"}>
                {!isDrawerOpen ? <SearchBar placeholder="Type to search for a business by name, UEI, CAGE code, keywords and more" /> : null}
              </div>

              <div className={"search-bar-menu-btn"} onClick={handleDrawerOpen}>
                <span>MENU</span>
              </div>
            </div>

            <div
              className={`
        ${"search-bar-drawer"} 
        ${!isDrawerOpen ? "drawer-closed" : ""}`}
            >
              <div className={"drawer-header"}>
                <Icon.Close size={4} onClick={handleDrawerClose} />
              </div>

              <hr className={"divider"} />

              <h5>
                Type to search for a business by name, UEI or CAGE code
              </h5>

              <SearchBar placeholder="Type to search" />
            </div>
          </div>
        )}
      </div>
<div className="padding-3">
      <Alert type={"info"} headingLevel={"h4"} heading="Small Business Search is currently in Beta">
            Try out the new design and <a className="usa-link" onClick={openFeedbackModel} href="javascript:void(0);">share your feedback</a> to help us improve before we officially launch. Please continue to rely on <a className="usa-link" target="_blank" href="https://dsbs.sba.gov/search/dsp_dsbs.cfm?CFID=413303&CFTOKEN=278a81c56bdaec4a-0A31D489-A2D7-CAC9-CE2C621DD1D6D390">DSBS</a> for your professional needs. We'll let you know when the new Small Business Search is ready!
      </Alert>
      </div>
      {/* <hr /> */}
    </>
  );
};

export default Header;
