import styles from "./SelectListBlock.module.scss";

import { useState } from "react";

// components
import { Heading, SelectList } from "../../Components";

const items = [
  { label: "List item 1", value: "item-1" },
  { label: "List item 2", value: "item-2" },
  { label: "List item 3", value: "item-3" },
  { label: "List item 4", value: "item-4" },
];

export default function SelectListBlock() {
  const [selectedItem, setSelectedItem] = useState<{
    label: string;
    value: string;
  }>(items[0]);

  return (
    <div className={styles["select-block-root"]}>
      <Heading title="Single select" />

      {/* Single select list*/}
      <div style={{ marginTop: "24px" }} />

      <div style={{ maxWidth: "220px" }}>
        <SelectList
          items={items}
          selectedItem={selectedItem}
          handleChange={(item) => setSelectedItem(item)}
        />
      </div>
    </div>
  );
}
