import styles from "./FilterRelationBox.module.scss";

import classNames from "classnames";
import { Radio } from "@trussworks/react-uswds";
import { OperatorType } from "constants/filters";

type FilterRelationBoxProps = {
  filterName: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

export default function FilterRelationBox(props: FilterRelationBoxProps) {
  const { filterName, className, onChange, value } = props;

  const filterRelationBoxClasses = classNames(
    styles["filter-relation-box-root"],
    {
      [className]: className,
    }
  );

  const idOR = `${filterName}-${OperatorType.OR}`;
  const idAND = `${filterName}-${OperatorType.AND}`;

  return (
    <div className={filterRelationBoxClasses}>
      <h5 className={styles["title"]}>How should {filterName} relate?</h5>

      <div className={styles["block"]}>
        <Radio
          id={idOR}
          name={idOR}
          value={OperatorType.OR}
          label={
            <>
              OR: results can include <b>any</b> of these {filterName}
            </>
          }
          className={styles["radio-btn"]}
          checked={idOR === value}
          onChange={onChange}
        />

        <Radio
          id={idAND}
          name={idAND}
          value={OperatorType.AND}
          label={
            <>
              AND: results must have <b>all</b> of these {filterName}
            </>
          }
          className={styles["radio-btn"]}
          checked={idAND === value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
