import "./NatureOfBusiness.scss";

// store
import { useFilterStore } from "../../../store/useFilterStore";

const businesses = [
  "Manufacturing",
  "Construction",
  "Research & Development",
  "Services",
];

export default function NatureOfBusiness() {
  const store = useFilterStore();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    const newList = store.filters.natureOfBusiness.business;

    if (checked) {
      store.setFilter("natureOfBusiness", {
        ...store.filters.natureOfBusiness,
        business: [...newList, { value: name, isApplied: false }],
      });
    } else {
      store.setFilter("natureOfBusiness", {
        ...store.filters.natureOfBusiness,
        business: newList.filter((item) => item.value !== name),
      });
    }
  };

  return (
    <>
      <div className="new">
        {businesses.map((item, i) => (
          <div key={i} className="usa-checkbox">
            <input
              className="usa-checkbox__input"
              id={item}
              type="checkbox"
              name={item}
              value={item}
              checked={store.filters.natureOfBusiness.business
                .map((item) => item.value)
                .includes(item)}
              onChange={handleChange}
            />
            <label className="usa-checkbox__label" htmlFor={item}>
              {item}
            </label>
          </div>
        ))}

        <hr className="break-line" />
        <p>
          Results can match <b>any</b> of these items.
        </p>
      </div>
    </>
  );
}
