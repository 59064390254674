import { useState } from "react";
import {
  useFloating,
  useClick,
  useInteractions,
  autoUpdate,
  shift,
  useRole,
  useDismiss,
  FloatingPortal,
  offset,
  flip,
  FloatingFocusManager,
} from "@floating-ui/react";

// components
import { Icon } from "@trussworks/react-uswds";
import {
  RoundedButton,
  SelectList,
} from "components/LibraryComponents/Components";

// constants
import { SortList } from "constants/filters";

// generic
import { Generic } from "types/filters-store.types";

// styles
import styles from "./SortBy.module.scss";

type SortByProps = {
  selectedSort: Generic;
  setSelectedSort: React.Dispatch<React.SetStateAction<Generic>>;
};

export default function SortBy(props: SortByProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    placement: "bottom-start",
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), flip(), shift()],
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, { bubbles: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss,
  ]);

  const handleChange = (items: Generic) => {
    props.setSelectedSort(items);
  };

  return (
    <div className={styles["sort-by-root"]}>
      <RoundedButton
        variant="ghost"
        className={isOpen ? styles["active"] : ""}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <Icon.SortArrow />
        Sort: {props.selectedSort.label}
      </RoundedButton>

      <FloatingPortal id="sort-by">
        <FloatingFocusManager context={context} modal={false}>
          {isOpen && (
            <div
              ref={refs.setFloating}
              style={{ ...floatingStyles, outline: "none" }}
              {...getFloatingProps()}
              className={styles["content"]}
            >
              {/* this div is required to make the focus trap work */}
              <div tabIndex={0} style={{ outline: "none" }} />

              <SelectList
                items={SortList}
                selectedItem={props.selectedSort}
                handleChange={handleChange}
              />
            </div>
          )}
        </FloatingFocusManager>
      </FloatingPortal>
    </div>
  );
}
