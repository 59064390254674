import React from "react";
import "./ServiceInformation.scss";
import DisplayValue from "../DisplayValue";

import { useSearchStore } from "../../../store/searchStore";

export default function ServiceInformation() {
  const { business_profile: profile } = useSearchStore();

  return (
    <>
      <h1>Service information</h1>
      <h5 className="sub-title">Special equipment/materials</h5>
      <p className="service-list">
        <DisplayValue value={profile?.service_information?.special_equipment} />
      </p>
      <h5 className="sub-title">Business type percentages</h5>
      <p className="service-list">
        <DisplayValue value={profile?.service_information?.business_percentages} />
      </p>
      <table className="usa-table usa-table--borderless">
        <thead>
          <tr>
            <th scope="col">Bonding levels</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Construction Bonding Level (per contract)</th>
            <td>
              <DisplayValue value={profile?.service_information?.bonding_levels?.construction_per_contract} prefix="$" />
            </td>
          </tr>
          <tr>
            <th scope="row">Construction Bonding Level (aggregate)</th>
            <td>
              <DisplayValue value={profile?.service_information?.bonding_levels?.construction_aggregate} prefix="$" />
            </td>
          </tr>
          <tr>
            <th scope="row">Service Bonding Level (per contract)</th>
            <td>
              <DisplayValue value={profile?.service_information?.bonding_levels?.service_per_contract} prefix="$" />
            </td>
          </tr>
          <tr>
            <th scope="row">Service Bonding Level (aggregate)</th>
            <td>
              <DisplayValue value={profile?.service_information?.bonding_levels?.service_aggregate} prefix="$" />
            </td>
          </tr>
        </tbody>
      </table>
      <h5 className="sub-title">Keywords</h5>
      <p className="service-list">
        <DisplayValue value={profile?.service_information?.keywords} />
      </p>

      <h4 className="sub-title">Miscellaneous</h4>
      <p className="service-list">
        <DisplayValue value={profile?.service_information?.miscellaneous} />
      </p>
      <h5 className="sub-title">Quality assurance standards</h5>
      <p className="service-list">
        <DisplayValue value={profile?.service_information?.quality_assurance} renderer={(_value) => {
          return (
            <>
              <div className="grid-row border-section">
                <div className="tablet:grid-col-6 inner">
                  <ul className="usa-list qas-list">
                    {profile?.service_information?.quality_assurance?.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          )
        }} />
      </p >
      <h5 className="sub-title">Electronic Data Interchange capable?</h5>
      <p className="service-list">
        <DisplayValue value={profile?.service_information?.electronic_data} />
      </p>
    </>
  );
}
