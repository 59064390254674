import { useState, useEffect } from "react";
import { Icon, TextInput } from "@trussworks/react-uswds";

// component
import {
  Checkbox,
  FilterFlyout,
  MultiSelectCheckbox,
  RoundedButton,
} from "components/LibraryComponents/Components";

// types
import {
  Bonding,
  Generic,
  QualityAssuranceStandards,
  SamStatus,
} from "types/filters-store.types";

// constants
import { FilterKeys, FilterTitles, QASList } from "constants/filters";

// store
import useFiltersStore from "store/useFiltersStoreNew";

// styles
import styles from "./MoreFilterButton.module.scss";

export default function MoreFilterButton() {
  const { samStatus, qualityAssuranceStandards, bonding, setFilter } =
    useFiltersStore((state) => ({
      samStatus: state.filters.samStatus,
      qualityAssuranceStandards: state.filters.qualityAssuranceStandards,
      bonding: state.filters.bondingLevels,
      setFilter: state.setFilter,
    }));

  const [isOpen, setIsOpen] = useState(false);
  // This state only control if filter is open,
  // and has more than one value apply max-width to filter button.
  const [isHalt, setIsHalt] = useState<boolean>(true);

  const selectedValues = optionBuilder(
    samStatus,
    qualityAssuranceStandards,
    bonding
  );
  const isDisabled = selectedValues.length === 0;

  useEffect(() => {
    if (selectedValues.length === 0) {
      if (!isHalt) setIsHalt(true);
    }
  }, [selectedValues]);

  const handleChange = (items: Generic[]) => {
    setFilter(FilterKeys.QUALITY_ASSURANCE_STANDARDS, {
      qas: items,
    });
  };

  const handleIsExpiredSAM = () => {
    setFilter(FilterKeys.SAM_STATUS, {
      isExpiredSAM: !samStatus.isExpiredSAM,
    });
  };

  const handleMinimumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(FilterKeys.BONDING_LEVELS, {
      ...bonding,
      [e.target.name]: e.target.value,
    });
  };

  const handleClear = () => {
    setFilter(FilterKeys.SAM_STATUS, {
      isExpiredSAM: false,
    });

    setFilter(FilterKeys.QUALITY_ASSURANCE_STANDARDS, {
      qas: [],
    });

    setFilter(FilterKeys.BONDING_LEVELS, {
      constructionIndividual: "",
      constructionAggregate: "",
      serviceIndividual: "",
      serviceAggregate: "",
    });

    setIsHalt(true);
  };

  const handleCallback = (open: boolean) => {
    if (!open && selectedValues.length > 0) {
      setIsHalt(false);
    }
  };

  return (
    <FilterFlyout
      id={"moreFilters"}
      filterName={"More filters"}
      title={"More filters"}
      FilterIcon={<Icon.Add />}
      options={selectedValues}
      isOpen={isOpen}
      isHalt={isHalt}
      setIsOpen={setIsOpen}
      callback={handleCallback}
    >
      <div className={styles["more-filter-content"]}>
        <Checkbox
          id="expired-sam"
          name="expired-sam"
          label="Include firms with expired SAM.gov registrations"
          checked={samStatus?.isExpiredSAM}
          onChange={handleIsExpiredSAM}
        />

        <div className={styles["qas-list"]}>
          <h4>Quality Assurance Standards</h4>
          <MultiSelectCheckbox
            items={QASList}
            checkedItems={qualityAssuranceStandards?.qas}
            handleChange={handleChange}
            hideOnly
          />
        </div>

        <div className={styles["bonding-content"]}>
          <h4>Bonding levels</h4>

          <div className={styles["group"]}>
            <label htmlFor="constructionIndividual">
              Minimum construction bonding level: Individual
            </label>
            <div className={styles["input-wrapper"]}>
              <TextInput
                id="constructionIndividual"
                name="constructionIndividual"
                type="number"
                value={bonding?.constructionIndividual}
                onChange={handleMinimumChange}
              />
            </div>
          </div>

          <div className={styles["group"]}>
            <label htmlFor="constructionAggregate">
              Minimum construction bonding level: Aggregate
            </label>
            <div className={styles["input-wrapper"]}>
              <TextInput
                id="constructionAggregate"
                name="constructionAggregate"
                type="number"
                value={bonding?.constructionAggregate}
                onChange={handleMinimumChange}
              />
            </div>
          </div>

          <div className={styles["group"]}>
            <label htmlFor="serviceIndividual">
              Minimum service bonding level: Individual
            </label>
            <div className={styles["input-wrapper"]}>
              <TextInput
                id="serviceIndividual"
                name="serviceIndividual"
                type="number"
                value={bonding?.serviceIndividual}
                onChange={handleMinimumChange}
              />
            </div>
          </div>

          <div className={styles["group"]}>
            <label htmlFor="serviceAggregate">
              Minimum service bonding level: Aggregate
            </label>
            <div className={styles["input-wrapper"]}>
              <TextInput
                id="serviceAggregate"
                name="serviceAggregate"
                type="number"
                value={bonding?.serviceAggregate}
                onChange={handleMinimumChange}
              />
            </div>
          </div>
        </div>

        <div className={styles["clear-filter"]}>
          <RoundedButton
            variant="ghost"
            onClick={handleClear}
            disabled={isDisabled}
          >
            Clear filter selections
          </RoundedButton>
        </div>
      </div>
    </FilterFlyout>
  );
}

function optionBuilder(
  samFilter: SamStatus,
  qas: QualityAssuranceStandards,
  bonding: Bonding
) {
  const options: string[] = [];

  if (samFilter?.isExpiredSAM) {
    options.push("include expired SAM registrations");
  }

  if (qas?.qas?.length > 0) {
    qas.qas.forEach((qas) => {
      options.push(`QAS ${qas.value}`);
    });
  }

  if (bonding?.constructionIndividual) {
    options.push(
      `Min individual construction bonding level $${bonding.constructionIndividual}`
    );
  }

  if (bonding?.constructionAggregate) {
    options.push(
      `Min aggregate construction bonding level $${bonding.constructionAggregate}`
    );
  }

  if (bonding?.serviceIndividual) {
    options.push(
      `Min individual service bonding level $${bonding.serviceIndividual}`
    );
  }

  if (bonding?.serviceAggregate) {
    options.push(
      `Min aggregate service bonding level $${bonding.serviceAggregate}`
    );
  }

  return options;
}
