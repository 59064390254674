import classNames from "classnames";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Tag } from "@trussworks/react-uswds";

// styles
import styles from "./SBATag.module.scss";

// icons
import TimerOutline from "./TimerOutline";

const PendingTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

type SBATagProps = {
  name: string;
  isPending: boolean;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
  background?: string;
  className?: string;
};

/**
 * `SBATag` component to show the certification in tag.
 *
 * @param {SBATagProps} props - The props for the `SBATag` component.
 * @param {string} props.name - The short name of the certification.
 * @param {boolean} props.isPending - Whether the certification is pending.
 * @param {string} props.placement - The placement of the tooltip.
 * @param {string} props.background - The background color of the tag.
 * @param {string} props.className - The class name for the root element.
 *
 * @see https://designsystem.digital.gov/components/tag/
 */
export default function SBATag({
  background = "#538200",
  placement = "top",
  ...props
}: SBATagProps) {
  const classes = classNames(styles["sba-tag-root"], {
    [styles["is-pending"]]: props.isPending,
    [props.className]: props.className,
  });

  return (
    <Tag
      background={!props.isPending ? background : "transparent"}
      className={classes}
    >
      <PendingTooltip
        title={props.isPending ? "This certification is pending." : ""}
        placement={placement}
      >
        <span>
          {props.isPending && <TimerOutline />}
          {props.name}
        </span>
      </PendingTooltip>
    </Tag>
  );
}
