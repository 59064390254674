import styles from "./NaicsCheckboxBlock.module.scss";

// components
import { Heading, NAICSCheckbox } from "../../Components";

// simulation component
import {
  AutoFocusNAICSCheckbox,
  AutoHoverNAICSCheckbox,
} from "../../Components/Checkbox";

export default function NaicsCheckboxBlock() {
  return (
    <div className={styles["naics-checkbox-block-root"]}>
      <Heading title="NAICS checkbox" />

      {/* Unchecked Naics checkbox */}
      <div style={{ marginTop: "16px" }} />

      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default</span>
          <NAICSCheckbox
            id="naics-default-unchecked"
            name="naics-default-unchecked"
            code="000000"
            label="NAICS description"
          />
        </div>

        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Hover</span>
          <AutoHoverNAICSCheckbox
            id="naics-default-hover-unchecked"
            name="naics-default-hover-unchecked"
            code="000000"
            label="NAICS description"
          />
        </div>

        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Focus</span>
          <AutoFocusNAICSCheckbox
            id="naics-default-focus-unchecked"
            name="naics-default-focus-unchecked"
            code="000000"
            label="NAICS description"
            checked
            readOnly
          />
        </div>

        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Disabled</span>
          <NAICSCheckbox
            id="naics-disabled-unchecked"
            name="naics-disabled-unchecked"
            code="000000"
            label="NAICS description"
            disabled
          />
        </div>
      </div>

      {/* checked Naics checkbox */}
      <div style={{ marginTop: "40px" }} />
      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default</span>
          <NAICSCheckbox
            id="naics-default-checked"
            name="naics-default-checked"
            code="000000"
            label="NAICS description"
            defaultChecked
          />
        </div>

        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Hover</span>
          <AutoHoverNAICSCheckbox
            id="naics-default-hover-checked"
            name="naics-default-hover-checked"
            code="000000"
            label="NAICS description"
            defaultChecked
          />
        </div>

        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Focus</span>
          <AutoFocusNAICSCheckbox
            id="naics-default-focus-checked"
            name="naics-default-focus-checked"
            code="000000"
            label="NAICS description"
            checked
            readOnly
          />
        </div>

        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Disabled</span>
          <NAICSCheckbox
            id="naics-disabled-unchecked"
            name="naics-disabled-unchecked"
            code="000000"
            label="NAICS description"
            disabled
          />
        </div>
      </div>
    </div>
  );
}
