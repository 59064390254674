import { Outlet } from "react-router-dom";

// styles
import styles from "./MainLayout.module.scss";

// component
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

export default function MainLayout() {
  return (
    <div className={styles["main-layout-root"]}>
      <Header />
      <div className={styles["main-content"]}>
        <Outlet />
      </div>

      <Footer />
    </div>
  );
}
