import { Generic } from "types/filters-store.types";

export enum OperatorType {
  OR = "Or",
  AND = "And",
}

export enum FilterKeys {
  SEARCH_TERM = "searchProfiles",
  LOCATION = "location",
  SBA_CERTIFICATIONS = "sbaCertifications",
  NAICS = "naics",
  SELF_CERTIFICATIONS = "selfCertifications",
  KEYWORDS = "keywords",
  LAST_UPDATED = "lastUpdated",
  QUALITY_ASSURANCE_STANDARDS = "qualityAssuranceStandards",
  BONDING_LEVELS = "bondingLevels",
  SAM_STATUS = "samStatus",
}

export const FilterTitles = {
  [FilterKeys.LOCATION]: "Location",
  [FilterKeys.SBA_CERTIFICATIONS]: "SBA certifications",
  [FilterKeys.NAICS]: "NAICS",
  [FilterKeys.SELF_CERTIFICATIONS]: "Self-certifications",
  [FilterKeys.KEYWORDS]: "Keywords",
  [FilterKeys.LAST_UPDATED]: "Last updated",
  [FilterKeys.QUALITY_ASSURANCE_STANDARDS]: "Quality assurance standards",
  [FilterKeys.BONDING_LEVELS]: "Bonding levels",
  [FilterKeys.SAM_STATUS]: "SAM.gov Status",
};

export const ActiveSBACertifications: Generic[] = [
  {
    label: "8(a) or 8(a) Joint Venture",
    value: "1,4",
  },
  {
    label: "HUBZone",
    value: "3",
  },
  {
    label: "Women-Owned Small Business (WOSB)",
    value: "5",
  },
  {
    label: "Economically-Disadvantaged Women-Owned Small Business (EDWOSB)",
    value: "6",
  },
  {
    label: "Veteran-Owned Small Business or Joint Venture (VOSB, VOSB JV)",
    value: "7,8",
  },
  {
    label:
      "Service-Disabled Veteran-Owned Small Business or Joint Venture (SDVOSB, SDVOSB JV)",
    value: "9,10",
  },
];

export const SelfCertifications: Generic[] = [
  {
    label: "Small Disadvantaged Business",
    value: "Self-Certified Small Disadvantaged Business",
  },
  {
    label: "Veteran-Owned",
    value: "Veteran Owned Business",
  },
  {
    label: "Service-Disabled Veteran-Owned",
    value: "Service Disabled Veteran Owned",
  },
  {
    label: "Community Development Corporation (CDC) Owned Firm",
    value: "Community Development Corporation Owned Firm",
  },
  {
    label: "Woman-Owned Small Business",
    value: "Women-Owned Small Business",
  },
  {
    label: "Woman-Owned Small Business Joint Venture",
    value: "Women-Owned Small Business Joint Venture",
  },
  // {
  //   label: "Any Minority Owned",
  //   value: "Minority-Owned business",
  // },
  {
    label: "Native American Owned",
    value: "Native American Owned",
  },
  {
    label: "American Indian Owned",
    value: "American Indian Owned",
  },
  {
    label: "Tribally Owned Firm",
    value: "Tribally Owned Firm",
  },
  {
    label: "Alaskan Native Corporation (ANC) Owned Firm",
    value: "Alaskan Native Corporation Owned Firm",
  },
  {
    label: "Native Hawaiian Organization (NHO) Owned Firm",
    value: "Native Hawaiian Organization Owned Firm",
  },
  // TODO: Investigate additional Self-Certifications
  // {
  //   label: "Any non-Native American Minority Owned",
  //   value: "Minority Owned (non-Native American)",
  // },
];

export const LastUpdatedList: Generic[] = [
  {
    label: "Anytime",
    value: "anytime",
  },
  {
    label: "Within the past 3 months",
    value: "past-3-months",
  },
  {
    label: "Within the past 6 months",
    value: "past-6-months",
  },
  {
    label: "Within the past year",
    value: "past-year",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const QASList: Generic[] = [
  {
    label: "ANSI/ASQC Z1.4",
    value: "ANSI/ASQC Z1.4",
  },
  {
    label: "ISO 9000 Series",
    value: "ISO-9000 Series",
  },
  {
    label: "ISO 10012-1",
    value: "ISO 10012-1",
  },
  {
    label: "MIL-Q-9858",
    value: "MIL-Q-9858",
  },
  {
    label: "MIL-STD-45662A",
    value: "MIL-STD-45662A",
  },
];

export const SortList: Generic[] = [
  {
    label: "Random",
    value: "random",
  },
  {
    label: "Alphabetical by small business name",
    value: "alphabetical",
  },
  // {
  //   label: "Recently updated",
  //   value: "recently_updated",
  // },
];
