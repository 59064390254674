import React from "react";
import classNames from "classnames";
import { Icon } from "@trussworks/react-uswds";

// components
import {
  Dialog,
  DialogContent,
  DialogHeading,
  DialogClose,
  IconButton,
  Button,
} from "components/LibraryComponents/Components";

// styles
import styles from "./Dialog.module.scss";

type DefaultDialogProps = {
  open: boolean;
  heading: string;
  children: React.ReactNode;
  rootClass?: string;
  bodyClass?: string;
  footerClass?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirm: () => void;
};

/**
 * Renders a default dialog component with the given props.
 *
 * @param {DefaultDialogProps} props - The props for the dialog component.
 * @param {boolean} props.open - Determines if the dialog is open or closed.
 * @param {string} props.heading - The heading of the dialog.
 * @param {React.ReactNode} props.children - The content of the dialog.
 * @param {string} [props.rootClass] - Additional CSS class for the root element of the dialog.
 * @param {string} [props.bodyClass] - Additional CSS class for the body element of the dialog.
 * @param {string} [props.footerClass] - Additional CSS class for the footer element of the dialog.
 * @param {string} [props.confirmBtnText] - The text for the confirmation button.
 * @param {string} [props.cancelBtnText] - The text for the cancel button.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setOpen - Function to toggle the open state of the dialog.
 * @param {() => void} props.handleConfirm - Function to handle the confirmation action.
 * @return {JSX.Element} The rendered default dialog component.
 */
export default function DefaultDialog(props: DefaultDialogProps) {
  const rootClasses = classNames(styles["default-dialog-root"], {
    [props.rootClass]: props.rootClass,
  });

  const bodyClasses = classNames(styles["dialog-body"], {
    [props.bodyClass]: props.bodyClass,
  });

  const footerClasses = classNames(styles["dialog-footer"], {
    [props.footerClass]: props.footerClass,
  });

  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogContent className={rootClasses} hasBackdrop lockScroll>
        <div className={bodyClasses}>
          <div className={styles["header"]}>
            <DialogHeading className={styles["heading"]}>
              {props.heading}
            </DialogHeading>
            <IconButton
              type="button"
              Icon={<Icon.Close />}
              size="sm"
              onClick={() => props.setOpen(false)}
              tabIndex={-1}
            />
          </div>

          <div className={styles["content"]}>{props.children}</div>
        </div>

        <div className={footerClasses}>
          <DialogClose className={styles["btn-close"]}>
            {props?.cancelBtnText ?? "Cancel and close"}
          </DialogClose>

          <Button type="button" onClick={() => props.handleConfirm()}>
            {props?.confirmBtnText ?? "Confirmation"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
