// style
import "./LocationFilter.scss";

import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

// components
import Select from "../../Select";
import Input from "../../Input";

// store
import { useFilterStore } from "../../../store/useFilterStore";

// icon
import ArrowUpDown from "../../icons/ArrowUpDown";
import { Search, Icon } from "@trussworks/react-uswds";

// data
import {
  states,
  statesCodes,
  arrCounties,
  stateAndTerritories,
} from "../../../data";

// constants
import { FilterKeys } from "../../../constants/SearchProperties";

export default function LocationFilter() {
  const store = useFilterStore();

  const handleAddLocations = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // const inputEl = document.getElementById("search-field") as HTMLInputElement;
    // const value = inputEl.value;
    // inputEl.value = "";

    // if (value.trim() !== "") {
    //   setLocationList((prev) => [...prev, value.trim()]);
    // }
  };

  const handleRemoveLocation = (value: string) => {
    const newList = store.filters.location.states;

    store.setFilter(FilterKeys.LOCATION, {
      ...store.filters.location,
      states: newList.filter((item) => item.value !== value),
    });
  };

  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, checked } = e.target;

    const newList = store.filters.location.states;

    if (checked) {
      store.setFilter(FilterKeys.LOCATION, {
        ...store.filters.location,
        states: [...newList, { value: name, isApplied: false }],
      });
    } else {
      handleRemoveLocation(name);
    }
  };

  return (
    <div className="location-filter-root">
      <h5>Search for businesses in multiple locations</h5>
      <fieldset className="usa-fieldset">
        <legend className="usa-legend">
          Narrow your results to state or territories:
        </legend>

        <div className="country-holder">
          {stateAndTerritories?.map((item, index) => (
            <div className="usa-checkbox" key={index}>
              <input
                className="usa-checkbox__input"
                id={item}
                type="checkbox"
                name={item}
                value={item}
                onChange={checkboxHandler}
                checked={store.filters.location.states
                  .map((item) => item.value)
                  .includes(item)}
              />
              <label className="usa-checkbox__label" htmlFor={item}>
                {item}
              </label>
            </div>
          ))}
        </div>
      </fieldset>

      {/* <legend className="usa-legend">Or, search for a location by:</legend> */}

      {/* <ul className="usa-list">
        <li>County, zip or area code</li>
        <li>Congressional District</li>
        <li>Metropolitan statistical area</li>
        <li>SBA Servicing Office</li>
      </ul> */}

      {/* <Search
        size="small"
        placeholder="Type a Locations"
        className="search-wrapper"
        id="location-search"
        onSubmit={handleAddLocations}
      /> */}

      {store.filters.location.states.length > 0 && (
        <div className="location-list-content">
          {store.filters.location.states.map((item, index) => (
            <div key={index} className="location">
              <span>{item.value}</span>
              <span
                className="icon-x-wrapper"
                onClick={() => handleRemoveLocation(item.value)}
              >
                <Icon.Close />
              </span>
            </div>
          ))}
        </div>
      )}

      <hr className="divider" />
      <p>
        Results can match <b>any</b> of these locations.
      </p>
    </div>
  );
}
