import React from 'react';
import { FooterNav, Grid, GridContainer, Icon, Logo, SocialLink, SocialLinks, Footer as USFooter } from '@trussworks/react-uswds';
import './footer.scss';

const sbaLogoHorizontal = require('../../../assets/images/sba_logo_horizontal.png');

interface FooterProps {

}

const Footer: React.FC<FooterProps> = () => {

    const socialLinks = [
        {
            href: 'https://www.facebook.com/SBAgov',
            name: 'Facebook'
        },
        {
            href: 'https://www.twitter.com/SBAgov',
            name: 'Twitter'
        },
        {
            href: 'https://youtube.com/c/sbagov',
            name: 'YouTube'
        },

    ]

    const footerLinks = [
        {
            url: 'https://www.sba.gov/contracting',
            label: 'SBA.gov/contracting'
        },
        {
            url: 'https://whitehouse.gov',
            label: 'WhiteHouse.gov'
        },
        {
            url: 'https://regulations.gov',
            label: 'Regulations.gov'
        },
        {
            url: 'https://business.usa.gov',
            label: 'BusinessUSA.gov'
        },
        {
            url: 'https://usa.gov',
            label: 'USA.gov'
        },
    ]



    interface IconProps {
        iconName: string;
    }

    const DynamicIcon: React.FC<IconProps> = ({ iconName }) => {
        const IconComponent = Icon[iconName];
        return <IconComponent />;
    };

    const footerPrimary = (
        <GridContainer>
            <FooterNav
                id='sbs-footer-links'
                size='medium'
                links={footerLinks.map((link) => (
                    <a href={link.url} key={link.url} className={`usa-footer__primary-link`}>
                        {link.label}
                    </a>
                ))}
                aria-label='SBS Footer Links'
            />
        </GridContainer>
    );

    const footerSecondary = (
        <>
            <GridContainer>
                <Grid row>
                    <Logo
                        size='medium'
                        image={
                            <a href='https://www.sba.gov' title='Go to sba.gov'>
                                <img id='sba-footer-logo' className='usa-footer__logo-img' src={sbaLogoHorizontal} alt='SBA Logo' />
                            </a>
                        }
                    />
                    <div className="usa-footer__contact-links mobile-lg:grid-col-6">
                        <SocialLinks links={socialLinks.map((link) => (
                            <SocialLink name={link.name as any} href={link.href} key={link.href} aria-label={link.name}>
                                <DynamicIcon iconName={link.name} />
                            </SocialLink>
                        ))} />
                        {/* <h3 className="usa-footer__contact-heading">Contact SBA</h3>
                        <a href='#'>SBS Help Desk</a> */}
                    </div>
                </Grid>

            </GridContainer>
        </>
    );

    const footerReturnToTop = (
        <GridContainer>
            <div className="grid-container usa-footer__return-to-top">
                <a href='#' className="usa-button usa-button--unstyled" data-testid="button">Return to top</a>
            </div>
        </GridContainer>
    );


    return (
        <>
            <USFooter id='sbs-footer-container' size='medium' returnToTop={footerReturnToTop} primary={footerPrimary} secondary={footerSecondary} />
        </>
    );
};

export default Footer;
