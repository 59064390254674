import { useState, useEffect } from "react";
import { DateRangePicker, Icon } from "@trussworks/react-uswds";

// component
import {
  FilterFlyout,
  RoundedButton,
  SelectList,
} from "components/LibraryComponents/Components";

// types
import { Generic } from "types/filters-store.types";

// constants
import { FilterKeys, FilterTitles, LastUpdatedList } from "constants/filters";

// store
import useFiltersStore from "store/useFiltersStoreNew";

// utils
import { formatLastUpdatedDate, getMaxDate } from "utils/helper";

// styles
import styles from "./LastUpdatedFilterButton.module.scss";

const CUSTOM = LastUpdatedList[LastUpdatedList.length - 1].label;
const ANYTIME = LastUpdatedList[0].value;

export default function LastUpdatedFilterButton() {
  const { lastUpdated, setFilter } = useFiltersStore((state) => ({
    lastUpdated: state.filters.lastUpdated,
    setFilter: state.setFilter,
  }));

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCustom, setIsCustom] = useState<boolean>(
    lastUpdated.date.label === CUSTOM
  );
  // This state only control if filter is open,
  // and has more than one value apply max-width to filter button.
  const [isHalt, setIsHalt] = useState<boolean>(true);

  const selectedValue = getSelectedValues(lastUpdated.date);
  const isDisabled = selectedValue.length === 0;
  const selectedItem = getSelectedItem(lastUpdated.date);

  const dates = lastUpdated.date.value.split("-");
  const fromDate =
    (isCustom && dates[0] === CUSTOM.toLowerCase() ? "" : dates[0]) || "";
  const toDate = (isCustom && dates[1]) || "";

  useEffect(() => {
    if (selectedValue.length === 0) {
      if (!isHalt) setIsHalt(true);
    }
  }, [selectedValue]);

  const handleChange = (item: Generic) => {
    setFilter(FilterKeys.LAST_UPDATED, {
      ...lastUpdated,
      date: item,
    });

    if (item.label === CUSTOM) {
      setIsCustom(true);
    } else if (isCustom) {
      setIsCustom(false);
    }
  };

  const handleClear = () => {
    setFilter(FilterKeys.LAST_UPDATED, {
      ...lastUpdated,
      date: LastUpdatedList[0],
    });

    if (isCustom) {
      setIsCustom(false);
    }

    setIsHalt(true);
  };

  const handleDateChange = (value: string, isFrom: boolean) => {
    const dates = lastUpdated.date.value.split("-");
    let newDate = "";

    if (dates.length === 0) return;

    const fromDate = dates[0] === CUSTOM.toLowerCase() ? "" : dates[0];
    const toDate = dates[1] || "";

    if (isFrom) {
      newDate = `${value}-${toDate}`;
    } else {
      newDate = `${fromDate}-${value}`;
    }

    setFilter(FilterKeys.LAST_UPDATED, {
      ...lastUpdated,
      date: {
        ...lastUpdated.date,
        value: newDate,
      },
    });
  };

  const handleCallback = (open: boolean) => {
    if (!open && lastUpdated.date.value !== "") {
      setIsHalt(false);
    }
  };

  return (
    <FilterFlyout
      id={FilterKeys.LAST_UPDATED}
      filterName={FilterTitles[FilterKeys.LAST_UPDATED]}
      title={FilterTitles[FilterKeys.LAST_UPDATED]}
      FilterIcon={<Icon.Schedule />}
      defaultValue="Anytime"
      options={selectedValue}
      isOpen={isOpen}
      isHalt={isHalt}
      setIsOpen={setIsOpen}
      contentClass={styles["content-wrapper"]}
      callback={handleCallback}
    >
      <div className={styles["last-updated-filter-content"]}>
        <p className={styles["description"]} tabIndex={0}>
          Narrow your search down to small businesses that have updated their profiles
          within a recent time period.
        </p>

        <div className={styles["last-updated-list"]}>
          <SelectList
            items={LastUpdatedList}
            selectedItem={selectedItem}
            handleChange={handleChange}
          />
        </div>

        {isCustom && (
          <div className={styles["custom-block"]}>
            <DateRangePicker
              startDateLabel="From"
              startDatePickerProps={{
                id: "last-updated-from",
                name: "last-updated-from",
                defaultValue: formatLastUpdatedDate(fromDate),
                value: formatLastUpdatedDate(fromDate),
                onChange: (val: string) => handleDateChange(val, true),
              }}
              endDateLabel="To"
              endDatePickerProps={{
                id: "last-updated-to",
                name: "last-updated-to",
                maxDate: getMaxDate(),
                defaultValue: formatLastUpdatedDate(toDate),
                value: formatLastUpdatedDate(toDate),
                onChange: (val: string) => handleDateChange(val, false),
              }}
            />
          </div>
        )}

        <div className={styles["clear-filter"]}>
          <RoundedButton
            variant="ghost"
            onClick={handleClear}
            disabled={isDisabled}
          >
            Clear filter selections
          </RoundedButton>
        </div>
      </div>
    </FilterFlyout>
  );
}

function getSelectedValues(date: Generic) {
  if (date.value === ANYTIME) {
    return [];
  } else {
    return [date.label.toLowerCase()];
  }
}

// since we are updating "Custom" property value when user select date range,
// need to re mapped the value -> custom, to show it as SelectedItem in the SelectList UI
function getSelectedItem(date: Generic) {
  if (date.label === CUSTOM) {
    return { label: CUSTOM, value: CUSTOM.toLowerCase() };
  } else {
    return date;
  }
}
