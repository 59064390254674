import styles from "./AdvancedSearch.module.scss";

import { useState, useRef } from "react";
import { Helmet } from "react-helmet";

// components
import ControlsPane from "../../components/ControlsPane";
import ResultsPane from "../../components/ResultsPane";
import AdvancedFooter from "./AdvandedFooter";

// constant
import { searchProperties } from "../../constants/SearchProperties";

// icons
import { Icon, Button } from "@trussworks/react-uswds";

// store
import { useFilterStore } from "../../store/useFilterStore";

// assets
const Logo = require("../../assets/images/sba_logo.png");

export default function AdvancedSearchPage() {
  const store = useFilterStore();

  const resultsPaneRef = useRef<any>();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isOpenExpand, setIsOpenExpand] = useState<boolean>(true);

  const handleAppliedFilter = () => {
    setIsOpenExpand(true);
    store.appliedFilter();
  };
  const handleFilterOpen = () => setIsFilterOpen(true);

  const handleFilterClose = () => setIsFilterOpen(false);

  const handleClear = () => {
    setIsOpenExpand(false);

    if (resultsPaneRef.current) {
      resultsPaneRef.current.handleClearFilter();
    }
  };

  return (
    <>
      <Helmet>
        <title>SBA Small Business Search</title>
      </Helmet>

      <div className={"font-family-sans " + styles["search-result-root"]}>
        <div
          className={`
        ${styles["main-content"]}
       `}
        >
          <div
            className={`${styles["search-left-section"]} ${
              !isFilterOpen ? styles.closed : ""
            }`}
          >
            <div className={styles["filters-header"]}>
              <Icon.FilterAlt size={3} />
              <h3>Filters</h3>

              <Icon.Close
                className={styles["close-icon"]}
                size={3}
                onClick={handleFilterClose}
              />
            </div>

            <div className={styles["filters-content"]}>
              <div style={{ height: "630px" }}>
                <ControlsPane
                  key={store.clearCount}
                  searchProperties={searchProperties}
                />
              </div>
            </div>

            <div className={styles["filter-btn-content"]}>
              <Button
                type="button"
                size="big"
                onClick={handleAppliedFilter}
                disabled={store.isBtnDisabled}
              >
                Apply filters
              </Button>

              <Button type="button" unstyled onClick={handleClear}>
                Clear filter selections
              </Button>
            </div>
          </div>
          <div className={styles["search-right-section"]} id="right-section">
            <ResultsPane
              ref={resultsPaneRef}
              handleOpen={handleFilterOpen}
              openExpand={isOpenExpand}
            />

            <AdvancedFooter />
          </div>
        </div>
      </div>
    </>
  );
}
