import styles from "./SBATagBlock.module.scss";

// components
import { Heading, SBATag, Subheading } from "../../Components";

export default function SBATagBlock() {
  return (
    <div className={styles["sba-tag-root"]}>
      <Heading title="SBA certifications" />

      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Normal Tag</span>
          <SBATag name="HUBZone" isPending={false} />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Pending Tag</span>
          <SBATag name="WOSB" isPending={true} />
        </div>
      </div>

      {/* SBA certificataion tags*/}
      <div style={{ marginTop: "24px" }} />
      <Subheading title="SBA active tags" />

      <div className={styles["group"]}>
        <div className={styles["block"]}>
          <SBATag name="8(a)" isPending={false} />
          <span>8(a) active</span>
        </div>
        <div className={styles["block"]}>
          <SBATag name="8(a) JV" isPending={false} />
          <span>8(a) 8(a) Joint Venture active</span>
        </div>
        <div className={styles["block"]}>
          <SBATag name="HUBZone" isPending={false} />
          <span>HUBZone active</span>
        </div>
        <div className={styles["block"]}>
          <SBATag name="WOSB" isPending={false} />
          <span>Women Owned Small Business active</span>
        </div>
        <div className={styles["block"]}>
          <SBATag name="EDWOSB" isPending={false} />
          <span>
            Economically-Disadvantaged Women Owned Small Business active
          </span>
        </div>
        <div className={styles["block"]}>
          <SBATag name="VOSB" isPending={false} />
          <span>Veteran Owned Small Business active</span>
        </div>
        <div className={styles["block"]}>
          <SBATag name="VOSB JV" isPending={false} />
          <span>Veteran Owned Small Business Joint Venture active</span>
        </div>
        <div className={styles["block"]}>
          <SBATag name="SDVOSB" isPending={false} />
          <span>Service Disabled Veteran Owned Small Business active</span>
        </div>
        <div className={styles["block"]}>
          <SBATag name="SDVOSB JV" isPending={false} />
          <span>
            Service Disabled Veteran Owned Small Business Joint Venture active
          </span>
        </div>
      </div>
    </div>
  );
}
