import React, { useEffect, useState, useMemo, useRef } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import _ from "lodash";
import { FaTimes } from "react-icons/fa";
import "./CapabilitiesComponent.scss";
import { Link, Radio, Search, Icon, Checkbox } from "@trussworks/react-uswds";

import { NAICS, useFilterStore } from "../../store/useFilterStore";
import { FilterKeys } from "../../constants/SearchProperties";

interface JSONDataItem {
  id: string;
  naics_code: string;
  code: string;
  naics_label: string;
  childIds: string[];
  parent_id: string;
  text: string;
  children?: JSONDataItem[];
}

function convertDataToNodes(data: JSONDataItem[]): any {
  return data?.length > 0
    ? data.map((item) => {
      const hasChildren = item.children && item.children.length > 0;
      const countlength = countLastNestedChildren(item.children || []);
      const childIds = countLastNestedChildrenIds(item.children || []);
      if (countlength === 1) {
        const lastNestedChildrenValueSet = lastNestedChildrenValue(item.children || []);
        const label =
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <span className="naics-code">{lastNestedChildrenValueSet?.naics_code} </span>
            </div>
            <div>{lastNestedChildrenValueSet?.naics_label}</div>
          </div>
        return {
          value: lastNestedChildrenValueSet?.id,
          code: lastNestedChildrenValueSet?.naics_code,
          label: label,
          text: lastNestedChildrenValueSet?.naics_label,
          childIds: [],
          children: null,
          id: lastNestedChildrenValueSet.id,
        };
      }
      else {
        const label =
          hasChildren && item.children ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div><span className="naics-code-label">All</span>&nbsp;&nbsp;
                <span className="naics-code">{item.naics_code}+</span>
                <span className="naics-code-label">Codes</span>
                <span className="naics-count">({countlength} Codes)</span>
              </div>
              <div className="naics-lebel">{item.naics_label}</div>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <span className="naics-code">{item.naics_code} </span>
              </div>
              <div>{item.naics_label}</div>
            </div>
          );
        return {
          id: item.id,
          value: item.id,
          code: item.naics_code,
          label: label,
          text: item.naics_label,
          childIds,
          children:
            hasChildren && item.children
              ? convertDataToNodes(item.children)
              : null,
        };
      }
    })
    : [];
}


function countLastNestedChildrenIds(data: JSONDataItem[]): string[] {
  const idArray: string[] = [];

  function collectIds(item: JSONDataItem) {
    if (!!item.children?.length) {
      item.children.forEach(collectIds);
    } else {
      idArray.push(item.id);
    }
  }

  data.forEach(collectIds);

  return idArray;
}
function countLastNestedChildrenCodeNew(data: JSONDataItem[], checked): string[] {
  const idArray: string[] = [];
  function allDirectChildChecked(item: JSONDataItem) {
    return item?.childIds && item?.childIds?.length > 0 && item.childIds.every((child) => checked.includes(child));
  }
  function collectIds(item: JSONDataItem) {
    if (item?.childIds && item?.childIds?.length > 0) {
      if (allDirectChildChecked(item)) {
        idArray.push(item.code)
      }
      else {
        item.children.forEach(collectIds);
      }
    }
    else {
      if (checked.includes(item.id)) {
        idArray.push(item.code)
      }
    }
  }


  data.forEach(collectIds);

  return idArray;
}


function countLastNestedChildren(data: JSONDataItem[]): number {
  let count = 0;

  function countChildren(item: JSONDataItem) {
    let childrensData = item.children || [];
    if (childrensData.length === 0) {
      count += 1; // Count the last level child
    } else {
      childrensData.forEach(countChildren); // Traverse deeper
    }
  }
  data.forEach(countChildren);
  return count;
}

function naicsChildrencode(data: any[]) {

  let code = [];

  function countChildrencode(item: any) {
    let childrensData = item.children || [];
    if (childrensData.length === 0) {

      code.push(item.code)
    } else {
      if (Array.isArray(childrensData)) {
        code.push(item.code)

        childrensData.forEach(countChildrencode);
      }
    }

  }
  data.forEach(countChildrencode);

  return code;
}


function lastNestedChildrenValue(data: JSONDataItem[]) {
  let value = null;

  function countChildren(item: JSONDataItem) {
    let childrensData = item.children || [];
    if (childrensData.length === 0) {
      value = item
    } else {
      childrensData.forEach(countChildren); // Traverse deeper
    }
  }
  data.forEach(countChildren);
  return value;
}

export default function CapabilitiesComponent() {
  const store = useFilterStore();

  const [result, setResult] = useState<JSONDataItem[]>([]);
  const [checked, setChecked] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showcheckbox, setShowcheckbox] = useState<boolean>(false);
  const [moreChecked, setMoreChecked] = useState<any[]>([...checked]);
  const popupRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    fetch("naicsJson.json")
      .then((data) => {
        return data.json();
      })
      .then((categories) => {
        // Step 1: Create a map of items by their IDs
        const itemMap: Record<string, JSONDataItem> = {};
        categories.forEach((item: any) => {
          // Clone the item to avoid modifying the original data
          const clonedItem = { ...item, children: [] };
          itemMap[clonedItem.id] = clonedItem;
        });

        // Step 2: Link items to their parent items
        const rootCategories: JSONDataItem[] = [];
        categories.forEach((item: any) => {
          const parent = itemMap[item.parent_id || "0"];
          if (parent) {
            parent.children = parent.children ?? [];
            parent.children.push(itemMap[item.id]);
          } else {
            rootCategories.push(itemMap[item.id]);
          }
        });

        const childitems: any = []
        const selectOnlyChilds = (data: JSONDataItem[]) => {
          data.forEach((child: JSONDataItem) => {
            if (child.children && child.children.length > 0) {
              selectOnlyChilds(child.children)
            } else {
              childitems.push(child)
            }
          });
        }

        rootCategories.forEach((category: JSONDataItem) => {
          selectOnlyChilds(category.children)
        })

        childitems.forEach((i: string) => {
          const ids = [] as string[]
          ids.push(i)
        });

        const nodes: any = childitems
          ? convertDataToNodes(childitems)
          : [];

        setResult(nodes);
      });
  }, []);

  useEffect(() => {
    if (store.filters.capabilities.naics.length > 0) {
      setChecked(
        store.filters.capabilities.naics.flatMap((item) => item.ids || [])
      );
      setMoreChecked(
        store.filters.capabilities.naics.flatMap((item) => item.ids || [])
      );
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowcheckbox(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  let selectedCategory = useMemo(() => {
    let selectedCategories: NAICS[] = [];

    result.forEach((item) => {
      const appliedCatg = store.filters.capabilities.naics.find(
        (catg) => catg.code === item.code
      );

      const currentCat = {
        name: item.text,
        code: item.code,
        id: item.id,
        ids: [] as string[],
        codeNumber: countLastNestedChildrenCodeNew([item], checked),
        isApplied: appliedCatg?.isApplied ?? false,
      };
      let a = checked.find((checkId) => {
        return checkId == currentCat.id;
      });
      if (a) {
        currentCat.ids.push(a);
      }
      if (currentCat.ids.length) {
        selectedCategories = [...selectedCategories, currentCat];
      }
    });

    return selectedCategories;
  }, [result, checked]);

  let modelselectedCategory = useMemo(() => {
    let selectedCategories: NAICS[] = [];

    result.forEach((item) => {
      const appliedCatg = store.filters.capabilities.naics.find(
        (catg) => catg.code === item.code
      );

      const currentCat = {
        name: item.text,
        code: item.code,
        id: item.id,
        ids: [] as string[],
        codeNumber: naicsChildrencode(item.children || []),
        isApplied: appliedCatg?.isApplied ?? false,
      };
      let a = moreChecked.find((checkId) => {
        return checkId == currentCat.id;
      });
      if (a) {
        currentCat.ids.push(a);
      }
      if (currentCat.ids.length) {
        selectedCategories = [...selectedCategories, currentCat];
      }
    });

    return selectedCategories;
  }, [result, moreChecked]);

  const caneclAndRemoveCode = () => {
    setChecked([]);
    setShowModal(false);
  }

  useEffect(() => {
    if (selectedCategory.length > 0) {
      store.setFilter(FilterKeys.CAPABILITIES, {
        ...store.filters.capabilities,
        naics: selectedCategory,
      });
    }
    else {
      store.setFilter(FilterKeys.CAPABILITIES, {
        ...store.filters.capabilities,
        naics: [],
      }
      );
    }
  }, [selectedCategory]);

  const getAllValuesFromNodes = (nodes: any, firstLevel: any): any => {
    if (firstLevel) {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }

      return values;
    } else {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    }
  };

  const onSearchInputChange = (data: string, searchedNodes: any) => {
    if (data.trim() === "") {
      setSearchQuery(data);
      setExpanded([]);
      setIsSearching(false);
      setShowError(false);
    } else {
      setSearchQuery(data);
      setExpanded(getAllValuesFromNodes(searchedNodes, true));
      setIsSearching(true);
      setShowcheckbox(true)
    }
  };

  const getHighlightText = (node: any, keyword: string) => {
    const startIndex = node.text.toLowerCase().indexOf(keyword.toLowerCase());
    const codeIndex = node.code.toLowerCase().indexOf(keyword.toLowerCase());
    const countlength = countLastNestedChildren(node.children || []);
    const hasChildren = !!node?.children?.length;
    return startIndex !== -1 ? (
      hasChildren ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <span className="naics-code">{node.code} </span>
            <span className="naics-count">({countlength} Codes)</span>
          </div>
          <div>
            <span>
              {node.text.substring(0, startIndex)}
              <span style={{ background: "#fce3a0" }}>
                {node.text.substring(startIndex, startIndex + keyword.length)}
              </span>
              {node.text.substring(startIndex + keyword.length)}
            </span>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <span className="naics-code">{node.code}</span>
          </div>
          <div>
            <span>
              {node.text.substring(0, startIndex)}
              <span style={{ background: "#fce3a0" }}>
                {node.text.substring(startIndex, startIndex + keyword.length)}
              </span>
              {node.text.substring(startIndex + keyword.length)}
            </span>
          </div>
        </div>
      )
    ) : codeIndex !== -1 ? (
      hasChildren ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <span>
              {node.code.substring(0, codeIndex)}
              <span style={{ background: "#fce3a0" }}>
                {node.code.substring(codeIndex, codeIndex + keyword.length)}
              </span>
              {node.code.substring(codeIndex + keyword.length)}
            </span>
            <span className="naics-count">({countlength} Codes)</span>
          </div>
          <div>{node.text}</div>
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <span>
              {node.code.substring(0, codeIndex)}
              <span style={{ background: "#fce3a0" }}>
                {node.code.substring(codeIndex, codeIndex + keyword.length)}
              </span>
              {node.code.substring(codeIndex + keyword.length)}
            </span>
          </div>
          <div>{node.text}</div>
        </div>
      )
    ) : hasChildren ? (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <span className="naics-code">{node.code} </span>
          <span className="naics-count">({countlength} Codes)</span>
        </div>
        <div>{node.text}</div>
      </div>
    ) : (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <span className="naics-code">{node.code} </span>
        </div>
        <div>{node.text}</div>
      </div>
    );
  };

  const keywordFilter = (nodes: any, keyword: any) => {
    const newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilter(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (
          n.text.toLowerCase().includes(keyword.toLowerCase()) ||
          n.code.toLowerCase().startsWith(keyword.toLowerCase())
        ) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.text.toLowerCase().includes(keyword.toLowerCase()) ||
          n.code.toLowerCase().startsWith(keyword.toLowerCase())
        ) {
          n.label = getHighlightText(n, keyword);
          newNodes.push(n);
        }
      } else {
        if (
          n.text.toLowerCase().includes(keyword.toLowerCase()) ||
          n.code.toLowerCase().startsWith(keyword.toLowerCase())
        ) {
          n.label = getHighlightText(n, keyword);
          newNodes.push(n);
        }
      }
    }
    return newNodes;
  };

  const filteredNodes = searchQuery
    ? keywordFilter(_.cloneDeep(result), searchQuery)
    : result;

  useEffect(() => {
    searchQuery && !filteredNodes.length
      ? setShowError(true)
      : setShowError(false);
  }, [filteredNodes]);

  const handleClose = (ids: string[]) => {
    let newChecked = checked.filter((item) => {
      return !ids.includes(item);
    });
    setChecked([...newChecked]);
  };

  const handleRelate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;

    store.setFilter(FilterKeys.CAPABILITIES, {
      ...store.filters.capabilities,
      matchType: {
        value: id,
        isApplied: false,
      },
    });
  };
  const handleLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;

    store.setFilter(FilterKeys.CAPABILITIES, {
      ...store.filters.capabilities,
      matchlimit: {
        value: id,
        isApplied: false,
      },
    });
  };

  const chooseMoreCodePopOpen = () => {
    setMoreChecked(checked);
    setShowModal(true);
    setSearchQuery("");
  }

  const chooseMoreCodePopClose = () => {
    setShowModal(false);
    setMoreChecked([]);
  }

  const continueWithThisCodes = () => {
    setShowModal(false);
    setChecked(moreChecked);
    setMoreChecked([]);
  }

  const handleModelClose = (ids: string[]) => {
    let newMoreChecked = moreChecked.filter((item) => {
      return !ids.includes(item);
    });
    setMoreChecked([...newMoreChecked]);
  };

  function findNestedChildernValues(data) {
    const values = [];
    function recurseChildren(children) {
      if (children && children?.length > 0) {
        children?.forEach(child => {
          if (child.children && child.children.length > 0) {
            values.push(child.value)
            recurseChildren(child.children)
          } else {
            values.push(child.value)
          }
        });
      }
      else {
        values.push(data.value)
      }
    }
    recurseChildren(data.children);
    return values
  }
  return (
    <>
      <div className={`usa-model usa-modal-wrapper ${showModal ? "is-visible" : "is-hidden"} `} role="dialog" id="example-modal-1"
        aria-labelledby="modal-1-heading" aria-describedby="modal-1-description" data-opener="modal-385097" >
        <div className="usa-modal-overlay" aria-controls="example-modal-1">
          <div className="usa-modal  usa-modal--lg" tabIndex={-1}>
            <div className="usa-modal__content overflow-auto">
              <div className="usa-modal__main">
                <div id="example-modal-1" className="naics-container">
                  <h2 className="usa-modal__heading">
                    Complete list of NAICS codes
                  </h2>
                  <div className="naics-section">
                    <div
                      className="naics">
                      <CheckboxTree
                        nodes={filteredNodes}
                        checked={moreChecked}
                        expanded={expanded}
                        onCheck={(checkedValues) => {
                          // if (!isSearching) {
                          setMoreChecked(checkedValues);
                          // } 
                          // else {
                          //   setMoreChecked([checkedValues]);
                          // }

                          if (checkedValues.length === 0) {
                            store.setFilter(FilterKeys.CAPABILITIES, {
                              ...store.filters.capabilities,
                              naics: [],
                            });
                          }
                        }}
                        onExpand={(expanded) => setExpanded(expanded)}
                        showNodeIcon={false}
                        icons={{
                          check: <span className="checked-box" />,
                          uncheck: <span className="unchecked-box" />,
                          halfCheck: <span className="half-checked-box" />,
                        }}
                      />
                    </div>

                    {modelselectedCategory.length > 0 && (
                      <div
                        className="selected-naics-section display-flex"
                        style={{
                          marginBottom: "1rem",
                          padding: "2px 8px",
                          flexWrap: "wrap",
                        }}
                      >
                        {modelselectedCategory?.map((selected: any, index: number) => (
                          <button key={index} className="option-button margin-05">
                            {selected.code}+ {selected.name} ({selected.ids.length})
                            <div className="close">
                              <span className="cheveron-icon">
                                <FaTimes
                                  onClick={() => handleModelClose(selected.ids)}
                                  color="#005ea2"
                                />
                              </span>
                            </div>
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="usa-modal__footer">
                  <ul className="usa-button-group">
                    <li className="usa-button-group__item">
                      <button
                        type="button"
                        className="usa-button"
                        data-testid="button"
                        aria-label="Open this window"
                        aria-controls="example-modal-1"
                        data-open-modal={() => setShowModal(false)}
                        onClick={continueWithThisCodes}
                      >
                        Continue with these codes
                      </button>
                    </li>
                    <li className="usa-button-group__item">
                      <button type="button"
                        className="usa-button usa-button--unstyled padding-105 text-center"
                        onClick={() => caneclAndRemoveCode()}
                      >
                        Cancel and remove codes
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <button type="button" className="usa-button usa-modal__close" aria-label="Close this window"
                data-open-modal={() => setShowModal(false)}
                onClick={() => chooseMoreCodePopClose()}>
                <Icon.Close />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="naics-container">
        <div className="naics-section">
          <h5>Search by Code or Name</h5>
          <Search
            inputId="capabilities"
            size="small"
            placeholder="Type to find codes"
            className="search-wrapper"
            onChange={(event) => {
              event?.preventDefault();
              const target = document.getElementById(
                "capabilities"
              ) as HTMLInputElement;
              onSearchInputChange(target.value, filteredNodes);
            }}
            onSelect={() => { setShowcheckbox(true) }}
            onSubmit={(event) => {
              event?.preventDefault();
              const target = document.getElementById(
                "capabilities"
              ) as HTMLInputElement;
              onSearchInputChange(target.value, filteredNodes);
            }}
          />
            <button type="button" className="usa-button usa-button--unstyled padding-105 text-center border-0 closebtn" 
            onClick={()=>{
              const target = document.getElementById(
                "capabilities"
              ) as HTMLInputElement;
              target.value = "";
              onSearchInputChange("", filteredNodes);
            }}>
                <Icon.Close />
              </button>
          {showError && (
            <div className="error">
              There are no NAICS codes found for "{searchQuery}". See a complete
              list of NAICS codes to search for here:
              <b>
                <Link
                  className="error"
                  target="_blank"
                  href={"https://www.census.gov/naics/"}
                >
                  {" "}
                  census.gov/naics/
                </Link>
              </b>
            </div>
          )}
          <div className="search-result-option" ref={popupRef}>
            {showcheckbox &&
              <CheckboxTree
                nodes={filteredNodes}
                checked={checked}
                expanded={expanded}
                onCheck={(checkedValues, nodes) => {


                  if (!isSearching) {
                    setChecked(checkedValues);
                  } else {
                    if (nodes.checked) {
                      setChecked([...checked, ...checkedValues]);
                    }
                    else {

                      const nestedChildrenValue = findNestedChildernValues(nodes);
 
                      let onlyChecked = checked.filter(value =>
                      (
                        !nestedChildrenValue.includes(value)
                      ))
                      setChecked([...onlyChecked]);
                    }
                  }

                  if (checkedValues.length === 0) {
                    store.setFilter(FilterKeys.CAPABILITIES, {
                      ...store.filters.capabilities,
                      naics: [],
                    });
                  }
                }}
                onExpand={(expanded) => setExpanded(expanded)}
                showNodeIcon={false}
                icons={{
                  check: <span className="checked-box" />,
                  uncheck: <span className="unchecked-box" />,
                  halfCheck: <span className="half-checked-box" />,
                }}
              />
            }
          </div>
          <div className="search-result">
            <button
              type="button"
              className="usa-button usa-button--unstyled padding-105 text-center"
              data-testid="button"
              aria-label="Open this window"
              aria-controls="example-modal-1"
              data-open-modal
              onClick={() => chooseMoreCodePopOpen()}
            >
              Or, choose from a list of all codes
            </button>

            {selectedCategory.length > 0 && (
              <div
                className="selected-naics-section"
                style={{
                  marginBottom: "1rem",
                  padding: "2px 8px",
                }}
              >
                {selectedCategory?.map((selected: any, index: number) => (
                  <button key={index}>
                    {selected.code} - {selected.name} ({selected.ids.length})
                    <div className="close">
                      <span>
                        <FaTimes
                          onClick={() => handleClose(selected.ids)}
                          color="#005ea2"
                        />
                      </span>
                    </div>
                  </button>
                ))}
              </div>
            )}
            <Checkbox
              id="primary"
              name="primary"
              label="Only search primary NAICS"
              checked={
                store.filters.capabilities.matchlimit?.value === "primary"
              }
              onChange={handleLimit}
            />
             <Checkbox
              id="small"
              name="small"
              label="Only search small NAICS"
              checked={
                store.filters.capabilities.matchlimit?.value === "small"
              }
              onChange={handleLimit}
            />
          </div>

          <hr className="divider" />
          <h5>How should these codes relate?</h5>
          <div className="radio-group">
            <Radio
              id="naics-or"
              name="naics-or"
              label="OR: results can match any of these"
              checked={
                store.filters.capabilities.matchType?.value === "naics-or"
              }
              onChange={handleRelate}
            />
            <Radio
              id="naics-and"
              name="naics-and"
              label="AND: results must match all of these"
              checked={
                store.filters.capabilities.matchType?.value === "naics-and"
              }
              onChange={handleRelate}
            />
          </div>
        </div>
      </div>
    </>
  );
}
