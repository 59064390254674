import ProfileOverviewSection from "../components/BusinessProfileControls/ProfileOverviewSection";
import OrganizationOwnershipSection from "../components/BusinessProfileControls/OrganizationOwnershipSection";
import ServiceInformation from "../components/BusinessProfileControls/ServiceInformation";
import Certifications from "../components/BusinessProfileControls/Certifications";
import NaicsCodes from "../components/BusinessProfileControls/NaicsCodes";
import ExportProfileSection from "../components/BusinessProfileControls/ExportProfileSection";
import PerformanceHistorySection from "../components/BusinessProfileControls/PerformanceHistorySection";

export interface BusinessProfile {
  name: string;
  header: string;
  subTitle?: string;
  content: string;
  component?: any;
  componentParams?: object;
}

export const businessProfiles: BusinessProfile[] = [
  {
    name: "profileOverview",
    header: "Profile Overview",
    content: "Profile Overview",
    component: ProfileOverviewSection,
  },
  {
    name: "organizationOwnership",
    header: "Organization & Ownership",
    content: "Organization & Ownership",
    component: OrganizationOwnershipSection,
  },
  {
    name: "certifications",
    header: "Certifications",
    subTitle: "Content!",
    content: "Content Content Content",
    component: Certifications,
  },
  {
    name: "naicsCodes",
    header: "NAICS Codes",
    subTitle: "Content!",
    content: "Content Content Content",
    component: NaicsCodes,
  },
  {
    name: "serviceInformation",
    header: "Service Information",
    subTitle: "Content!",
    content: "Content Content Content",
    component: ServiceInformation,
  },
  {
    name: "exportProfile",
    header: "Export Profile",
    content: "Export Profile",
    component: ExportProfileSection,
  },
  {
    name: "performanceHistory",
    header: "Performance History",
    content: "Content Content Content",
    component: PerformanceHistorySection,
  },
];
