import { create } from "zustand";
import { persist } from "zustand/middleware";

const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    console.log(e);
  }
};

const initialData = {
    exportTablecolumnData:[]
};

export const useExportTableColumnStore = create<any>()(
  persist(
    (set) => ({
      ...initialData,
      setFilter: (filterItems) => {
        set((state) =>  ({
          ...state,exportTablecolumnData:filterItems
        }));
      },
      clearFilter: () => {
        set((state) => ({
         
        }));
      },
    }),
    {
      name: "exportTableColumnStore-v1.0",
      getStorage: () => localStorage,
      serialize: (state) => JSON.stringify(state),
      deserialize: (state) => JSON.parse(state),
    }
  )
);

useExportTableColumnStore.subscribe(() => {
  saveState("exportTableColumnStore-v1.0", useExportTableColumnStore.getState());
});
