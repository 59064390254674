import styles from "./AppliedFilterSentence.module.scss";

// store
import useFiltersStore from "store/useFiltersStoreNew";

// utils
import { getFiltersSentence } from "utils/sentence-builder";

export default function AppliedFilterSentence() {
  const filters = useFiltersStore((state) => state.filters);

  const sentence = getFiltersSentence(filters);

  return (
    <p
      className={styles["applied-fliter-sentence-root"]}
      dangerouslySetInnerHTML={{ __html: sentence }}
    />
  );
}
