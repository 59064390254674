import { Checkbox } from '@trussworks/react-uswds'

export const OverviewInformation = ({ handleCheckBoxChange, checked,length,name }) => {
  const checkBoxData = [
    {
      id: "Unique Entity Identifier",
      name: "uei",
      label: "UEI (Unique Entity Identifier)"
    },
    {
      id: "Last updated date",
      name: "last_updated",
      label: "Last updated date"
    },
    {
      id: "Phone number",
      name: "phone",
      label: "Phone number"
    },
    {
      id: "Fax number",
      name: "fax",
      label: "Fax number"
    },
    {
      id: "Website",
      name: "url",
      label: "Website"
    },
    {
      id: "E-Commerce website",
      name: "e_commerce_website",
      label: "E-Commerce website"
    },
    {
      id: "Business-trade-name",
      name: "business_trade_name",
      label: "Business trade name"
    },
    {
      id: "county-code",
      name: "county_code",
      label: "County code"
    },
    {
      id: "congressional-district",
      name: "congressional_district",
      label: "Congressional district"
    },
    {
      id: "metropolitan-area",
      name: "metropolitan_statistical_area",
      label: "Metropolitan statistical area"
    },
    {
      id: "CAGE-code",
      name: "cage_code",
      label: "CAGE code"
    },
    {
      id: "year-established",
      name: "year_established",
      label: "Year established"
    },
    {
      id: "accepts-government",
      name: "government_credit_card",
      label: "Accepts government credit card?"
    },

  ]
  return (
    <>
      {
        checkBoxData?.map((checkbox:any) => {
          
          return (
            <Checkbox
              id={name+'_'+checkbox.id}
              name={checkbox.name}
              label={checkbox.label}
              className="margin-y-2"
              onChange={(e) => handleCheckBoxChange(checkbox.name, checkbox.label, e.target.checked,checkbox.id)}
              checked={ checked.some(item => item.name === checkbox.name)}
              disabled={checked.length == length && checked.some(item => item.name !== checkbox.name && !checked.some(item => item.name === checkbox.name)) }
              labelDescription={checkbox?.labelDescriptions}
            />
          )
        })
      }

    </>
  )
}
export const OrganizationProperty = ({ handleCheckBoxChange, checked,length,name}) => {
  const checkBoxData = [
    {
      id: "legal-structure",
      name: "legal_structure",
      label: "Legal structure"
    }
    ,
    {
      id: "current-principal",
      name: "current_principals",
      label: "Current principals",
      labelDescription:"Principal names and titles will be in a numbered list in a single cell.",
    }
  ]
  return (
    <div>

      {
        checkBoxData?.map((checkbox) => {
          return (
            <Checkbox
              id={name+'_'+checkbox.id}
              name={checkbox.name}
              label={checkbox.label}
              className="margin-y-2"
              onChange={(e) => handleCheckBoxChange(checkbox.name, checkbox.label, e.target.checked,checkbox.id)}
              checked={checked.some(item => item.name === checkbox.name)}
              disabled={checked.length == length && checked.some(item => item.name !== checkbox.name && !checked.some(item => item.name === checkbox.name)) }
              labelDescription={checkbox?.labelDescription}
            />
          )
        })
      }

</div>
  )
}
export const Certifications = ({ handleCheckBoxChange, checked ,length,name}) => {
  const checkBoxData = [
    {
      id: "certification-status",
      name: "certStatus_8a",
      label: "8(a) certification status",
      labelDescription:"The cell will say active, expired, or will be left blank."
    },
    // {
    //   id: "case-number",
    //   name: "caseNumber",
    //   label: "8(a) case number",
    // },
    {
      id: "entrance-date",
      name: "certDateStart_8a",
      label: "8(a) entrance date",
    },
    {
      id: "exit-date",
      name: "certDateExit_8a",
      label: "8(a) exit date",
    },
    {
      id: "joint-venture-certification",
      name: "certStatus_8aJV",
      label: "8(a) Joint Venture certification status",
      labelDescription:"The cell will say active, expired, or will be left blank."
    },
    {
      id: "joint-venture-entrance-date",
      name: "certDateStart_8aJV",
      label: "8(a) Joint Venture entrance date",
    },
    {
      id: "joint-venture-exit-date",
      name: "certDateExit_8aJV",
      label: "8(a) Joint Venture exit dates",
    }
  ]
  const checkBoxData2 = [
    {
      id: "HBZone-status",
      name: "certStatus_HZ",
      label: "HUBZone certification status",
      labelDescription:"The cell will say active, expired, or will be left blank."
    },
    {
      id: "HUBZone-date",
      name: "certDateStart_HZ",
      label: "HUBZone certification date",
    },
  ]
  const checkBoxData3 = [
    {
      id: "certStatus_WOSB",
      name: "certStatus_WOSB",
      label: "Women-Owned Small Business (WOSB) certification status",
      labelDescription:"The cell will say pending, active, expired, or will be left blank."

    },
    {
      id: "WOSB-date",
      name: "certDateStart_WOSB",
      label: "Women-Owned Small Business (WOSB) certification date",
    },
    {
      id: "certStatus_EDWOSB",
      name: "certStatus_EDWOSB",
      label: "Economically-Disadvantaged Women-Owned Small Business (EDWOSB) certification status",
      labelDescription:"The cell will say pending, active, expired, or will be left blank."

    },
    {
      id: "EDWOSB-date",
      name: "certDateStart_EDWOSB",
      label: "Economically-Disadvantaged Women-Owned Small Business (EDWOSB) certification date",
      
    },
  ]
  const checkBoxData4 = [
    {
      id: "certStatus_VOSB",
      name: "certStatus_VOSB",
      label: "Veteran-Owned Small Business (VOSB) certification status",
      labelDescription:"The cell will say active, expired, or will be left blank."
    },

    {
      id: "VOSB-joint-status",
      name: "certStatus_VOSBJV",
      label: "Veteran-Owned Small Business Joint Venture (VOSB JV) certification status",
      labelDescription:"The cell will say active, expired, or will be left blank."
    },

    {
      id: "VOSB-entrance-date",
      name: "certDateStart_VOSB",
      label: "Veteran-Owned Small Business (VOSB) entrance date",
    },

    {
      id: "VOSB-exit-date",
      name: "certDateExit_VOSB",
      label: "Veteran-Owned Small Business (VOSB) exit dates",
    },
    {
      id: "SDVOSB-status",
      name: "certStatus_SDVOSB",
      label: "Service-Disabled Veteran-Owned Small Business (SDVOSB) certification status",
      labelDescription:"The cell will say active, expired, or will be left blank."
    },

    {
      id: "SDVOSB-Joint-status",
      name: "certStatus_SDVOSBJV",
      label: "Service-Disabled Veteran-Owned Small Business Joint Venture (SDVOSB JV) certification status",
      labelDescription:"The cell will say active, expired, or will be left blank."
    },
    {
      id: "SDVOSB-entrance-date",
      name: "certDateStart_SDVOSB",
      label: "Service-Disabled Veteran-Owned Small Business (SDVOSB) entrance date",
    },

    {
      id: "SDVOSB-exit-date",
      name: "certDateExit_SDVOSB",
      label: "Service-Disabled Veteran-Owned Small Business (SDVOSB) exit dates",
    }
  ]
  const checkBoxData5 = [
    {
      id: "Ownership-self",
      name: "OSC",
      label: "Ownership and self-certifications",
      labelDescription:"In a spreadsheet export, list items will be comma-separated in a single cell."

    },
    {
      id: "government",
      name: "NFGC",
      label: "Non-Federal government certifications",
      labelDescription:"In a spreadsheet export, list items will be comma-separated in a single cell."

    }
  ]
  const allCheckbox = [
    { label: <p className="margin-y-2"><b>8(a)</b></p>, data: checkBoxData },
    { label: <p className="margin-y-2"><b>HUBZone</b></p>, data: checkBoxData2 },
    { label: <p className="margin-y-2"><b>Women-Owned Small Business</b></p>, data: checkBoxData3 },
    { label: <p className="margin-y-2"><b>Veteran-Owned Small Business</b></p>, data: checkBoxData4 },
    // { label: <p className="margin-y-2"><b>Self-certifications</b></p>, data: checkBoxData5 },
  ]
  return (
    <div>
      <p className="margin-y-2"><b>SBA Certifications</b></p>
      {
        allCheckbox?.map((group) =>
        (
          <>
            {group.label}
            {group.data.map((checkbox:any) => (
              <Checkbox
                id={name+'_'+checkbox.id}
                name={checkbox.name}
                label={checkbox.label}
                className="margin-y-2"
                onChange={(e) => handleCheckBoxChange(checkbox.name, checkbox.label, e.target.checked,checkbox.id)}
                checked={checked.some(item => item.name === checkbox.name)}
                disabled={checked.length == length && checked.some(item => item.name !== checkbox.name && !checked.some(item => item.name === checkbox.name)) }
                labelDescription={checkbox?.labelDescription}
              />
            ))
            }
          </>
        )
        )
      }


    </div>
  )
}
export const NAICS = ({ handleCheckBoxChange, checked,length,name }) => {
  const checkBoxData = [
    {
      id: "naics-code",
      name: "PNC",
      label: "Primary NAICS code",
    },
    {
      id: "all-naics-code",
      name: "ANC",
      label: "All NAICS codes",
      labelDescription:`NAICS codes will be comma-separated in a single cell.`
    },
    {
      id: "samll-naics-code",
      name: "SNC",
      label: `“Small” NAICS codes`,
      labelDescription:`List all NAICS codes, comma-separated, that a firm has a “small” designation for.`
    },
    {
      id: "buy-green-code",
      name: "BGNC",
      label: `“Buy Green” NAICS codes`,
      labelDescription:`List all NAICS codes, comma-separated, that a firm has a “buy green” designation for.`

    },
  ]
  return (
    <>
      {
        checkBoxData?.map((checkbox:any) => {
          return (
            <Checkbox
            id={name+'_'+checkbox.id}
              name={checkbox.name}
              label={checkbox.label}
              className="margin-y-2"
              onChange={(e) => handleCheckBoxChange(checkbox.name, checkbox.label, e.target.checked,checkbox.id)}
              checked={checked.some(item => item.name === checkbox.name)}
              disabled={checked.length == length && checked.some(item => item.name !== checkbox.name && !checked.some(item => item.name === checkbox.name)) }
              labelDescription={checkbox?.labelDescription}
            />
          )
        })
      }

    </>
  )
  
}
export const ServiceInformation = ({ handleCheckBoxChange, checked,length,name }) => {
  const checkBoxData = [
    {
      id: "special_equipment",
      name: "special_equipment",
      label: "Special equipment/materials",
    },
    {
      id: "cbonc",
      name: "cbonc",
      label: "Bonding level: Construction, per contract",
    },
    {
      id: "cbona",
      name: "cbona",
      label: "Bonding level: Construction, aggregate",
    },
    {
      id: "sbonc",
      name: "sbonc",
      label: "Bonding level: Service, per contract",
    },
    {
      id: "sbona",
      name: "sbona",
      label: "Bonding level: Service, aggregate",
    },
    {
      id: "keywords",
      name: "keywords",
      label: "Keywords",
      labelDescription:"Keywords will be listed comma-separated in a single cell."

    },
    {
      id: "qas_standards",
      name: "qas_standards",
      label: "Quality Assurance Standards",
      labelDescription:"Quality Assurance Standards will be listed comma-separated in a single cell."
    },

  ]
  return (
    <>
      {
        checkBoxData?.map((checkbox:any) => {
          return (
            <Checkbox
            id={name+'_'+checkbox.id}
              name={checkbox.name}
              label={checkbox.label}
              className="margin-y-2"
              onChange={(e) => handleCheckBoxChange(checkbox.name, checkbox.label, e.target.checked,checkbox.id)}
              checked={checked.some(item => item.name === checkbox.name)}
              disabled={checked.length == length && checked.some(item => item.name !== checkbox.name && !checked.some(item => item.name === checkbox.name)) }
              labelDescription={checkbox?.labelDescription}
            />
          )
        })
      }

    </>
  )
}



