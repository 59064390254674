import * as React from "react";

function Blob(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={322} height={288} viewBox="0 0 332 288" fill="none" {...props}>
      <path
        d="M272.973 2.393c30.841 8.689 54.026 43.222 58.28 79.091 4.254 35.647-10.423 72.631-24.461 107.387-14.251 34.533-27.864 66.838-51.261 79.314-23.609 12.477-57.003 5.57-92.737 8.912-35.733 3.342-73.807 17.155-106.137 7.575-32.118-9.58-58.28-42.776-56.578-76.195 1.488-33.419 30.841-67.061 49.559-97.584 18.717-30.3 26.587-57.48 44.029-70.625 17.441-13.368 44.454-12.922 77.21-21.166 32.756-8.243 71.254-25.398 102.096-16.71z"
        fill="#E6E6E2"
      />
    </svg>
  );
}

export default Blob;
