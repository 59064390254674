import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { ComboBox, Button, Icon, Search } from "@trussworks/react-uswds";

// data
import { stateAndTerritories } from "../../data";

// style
import styles from "./BasicSearch.module.scss";

// store
import { useFilterStore } from "../../store/useFilterStore";
import { FilterKeys } from "../../constants/SearchProperties";
import { getTotalActiveBusinesses } from "../../data/fetch";

const SBACertificationsOptions = [
  "Any",
  "8(a) or 8(a) Joint Venture",
  "HUBZone",
  "Women-Owned Small Business (WOSB)",
  "Economically-Disadvantaged Women-Owned Small Business",
  "Veteran Owned Small Business or Joint Venture",
  "Service Disabled Veteran Owned Small Business or Joint Venture",
];

const CertificationsValue = {
  "8(a) or 8(a) Joint Venture": "1,4",
  HUBZone: "3",
  "Women-Owned Small Business (WOSB)": "5",
  "Economically-Disadvantaged Women-Owned Small Business": "6",
  "Veteran Owned Small Business or Joint Venture": "7,8",
  "Service Disabled Veteran Owned Small Business or Joint Venture": "9,10",
};

const formatStatesOptions = stateAndTerritories.map((item) => ({
  value: item,
  label: item,
}));

// insert "Any" in the beginning of the list (array).
formatStatesOptions.unshift({ value: "Any", label: "Any" });

const formatSBAOptions = SBACertificationsOptions.map((item) => ({
  value: item,
  label: item,
}));

const promise = getTotalActiveBusinesses();

export default function BasicSearchPage() {
  const navigate = useNavigate();

  const store = useFilterStore();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [certification, setCertification] = useState<string>("");
  const [totalActive, setTotalActive] = useState<string>("");

  useEffect(() => {
    promise
      .then((data) => {
        setTotalActive(data?.results?.[0]?.active_sam?.toLocaleString() ?? "");
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const input = e.target as HTMLInputElement;
    const value = input.value;

    setSearchQuery(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchQuery.trim() === "") {
      alert("Please enter a search criteria");
      return;
    }

    navigate(`/archive/advanced/${encodeURIComponent(searchQuery)}`);
  };

  const handleSearch = () => {
    // Applying location in filters.
    if (location !== "Any") {
      store.setFilter(FilterKeys.LOCATION, {
        ...store.filters.location,
        states: [{ value: location, isApplied: false }],
      });
    } else {
      store.setFilter(FilterKeys.LOCATION, {
        ...store.filters.location,
        states: [],
      });
    }

    // Applying SBA Certifications in filters
    if (certification !== "Any") {
      store.setFilter(FilterKeys.SBA_CERTIFICATIONS, {
        ...store.filters.sbaCertifications,
        activeCertification: [
          {
            value: CertificationsValue[certification],
            label: certification,
            isApplied: false,
          },
        ],
      });
    } else {
      store.setFilter(FilterKeys.SBA_CERTIFICATIONS, {
        ...store.filters.sbaCertifications,
        activeCertification: SBACertificationsOptions.filter(
          (item) => item !== "Any"
        ).map((item) => ({
          value: CertificationsValue[item],
          label: item,
          isApplied: false,
        })),
      });
    }

    store.appliedFilter();

    navigate(`/archive/advanced`);
  };

  return (
    <>
      <Helmet>
        <title>SBA Small Business Search</title>
      </Helmet>

      <div className={styles["basic-search-root"]}>
        <h1 className={styles["heading"]}>
          There are {totalActive} small businesses ready to work with the
          federal government.
        </h1>
        <h2 className={styles["sub-heading"]}>
          Find the ones you’re looking for.
        </h2>

        <div className={styles["search-block"]}>
          <span>Search for small businesses in</span>

          <div className={styles["combo-box-wrapper"]}>
            <ComboBox
              id="state"
              name="state"
              options={formatStatesOptions}
              onChange={(value) => setLocation(value)}
              defaultValue="Any"
            />
          </div>

          <span>with</span>

          <div className={styles["combo-box-wrapper"]}>
            <ComboBox
              id="sba"
              name="sba"
              options={formatSBAOptions}
              onChange={(value) => setCertification(value)}
              defaultValue="Any"
            />
          </div>

          <span>SBA certifications</span>
        </div>

        <Button
          type="button"
          size="big"
          className={styles["search-btn"]}
          // disabled={false}
          onClick={handleSearch}
        >
          Search{" "}
          <Icon.ArrowForward color={certification ? "#fff" : "#454545"} />
        </Button>

        <Link to="/archive/advanced" className={styles["advanced-link"]}>
          Skip directly to Advanced Search
        </Link>

        <div className={styles["market-bg-image"]} />

        <p className={styles["search-by"]}>
          Or, search for a specific business by UEI, company name, CAGE Code, or
          8(a) Case number:
        </p>

        <div className={styles["search-bar"]}>
          <Search
            onSubmit={handleSubmit}
            onChange={handleChange}
            size="small"
            placeholder="Enter search criteria"
          />
        </div>
      </div>
    </>
  );
}
