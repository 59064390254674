import styles from "./EmptyQuery.module.scss";

import classNames from "classnames";

// components
import Blob from "components/icons/Blob";

// assets
import EmptyQueryImg from "assets/images/empty-query.png";

type EmptyQueryProps = {
  content: string;
  className?: string;
} & JSX.IntrinsicElements["div"];

export default function EmptyQuery(props: EmptyQueryProps) {
  const { content, className, ...rest } = props;
  const classes = classNames(styles["empty-query-root"], className);

  return (
    <div className={classes} {...rest}>
      <div className={styles["blob-content"]}>
        <Blob />
        <img src={EmptyQueryImg} alt="empty query" />
      </div>

      <p>{content}</p>
    </div>
  );
}
