import styles from "./FilterRelationBoxBlock.module.scss";

import { useState } from "react";

// components
import { FilterRelationBox, Heading } from "../../Components";

export default function FilterRelationBoxBlock() {
  const [value, setValue] = useState<string>("keywords-or");

  return (
    <div className={styles["filter-relation-box-block-root"]}>
      <Heading title="Filter relation box" />

      <div className={styles["block"]}>
        <FilterRelationBox
          filterName="keywords"
          value={value}
          onChange={(e) => {
            const { id } = e.target;
            setValue(id);
          }}
        />
      </div>
    </div>
  );
}
