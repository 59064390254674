import styles from "./MultiSelectCheckboxBlock.module.scss";

import { useState } from "react";

// components
import {
  Heading,
  Subheading,
  OnlyButton,
  MultiSelectCheckbox,
} from "../../Components";

// simulation components
import {
  AutoFocusOnlyButton,
  AutoHoverOnlyButton,
} from "../../Components/MultiSelectCheckbox";

const items = [
  { label: "Multi select checkbox 1", value: "item-1" },
  { label: "Multi select checkbox 2", value: "item-2" },
  { label: "Multi select checkbox 3", value: "item-3" },
  { label: "Multi select checkbox 4", value: "item-4" },
];

export default function MultiSelectCheckboxBlock() {
  const [checkedItems, setCheckedItems] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  return (
    <div className={styles["multi-select-checkbox-block-root"]}>
      <Heading title="Multi select checkbox" />

      <div style={{ marginTop: "16px" }} />

      <Subheading
        className={styles["subheading-sm"]}
        title="Sub-component: “Only” toggle, clicking will uncheck all other checkboxes in the list"
      />

      {/* OnlyButtons */}
      <div style={{ marginTop: "16px" }} />

      <div
        className={styles["grid-toggle-cols"]}
        style={{ gridTemplateColumns: "repeat(4, 80px)" }}
      >
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default</span>
          <OnlyButton />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Hover</span>
          <AutoHoverOnlyButton />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Focus</span>
          <AutoFocusOnlyButton />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Disabled</span>
          <OnlyButton disabled />
        </div>
      </div>

      {/* Selected multi select checkboxes */}
      <div style={{ marginTop: "32px" }} />
      <Subheading title="Multi select list" />

      <div style={{ marginTop: "16px" }} />

      <div style={{ maxWidth: "320px" }}>
        <MultiSelectCheckbox
          items={items}
          checkedItems={checkedItems}
          handleChange={(items) => {
            setCheckedItems(items);
          }}
        />
      </div>
    </div>
  );
}
