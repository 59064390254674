import classNames from "classnames";

// components
import Checkbox from "../Checkbox";

// styles
import styles from "./MultiSelectCheckbox.module.scss";

export type Item = {
  label: string;
  value: string;
};

type MultiSelectCheckboxProps = {
  items: Item[];
  checkedItems: Item[];
  hideOnly?: boolean;
  className?: string;
  listClassName?: string;
  isInnerHtml?: boolean;
  handleChange: (items: Item[]) => void;
};

/**
 * `MultiSelectCheckbox` component allow users to select one or more options from a list
 * "OnlyButton" component will de-select all except the clicked checkbox
 *
 * @param {MultiSelectCheckboxProps} props - The props for the `MultiSelectCheckbox` component.
 * @param {Item[]} props.items - The list of items to be displayed.
 * @param {Item[]} props.checkedItems - The list of items that are currently checked.
 * @param {boolean} props.hideOnly - If true, "Only" button will be hidden.
 * @param {string} props.className - The class name for the root element (Optional).
 * @param {string} props.listClassName - The class name for the list element (Optional).
 * @param {boolean} props.isInnerHtml - If true, the `label` prop will be rendered as HTML (Optional).
 * @param {function} props.handleChange - returns the selected items.
 */
export default function MultiSelectCheckbox(props: MultiSelectCheckboxProps) {
  const rootClasses = classNames(styles["multi-select-checkbox-root"], {
    [props.className]: props.className,
  });

  const listClasses = classNames(styles["multi-list"], {
    [props.listClassName]: props.listClassName,
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (checked) {
      const item = props.items.find((item) => item.value === name);
      props.handleChange([...props.checkedItems, item]);
    } else {
      props.handleChange(
        props.checkedItems.filter((item) => item.value !== name)
      );
    }
  };

  const handleButtonClick = (item: Item) => {
    props.handleChange([item]);
  };

  const selectedItemsValue = props.checkedItems?.map((item) => item.value);

  return (
    <div className={rootClasses}>
      <ul className={listClasses}>
        {props?.items?.map((item) => (
          <li className={styles["list-item"]} key={item.value}>
            <Checkbox
              id={item.value}
              name={item.value}
              label={item.label}
              className={styles["checkbox-wrapper"]}
              onChange={handleOnChange}
              checked={selectedItemsValue?.includes(item.value)}
              isInnerHtml={props.isInnerHtml}
            />
            {!props.hideOnly && (
              <OnlyButton
                className={styles["btn-wrapper"]}
                onClick={() => handleButtonClick(item)}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

type OnlyButtonProps = JSX.IntrinsicElements["button"];

/**
 * `OnlyButton` component allow to uncheck all other checkboxes.
 * It is intended to be used with `MultiSelectCheckbox`.
 *
 * @param {OnlyButtonProps} props - The props for the `OnlyButton` component.
 */
export function OnlyButton(props: OnlyButtonProps) {
  const { className, ...rest } = props;

  const onlyButtonClasses = classNames(styles["only-button-root"], {
    [className]: className,
  });

  return (
    <button className={onlyButtonClasses} {...rest}>
      Only
    </button>
  );
}

/**
 * `AutoHoverOnlyButton` component imitate `OnlyButton` with hover state.
 * It is intended to be used with `MultiSelectCheckbox`.
 *
 * @param {OnlyButtonProps} props - The props for the `OnlyButton` component.
 *
 * @summary `AutoHoverOnlyButton` component shouldn't be used in real scenario and is just for Simulation purpose.
 */
export function AutoHoverOnlyButton(props: OnlyButtonProps) {
  const onlyButtonClasses = classNames(
    styles["only-button-root"],
    styles["only-button-hover-simulation"],
    {
      [props.className]: props.className,
    }
  );

  return (
    <button className={onlyButtonClasses} {...props}>
      Only
    </button>
  );
}

/**
 * `AutoFocusOnlyButton` component imitate `OnlyButton` with focus state.
 * It is intended to be used with `MultiSelectCheckbox`.
 *
 * @param {OnlyButtonProps} props - The props for the `OnlyButton` component.
 *
 * @summary `AutoFocusOnlyButton` component shouldn't be used in real scenario and is just for Simulation purpose.
 */
export function AutoFocusOnlyButton(props: OnlyButtonProps) {
  const onlyButtonClasses = classNames(
    styles["only-button-root"],
    styles["only-button-focus-simulation"],
    {
      [props.className]: props.className,
    }
  );

  return (
    <button className={onlyButtonClasses} {...props}>
      Only
    </button>
  );
}
