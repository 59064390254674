import { Icon } from "@trussworks/react-uswds";

// styles
import styles from "./Footer.module.scss";

const SBAHLogoImage = require("../../../assets/images/sba_logo_horizontal.png");

const footerLinks = [
  {
    url: "https://www.sba.gov/contracting",
    label: "SBA.gov/contracting",
  },
  {
    url: "https://whitehouse.gov",
    label: "WhiteHouse.gov",
  },
  {
    url: "https://regulations.gov",
    label: "Regulations.gov",
  },
  {
    url: "https://business.usa.gov",
    label: "BusinessUSA.gov",
  },
  {
    url: "https://usa.gov",
    label: "USA.gov",
  },
];

const socialLinks = [
  {
    href: "https://www.facebook.com/SBAgov",
    name: <Icon.Facebook />,
  },
  {
    href: "https://www.twitter.com/SBAgov",
    name: <Icon.Twitter />,
  },
  {
    href: "https://youtube.com/c/sbagov",
    name: <Icon.Youtube />,
  },
  {
    href: "https://instagram.com/sbagov",
    name: <Icon.Instagram />,
  },
  {
    href: "https://rss.feedburner.com/sbagov",
    name: <Icon.RssFeed />,
  },
];

export default function Footer() {
  const scrollToTop = () => {
    scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles["footer-root"]}>
      <div className={styles["to-top"]}>
        <button role="button" onClick={scrollToTop}>
          Return to top
        </button>
      </div>
      <div className={styles["links-section"]}>
        {footerLinks.map((item, i) => (
          <a
            key={i}
            href={item.url}
            rel="noreferrer"
            target="_blank"
            className={styles["link"]}
          >
            {item.label}
          </a>
        ))}
      </div>
      <div className={styles["content-section"]}>
        <a
          href="https://www.sba.gov"
          rel="noreferrer"
          target="_blank"
          className={styles["img-wrapper"]}
        >
          <img src={SBAHLogoImage} alt="SBA Logo" />
        </a>

        <div className={styles["social-content"]}>
          <div className={styles["social-links"]}>
            {socialLinks.map((item, i) => (
              <a key={i} href={item.href} rel="noreferrer" target="_blank">
                {item.name}
              </a>
            ))}
          </div>

          {/* <h3>Contact SBA</h3>
          <a href="#">SBS Help Desk</a> */}
        </div>
      </div>
    </div>
  );
}
