import React from "react";
import { businessProfiles } from "../../constants/BusinessProfile";

export default function BusinessProfileComponent(){
    return (
        <>
            {
                businessProfiles?.map((items, index) => {
                    const ProfileComponent = items.component || null;
                    return (
                        <div key={index} id={items.name} className="business-profile-single-card">
                            
                            {
                                ProfileComponent === null ? (
                                <>
                                    <h2>{items.header}</h2>
                                    <h4>{items.subTitle}</h4>
                                    <p>{items.content}</p>
                                </>
                                ) : (
                                    <ProfileComponent />
                                )
                            }
                        </div>
                    )
                })
            }
        </>
    );
}
