import { useState } from "react";
import { Alert } from "@trussworks/react-uswds";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

// components
import { FeedbackDialog } from "components/Dialogs";

// styles
import styles from "./Header.module.scss";

const DSBSLink = () => (
  <a
    className="usa-link"
    target="_blank"
    href="https://dsbs.sba.gov/search/dsp_dsbs.cfm"
  >
    DSBS
  </a>
);

export default function Banner() {
  const { pathname } = useLocation();
  const isHomePath = pathname === "/";

  const [open, setOpen] = useState<boolean>(false);

  const rootClasses = classNames(styles["alert-root"], {
    [styles["bottom-spacing"]]: isHomePath,
  });

  return (
    <>
      <Alert type={"info"} headingLevel={"h4"} className={rootClasses}>
        <h4 className="usa-alert__heading">
          Small Business Search is currently in Beta
        </h4>
        <p className="usa-alert__text">
          Try out the new design and{" "}
          <button
            type="button"
            className={styles["dialog-btn"]}
            onClick={() => setOpen(true)}
          >
            share your feedback
          </button>{" "}
          to help us improve before we officially launch. You may find different
          search results here than in {<DSBSLink />}, so please continue to rely
          on {<DSBSLink />} for your professional needs for now. We'll let you
          know when the new Small Business Search is ready!
        </p>
      </Alert>

      {open && <FeedbackDialog open={open} setOpen={setOpen} />}
    </>
  );
}
