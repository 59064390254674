import classNames from "classnames";
import { Icon } from "@trussworks/react-uswds";

import styles from "./SelectList.module.scss";

type Item = {
  label: string;
  value: string;
};

type SelectListProps = {
  items: Item[];
  selectedItem: Item;
  className?: string;
  handleChange: (item: Item) => void;
};

/**
 * `SelectList` component allow users to select one option from a list.
 *
 * @param {SelectListProps} props - The props for the `SelectListProps` component.
 * @param {Item[]} props.items - The list of items to be displayed.
 * @param {Item} props.selectedItem - selected item.
 * @param {string} props.className - The class name for the root element (Optional).
 * @param {function} props.handleChange - returns the selected item.
 */
export default function SelectList(props: SelectListProps) {
  const rootClasses = classNames(styles["select-list-root"], {
    [props.className]: props.className,
  });

  const handleOnClick = (item: Item) => {
    props.handleChange(item);
  };

  return (
    <ul className={rootClasses}>
      {props.items.map((item) => (
        <li key={item.value} className={styles["list-item"]}>
          {item.value === props.selectedItem.value && (
            <Icon.Check className={styles["check-icon"]} />
          )}
          <button
            type="button"
            className={classNames(styles["item-btn"], {
              [styles["selected"]]: item.value === props.selectedItem.value,
            })}
            onClick={() => handleOnClick(item)}
          >
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  );
}
