import { Link } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";

// components
import { SBATag } from "components/LibraryComponents/Components";

// types
import { AccordionBusinessItem, BusinessItem } from "types/results-panel.types";

// utils
import { padding } from "@mui/system";
import moment from "moment";
import { getCapabilitiesNarrativeLink, refineCertificationTags } from "utils/helper";

const trunceText = (text: string, length: number) => {
  if (!text) return null;

  if (text.length > length) {
    return text.substring(0, length) + "...";
  }
  return text;
};

enum CertificationHeaderToName {
  "cert_8a" = "8(a) Certification",
  "cert_8aJV" = "8(a) Joint Venture",
  "cert_HZ" = "HUBZone Certification",
  "cert_WOSB" = "Women-Owned Small Business (WOSB)",
  "cert_EDWOSB" = "Economically Disadvantaged Women-Owned Small Business (EDWOSB)",
  "cert_VOSB" = "Veteran-Owned Small Business",
  "cert_VOSBJV" = "Veteran-Owned Small Business Joint Venture",
  "cert_SDVOSB" = "Service-Disabled Veteran-Owned Small Business",
  "cert_SDVOSBJV" = "Service-Disabled Veteran-Owned Small Business Joint Venture",
}

const format = {
  'certification_status': (data, id) => {
    const mapCertStatus = {
      'A': 'Active',
      'E': 'Expired',
      'P': 'Pending',
      'I': 'Expired'
    };
    return data.active_certifications
              ?.filter(cert => cert?.name === CertificationHeaderToName[id])
              ?.map(cert => mapCertStatus[cert?.cert_active] ?? cert?.cert_active)[0];
  },
  'certification_date': (data, id, _isStart=true) => {
    let certDate = data.active_certifications
              ?.filter(cert => cert?.name === CertificationHeaderToName[id])
              ?.map(cert => _isStart === true ? cert?.start_date : cert?.exit_date)[0];
    return !certDate ? "" : moment(certDate).format("MM/D/YYYY");
  }
}

const columnHelper = createColumnHelper<BusinessItem | AccordionBusinessItem>();

const columns = [
  columnHelper.accessor("businessName", {
    id: "businessName",
    header: () => "Small business name",
    cell: (props) => {
      const data = props.row.original;

      return <Link to={`/profile/${data.pronetID}`}>{data.businessName}</Link>;
    },
  }),

  columnHelper.accessor("capabilitiesNarrative", {
    id: "capabilitiesNarrative",
    header: () => "Capabilities narrative",
    cell: (props) => {
      const data = props.row.original;
      const link = getCapabilitiesNarrativeLink(data);

      return (
        <>
          {trunceText(data.capabilitiesNarrative, 150)}
          <br />
          {link ? (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="usa-link usa-link--external"
            >
              Capabilities statement
            </a>
          ) : null}
        </>
      );
    },
  }),

  columnHelper.accessor("active_certifications", {
    id: "activeCertifications",
    header: () => "Active SBA certifications",
    cell: (props) => {
      const data = props.row.original;
      const activeCerts = data.active_certifications?.filter(x => x?.cert_active === 'A');
      if(activeCerts.length > 0) 
        return activeCerts.map((cert, i) => {
          const isPending =
            cert?.cert_active !== "A" && cert?.pending && cert?.pending === "Y";
          const refineTag = refineCertificationTags(cert?.name, isPending);

          return (
            <SBATag
              key={i}
              name={refineTag.name}
              isPending={refineTag.isPending}
            />
          );
        });
      else return null;
    },
  }),

  columnHelper.accessor("contact", {
    id: "contact",
    header: () => "Contact person",
    cell: (props) => {
      const data = props.row.original;

      return (
        <>
          {data.contact}
          <br />
          <a href={`mailto:${data.email}`} className="usa-link usa-link">
            {data.email}
          </a>
        </>
      );
    },
  }),

  columnHelper.accessor("address", {
    id: "address",
    header: () => "Address",
    cell: (props) => {
      const data = props.row.original;
      let googleMapsLink = `https://www.google.com/maps/place/${data.address.line1} ${data.address.city} ${data.address.state} ${data.address.zip}`;
      return (
        data.address.line1?.length > 0 ?
        <a
          href={googleMapsLink}
          target="_blank"
          rel="noreferrer"
          className="usa-link usa-link--external"
        >
          <span>{`${data.address.line1}${data.address.line2?.length > 0 ? ', ' + data.address.line2 : ''}`}</span><br/>
          <span>{`${data.address.city}, ${data.address.state} ${data.address.zip}`}</span>
        </a>
        : null
      );
    },
  })
];

const optionalColumns = [

  columnHelper.accessor("government_credit_card", {
    id: "government_credit_card",
    header: () => "Accepts government credit card",
    cell: (props) => {
      const data = props.row.original;
      return data.government_credit_card === "Y" ? "Yes" : "No";
    },
  }),

  columnHelper.accessor("phone", {
    id: "phone",
    header: () => "Phone",
    cell: (props) => {
      const data = props.row.original;
      const phone = data.phone;
      const phone_ext = data.phone_ext;
      return phone_ext ? `${phone} ext. ${phone_ext}` : phone;
    },
  }),

  columnHelper.accessor("fax", {
    id: "fax",
    header: () => "Fax",
    cell: (props) => {
      const data = props.row.original;
      return data.fax;
    },
  }),

  columnHelper.accessor("url", {
    id: "url",
    header: () => "Website",
    cell: (props) => {
      const data = props.row.original;
      const data_url = data.url?.indexOf("http") === -1 ? `http://${data.url}` : data.url;
      return (
        <a
          href={data_url}
          target="_blank"
          rel="noreferrer"
          className="usa-link usa-link--external"
        >
          {data.url}
        </a>
      ) ?? null;
    },
  }),

  columnHelper.accessor("metropolitan_statistical_area", {
    id: "metropolitan_statistical_area",
    header: () => "Metropolitan statistical area",
    cell: (props) => {
      const data = props.row.original;
      return data.metropolitan_statistical_area;
    },
  }),

  columnHelper.accessor("uei", {
    id: "uei",
    header: () => "Unique Entity Identifier",
    cell: (props) => {
      const data = props.row.original;
      return data.uei;
    },
  }),

  columnHelper.accessor("last_updated", {
    id: "last_updated",
    header: () => "Last updated",
    cell: (props) => {
      const data = props.row.original;
      return new Date(data.last_updated)?.toLocaleDateString();
    },
  }),

  columnHelper.accessor("business_trade_name", {
    id: "business_trade_name",
    header: () => "Business trade name",
    cell: (props) => {
      const data = props.row.original;
      return data.business_trade_name;
    },
  }),

  columnHelper.accessor("county_code", {
    id: "county_code",
    header: () => "County code",
    cell: (props) => {
      const data = props.row.original;
      return data.county_code;
    },
  }),

  columnHelper.accessor("congressional_district", {
    id: "congressional_district",
    header: () => "Congressional district",
    cell: (props) => {
      const data = props.row.original;
      return data.congressional_district;
    },
  }),

  columnHelper.accessor("e_commerce_website", {
    id: "e_commerce_website",
    header: () => "E-Commerce website",
    cell: (props) => {
      const data = props.row.original;
      const website = data.e_commerce_website?.indexOf("http") === -1 ? `http://${data.e_commerce_website}` : data.e_commerce_website;
      return (
        <a
          href={website}
          target="_blank"
          rel="noreferrer"
          className="usa-link usa-link--external"
        >
          {data.e_commerce_website}
        </a>
      );
    },
  }),

  columnHelper.accessor("cage_code", {
    id: "cage_code",
    header: () => "CAGE code",
    cell: (props) => {
      const data = props.row.original;
      return data.cage_code;
    },
  }),
  
  columnHelper.accessor("year_established", {
    id: "year_established",
    header: () => "Year established",
    cell: (props) => {
      const data = props.row.original;
      return data.year_established;
    },
  }),

  columnHelper.accessor("legal_structure", {
    id: "legal_structure",
    header: () => "Legal structure",
    cell: (props) => {
      const data = props.row.original;
      return data.legal_structure;
    },
  }),

  columnHelper.accessor("current_principals", {
    id: "current_principals",
    header: () => "Current principals",
    cell: (props) => {
      const data = props.row.original;
      return (
        <>
          <ol style={{padding:'inherit'}}>
            {data.current_principals?.map((principal, i) => (
              <li key={i}>
                {principal?.name}, {principal?.title}
              </li>
            ))}
          </ol>
        </>
      ) ?? null;
    },
  }),

  columnHelper.accessor("qas_standards", {
    id: "qas_standards",
    header: () => "QAS standards",
    cell: (props) => {
      const data = props.row.original;
      return data.qas_standards?.join(", ");
    },
  }),

  columnHelper.accessor("service_information.keywords", {
    id: "keywords",
    header: () => "Keywords",
    cell: (props) => {
      const data = props.row.original;
      return data.service_information?.keywords;
    },
  }),

  columnHelper.accessor("service_information.special_equipment", {
    id: "special_equipment",
    header: () => "Special equipment",
    cell: (props) => {
      const data = props.row.original;
      return data.service_information?.special_equipment;
    },
  }),

  columnHelper.accessor("service_information.cbona", {
    id: "cbona",
    header: () => "Bonding level: Construction, aggregate",
    cell: (props) => {
      const data = props.row.original;
      return data.service_information?.cbona;
    },
  }),
  
  columnHelper.accessor("service_information.cbonc", {
    id: "cbonc",
    header: () => "Bonding level: Construction, per contract",
    cell: (props) => {
      const data = props.row.original;
      return data.service_information?.cbonc;
    },
  }),

  columnHelper.accessor("service_information.sbona", {
    id: "sbona",
    header: () => "Bonding level: Service, aggregate",
    cell: (props) => {
      const data = props.row.original;
      return data.service_information?.sbona;
    },
  }),

  columnHelper.accessor("service_information.sbonc", {
    id: "sbonc",
    header: () => "Bonding level: Service, per contract",
    cell: (props) => {
      const data = props.row.original;
      return data.service_information?.sbonc;
    },
  }),

  /** 8a Certification Columns */
  columnHelper.accessor("certStatus_8a", {
    id: "certStatus_8a",
    header: () => "8(a) certification status",
    cell: (props) => format.certification_status(props.row.original, 'cert_8a'),
  }),
  
  columnHelper.accessor("certDateStart_8a", {
    id: "certDateStart_8a",
    header: () => "8(a) entrance date",
    cell: (props) => format.certification_date(props.row.original, 'cert_8a'),
  }),
  
  columnHelper.accessor("certDateExit_8a", {
    id: "certDateExit_8a",
    header: () => "8(a) exit date",
    cell: (props) => format.certification_date(props.row.original, 'cert_8a', false),
  }),
  
  /** 8a JV Certification Columns */
  columnHelper.accessor("certStatus_8aJV", {
    id: "certStatus_8aJV",
    header: () => "8(a) Joint Venture certification status",
    cell: (props) => format.certification_status(props.row.original, 'cert_8aJV'),
  }),

  columnHelper.accessor("certDateStart_8aJV", {
    id: "certDateStart_8aJV",
    header: () => "8(a) Joint Venture entrance date",
    cell: (props) => format.certification_date(props.row.original, 'cert_8aJV'),
  }),

  columnHelper.accessor("certDateExit_8aJV", {
    id: "certDateExit_8aJV",
    header: () => "8(a) Joint Venture exit date",
    cell: (props) => format.certification_date(props.row.original, 'cert_8aJV', false),
  }),

  /** HUBZone Certification Columns */
  columnHelper.accessor("certStatus_HZ", {
    id: "certStatus_HZ",
    header: () => "HUBZone status",
    cell: (props) => format.certification_status(props.row.original, 'cert_HZ'),
  }),
  
  columnHelper.accessor("certDateStart_HZ", {
    id: "certDateStart_HZ",
    header: () => "HUBZone certification date",
    cell: (props) => format.certification_date(props.row.original, 'cert_HZ'),
  }),

  /** WOSB Certification Columns */
  columnHelper.accessor("certStatus_WOSB", {
    id: "certStatus_WOSB",
    header: () => "WOSB status",
    cell: (props) => format.certification_status(props.row.original, 'cert_WOSB'),
  }),

  columnHelper.accessor("certDateStart_WOSB", {
    id: "certDateStart_WOSB",
    header: () => "WOSB certification date",
    cell: (props) => format.certification_date(props.row.original, 'cert_WOSB'),
  }),
  
  /** EDWOSB Certification Columns */
  columnHelper.accessor("certStatus_EDWOSB", {
    id: "certStatus_EDWOSB",
    header: () => "EDWOSB status",
    cell: (props) => format.certification_status(props.row.original, 'cert_EDWOSB'),
  }),
  
    columnHelper.accessor("certDateStart_EDWOSB", {
      id: "certDateStart_EDWOSB",
      header: () => "EDWOSB certification date",
      cell: (props) => format.certification_date(props.row.original, 'cert_EDWOSB'),
    }),

  /** VOSB Certification Columns */
  columnHelper.accessor("certStatus_VOSB", {
    id: "certStatus_VOSB",
    header: () => "VOSB status",
    cell: (props) => format.certification_status(props.row.original, 'cert_VOSB'),
  }),
  
  columnHelper.accessor("certDateStart_VOSB", {
    id: "certDateStart_VOSB",
    header: () => "VOSB entrance date",
    cell: (props) => format.certification_date(props.row.original, 'cert_VOSB'),
  }),
  
  columnHelper.accessor("certDateExit_VOSB", {
    id: "certDateExit_VOSB",
    header: () => "VOSB exit date",
    cell: (props) => format.certification_date(props.row.original, 'cert_VOSB', false),
  }),
  
  columnHelper.accessor("certStatus_VOSBJV", {
    id: "certStatus_VOSBJV",
    header: () => "VOSB JV status",
    cell: (props) => format.certification_status(props.row.original, 'cert_VOSBJV'),
  }),
  
  /** SDVOSB Certification Columns */
  columnHelper.accessor("certStatus_SDVOSB", {
    id: "certStatus_SDVOSB",
    header: () => "SDVOSB status",
    cell: (props) => format.certification_status(props.row.original, 'cert_SDVOSB'),
  }),
  
  columnHelper.accessor("certDateStart_SDVOSB", {
    id: "certDateStart_SDVOSB",
    header: () => "SDVOSB entrance date",
    cell: (props) => format.certification_date(props.row.original, 'cert_SDVOSB'),
  }),
  
  columnHelper.accessor("certDateExit_SDVOSB", {
    id: "certDateExit_SDVOSB",
    header: () => "SDVOSB exit date",
    cell: (props) => format.certification_date(props.row.original, 'cert_SDVOSB', false),
  }),
  
  columnHelper.accessor("certStatus_SDVOSBJV", {
    id: "certStatus_SDVOSBJV",
    header: () => "SDVOSB JV status",
    cell: (props) => format.certification_status(props.row.original, 'cert_SDVOSBJV'),
  }),

  columnHelper.accessor("OSC",{
    id: "OSC",
    header: () => "Ownership and self-certifications",
    cell: (props) => {
      const data = props.row.original;
      return data.self_certifications?.join(", ");
    },
  }),

  columnHelper.accessor("NFGC",{
    id: "NFGC",
    header: () => "Non-Federal government certifications",
    cell: (props) => {
      const data = props.row.original;
      return data.non_federal_certifications?.join(", ");
    },
  }),
  
  columnHelper.accessor("PNC",{
    id: "PNC",
    header: () => "Primary NAICS code",
    cell: (props) => {
      const data = props.row.original;
      return data.naics?.primary;
    },
  }),
  
  columnHelper.accessor("ANC",{
    id: "ANC",
    header: () => "All NAICS codes",
    cell: (props) => {
      const data = props.row.original;
      return data.naics?.all?.join(", ");
    },
  }),

  columnHelper.accessor("SNC",{
    id: "SNC",
    header: () => "Small NAICS codes",
    cell: (props) => {
      const data = props.row.original;
      return data.naics?.smalls?.join(", ");
    },
  }),
  
  columnHelper.accessor("BGNC",{
    id: "BGNC",
    header: () => "Buy Green NAICS codes",
    cell: (props) => {
      const data = props.row.original;
      return data.naics?.greens?.join(", ");
    },
  }),

];

export {
  columns,
  optionalColumns
}
