import { useQuery } from "@tanstack/react-query";
import { Generic } from "types/filters-store.types";

const fetchNaicsCodes = async () => {
  const response = await fetch("/naicsJson.json"); // Path to your JSON file in the public directory
  if (!response.ok) {
    throw new Error("Failed to fetch JSON data");
  }
  return response.json();
};

type NaicsCode = {
  id: string;
  naics_code: string;
  naics_label: string;
  parent_id: string;
};

export type FilterNaics = Pick<Generic, "label" | "value"> & {
  originalLabel: string;
  originalValue: string;
};

export default function useNaicsCodesQuery() {
  const { isLoading, error, data } = useQuery<NaicsCode[]>({
    queryKey: ["naics-codes"],
    queryFn: fetchNaicsCodes,

    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const refineCodes = !data
    ? []
    : data.map((item: NaicsCode) => ({
        label: item.naics_label,
        value: item.naics_code,
        originalLabel: item.naics_label,
        originalValue: item.naics_code,
      }));

  // returns only naics codes that has  6 digits
  const filterNaicsCodes: FilterNaics[] = refineCodes.filter(
    (item) => item.value.length === 6
  );

  return {
    isLoading,
    error,
    naicsCodes: filterNaicsCodes,
  };
}
