import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const TooltipContainer = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 380,
    border: "1px solid #1f2937",
    padding: "8px",
  },
}));

const Content = styled("div")({
  "& > p": {
    margin: 0,
    fontSize: "14px",
    lineHeight: "1.2",
    color: "#1f2937",

    "&:first-child": {
      marginBottom: "6px",
      paddingBottom: "4px",
      color: "#0071bc",
      borderBottom: "1px solid #1f2937",
    },
  },
});

type Placement =
  | "bottom"
  | "left"
  | "right"
  | "top"
  | "bottom-end"
  | "bottom-start"
  | "left-end"
  | "left-start"
  | "right-end"
  | "right-start"
  | "top-end"
  | "top-start";

interface IProps {
  children: React.ReactElement<any, any>;
  title: string;
  description: string;
  placement?: Placement;
}

export default function CustomTooltip({
  children,
  title,
  description,
  placement = "right",
}: IProps) {
  return (
    <TooltipContainer
      title={
        <>
          <Content>
            <p>{title}</p>
            <p>{description}</p>
          </Content>
        </>
      }
      arrow
      placement={placement as any}
    >
      {children}
    </TooltipContainer>
  );
}

const SimpleTooltipStyle = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

type SimpleToopTipProps = {
  children: React.ReactElement;
  title: React.ReactNode;
  placement?: Placement;
  className?: string;
} & TooltipProps;

export function SimpleToopTip({
  children,
  title,
  placement,
  className,
  ...rest
}: SimpleToopTipProps) {
  return (
    <SimpleTooltipStyle
      title={title}
      placement={placement ?? "bottom"}
      className={className}
      {...rest}
    >
      {children}
    </SimpleTooltipStyle>
  );
}
