import * as React from "react";

function SbaCertify(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 14.197a6 6 0 116 0v6.636a.5.5 0 01-.845.362l-1.81-1.724a.5.5 0 00-.69 0l-1.81 1.724A.5.5 0 019 20.833v-6.636zm5 .462A5.99 5.99 0 0112 15a5.99 5.99 0 01-2-.341v5.008l.966-.92a1.5 1.5 0 012.069 0l.965.92v-5.008zm-1.524-9.195l.534 1.645a.5.5 0 00.476.346h1.73a.5.5 0 01.294.904l-1.4 1.018a.5.5 0 00-.181.559l.534 1.646a.5.5 0 01-.769.559l-1.4-1.018a.5.5 0 00-.588 0l-1.4 1.018a.5.5 0 01-.77-.56l.535-1.645a.5.5 0 00-.181-.56L8.49 8.36a.5.5 0 01.294-.904h1.73a.5.5 0 00.476-.346l.534-1.645a.5.5 0 01.952 0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SbaCertify;
