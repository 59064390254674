import * as React from "react";

function FaxIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19 8h-1V3H8v16h14v-8c0-1.66-1.34-3-3-3zm-9-3h6v3h-6V5zm4 11h-4v-5h4v5zm2 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm0-3c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm3 3c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm0-3c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM4.5 7A2.5 2.5 0 002 9.5v8a2.5 2.5 0 005 0v-8A2.5 2.5 0 004.5 7z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FaxIcon;
