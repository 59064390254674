import * as React from "react";

function TimerOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75.583h-3.5V1.75h3.5V.583zM6.417 8.167h1.166v-3.5H6.417v3.5zM11.1 4.31l.828-.829a6.45 6.45 0 00-.822-.822l-.829.828A5.227 5.227 0 007 2.333a5.25 5.25 0 105.25 5.25 5.228 5.228 0 00-1.15-3.272zM7 11.667a4.08 4.08 0 01-4.083-4.084A4.08 4.08 0 017 3.5a4.08 4.08 0 014.083 4.083A4.08 4.08 0 017 11.667z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoTimerOutline = React.memo(TimerOutline);
export default MemoTimerOutline;
