import { Link } from "react-router-dom";
import { useState } from "react";

// layout
import ErrorLayout from "layouts/ErrorLayout/ErrorLayout";

// components
import Blob from "components/icons/Blob";

// styles
import styles from "./NotFoundPage.module.scss";

// assets
import Error404Image from "assets/images/error-404.png";
import { FeedbackDialog } from "components/Dialogs";

export default function NotFoundPage({ hideLayout }: { hideLayout?: boolean }) {
  if (hideLayout) {
    return <Content />;
  }

  return (
    <ErrorLayout>
      <Content />
    </ErrorLayout>
  );
}

function Content() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={styles["not-found-root"]}>
      <h1 className={styles["title"]}>404: Page not found</h1>
      <h3 className={styles["sub-title"]}>
        Sorry, the page you are looking for does not exist.
      </h3>

      <div className={styles["blob-content"]}>
        <Blob />
        <img src={Error404Image} alt="404" />
      </div>

      <p className={styles["desc"]}>
        Please check that you typed the address correctly, go back to your
        previous page or try these helpful links instead:
      </p>

      <div className={styles["group"]}>
        <Link to="/">Back to home</Link>

        <button
          type="button"
          className={styles["btn"]}
          onClick={() => setOpen(true)}
        >
          Report a problem
        </button>

        {open && <FeedbackDialog open={open} setOpen={setOpen} />}
      </div>
    </div>
  );
}
