import { ReactNode, useState } from "react";
import KeywordComponent from "../components/FilterControls/Keyword";
import LocationFilter from "../components/FilterControls/LocationFilter";
import CapabilitiesComponent from "../components/FilterControls/CapabilitiesComponent";
import BondingLevels from "../components/FilterControls/BondingLevels";
import PrivilegedSearch from "../components/FilterControls/PrivilegedSearch";
import QualityAssuranceFilter from "../components/FilterControls/QualityAssuranceFilter";
import ProfileStatusFilter from "../components/FilterControls/ProfileStatusFilter";
import SBACertifications from "../components/FilterControls/SBACertifications";
import ContractingDetailsFilter from "../components/FilterControls/ContractingDetailsFilter";
import OwnershipAndSelfCertifications from "../components/FilterControls/OwnershipAndSelfCertifications";
import NatureOfBusiness from "../components/FilterControls/NatureOfBusiness";
import CapabilityKeywordsFilter from "../components/FilterControls/CapabilityKeywordsFilter";
import { Certifications, NAICS, OrganizationProperty, OverviewInformation, ServiceInformation } from "../components/AllAccordion/allAccordion";
import ControlAccordion from "../components/ControlAccordion";
import { Checkbox } from "@trussworks/react-uswds";

export interface SearchProperty {
  name: string;
  label?: string;
  inputType: string;
  helpText: string;
  display: any;
  component?: any;
  componentParams?: object;
}

export enum FilterKeys {
  SMALL_BUSINESS_PROFILE = "smallBusinessProfile",
  CAPABILITIES = "capabilities",
  LOCATION = "location",
  SBA_CERTIFICATIONS = "sbaCertifications",
  OWNERSHIP_AND_SELF_CERTIFICATIONS = "ownershipSelfCerts",
  CAPABILITY_KEYWORDS = "capabilityKeywords",
  BONDING_LEVELS = "bondingLevels",
  PRIVILEGED_SEARCH = "privilegedSearch",
  QUALITY_ASSURANCE_STANDARDS = "qualityAssuranceStandards",
  PROFILE_STATUS = "profileStatus",
  CONTRACTING_DETAILS = "contractingDetails",
  NATURE_OF_BUSINESS = "natureOfBusiness",
  SEARCH_BUSINESS= "searchBusiness",
}

export const FilterTitles = {
  [FilterKeys.SMALL_BUSINESS_PROFILE]: "Small Business Profile",
  [FilterKeys.CAPABILITIES]:
    "North American Industry Classification System (NAICS)",
  [FilterKeys.LOCATION]: "Location",
  [FilterKeys.SBA_CERTIFICATIONS]: "SBA certifications",
  [FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS]:
    "Ownership & self-certifications",
  [FilterKeys.CAPABILITY_KEYWORDS]: "Capability keywords",
  [FilterKeys.BONDING_LEVELS]: "Bonding levels",
  [FilterKeys.PRIVILEGED_SEARCH]: "Privileged Search",
  [FilterKeys.QUALITY_ASSURANCE_STANDARDS]: "Quality assurance standards",
  [FilterKeys.PROFILE_STATUS]: "Profile status",
  [FilterKeys.CONTRACTING_DETAILS]: "Contracting Details",
  [FilterKeys.NATURE_OF_BUSINESS]: "Nature of business",
};

export const searchProperties: SearchProperty[] = [
  // {
  //   name: FilterKeys.SMALL_BUSINESS_PROFILE,
  //   label: "",
  //   inputType: "",
  //   helpText: "",
  //   display: FilterTitles[FilterKeys.SMALL_BUSINESS_PROFILE],
  //   component: KeywordComponent,
  // },
  {
    name: FilterKeys.LOCATION,
    inputType: "text",
    helpText: "Saerch by location",
    display: FilterTitles[FilterKeys.LOCATION],
    component: LocationFilter,
  },
  {
    name: FilterKeys.SBA_CERTIFICATIONS,
    inputType: "",
    helpText: "",
    display: FilterTitles[FilterKeys.SBA_CERTIFICATIONS],
    component: SBACertifications,
  },
  {
    name: FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS,
    inputType: "text",
    helpText: "",
    display: FilterTitles[FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS],
    component: OwnershipAndSelfCertifications,
  },
  {
    name: FilterKeys.CAPABILITIES,
    inputType: "text",
    helpText: "Search by capability",
    display: FilterTitles[FilterKeys.CAPABILITIES],
    component: CapabilitiesComponent,
  },
  {
    name: FilterKeys.CAPABILITY_KEYWORDS,
    inputType: "",
    helpText: "",
    display: FilterTitles[FilterKeys.CAPABILITY_KEYWORDS],
    component: CapabilityKeywordsFilter,
  },
  {
    name: FilterKeys.PROFILE_STATUS,
    inputType: "",
    helpText: "",
    display: FilterTitles[FilterKeys.PROFILE_STATUS],
    component: ProfileStatusFilter,
  },
  {
    name: FilterKeys.NATURE_OF_BUSINESS,
    inputType: "",
    helpText: "",
    display: FilterTitles[FilterKeys.NATURE_OF_BUSINESS],
    component: NatureOfBusiness,
  },
  {
    name: FilterKeys.BONDING_LEVELS,
    inputType: "text",
    helpText: "",
    display: FilterTitles[FilterKeys.BONDING_LEVELS],
    component: BondingLevels,
  },
  {
    name: FilterKeys.QUALITY_ASSURANCE_STANDARDS,
    inputType: "",
    helpText: "",
    display: FilterTitles[FilterKeys.QUALITY_ASSURANCE_STANDARDS],
    component: QualityAssuranceFilter,
  },
];

export const modalAccordionProperty: SearchProperty[] = [
  {
    name: "Overview Information",
    label: "",
    inputType: "",
    helpText: "",
    display: <strong>Overview information</strong>,
    component: OverviewInformation,
  },
  {
    name: "Organization & Property",
    label: "",
    inputType: "text",
    helpText: "Search by location",
    display: <strong>Organization & ownership</strong>,
    component: OrganizationProperty,
  },
  {
    name: "Certifications",
    inputType: "",
    helpText: "",
    display: <strong>Certifications</strong>,
    component: Certifications,
  },
  // {
  //   name: "North American Industry Classification System (NAICS)",
  //   inputType: "text",
  //   helpText: "",
  //   display: <strong>North American Industry Classification System (NAICS) Codes</strong>,
  //   component: NAICS,
  // },
  {
    name: "Service Information",
    inputType: "text",
    helpText: "Search by capability",
    display: <strong>Service information</strong>,
    component: ServiceInformation,
  },
];