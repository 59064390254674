import { useState, useRef } from "react";

// components
import { RoundedButton } from "components/LibraryComponents/Components";

import useResultQuery from "../../../pages/AdvancedSearchNew/queries";

// icons
import { Icon, Modal, Checkbox, ModalRef } from "@trussworks/react-uswds";
import ControlAccordion from "components/ControlAccordion";
import { modalAccordionProperty } from "constants/SearchProperties";
import { useExportTableColumnStore } from "store/exportTableColumnStore";
import {
  formatDateToYYYYMMDD,
  selectedexportTableResultsData,
} from "utils/helper";
import { unparse } from "papaparse";

export default function ExportModal() {
  const exportColumnStore = useExportTableColumnStore();
  const { exportTablecolumnData } = exportColumnStore;

  const { data } = useResultQuery();

  const [showExportModal, setShowExportModal] = useState(false);
  const [exportTableChecked, setExportTableChecked] = useState<any>(
    exportTablecolumnData && exportTablecolumnData.length > 0
      ? exportTablecolumnData
      : []
  );
  // ====Edit Export table columns====

  const exportModelCheckBoxData = [
    {
      id: "businessName",
      name: "businessName",
      label: "Business names",
      labelDescription:
        "Business name will be hyperlinked to the business’s Small Business Search profile page.",
    },
    {
      id: "capabilitiesNarrative",
      name: "capabilitiesNarrative",
      label: "Capabilities narrative and capabilities statement link",
      labelDescription:
        "In a spreadsheet export, the capabilities narrative and capabilities statement will be separated into 2 columns.",
    },
    {
      id: "active_certifications",
      name: "active_certifications",
      label: "Active SBA certifications",
      labelDescription:
        "This column will list each active SBA certification in a single cell. To separate them, scroll to the SBA certification settings section below.",
    },
    {
      id: "contact",
      name: "contact",
      label: "Contact person's name and email address",
      labelDescription:
        "In a spreadsheet export, the contact person's name and their email address will be separated into 2 columns.",
    },
    {
      id: "address",
      name: "address",
      label: "Address",
      labelDescription:
        "In a spreadsheet export, each part of the business address will be separated into columns: street address line 1, street address line 2, city, state, and zip code.",
    },
  ];

  /**
   * This function checks if the column is not a default column
   * @param id The id of the column object
   * @returns true if the id is not represented in the exportModelCheckBoxData array
   */
  const isNonDefaultColumn = ({id}): boolean => {
    return !exportModelCheckBoxData.some((item) => item.id === id);
  }

  const exportModelRef = useRef<ModalRef>({
    modalId: "export-table-columns",
    modalIsOpen: false,
    toggleModal: (
      event?: React.MouseEvent,
      open?: boolean,
      close?: boolean
    ) => {
      if (open) {
        const currentState = exportModelRef.current.modalIsOpen;
        const newState = open ?? !currentState;
        exportModelRef.current.modalIsOpen = newState;
        setShowExportModal(newState);
        return newState;
      }
      if (close) {
        exportModelRef.current.modalIsOpen = false;
        setShowExportModal(false);
        return false;
      }
    },
  });
  const openExportModel = () => {
    setExportTableChecked(
      exportTablecolumnData && exportTablecolumnData.length > 0
        ? [...exportTablecolumnData]
        : [...exportModelCheckBoxData]
    );
    exportModelRef.current?.toggleModal(undefined, true);
  };
  const closeExportModel = () => {
    setExportTableChecked([]);
    exportModelRef.current?.toggleModal(undefined, false, true);
  };

  const exportData = (data, fileName, type) => {
    const csv = unparse(data);
    const blob = new Blob([csv], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  
  const handleExportTableCSV = () => {
    const fileName = `SBS_export_[${formatDateToYYYYMMDD(new Date())}].csv`;
    exportData(
      selectedexportTableResultsData(exportTableChecked, data),
      fileName,
      "text/csv;"
    );
  };
  const handleExportTableCheckBoxChange = (
    name: string,
    label: string,
    isChecked: Boolean,
    id
  ) => {
    if (isChecked) {
      setExportTableChecked((prevChecked) => [
        ...prevChecked,
        { name, label, id },
      ]);
    } else {
      setExportTableChecked((prevChecked) =>
        prevChecked.filter((item) => item.name !== name)
      );
    }
  };
  const handleExportTableSelectedCheckBoxChange = (
    name: string,
    label: string,
    id
  ) => {
    const isChecked = exportTableChecked.find(
      (item) => item.name === name && label === label
    );
    if (isChecked) {
      setExportTableChecked((prevChecked) =>
        prevChecked.filter((item) => item.name !== name)
      );
    } else {
      setExportTableChecked((prevChecked) => [
        ...prevChecked,
        { name, label, id },
      ]);
    }
  };

  return (
    <>
      <RoundedButton variant="ghost" onClick={openExportModel}>
                <Icon.SaveAlt />
                Export
      </RoundedButton>
      {showExportModal && (
        <Modal
          placeholder=""
          isLarge
          id={exportModelRef.current.modalId}
          ref={exportModelRef}
          className={`padding-0 position-relative`}
          isInitiallyOpen={exportModelRef.current.modalIsOpen}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        >
          <div
            className={`margin-bottom-1 overflow-auto resultpane-model-content`}
          >
            <p className="font-sans-xl text-bold">Edit export columns</p>
            <div className="margin-top-2 text-ink">
              <p>
                Choose up to 20 table columns that will appear in your spreadsheet export.
              </p>
              <p className="font-sans-lg text-bold margin-top-1">
                Default columns
              </p>
              {exportModelCheckBoxData?.map((checkbox: any) => {
                return (
                  <Checkbox
                    id={checkbox.id}
                    name={checkbox.name}
                    label={checkbox.label}
                    className="margin-y-2"
                    onChange={(e) =>
                      handleExportTableCheckBoxChange(
                        checkbox.name,
                        checkbox.label,
                        e.target.checked,
                        checkbox.id
                      )
                    }
                    checked={exportTableChecked.some(
                      (item) => item.name === checkbox.name
                    )}
                    disabled={
                      exportTableChecked.length == 20 &&
                      exportTableChecked.some(
                        (item) =>
                          item.name !== checkbox.name &&
                          !exportTableChecked.some(
                            (item) => item.name === checkbox.name
                          )
                      )
                    }
                    labelDescription={checkbox?.labelDescription}
                  />
                );
              })}
            </div>

            <div className="padding-y-3">
              {exportTableChecked?.filter(isNonDefaultColumn)?.length > 0 && (
                <p className="font-sans-xs">SELECTED COLUMNS</p>
              )}

              {exportTableChecked?.filter(isNonDefaultColumn)?.map((i, index) => {
                return (
                  <>
                    <Checkbox
                      key={index}
                      id={"export" + "_" + i.id}
                      name={i.name}
                      label={i.label}
                      className="margin-1"
                      checked={exportTableChecked.find(
                        (item) => item.name === i.name
                      )}
                      onChange={() =>
                        handleExportTableSelectedCheckBoxChange(
                          i.name,
                          i.label,
                          i.id
                        )
                      }
                    />
                  </>
                );
              })}
            </div>
            {modalAccordionProperty.map((sProp, index) => {
              const PropertyComponent = sProp.component || null;
              const fields = sProp.display || null;

              return (
                <ControlAccordion
                  key={sProp.name}
                  title={sProp.display}
                  name={sProp.name}
                >
                  <PropertyComponent
                    handleCheckBoxChange={handleExportTableCheckBoxChange}
                    checked={exportTableChecked}
                    length={20}
                    name="Export"
                  />
                </ControlAccordion>
              );
            })}
          </div>
          <div className="usa-modal__footer bg-accent-cool-lighter position-sticky margin-top-0 padding-0">
            <ul className="usa-button-group flex-justify-end padding-1 padding-x-2">
              <li className="usa-button-group__item">
                <button
                  type="button"
                  className="usa-button usa-button--unstyled padding-105 text-center"
                  onClick={closeExportModel}
                  data-close-modal
                >
                  Cancel and close
                </button>
              </li>
              <li className="usa-button-group__item">
                <button
                  type="button"
                  className="usa-button"
                  onClick={handleExportTableCSV}
                >
                  Export results to .csv
                </button>
              </li>
            </ul>
          </div>
        </Modal>
      )}
    </>
  );
}