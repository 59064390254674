import styles from "./SearchBar.module.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icon, Search } from "@trussworks/react-uswds";

// mock data
import { searchBarMockData } from "../../data";
import { ArrowRight } from "@mui/icons-material";
import { FilterKeys } from "../../constants/SearchProperties";
import { useFilterStore } from "../../store/useFilterStore";
import { useQuery } from "react-query";
import { getBusinessResults } from "../../data/fetch";
import useSBSResultsQuery from "components/ResultsPane/queries";
import { getAppliedFilterOptions } from "utils/helper";

interface IProps {
  placeholder?: string;
  isDrawerOpen?: boolean;
}

export default function SearchBar({ placeholder }: IProps) {
  const store = useFilterStore();

  const [list, setList] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const navigate = useNavigate();
  const currentUrl = window.location.href

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const inputEl = document.getElementById("search-field-header") as HTMLInputElement;
    const value = inputEl.value;
    setList([]);

    if (value) {
      store.setFilter(FilterKeys.SEARCH_BUSINESS, {
        isApplied:true,
        value
      });
      store.appliedFilter();
    }
    else{
      store.setFilter(FilterKeys.SEARCH_BUSINESS, {
        isApplied: false,
      });
      store.appliedFilter();
    }

    if (currentUrl !== "http://localhost:3000/advanced"){
      navigate(`/advanced`);
    }
  };

 const getBusinessData=(value)=>{
 if (value){
    getBusinessResults({ value }).then((promise)=>{
      if(promise?.status === 200){
       const { results } = promise
       results.forEach(element => {
         element['DISPLAYNAME']= `${element.NAME} | ${element.SAM_UEI}`
       });
       setList(results)
      }
    },error=>{
      alert("Internal server error");
    }).catch(()=>{
      alert("Internal server error");

    })
  }
  }
  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const input = e.target as HTMLInputElement;
    const value = input.value;

    setSearchValue(value);
    getBusinessData(value);

    if(!value){
      store.setFilter(FilterKeys.SEARCH_BUSINESS, {
        isApplied: false,
        
      });
      store.appliedFilter();
    }
   
  };

  const handleItemClick = (item: string) => {
    navigate(`/profile/${encodeURIComponent(item)}`);
    setList([]);
  };

  const HighlightText = ({ item, term }: { item: string; term: string }) => {
    const parts = item?.split(new RegExp(`(${term})`, "i"));

    return (
      <>
        {parts.map((part, i) =>
          part.toLowerCase() === term.toLowerCase() ? (
            <strong key={i}>{part}</strong>
          ) : (
            part
          )
        )}
      </>
    );
  };
  const appliedfilters = getAppliedFilterOptions(store.appliedFilters);
  const {  resetData } = useSBSResultsQuery(appliedfilters);

  const handleClose = () => {
    const target  = document.getElementById(
      "search-field-header"
    )as HTMLInputElement ;
    target.value = "";

    setSearchValue("");
    setList([]);
    store.clearFilter();
    resetData();
  }
  return (
    <div className={styles.searchbar_root}>
      <Search
        onSubmit={handleSubmit}
        onChange={handleChange}
        placeholder={placeholder}
        size="small"
        inputId="search-field-header"
      />
      <button type="button" className={`usa-button usa-button--unstyled padding-105 border-0 ${styles.closebtn} `}
        onClick={handleClose}>
        <Icon.Close />
      </button>

      {list.length > 0 && (
        <ul className={styles.dropdown}>
          {list.map((item:any) => (
            <li className="display-flex" key={item.DISPLAYNAME} onClick={() => handleItemClick(item?.USER_ID)}>
              <span>
                <HighlightText item={item.DISPLAYNAME} term={searchValue} />
              </span>
              <ArrowRight />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
