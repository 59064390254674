import React from "react";
import { toast } from "sonner";

// component
import { Textarea, Radio, TextInput, Icon } from "@trussworks/react-uswds";
import {
  Dialog,
  DialogContent,
  DialogHeading,
  DialogClose,
  IconButton,
  Button,
} from "components/LibraryComponents/Components";
import { Spinner } from "components/icons";

// utils
import { formatFeedbackData } from "utils/helper";
import { sendFeedback } from "data/fetch";

// styles
import styles from "./FeedbackDialog.module.scss";

type FeedbackDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function FeedbackDialog(props: FeedbackDialogProps) {
  const [feedbackChoice, setFeedbackChoice] = React.useState<
    "anonymously" | "information"
  >("anonymously");

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    feedback: "",
  });

  const handleFormSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();

      let formattedData = {};

      if (feedbackChoice === "anonymously") {
        formattedData = formatFeedbackData({
          ...formData,
          selectOption: "send-anonymously",
        });
      } else {
        formattedData = formatFeedbackData({
          ...formData,
          selectOption: "send-information",
        });
      }

      setIsLoading(true);

      await sendFeedback(formattedData);

      toast.success(
        <div>
          <h3>Thanks</h3>
          <p>Your valuable feedback helps us continually improve.</p>
        </div>
      );

      setIsLoading(false);

      props.setOpen(false);

      setFormData({
        name: "",
        email: "",
        feedback: "",
      });

      setFeedbackChoice("anonymously");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again.");
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogContent
        className={styles["feedback-dialog-root"]}
        hasBackdrop
        lockScroll
      >
        <form onSubmit={handleFormSubmit}>
          <div className={styles["feedback-dialog-body"]}>
            <div className={styles["header"]}>
              <DialogHeading className={styles["heading"]}>
                Give feedback
              </DialogHeading>
              <IconButton
                type="button"
                Icon={<Icon.Close />}
                size="sm"
                onClick={() => props.setOpen(false)}
                tabIndex={-1}
              />
            </div>

            <div className={styles["feedback-content"]}>
              <p className={styles["desc"]}>
                Report a bug, request a feature, ask a question, or share your
                thoughts about Small Business Search. If you provide your
                contact information, our team may follow up for more details.
                <span>
                  A red asterisk (<span className={styles["asterik"]}>*</span>)
                  indicates a required field.
                </span>
              </p>

              <div className={styles["comment-section"]}>
                <label htmlFor="comment">
                  What would you like us to know?{" "}
                  <abbr title="required">*</abbr>
                </label>
                <Textarea
                  id="feedback"
                  name="feedback"
                  placeholder="Type your feedback here."
                  inputMode="text"
                  value={formData.feedback}
                  required
                  className={styles["text-area"]}
                  onChange={(e) =>
                    setFormData({ ...formData, feedback: e.target.value })
                  }
                />
              </div>

              <div className={styles["radio-group"]}>
                <Radio
                  id={"anonymously"}
                  name={"anonymously"}
                  label={"Send anonymously"}
                  className={styles["radio-btn"]}
                  checked={feedbackChoice === "anonymously"}
                  onChange={() => setFeedbackChoice("anonymously")}
                />

                <Radio
                  id={"information"}
                  name={"information"}
                  label={"Send with contact information"}
                  className={styles["radio-btn"]}
                  checked={feedbackChoice === "information"}
                  onChange={() => setFeedbackChoice("information")}
                />
              </div>

              {feedbackChoice === "information" && (
                <div className={styles["control-group"]}>
                  <div className={styles["group"]}>
                    <label htmlFor="name">
                      Your name <abbr title="required">*</abbr>
                    </label>

                    <TextInput
                      id="name"
                      name="name"
                      type="text"
                      inputMode="text"
                      value={formData.name}
                      required
                      className={styles["input-wrapper"]}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </div>

                  <div className={styles["group"]}>
                    <label htmlFor="email">
                      Your email address <abbr title="required">*</abbr>
                    </label>

                    <TextInput
                      id="email"
                      name="email"
                      type="email"
                      inputMode="email"
                      value={formData.email}
                      required
                      className={styles["input-wrapper"]}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles["feedback-dialog-footer"]}>
            <DialogClose className={styles["btn-close"]}>
              Cancel and close
            </DialogClose>

            <Button
              type="submit"
              className={styles["btn-submit"]}
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : "Send feedback"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
