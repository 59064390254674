import * as React from "react";

function DownArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none" {...props}> 
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.12 0.453369L8 6.56004L1.88 0.453369L0 2.33337L8 10.3334L16 2.33337L14.12 0.453369Z" fill="#1B1B1B"/>
    </svg>
  );
}

export default DownArrow;