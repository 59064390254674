import { FilterKeys, LastUpdatedList, OperatorType } from "constants/filters";
import { QueryParams } from "types/common.types";
import { Filters, Generic } from "types/filters-store.types";

// data
import { stateAndTerritories } from "data";

// fetch
import { getResults } from "data/fetch";

export const formattedStatesList = stateAndTerritories.map((item) => ({
  label: `${item.split(" - ")[1]} (${item.split(" - ")[0]})`,
  value: item,
}));

export function getStateAbbreviation(state: string) {
  const stateAbbreviation = stateAndTerritories.find(
    (item) => item.split(" - ")[1] === state
  );

  if (!stateAbbreviation) {
    return state;
  }

  return stateAbbreviation.split(" - ")[0];
}

export function getHighlightedText(
  list: Generic[],
  searchTerm: string,
  highlightValue: boolean = false
) {
  const trimmedSearchTerm = searchTerm.trim();
  if (!trimmedSearchTerm) {
    return list;
  }

  const escapedSearchTerm = searchTerm.replace(
    /[-\/\\^$*+?.()|[\]{}]/g,
    "\\$&"
  );

  const regex = new RegExp(`(${escapedSearchTerm})`, "i");

  const highlightedText = list.map((item) => {
    const highlightedLabel = item.label.replace(regex, `<strong>$&</strong>`);
    let highlightedValue = item.value ?? "";

    if (highlightValue) {
      highlightedValue = item.value.replace(regex, `<strong>$&</strong>`);
    }

    return {
      ...item,
      label: highlightedLabel,
      value: highlightedValue,
    };
  });

  return highlightedText;
}

export function getFilterCount(filters: Filters, filterName: string) {
  switch (filterName) {
    case FilterKeys.LOCATION: {
      return (
        filters.location.states.length +
        filters.location.zipCodes.length +
        filters.location.counties.length
      );
    }

    case FilterKeys.SEARCH_TERM: {
      return filters.searchProfiles.searchTerm?.length > 0 ? 1 : 0 ?? 0;
    }

    case FilterKeys.SBA_CERTIFICATIONS: {
      return filters.sbaCertifications.activeCerts.length;
    }

    case FilterKeys.NAICS: {
      return filters.naics.codes.length;
    }

    case FilterKeys.SELF_CERTIFICATIONS: {
      return filters.selfCertifications.certifications.length;
    }

    case FilterKeys.KEYWORDS: {
      return filters.keywords.list.length;
    }

    case FilterKeys.LAST_UPDATED: {
      const lastUpdatedValue = filters.lastUpdated.date.value;
      return lastUpdatedValue === LastUpdatedList[0].value ? 0 : 1;
    }

    case FilterKeys.SAM_STATUS: {
      return filters.samStatus.isExpiredSAM ? 1 : 0;
    }

    case FilterKeys.QUALITY_ASSURANCE_STANDARDS: {
      return filters.qualityAssuranceStandards.qas.length;
    }

    case FilterKeys.BONDING_LEVELS: {
      return Object.values(filters.bondingLevels).filter((val) => val).length;
    }

    default:
      return 0;
  }
}

export const refineFilterOptions = (filters: Filters) => {
  const filterCategories = Object.keys(filters) as Array<keyof Filters>;

  const appliedFilterCategories = filterCategories
    .filter((category) => getFilterCount(filters, category) > 0)
    .map((category) => category);

  const queryParams: QueryParams[] = [];

  appliedFilterCategories.forEach((category) => {
    switch (category) {
      case FilterKeys.SEARCH_TERM:
        queryParams.push({
          category,
          options: [filters.searchProfiles.searchTerm],
        });
        break;

      case FilterKeys.LOCATION: {
        const states = filters.location.states.map((state) =>
          state.value.slice(0, 2)
        );

        const zipCodes = filters.location.zipCodes.map(
          (zip) => `z-${zip.value}`
        );

        const counties = filters.location.counties.map((county) => {
          const [_, stateName, fipCode] = county.value.split("-");
          const stateAbbr = getStateAbbreviation(stateName);
          return `c-${stateAbbr}-${fipCode}`;
        });

        const options = [...states, ...zipCodes, ...counties];

        queryParams.push({
          category,
          options: options,
        });
        break;
      }

      case FilterKeys.SBA_CERTIFICATIONS:
        queryParams.push({
          category,
          options: filters.sbaCertifications.activeCerts.map(
            (cert) => cert.value
          ),
          operator: filters.sbaCertifications.operatorType.toUpperCase(),
          isPrevious: filters.sbaCertifications.isPreviousCert,
        });
        break;

      case FilterKeys.NAICS:
        queryParams.push({
          category,
          options: filters.naics.codes.map((code) => code.value),
          operator: filters.naics.operatorType.toUpperCase(),
          isPrimary: filters.naics.isPrimary,
        });
        break;

      case FilterKeys.KEYWORDS:
        queryParams.push({
          category,
          options: filters.keywords.list.map((keyword) => keyword.value),
          operator: filters.keywords.operatorType.toUpperCase(),
        });
        break;

      case FilterKeys.SELF_CERTIFICATIONS:
        queryParams.push({
          category,
          options: filters.selfCertifications.certifications.map(
            (cert) => cert.value
          ),
          operator: filters.selfCertifications.operatorType.toUpperCase(),
        });
        break;

      case FilterKeys.LAST_UPDATED:
        queryParams.push({
          category,
          options: [filters.lastUpdated.date.value],
        });
        break;

      case FilterKeys.SAM_STATUS:
        queryParams.push({
          category,
          options: filters.samStatus.isExpiredSAM && ["expired"],
        });
        break;

      case FilterKeys.QUALITY_ASSURANCE_STANDARDS:
        queryParams.push({
          category,
          options: filters.qualityAssuranceStandards.qas.map(
            (qas) => qas.value
          ),
        });
        break;

      case FilterKeys.BONDING_LEVELS: {
        const bondingLevels = filters.bondingLevels;

        const options = {
          construction: {
            individual: bondingLevels.constructionIndividual || null,
            aggregate: bondingLevels.constructionAggregate || null,
          },
          service: {
            individual: bondingLevels.serviceIndividual || null,
            aggregate: bondingLevels.serviceAggregate || null,
          },
        };

        queryParams.push({
          category,
          options: options as any,
        });
        break;
      }
    }
  });

  // if(filters?.location?.searchTerm){
  //   queryParams.push({
  //     category:"searchBusiness",
  //     options:[filters?.location?.searchTerm],
  //   });
  // }

  return queryParams;
};

export const fetchAllFilterData = async (query: QueryParams[]) => {
  try {
    // fetch initial 25 results.
    const fetchInitialData = await getResults({
      queryKey: [query],
      pageSize: 25,
    });

    // check if results are more than 25
    if (fetchInitialData.total > 25) {
      // fetch all results
      const fetchAllData = await getResults({
        queryKey: [query],
        pageSize: fetchInitialData.total,
      });

      // merge results
      return {
        results: [
          ...fetchInitialData.results,
          ...fetchAllData.results.slice(25),
        ],
        total: fetchAllData.total,
        totalPages: fetchAllData.totalPages,
      };
    } else {
      return {
        results: fetchInitialData.results,
        total: fetchInitialData.total,
        totalPages: fetchInitialData.totalPages,
      };
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};
