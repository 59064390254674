import React, { useState } from "react";

import "./bondingLevels.scss";
import Input from "../../Input";

import { useFilterStore } from "../../../store/useFilterStore";

export default function BondingLevels() {
  const store = useFilterStore();

  const [bondingInput, setBondingInput] = useState({
    construction_individual: "",
    construction_aggregate: "",
    service_individual: "",
    service_aggregate: "",
  });

  const handleBondingInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    const { id, value } = e.target;

    if (e.target.value === "" || re.test(e.target.value)) {
      // setBondingInput({
      //   ...bondingInput,
      //   [id]: value,
      // });

      const newBondingLevelState = {
        ...store.filters.bondingLevels,
        level: {
          ...store.filters.bondingLevels?.level,
          [id]: {
            value: value,
            isApplied: false,
          },
        },
      };

      console.log(newBondingLevelState);

      store.setFilter("bondingLevels", newBondingLevelState);
    }
  };

  return (
    <>
      <div className="bonding_level_holder">
        <h4>Minimum Construction Bonding Level</h4>

        <div className="group">
          <Input
            id="construction_individual"
            type="text"
            // placeholder="Business Name, UEI, Legacy DUNS, or 8(a) Case Number"
            label="Individual"
            value={
              store.filters.bondingLevels.level?.construction_individual
                ?.value ?? ""
            }
            onChange={handleBondingInputChange}
            // onKeyDown={handleKeyPress}
          />
          <span className="doller_icon">
            <i className="fa-solid fa-dollar-sign"></i>
          </span>
        </div>

        <div className="group">
          <Input
            id="construction_aggregate"
            type="text"
            // placeholder="Business Name, UEI, Legacy DUNS, or 8(a) Case Number"
            label="Aggregate"
            value={
              store.filters.bondingLevels.level?.construction_aggregate
                ?.value ?? ""
            }
            onChange={handleBondingInputChange}
            // onKeyDown={handleKeyPress}
          />
          <span className="doller_icon">
            <i className="fa-solid fa-dollar-sign"></i>
          </span>
        </div>

        <div className="margin-top-3" />

        <h4>Minimum Service Bonding Level</h4>

        <div className="group">
          <Input
            id="service_individual"
            type="text"
            // placeholder="Business Name, UEI, Legacy DUNS, or 8(a) Case Number"
            label="Individual"
            value={
              store.filters.bondingLevels.level?.service_individual?.value ?? ""
            }
            onChange={handleBondingInputChange}
            // onKeyDown={handleKeyPress}
          />
          <span className="doller_icon">
            <i className="fa-solid fa-dollar-sign"></i>
          </span>
        </div>
        <div className="group">
          <Input
            id="service_aggregate"
            type="text"
            // placeholder="Business Name, UEI, Legacy DUNS, or 8(a) Case Number"
            label="Aggregate"
            value={
              store.filters.bondingLevels.level?.service_aggregate?.value ?? ""
            }
            onChange={handleBondingInputChange}
            // onKeyDown={handleKeyPress}
          />
          <span className="doller_icon">
            <i className="fa-solid fa-dollar-sign"></i>
          </span>
        </div>

        <hr />
        <p>
          Results can match <b>any</b> of these amounts.
        </p>
      </div>
    </>
  );
}
