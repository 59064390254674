import { useState } from "react";
import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// styles
import styles from "./AdvancedSearchNew.module.scss";

// components
import FilterFlyoutBar from "components/FilterFlyoutBar";
import AppliedFilterSentence from "components/AppliedFilterSentence";
import SearchBarNew from "components/SearchBarNew/SearchBarNew";
import EmptyQuery from "components/EmptyQuery/EmptyQuery";
import NoResultFound from "components/NoResultFound/NoResultFound";
import SortBy from "components/SortBy/SortBy";
import ResultTable from "components/ResultTable/ResultTable";
import TableSkeleton from "components/ResultTable/TableSkeleton";
import ExportModal from "layouts/MainLayout/Modal/ExportModal";
import ExportTableModal from "layouts/MainLayout/Modal/ExportTableModal";
import Banner from "layouts/MainLayout/Header/Banner";
import { RoundedButton } from "components/LibraryComponents/Components";
import { StarsIcon } from "components/icons";

// tyoes
import { Generic } from "types/filters-store.types";

// constant
import { SortList } from "constants/filters";

// store
import useFiltersStore from "store/useFiltersStoreNew";

// query
import useResultQuery from "./queries";

export default function AdvancedSearchNew() {
  const { areFiltersApplied, searchTerm } = useFiltersStore((state) => ({
    areFiltersApplied: state.areFiltersApplied(),
    searchTerm: state.filters?.searchProfiles?.searchTerm,
  }));

  const { data, totalCount, isLoading, isError, totalPages } = useResultQuery();

  const [sortBy, setSortBy] = useState<Generic>(SortList[0]);

  const showTable = !isLoading && data && data.length > 0 && !isError;
  const showNotFound =
    (!isLoading && areFiltersApplied && data && data.length === 0) || isError;

  return (
    <>
      <Helmet>
        <title>Advanced Search | Small Business Search</title>
      </Helmet>

      <Banner />

      <div className={styles["advanced-search-root"]}>
        <div className={styles["container"]}>
          <div className={styles["search-bar-wrapper"]}>
            <SearchBarNew />
          </div>

          <div className={styles["flyout-bar-section"]}>
            <FilterFlyoutBar />
          </div>

          <hr className={styles["divider"]} />

          <div hidden={!areFiltersApplied} className={styles["table-controls"]}>
            <div className={styles["top-section"]}>
              {!isLoading && areFiltersApplied && (
                <h3>
                  There are <strong>{totalCount}</strong> small businesses
                </h3>
              )}

              {isLoading && (
                <Skeleton
                  variant="text"
                  className={styles["skeleton"]}
                  animation="wave"
                />
              )}

              <div className={styles["group"]}>
                <ExportTableModal />
                <ExportModal />
              </div>
            </div>
            <div className={styles["bottom-section"]}>
              {!isLoading && <AppliedFilterSentence />}

              {isLoading && (
                <Skeleton
                  variant="text"
                  className={styles["skeleton"]}
                  animation="wave"
                />
              )}
              <SortBy selectedSort={sortBy} setSelectedSort={setSortBy} />

              {/* Hide Market Insights */}
              {/* <div className={styles["group"]}>
                <Link to="/market-insights">
                  <RoundedButton variant="ghost" disabled={isLoading}>
                    <StarsIcon />
                    Market insights
                  </RoundedButton>
                </Link>

                <SortBy selectedSort={sortBy} setSelectedSort={setSortBy} />
              </div> */}
            </div>
          </div>

          {!areFiltersApplied && (
            <EmptyQuery
              content="Choose filters or search for a term to start finding small businesses"
              style={{ marginTop: 88 }}
            />
          )}

          {showNotFound && (
            <NoResultFound
              content="No results found"
              style={{ marginTop: 88 }}
            />
          )}
        </div>

        {isLoading && <TableSkeleton />}

        {showTable && (
          <div className={styles["table-wrapper"]}>
            <ResultTable
              data={data}
              sortType={sortBy.value}
              rootClassname={styles["table-root-wrapper"]}
              totalPages={totalPages}
            />
          </div>
        )}
      </div>
    </>
  );
}
