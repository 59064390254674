// types
import { Filters, Generic } from "types/filters-store.types";

// utils
import { FilterKeys, LastUpdatedList } from "constants/filters";
import { getFilterCount, getStateAbbreviation } from "./advanced-search";

const FilterOrder = {
  [FilterKeys.SEARCH_TERM]: 0,
  [FilterKeys.LOCATION]: 1,
  [FilterKeys.SBA_CERTIFICATIONS]: 2,
  [FilterKeys.NAICS]: 3,
  [FilterKeys.KEYWORDS]: 4,
  [FilterKeys.SELF_CERTIFICATIONS]: 5,
  [FilterKeys.LAST_UPDATED]: 6,
  [FilterKeys.SAM_STATUS]: 7,
  [FilterKeys.QUALITY_ASSURANCE_STANDARDS]: 8,
  [FilterKeys.BONDING_LEVELS]: 9,
};

const SBAActive = {
  "1,4": "8(a) or 8(a) JV",
  "3": "HUBZone",
  "5": "WOSB",
  "6": "EDWOSB",
  "7,8": "VOSB or VOSB JV",
  "9,10": "SDVOSB or SDVOSB JV",
};

const LastUpdated = {
  "past-3-months": "within the past 3 months",
  "past-6-months": "within the past 6 months",
  "past-year": "within the past year",
};

export function getFiltersSentence(filters: Filters) {
  const builder: { sentence: string; order: number }[] = [];

  const filterCategories = Object.keys(filters) as Array<keyof Filters>;

  const appliedFilterCategories = filterCategories
    .filter((category) => getFilterCount(filters, category) > 0)
    .map((category) => ({ category, order: FilterOrder[category] }));

  appliedFilterCategories.forEach((filter) => {
    switch (filter.category) {
      case FilterKeys.SEARCH_TERM:
        builder.push({
          sentence: searchTermSentenceBuilder(filters.searchProfiles),
          order: filter.order,
        });
        break;

      case FilterKeys.LOCATION:
        builder.push({
          sentence: locationSentenceBuilder(filters.location),
          order: filter.order,
        });

        break;

      case FilterKeys.SBA_CERTIFICATIONS:
        builder.push({
          sentence: sbaCertificationsSentenceBuilder(filters.sbaCertifications),
          order: filter.order,
        });
        break;

      case FilterKeys.NAICS:
        builder.push({
          sentence: naicsSentenceBuilder(filters.naics),
          order: filter.order,
        });
        break;

      case FilterKeys.SELF_CERTIFICATIONS:
        builder.push({
          sentence: selfCertifySentenceBuilder(filters.selfCertifications),
          order: filter.order,
        });
        break;

      case FilterKeys.KEYWORDS:
        builder.push({
          sentence: keywordsSentenceBuilder(filters.keywords),
          order: filter.order,
        });
        break;

      case FilterKeys.LAST_UPDATED:
        builder.push({
          sentence: lastUpdatedSentenceBuilder(filters.lastUpdated),
          order: filter.order,
        });
        break;

      case FilterKeys.SAM_STATUS:
        builder.push({
          sentence: samStatusSentenceBuilder(filters.samStatus),
          order: filter.order,
        });
        break;

      case FilterKeys.QUALITY_ASSURANCE_STANDARDS:
        builder.push({
          sentence: qualityAssuranceStandardsSentenceBuilder(
            filters.qualityAssuranceStandards
          ),
          order: filter.order,
        });
        break;

      case FilterKeys.BONDING_LEVELS:
        builder.push({
          sentence: bondingSentenceBuilder(filters.bondingLevels),
          order: filter.order,
        });
        break;

      default:
        break;
    }
  });

  if (builder.length === 0) {
    return "";
  }

  const sentence = builder
    .sort((a, b) => a.order - b.order)
    .map((item) => item.sentence);

  if (sentence.length === 1) {
    return `${sentence[0]}.`;
  }

  return `${sentence.slice(0, -1).join(", ")}, and ${
    sentence[sentence.length - 1]
  }.`;
}

function searchTermSentenceBuilder(
  searchProfilesFilter: Filters["searchProfiles"]
) {
  let searchProfilesSentence = searchProfilesFilter
    ? `containing "<b>${searchProfilesFilter.searchTerm}</b>" in their profile`
    : "";
  return sentenceBuilder(searchProfilesSentence, "", []);
}

function locationSentenceBuilder(locationFilter: Filters["location"]) {
  const locationOptions: string[] = locationFilter.states.map((item: Generic) =>
    item.value.slice(0, 2)
  );

  const zipCodesOptions = locationFilter.zipCodes.map(
    (item: Generic) => `zip code ${item.value}`
  );

  const countiesOptions = locationFilter.counties.map((item: Generic) => {
    const [county, stateName] = item.value.split("-");

    if (stateName === "Louisiana") {
      return `${county} Parish, ${getStateAbbreviation(stateName)}`;
    }

    return `${county} County, ${getStateAbbreviation(stateName)}`;
  });

  const options = [...locationOptions, ...zipCodesOptions, ...countiesOptions];

  if (options.length === 0) return "";

  return sentenceBuilder("in ", "or", options);
}

function sbaCertificationsSentenceBuilder(
  sbaFilter: Filters["sbaCertifications"]
) {
  let sbaSentence = "SBA certified as ";

  const options = sbaFilter.activeCerts.map(
    (item: Generic) => SBAActive[item.value]
  );

  if (options.length === 0) return "";

  if (sbaFilter.isPreviousCert) {
    sbaSentence += "active or previously active ";
  }

  const operator = sbaFilter.operatorType.toLowerCase();

  return sentenceBuilder(sbaSentence, operator, options);
}

function naicsSentenceBuilder(naicsFilter: Filters["naics"]) {
  let naicsSentence = "designated small in NAICS code(s) ";

  if (naicsFilter.isPrimary)
    naicsSentence = "designated small in primary NAICS code(s) ";

  const options = naicsFilter.codes.map((item: Generic) => item.value);

  if (options.length === 0) return "";

  const operator = naicsFilter.operatorType.toLowerCase();

  return sentenceBuilder(naicsSentence, operator, options);
}

function selfCertifySentenceBuilder(
  certifyFilter: Filters["selfCertifications"]
) {
  let certifySentence = "self-certified as ";

  const options: string[] = certifyFilter.certifications.map(
    (item: Generic) => item.label
  );

  if (options.length === 0) return "";

  const operator = certifyFilter.operatorType.toLowerCase();

  return sentenceBuilder(certifySentence, operator, options);
}

function keywordsSentenceBuilder(keywordsFilter: Filters["keywords"]) {
  let keywordSentence = "including keyword(s) ";

  const options: string[] = keywordsFilter.list.map(
    (item: any) => `"${item.value}"`
  );

  const operator = keywordsFilter.operatorType.toLowerCase();

  let sentence = sentenceBuilder(keywordSentence, operator, options);

  return `${sentence} in their profile`;
}

function lastUpdatedSentenceBuilder(lastUpdateFilter: Filters["lastUpdated"]) {
  let lastUpdateSentence = "that have updated their profile ";
  const lastUpdatedValue = lastUpdateFilter.date.value;

  if (lastUpdatedValue && lastUpdatedValue !== LastUpdatedList[0].value) {
    lastUpdateSentence += ` <b>${lastUpdateFilter.date.label.toLowerCase()}</b>`;
    return lastUpdateSentence;
  }

  return "";
}

function samStatusSentenceBuilder(samStatusFilter: Filters["samStatus"]) {
  let samStatusSentence = "that have ";

  if (samStatusFilter.isExpiredSAM) {
    samStatusSentence += "active or expired SAM registrations";
    return samStatusSentence;
  }

  return "";
}

function qualityAssuranceStandardsSentenceBuilder(
  qasFilter: Filters["qualityAssuranceStandards"]
) {
  let qasSentence = "that have ";

  const options = qasFilter.qas.map((item: Generic) => item.value);

  if (options.length === 0) return "";

  const sentence = sentenceBuilder(qasSentence, "or", options);

  return `${sentence} quality assurance standards`;
}

function bondingSentenceBuilder(bonding: Filters["bondingLevels"]) {
  let bondingSentence = "that ";
  let options = [];

  if (bonding.serviceIndividual)
    options.push(
      `have have an individual minimum service bonding level of <b>$${bonding.serviceIndividual}</b>`
    );

  if (bonding.serviceAggregate)
    options.push(
      `have have an aggregate minimum service bonding level of <b>$${bonding.serviceAggregate}</b>`
    );

  if (bonding.constructionIndividual)
    options.push(
      `have have an individual minimum construction bonding level of <b>$${bonding.constructionIndividual}</b>`
    );

  if (bonding.constructionAggregate)
    options.push(
      `have have an aggregate minimum construction bonding level of <b>$${bonding.constructionAggregate}</b>`
    );

  if (options.length === 0) return "";

  let newSentence = bondingSentence;

  if (options.length === 1) newSentence += options[0];
  else if (options.length == 2) newSentence += options.join(` <b>or</b> `);
  else
    newSentence += `${options.slice(0, -1).join(", ")}, <b>or</b> ${
      options[options.length - 1]
    }`;

  return newSentence;
}

function sentenceBuilder(
  sentence: string,
  operator: string,
  options: string[]
) {
  let newSentence = sentence;
  if (options.length === 0) return newSentence;
  else if (options.length === 1) newSentence += `<b>${options[0]}</b>`;
  else if (options.length == 2)
    newSentence += `<b>${options.join(` <b>${operator}</b> `)}</b>`;
  else
    newSentence += `<b>${options
      .slice(0, -1)
      .join(", ")}</b>, <b>${operator}</b> <b>${
      options[options.length - 1]
    }</b>`;

  return newSentence;
}
