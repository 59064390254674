import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

const HoverClass = {
  content: '""',
  position: "absolute",
  top: "-2px",
  left: "7.7px",
  width: "42px",
  height: "42px",
  borderRadius: "50%",
  background: "#D9D9D9",
};

const FocusClass = {
  ...HoverClass,
  outline: "4px solid #0050D8",
  outlineOffset: 2,
};

const SwitchContainer = styled("span")<{ isDisabled?: boolean }>(
  ({ isDisabled }) => ({
    position: "relative",
    display: "inline-block",
    outline: isDisabled && "none !important",

    "&:hover": !isDisabled && {
      "&:before": {
        ...HoverClass,
      },
    },

    "&:focus": !isDisabled && {
      outline: "none",

      "&:before": {
        ...FocusClass,
      },
    },
  })
);

const CustomSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase": {
    // The default state
    "& .MuiSwitch-thumb": {
      color: "#ffffff", // Color of the thumb
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#969696", // Lighter shade of blue for the track
    },

    // checked state
    "&.Mui-checked": {
      "& .MuiSwitch-thumb": {
        color: "#005EA2", // Color of the thumb
      },
      "+ .MuiSwitch-track": {
        backgroundColor: "##97D4EA", // Color of the track when checked
      },
      "&:hover": {
        backgroundColor: "transparent", // Remove the default hover
      },
    },

    // The hover state
    "&:hover": {
      backgroundColor: "transparent", // Remove the default hover
    },
  },
}));

/**
 * Toggle component
 * Toggle the state of a single setting on or off.
 *
 * @param {SwitchProps} props - mui switch props
 * @see https://mui.com/material-ui/react-switch/
 */
export default function Toggle(props: SwitchProps) {
  return (
    <SwitchContainer tabIndex={0} isDisabled={props.disabled}>
      <CustomSwitch disableRipple {...props} />
    </SwitchContainer>
  );
}

/**
 * AutoFocusToggle component
 * Show the Toggle component in Focus state.
 *
 * @param {SwitchProps} props - mui switch props
 * @see https://mui.com/material-ui/react-switch/
 *
 * @summary The component shouldn't be used in real scenario and is just for Simulation purpose.
 */
export function AutoFocusToggle(props: SwitchProps) {
  const AutoFocusSwitchContainer = styled("span")(() => ({
    position: "relative",
    outline: "none !important",

    "&:before": {
      ...FocusClass,
    },
  }));

  return (
    <AutoFocusSwitchContainer tabIndex={0}>
      <CustomSwitch disableRipple {...props} />
    </AutoFocusSwitchContainer>
  );
}

/**
 * AutoHoverToggle component
 * Show the Toggle component in Hover state.
 *
 * @param {SwitchProps} props - mui switch props
 * @see https://mui.com/material-ui/react-switch/
 *
 * @summary The component shouldn't be used in real scenario and is just for Simulation purpose.
 */
export function AutoHoverToggle(props: SwitchProps) {
  const AutoHoverSwitchContainer = styled("span")(() => ({
    position: "relative",
    outline: "none !important",

    "&:before": {
      ...HoverClass,
    },
  }));

  return (
    <AutoHoverSwitchContainer tabIndex={0}>
      <CustomSwitch disableRipple {...props} />
    </AutoHoverSwitchContainer>
  );
}
