import classNames from "classnames";
import { Icon } from "@trussworks/react-uswds";
import {
  useFloating,
  useClick,
  useInteractions,
  autoUpdate,
  shift,
  useRole,
  useDismiss,
  FloatingPortal,
  offset,
  flip,
  FloatingFocusManager,
} from "@floating-ui/react";

// components
import IconButton from "../IconButton";

// styles
import styles from "./FilterFlyout.module.scss";

type FilterFlyoutProps = {
  id: string;
  FilterIcon: React.ReactElement;
  filterName: string;
  title: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  options: string[];
  isApplied?: boolean;
  isHalt?: boolean;
  defaultValue?: string;
  operator?: string;
  callback?: (open: boolean) => void;
  className?: string;
  btnClassName?: string;
  contentClass?: string;
};

/**
 * `FilterFlyout` component for displaying filter content.
 *
 * @param {FilterFlyoutProps} props - The props for the `FilterFlyout` component.
 * @param {string} props.id - The id of the `FilterFlyout` component.
 * @param {React.ReactNode} props.children - The content to be displayed in the flyout.
 * @param {string[]} props.options - The list of options to be displayed in the flyout button.
 * @param {React.ReactElement} props.FilterIcon - Icon to be displayed in the flyout button.
 * @param {string} props.filterName - The name of the filter to be displayed in the flyout button.
 * @param {string} props.title - Header title of the flyout.
 * @param {boolean} props.isOpen - Show or hide the flyout content.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsOpen - Toggle the flyout content.
 * @param {string} props.defaultValue - The default value of the flyout (optional).
 * @param {boolean} props.isApplied - Whether the flyout is applied or not (optional).
 * @param {boolean} props.isHalt - If the flyout has value halt the button from expanding (optional).
 * @param {string} props.operator - The operator (or | and) to show in the flyout button (optional).
 * @param {Function} props.callback - The callback function to be called when the flyout is closed/open.
 * @param {string} props.className - The class name for the root element (Optional).
 * @param {string} props.btnClassName - The class name for the flyout button element (Optional).
 * @param {string} props.contentClass - The class name for the content wrapper element (Optional).
 */
export default function FilterFlyout(props: FilterFlyoutProps) {
  const rootClasses = classNames(styles["filter-flyout-root"], {
    [props.className]: props.className,
  });

  const btnClasses = classNames(styles["flyout-btn"], {
    [styles["selected"]]: props.options && props.options.length > 0,
    [styles["applied"]]: props.isApplied,
    [styles["halt"]]: props.isOpen && props.isHalt,
    [props.btnClassName]: props.btnClassName,
  });

  const arrowClasses = classNames({
    [styles["is-open"]]: props.isOpen,
  });

  const contentClasses = classNames(styles["flyout-content"], {
    [props.contentClass]: props.contentClass,
  });

  const firstSelectedValue =
    props.options && props.options.length > 0
      ? props.options[0]
      : props.defaultValue;
  let moreCount = null;

  if (props.options && props.options.length > 1) {
    moreCount = props.options.slice(1, props.options.length).length;
  }

  const { refs, floatingStyles, context } = useFloating({
    open: props.isOpen,
    placement: "bottom-start",
    onOpenChange: (open) => {
      props.setIsOpen(open);
      props?.callback?.(open);
    },
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), flip(), shift()],
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, { bubbles: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss,
  ]);

  return (
    <div className={rootClasses}>
      <button
        className={btnClasses}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {props.FilterIcon}
        <span>
          {props.filterName}
          {firstSelectedValue && `: ${firstSelectedValue}`}
          {moreCount && <b> {props.operator || "or"} </b>}
          {moreCount && <span className={styles["more"]}>+{moreCount}</span>}
        </span>
        <Icon.ArrowDropDown className={arrowClasses} />
      </button>

      <FloatingPortal id={props.id} preserveTabOrder>
        <FloatingFocusManager context={context} modal={false}>
          {props.isOpen && (
            <div
              ref={refs.setFloating}
              style={{ ...floatingStyles, outline: "none" }}
              className={contentClasses}
              {...getFloatingProps()}
            >
              <div className={styles["header"]}>
                <h3>{props.title}</h3>
                <IconButton
                  type="button"
                  Icon={<Icon.Close />}
                  size="sm"
                  onClick={() => {
                    props.setIsOpen(false);
                    props?.callback?.(false);
                  }}
                  tabIndex={-1}
                />
              </div>
              {props.children}
            </div>
          )}
        </FloatingFocusManager>
      </FloatingPortal>
    </div>
  );
}
