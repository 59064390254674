// styles
import styles from "./ErrorLayout.module.scss";

// components
import Header from "layouts/MainLayout/Header/Header";
import Footer from "layouts/MainLayout/Footer/Footer";

type ErrorProps = {
  children: React.ReactNode;
};

export default function ErrorLayout({ children }: ErrorProps) {
  return (
    <div className={styles["error-layout-root"]}>
      <Header />

      <div className={styles["main-content"]}>{children}</div>

      <Footer />
    </div>
  );
}
