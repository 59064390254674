import { useState, useMemo, useEffect } from "react";
import { Icon } from "@trussworks/react-uswds";

// component
import {
  Checkbox,
  FilterFlyout,
  FilterRelationBox,
  RoundedButton,
  SearchInput,
} from "components/LibraryComponents/Components";

// constants
import { FilterKeys, FilterTitles, OperatorType } from "constants/filters";

// hooks
import useDebounce from "hooks/useDebounce";

// store
import useFiltersStore from "store/useFiltersStoreNew";

// query
import useNaicsCodesQuery, { FilterNaics } from "./query";

// utils
import { getHighlightedText } from "utils/advanced-search";

// styles
import styles from "./NaicsFilterButton.module.scss";

export default function NaicsFilterButton() {
  const { naics, setFilter } = useFiltersStore((state) => ({
    naics: state.filters.naics,
    setFilter: state.setFilter,
  }));

  const { naicsCodes } = useNaicsCodesQuery();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  // This state only control if filter is open,
  // and has more than one value apply max-width to filter button.
  const [isHalt, setIsHalt] = useState<boolean>(true);

  const debouncedSearchTerm = useDebounce(searchTerm, 150);

  const selectedNaicsValues = naics.codes.map((item) => item.value);
  const isDisabled = selectedNaicsValues.length === 0;
  const isSearchState = searchTerm.length > 0;

  const filteredNaicsCodes = useMemo(() => {
    if (!debouncedSearchTerm.trim()) return [];

    // Filter the list based on the search term and limit the results
    const searchLower = debouncedSearchTerm.toLowerCase();
    const filtered = naicsCodes.filter(
      (item) =>
        item.value.toLowerCase().startsWith(searchLower) ||
        item.label.toLowerCase().includes(searchLower)
    );

    // Limit the number of results to 5
    // const list = filtered.slice(0, 5);

    const highlightedList = getHighlightedText(
      filtered,
      debouncedSearchTerm,
      true
    );

    return highlightedList as FilterNaics[];
  }, [naicsCodes, debouncedSearchTerm]);

  useEffect(() => {
    if (naics.codes.length === 0) {
      if (!isHalt) setIsHalt(true);
    }
  }, [naics.codes]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (checked) {
      const item = filteredNaicsCodes.find(
        (item) => item.originalValue === name
      );
      setFilter(FilterKeys.NAICS, {
        ...naics,
        codes: [
          ...naics.codes,
          { label: item.originalLabel, value: item.originalValue },
        ],
      });
    } else {
      setFilter(FilterKeys.NAICS, {
        ...naics,
        codes: naics.codes.filter((item) => item.value !== name),
      });
    }
  };

  const handlePrimaryChange = () => {
    setFilter(FilterKeys.NAICS, {
      ...naics,
      isPrimary: !naics.isPrimary,
    });
  };

  const handleOperatorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setFilter(FilterKeys.NAICS, {
      ...naics,
      operatorType: value,
    });
  };

  const handleClear = () => {
    setFilter(FilterKeys.NAICS, {
      ...naics,
      codes: [],
      isPrimary: false,
      operatorType: OperatorType.OR,
    });

    setIsHalt(true);
  };

  const handleCallback = (open: boolean) => {
    if (!open) {
      if (searchTerm) {
        setSearchTerm("");
      }
    }

    if (!open && selectedNaicsValues.length > 0) {
      setIsHalt(false);
    }
  };

  return (
    <FilterFlyout
      id={FilterKeys.NAICS}
      filterName={FilterTitles[FilterKeys.NAICS]}
      title={`${FilterTitles[FilterKeys.NAICS]} codes`}
      FilterIcon={<Icon.Topic />}
      options={selectedNaicsValues}
      operator={naics.operatorType}
      isOpen={isOpen}
      isHalt={isHalt}
      setIsOpen={setIsOpen}
      callback={handleCallback}
    >
      <div className={styles["naics-filter-content"]}>
        <p className={styles["description"]}>
          Search for small businesses that are designated “small” for a NAICS code.
          The North American Industry Classification System (NAICS) is the
          standard used by Federal statistical agencies in classifying
          businesses.{" "}
          <a
            href="https://www.census.gov/naics/"
            target="_blank"
            rel="noreferrer"
            tabIndex={-1}
          >
            Learn more about NAICS here.
          </a>
        </p>

        <div className={styles["search-input-wrapper"]}>
          <SearchInput
            id="naics-search"
            name="naics-search"
            placeholder="Type numbers or text to search NAICS codes"
            inputSize="small"
            value={searchTerm}
            onChange={handleSearch}
            handleClear={() => setSearchTerm("")}
          />
        </div>

        <div className={styles["naics-list-wrapper"]}>
          {/* selected list */}
          {!isSearchState && !isDisabled && (
            <>
              <p>SELECTED</p>
              <ul className={styles["list"]}>
                {naics.codes.map((item) => (
                  <li key={item.value} className={styles["item"]}>
                    <Checkbox
                      id={item.value}
                      name={item.value}
                      label={`<div><span style="font-weight: 700;">${item.value}</span><span>${item.label}</span></div>`}
                      className={styles["checkbox-wrapper"]}
                      onChange={handleOnChange}
                      isInnerHtml
                      checked={selectedNaicsValues.includes(item.value)}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}

          {/* highlighted list */}
          {isSearchState && (
            <ul className={styles["list"]}>
              {filteredNaicsCodes.map((item) => (
                <li key={item.originalValue} className={styles["item"]}>
                  <Checkbox
                    id={item.originalValue}
                    name={item.originalValue}
                    label={`<div><span>${item.value}</span><span>${item.label}</span></div>`}
                    className={styles["checkbox-wrapper"]}
                    onChange={handleOnChange}
                    isInnerHtml
                    checked={selectedNaicsValues.includes(item.originalValue)}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Relation box */}
        <div
          hidden={isSearchState || selectedNaicsValues.length <= 1}
          className={styles["relation-box"]}
        >
          {/* <FilterRelationBox
            filterName={FilterKeys.NAICS}
            value={`${FilterKeys.NAICS}-${naics.operatorType}`}
            onChange={handleOperatorChange}
          /> */}
        </div>

        <div hidden={isSearchState} className={styles["divider"]}>
          <hr />
        </div>

        {/* primary naics checkbox */}
        {!isSearchState && (
          <Checkbox
            id="naics-primary"
            name="naics-primary"
            label="Only search primary NAICS"
            labelDescription="This will limit your results to firms that have listed the code(s) as 
          their primary category of business. A firm may only have one primary NAICS code."
            checked={naics.isPrimary}
            onChange={handlePrimaryChange}
          />
        )}

        <div hidden={isSearchState} className={styles["clear-filter"]}>
          <RoundedButton
            variant="ghost"
            onClick={handleClear}
            disabled={isDisabled}
          >
            Clear filter selections
          </RoundedButton>
        </div>
      </div>
    </FilterFlyout>
  );
}
