import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// styles
import styles from "./Home.module.scss";

// components
import SearchBarNew from "components/SearchBarNew/SearchBarNew";
import Banner from "layouts/MainLayout/Header/Banner";

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>Small Business Search</title>
      </Helmet>

      <Banner />

      <div className={styles["hoom-root"]}>
        <div className={styles["content"]}>
          <h1 className={styles["heading"]}>
            There are over 55,000 small businesses ready to work with the
            federal government.
          </h1>

          <h2 className={styles["sub-heading"]}>
            Find the ones you’re looking for.
          </h2>

          <div className={styles["input-wrapper"]}>
            <SearchBarNew />
          </div>

          <Link to="/advanced" className={styles["link-wrapper"]}>
            Or, skip directly to Advanced Search
          </Link>
        </div>
      </div>
    </>
  );
}
