import styles from "./FilterFlyoutBlock.module.scss";

import { useState } from "react";

// components
import { FilterFlyout, Heading, Subheading } from "../../Components";
import { Icon } from "@trussworks/react-uswds";

export default function FilterFlyoutBlock() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectedOpen, setIsSelectedOpen] = useState(false);
  const [isAppliedOpen, setIsAppliedOpen] = useState(false);

  return (
    <div className={styles["filter-flyout-block-root"]}>
      <Heading title="Filter flyout button" />

      <div style={{ marginTop: "16px" }} />
      <Subheading title="Default" />
      <div style={{ marginTop: "16px" }} />

      {/* Default Filter Flyout component */}
      <FilterFlyout
        id="location-default"
        FilterIcon={<Icon.Map />}
        filterName="Location"
        title="Location"
        options={[]}
        isApplied={false}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div className={styles["content"]}>[filter content goes here]</div>
      </FilterFlyout>

      {/* Selected Filter Flyout component */}
      <div style={{ marginTop: "16px" }} />
      <Subheading title="Selected" />
      <div style={{ marginTop: "16px" }} />

      <FilterFlyout
        id="location-selected"
        FilterIcon={<Icon.Map />}
        filterName="Location"
        title="Location"
        options={["Alaska", "Alamba", "Hawaii", "Maine"]}
        isApplied={false}
        isOpen={isSelectedOpen}
        setIsOpen={setIsSelectedOpen}
      >
        <div className={styles["content"]}>[filter content goes here]</div>
      </FilterFlyout>

      {/* Applied Filter Flyout component */}
      <div style={{ marginTop: "16px" }} />
      <Subheading title="Applied" />
      <div style={{ marginTop: "16px" }} />

      <FilterFlyout
        id="location-applied"
        FilterIcon={<Icon.Map />}
        filterName="Location"
        title="Location"
        options={["Alaska", "Alamba", "Hawaii", "Maine"]}
        isApplied={true}
        isOpen={isAppliedOpen}
        setIsOpen={setIsAppliedOpen}
      >
        <div className={styles["content"]}>[filter content goes here]</div>
      </FilterFlyout>
    </div>
  );
}
