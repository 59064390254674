import * as React from "react";

function Feedback(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M6 14h3.05l5-5a1.538 1.538 0 00.45-1.075c0-.183-.042-.362-.125-.537a2.073 2.073 0 00-.325-.488l-.9-.95a1.504 1.504 0 00-1.638-.338A1.483 1.483 0 0011 5.95l-5 5V14zm1.5-1.5v-.95l2.525-2.525.5.45.45.5L8.45 12.5H7.5zm3.025-3.025l.45.5-.95-.95.5.45zm.65 4.525H18v-2h-4.825l-2 2zM2 22V4c0-.55.196-1.02.587-1.413A1.926 1.926 0 014 2h16c.55 0 1.02.196 1.413.587C21.803 2.98 22 3.45 22 4v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0120 18H6l-4 4zm3.15-6H20V4H4v13.125L5.15 16z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Feedback;
