import * as React from "react";
import { useState, useEffect } from "react";
import { SearchProperty } from "../constants/SearchProperties";
import ControlAccordion from "./ControlAccordion";
import { Paper } from "@mui/material";

interface ControlsPaneProps {
  searchProperties: SearchProperty[];
}

const ControlsPane: React.FC<ControlsPaneProps> = ({ searchProperties }) => {
  const [searchObject, setSearchObject] = useState<{ [key: string]: string }>(
    searchProperties?.reduce((acc, prop) => ({ ...acc, [prop.name]: "" }), {})
  );

  const handleControlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchObject((prevSearchObject) => ({
      ...prevSearchObject,
      [name]: value,
    }));
  };

  useEffect(() => {}, []);

  if (!searchProperties) {
    return <div>Loading Search Controls...</div>;
  }

  return (
    <>
      {searchProperties.map((sProp, index) => {
        const PropertyComponent = sProp.component || null;

        return (
          <Paper key={index}>
            <ControlAccordion
              key={sProp.name}
              title={sProp.display}
              name={sProp.name}
            >
              {PropertyComponent === null ? (
                <div key={sProp.name} className="control-group">
                  {sProp.label && (
                    <label htmlFor={sProp.name}>{sProp.label}</label>
                  )}
                  <input
                    type={sProp.inputType}
                    id={sProp.name}
                    name={sProp.name}
                    value={searchObject[sProp.name]}
                    onChange={handleControlChange}
                    placeholder={sProp.helpText}
                    className="filter-input"
                  />
                </div>
              ) : (
                <PropertyComponent />
              )}
            </ControlAccordion>
          </Paper>
        );
      })}
    </>
  );
};

export default ControlsPane;
