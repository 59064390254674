import React from "react";
import { Checkbox as TrussCheckbox } from "@trussworks/react-uswds";
import classNames from "classnames";

import styles from "./Checkbox.module.scss";

export declare type CheckboxProps = {
  id: string;
  name: string;
  className?: string;
  label: React.ReactNode;
  inputRef?:
    | string
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
  tile?: boolean;
  labelDescription?: React.ReactNode;
  isInnerHtml?: boolean;
} & JSX.IntrinsicElements["input"];

/**
 * Checkbox component
 * Checkboxes allow users to select one or more options from a list.
 *
 * @param {CheckboxProps} props - checkbox props
 * @see https://designsystem.digital.gov/components/checkbox/
 */
export default function Checkbox(props: CheckboxProps) {
  const { className, label, isInnerHtml, ...rest } = props;

  const checkboxClasses = classNames(styles["checkbox-root"], {
    [styles["disabled"]]: props.disabled,
    [className]: className,
  });

  return (
    <TrussCheckbox
      className={checkboxClasses}
      label={
        !isInnerHtml ? (
          label
        ) : (
          <span dangerouslySetInnerHTML={{ __html: props.label as any }} />
        )
      }
      {...rest}
    />
  );
}

export type NAICSCheckboxProps = {
  code: string;
  label: string;
} & CheckboxProps;

/**
 * `NAICSCheckbox` component allow users to select one or more options from a list
 *
 * @param {NAICSCheckboxProps} props - The props for the `NAICSCheckbox` and `Checkbox` component .
 * @param {Item[]} props.code - The code of item to be displayed.
 * @param {Item[]} props.label - The label of item to be displayed.
 *
 * @see https://designsystem.digital.gov/components/checkbox/
 */
export function NAICSCheckbox(props: NAICSCheckboxProps) {
  const { code, label, ...rest } = props;

  const naicsCheckboxClasses = classNames(styles["naics-checkbox-root"], {
    [props.className]: props.className,
  });

  return (
    <Checkbox
      {...rest}
      className={naicsCheckboxClasses}
      label={
        <div>
          <span>{code}</span> <span>{label}</span>
        </div>
      }
    />
  );
}

// *********** SIMULATION COMPONENTS *****************

/**
 * Checkbox component
 * Checkboxes allow users to select one or more options from a list.
 *
 * @param {CheckboxProps} props - checkbox props
 * @see https://designsystem.digital.gov/components/checkbox/
 *
 * @summary The component shouldn't be used in real scenario and is just for Simulation purpose.
 */
export function AutoHoverCheckbox(props: CheckboxProps) {
  const checkboxClasses = classNames(
    styles["checkbox-root"],
    styles["checkbox-hover"]
  );
  return <TrussCheckbox {...props} className={checkboxClasses} />;
}

/**
 * Checkbox component
 * Checkboxes allow users to select one or more options from a list.
 *
 * @param {CheckboxProps} props - checkbox props
 * @see https://designsystem.digital.gov/components/checkbox/
 *
 * @summary The component shouldn't be used in real scenario and is just for Simulation purpose.
 */
export function AutoFocusCheckbox(props: CheckboxProps) {
  const checkboxClasses = classNames(
    styles["checkbox-root"],
    styles["checkbox-hover"],
    styles["checkbox-focus-simulation"]
  );

  return <TrussCheckbox {...props} className={checkboxClasses} />;
}

/**
 * `NAICSCheckbox` component allow users to select one or more options from a list
 *
 * @param {NAICSCheckboxProps} props - The props for the `NAICSCheckbox` and `Checkbox` component .
 * @param {Item[]} props.code - The code of item to be displayed.
 * @param {Item[]} props.label - The label of item to be displayed.
 *
 * @see https://designsystem.digital.gov/components/checkbox/
 *
 * @summary The component shouldn't be used in real scenario and is just for Simulation purpose.
 */
export function AutoHoverNAICSCheckbox(props: NAICSCheckboxProps) {
  const { code, label, ...rest } = props;

  const naicsCheckboxClasses = classNames(
    styles["naics-checkbox-root"],
    styles["checkbox-hover"],
    {
      [props.className]: props.className,
    }
  );

  return (
    <Checkbox
      {...rest}
      className={naicsCheckboxClasses}
      label={
        <div>
          <span>{code}</span> <span>{label}</span>
        </div>
      }
    />
  );
}

/**
 * `NAICSCheckbox` component allow users to select one or more options from a list
 *
 * @param {NAICSCheckboxProps} props - The props for the `NAICSCheckbox` and `Checkbox` component .
 * @param {Item[]} props.code - The code of item to be displayed.
 * @param {Item[]} props.label - The label of item to be displayed.
 *
 * @see https://designsystem.digital.gov/components/checkbox/
 *
 * @summary The component shouldn't be used in real scenario and is just for Simulation purpose.
 */
export function AutoFocusNAICSCheckbox(props: NAICSCheckboxProps) {
  const { code, label, ...rest } = props;

  const naicsCheckboxClasses = classNames(
    styles["naics-checkbox-root"],
    styles["checkbox-hover"],
    styles["checkbox-focus-simulation"],
    {
      [props.className]: props.className,
    }
  );

  return (
    <Checkbox
      {...rest}
      className={naicsCheckboxClasses}
      label={
        <div>
          <span>{code}</span> <span>{label}</span>
        </div>
      }
    />
  );
}
