import * as React from "react";

function SBSLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.048 24.927A13.443 13.443 0 0030 16.5C30 9.044 23.956 3 16.5 3S3 9.044 3 16.5 9.044 30 16.5 30c3.188 0 6.117-1.105 8.427-2.952l1.418 1.418c-.268 1-.009 2.113.776 2.898l5.44 5.44a3 3 0 004.242-4.243l-5.439-5.44a2.999 2.999 0 00-2.898-.776l-1.418-1.418zM16.5 27C22.299 27 27 22.299 27 16.5S22.299 6 16.5 6 6 10.701 6 16.5 10.701 27 16.5 27z"
        fill="#002E6D"
      />
      <circle cx={16.5} cy={16.5} r={10.5} fill="#007DBC" />
      <path
        d="M9.127 9.217c-1.051 4.402.847 9.15 4.966 11.528 4.12 2.378 9.18 1.648 12.467-1.462a10.5 10.5 0 01-1.123 2.815c-2.9 5.022-9.322 6.743-14.344 3.843-5.022-2.9-6.742-9.32-3.843-14.343a10.501 10.501 0 011.877-2.38z"
        fill="#fff"
      />
    </svg>
  );
}

export default SBSLogo;
