import "./OwnershipAndSelfCertifications.scss";

import React, { useEffect, useState } from "react";
import { Radio, Icon } from "@trussworks/react-uswds";

// store
import { useFilterStore } from "../../../store/useFilterStore";

// constants
import { FilterKeys } from "../../../constants/SearchProperties";

const SelfCertifications = [
  "Small Disadvantaged Business",
  "Veteran-Owned",
  "Service-Disabled Veteran-Owned",
  "Community Development Corporation (CDC)-Owned",
];

const SelfCertifiedWomenOwned = [
  "Woman-Owned Small Business",
  "Woman-Owned Small Business Joint Venture",
  "Economically-Disadvantaged Woman-Owned Small Business",
];

const NativeAmericanOwned = [
  "Tribally owned",
  "Alaskan Native Corp (ANC)-Owned",
  "Other Native American-Owned",
];

export default function OwnershipAndSelfCertifications() {
  const store = useFilterStore();

  const [ownershipArrow, setOwnershipArrow] = useState({
    anySelfCertifiedWoman: false,
    anyMinorityOwned: false,
    anyNativeAmericanOwned: false,
  });

  const [ownershipToogle, setOwnershipToogle] = useState({
    anySelfCertifiedWoman: false,
    anyMinorityOwned: false,
    anyNativeAmericanOwned: false,
  });

  const arrowHandler = (key: string, value: boolean) => {
    setOwnershipArrow({ ...ownershipArrow, [key]: !value });
  };

  const handleChangeSelfCertifications = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;

    const newList = store.filters.ownershipSelfCerts.selfCertifications;

    if (checked) {
      store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
        ...store.filters.ownershipSelfCerts,
        selfCertifications: [...newList, { value: name, isApplied: false }],
      });
    } else {
      const filterList = newList.filter((item) => item.value !== name);

      store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
        ...store.filters.ownershipSelfCerts,
        selfCertifications: filterList,
      });
    }
  };

  const handleChangeSelfCertifiedWomenOwner = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;

    const list = store.filters.ownershipSelfCerts.selfCertifiedWomanOwned;

    if (checked) {
      const newList = [...list, { value: name, isApplied: false }];

      // set value in store
      store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
        ...store.filters.ownershipSelfCerts,
        selfCertifiedWomanOwned: newList,
      });

      // toggle switch based on condition
      if (newList.length === SelfCertifiedWomenOwned.length) {
        setOwnershipToogle({
          ...ownershipToogle,
          anySelfCertifiedWoman: true,
        });
      }
    } else {
      const filterList = list.filter((item) => item.value !== name);

      // update value in store
      store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
        ...store.filters.ownershipSelfCerts,
        selfCertifiedWomanOwned: filterList,
      });

      // toggle switch based on condition
      if (filterList.length < SelfCertifiedWomenOwned.length) {
        setOwnershipToogle({
          ...ownershipToogle,
          anySelfCertifiedWoman: false,
        });
      }
    }
  };

  const handleChangeNativeAmericanOwned = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;

    const list =
      store.filters.ownershipSelfCerts.anyMinorityOwned.anyNativeAmericanOwner;

    if (checked) {
      const newList = [...list, { value: name, isApplied: false }];

      // set value in store
      store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
        ...store.filters.ownershipSelfCerts,
        anyMinorityOwned: {
          ...store.filters.ownershipSelfCerts.anyMinorityOwned,
          anyNativeAmericanOwner: newList,
        },
      });

      // toggle switch based on condition
      if (newList.length === SelfCertifiedWomenOwned.length) {
        if (
          store.filters.ownershipSelfCerts.anyMinorityOwned.otherMinorityOwned
            ?.value
        ) {
          setOwnershipToogle({
            ...ownershipToogle,
            anyNativeAmericanOwned: true,
            anyMinorityOwned: true,
          });
        } else {
          setOwnershipToogle({
            ...ownershipToogle,
            anyNativeAmericanOwned: true,
          });
        }
      }
    } else {
      const filterList = list.filter((item) => item.value !== name);

      // set value in store
      store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
        ...store.filters.ownershipSelfCerts,
        anyMinorityOwned: {
          ...store.filters.ownershipSelfCerts.anyMinorityOwned,
          anyNativeAmericanOwner: filterList,
        },
      });

      // toggle switch based on condition
      if (filterList.length < SelfCertifiedWomenOwned.length) {
        if (ownershipToogle.anyMinorityOwned) {
          setOwnershipToogle({
            ...ownershipToogle,
            anyNativeAmericanOwned: false,
            anyMinorityOwned: false,
          });
        } else {
          setOwnershipToogle({
            ...ownershipToogle,
            anyNativeAmericanOwned: false,
          });
        }
      }
    }
  };

  const handleChangeMinorityOwned = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;

    if (checked) {
      store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
        ...store.filters.ownershipSelfCerts,
        anyMinorityOwned: {
          ...store.filters.ownershipSelfCerts.anyMinorityOwned,
          otherMinorityOwned: { value: name, isApplied: false },
        },
      });

      // turn on anyMinorityOwned switch if all native is checked
      if (
        store.filters.ownershipSelfCerts.anyMinorityOwned.anyNativeAmericanOwner
          .length === NativeAmericanOwned.length
      ) {
        setOwnershipToogle({
          ...ownershipToogle,
          anyMinorityOwned: true,
        });
      }
    } else {
      store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
        ...store.filters.ownershipSelfCerts,
        anyMinorityOwned: {
          ...store.filters.ownershipSelfCerts.anyMinorityOwned,
          otherMinorityOwned: {} as any,
        },
      });

      setOwnershipToogle({
        ...ownershipToogle,
        anyMinorityOwned: false,
      });
    }
  };

  const handleRelate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;

    store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
      ...store.filters.ownershipSelfCerts,
      matchType: {
        value: id,
        isApplied: false,
      },
    });
  };

  const handleToggle = (toggleKey: string, toggle: boolean) => {
    if (toggleKey === "anySelfCertifiedWoman") {
      store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
        ...store.filters.ownershipSelfCerts,
        selfCertifiedWomanOwned: toggle
          ? SelfCertifiedWomenOwned.map((item) => ({
              value: item,
              isApplied: false,
            }))
          : [],
      });
    } else if (toggleKey === "anyNativeAmericanOwned") {
      store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
        ...store.filters.ownershipSelfCerts,
        anyMinorityOwned: {
          ...store.filters.ownershipSelfCerts.anyMinorityOwned,
          anyNativeAmericanOwner: toggle
            ? NativeAmericanOwned.map((item) => ({
                value: item,
                isApplied: false,
              }))
            : [],
        },
      });
    } else {
      store.setFilter(FilterKeys.OWNERSHIP_AND_SELF_CERTIFICATIONS, {
        ...store.filters.ownershipSelfCerts,
        anyMinorityOwned: {
          otherMinorityOwned: toggle
            ? {
                value: "Other minority owned",
                isApplied: false,
              }
            : ({} as any),

          anyNativeAmericanOwner: toggle
            ? NativeAmericanOwned.map((item) => ({
                value: item,
                isApplied: false,
              }))
            : [],
        },
      });
    }

    if (toggleKey === "anyMinorityOwned") {
      setOwnershipToogle({
        ...ownershipToogle,
        anyMinorityOwned: toggle,
        anyNativeAmericanOwned: toggle,
      });
    } else {
      setOwnershipToogle({
        ...ownershipToogle,
        [toggleKey]: toggle,
      });
    }
  };

  return (
    <div className="ownership-certification">
      {SelfCertifications.map((item) => (
        <fieldset className="usa-fieldset" key={item}>
          <div className="usa-checkbox">
            <input
              className="usa-checkbox__input"
              id={item}
              type="checkbox"
              name={item}
              value={item}
              checked={store.filters.ownershipSelfCerts.selfCertifications
                .map((item) => item.value)
                .includes(item)}
              onChange={handleChangeSelfCertifications}
            />
            <label className="usa-checkbox__label" htmlFor={item}>
              {item}
            </label>
          </div>
        </fieldset>
      ))}

      <fieldset className="usa-fieldset">
        <div className="parent">
          <span
            className="icon"
            onClick={() =>
              arrowHandler(
                "anySelfCertifiedWoman",
                ownershipArrow.anySelfCertifiedWoman
              )
            }
          >
            {ownershipArrow.anySelfCertifiedWoman ? (
              <Icon.ExpandLess />
            ) : (
              <Icon.ExpandMore />
            )}
          </span>
          <p className="label">Any Self-Certified Woman-Owned Small Business</p>
          <span
            onClick={() => {
              handleToggle(
                "anySelfCertifiedWoman",
                !ownershipToogle.anySelfCertifiedWoman
              );
            }}
            className="toggle-on-off"
          >
            {ownershipToogle.anySelfCertifiedWoman ? (
              <Icon.ToggleOn color="#005ea2" />
            ) : (
              <Icon.ToggleOff />
            )}
          </span>
        </div>

        <div
          className={`children-box ${
            ownershipArrow.anySelfCertifiedWoman ? "active" : ""
          }`}
        >
          {SelfCertifiedWomenOwned.map((item) => (
            <div className="usa-checkbox" key={item}>
              <input
                className="usa-checkbox__input"
                id={item}
                type="checkbox"
                name={item}
                value={item}
                checked={store.filters.ownershipSelfCerts.selfCertifiedWomanOwned
                  .map((item) => item.value)
                  .includes(item)}
                onChange={handleChangeSelfCertifiedWomenOwner}
              />
              <label className="usa-checkbox__label" htmlFor={item}>
                {item}
              </label>
            </div>
          ))}
        </div>
      </fieldset>

      <fieldset className="usa-fieldset">
        <div className="parent">
          <span
            className="icon"
            onClick={() =>
              arrowHandler("anyMinorityOwned", ownershipArrow.anyMinorityOwned)
            }
          >
            {ownershipArrow.anyMinorityOwned ? (
              <Icon.ExpandLess />
            ) : (
              <Icon.ExpandMore />
            )}
          </span>
          <p className="label">Any minority owned</p>
          <span
            onClick={() => {
              handleToggle(
                "anyMinorityOwned",
                !ownershipToogle.anyMinorityOwned
              );
            }}
            className="toggle-on-off"
          >
            {ownershipToogle.anyMinorityOwned ? (
              <Icon.ToggleOn color="#005ea2" />
            ) : (
              <Icon.ToggleOff />
            )}
          </span>
        </div>

        <div
          className={`children-box ${
            ownershipArrow.anyMinorityOwned ? "active" : ""
          }`}
        >
          <div className="usa-checkbox">
            <input
              className="usa-checkbox__input"
              id={"Other minority owned"}
              type="checkbox"
              name={"Other minority owned"}
              value="Other minority owned"
              checked={
                store.filters.ownershipSelfCerts.anyMinorityOwned
                  .otherMinorityOwned?.value === "Other minority owned"
              }
              onChange={handleChangeMinorityOwned}
            />
            <label
              className="usa-checkbox__label"
              htmlFor={"Other minority owned"}
            >
              Other minority owned
            </label>
          </div>

          <fieldset className="usa-fieldset">
            <div className="parent">
              <span
                className="icon"
                onClick={() =>
                  arrowHandler(
                    "anyNativeAmericanOwned",
                    ownershipArrow.anyNativeAmericanOwned
                  )
                }
              >
                {ownershipArrow.anyNativeAmericanOwned ? (
                  <Icon.ExpandLess />
                ) : (
                  <Icon.ExpandMore />
                )}
              </span>
              <p className="label">Any Native-American owned</p>
              <span
                onClick={() => {
                  handleToggle(
                    "anyNativeAmericanOwned",
                    !ownershipToogle.anyNativeAmericanOwned
                  );
                }}
                className="toggle-on-off"
              >
                {ownershipToogle.anyNativeAmericanOwned ? (
                  <Icon.ToggleOn color="#005ea2" />
                ) : (
                  <Icon.ToggleOff />
                )}
              </span>
            </div>

            <div
              className={`children-box ${
                ownershipArrow.anyNativeAmericanOwned ? "active" : ""
              }`}
            >
              {NativeAmericanOwned.map((item) => (
                <div className="usa-checkbox" key={item}>
                  <input
                    className="usa-checkbox__input"
                    id={item}
                    type="checkbox"
                    name={item}
                    value={item}
                    checked={store.filters.ownershipSelfCerts.anyMinorityOwned.anyNativeAmericanOwner
                      .map((item) => item.value)
                      .includes(item)}
                    onChange={handleChangeNativeAmericanOwned}
                  />
                  <label className="usa-checkbox__label" htmlFor={item}>
                    {item}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
      </fieldset>

      <hr className="divider" />
      <h5 className="text-before-radio">
        How should these certifications relate?
      </h5>
      <div className="radio-group">
        <Radio
          type="radio"
          id="ownership-or"
          name="ownership-or"
          label={
            <p>
              OR: results can match <strong>any</strong> of these.
            </p>
          }
          checked={
            store.filters.ownershipSelfCerts.matchType?.value === "ownership-or"
          }
          onChange={handleRelate}
        />
        <Radio
          type="radio"
          id="ownership-and"
          name="ownership-and"
          label={
            <p>
              AND: results must match <strong>all</strong> of these.
            </p>
          }
          checked={
            store.filters.ownershipSelfCerts.matchType?.value ===
            "ownership-and"
          }
          onChange={handleRelate}
        />
      </div>
    </div>
  );
}
