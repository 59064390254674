import { useState, useEffect } from "react";

/**
 * useDebounce hook
 *
 * This hook returns a debounced value that changes only after a specified delay.
 * It's useful for delaying a function call until after the user has stopped typing
 * to minimize expensive operations like API calls or filtering large datasets.
 *
 * @param value - The value to debounce.
 * @param delay - The delay in milliseconds to wait before updating the debounced value.
 * @returns The debounced value.
 */
export default function useDebounce<T>(value: T, delay: number): T {
  // State to hold the debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup function to clear the timeout if value or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
}
