import { Banner, GovBanner } from "@trussworks/react-uswds";

import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
  const locations = useLocation();

  const { pathname } = locations;

  const pathToshowHeader = ["/advanced", "/profile"];

  const isAdvancedPage = pathname.startsWith("/advanced");
  const isNewAdvancedPage = pathname.startsWith("/new/advanced");

  const showHeader = pathToshowHeader.some((path) => pathname.startsWith(path));

  return (
    <>
      <Header showHeader={showHeader} />

      <main
        style={{
          height: isAdvancedPage ? "calc(100vh - 80px)" : "auto",
        }}
      >
        <Outlet />
      </main>

      {!isAdvancedPage && !isNewAdvancedPage && (
        <footer>
          <Footer />
        </footer>
      )}
    </>
  );
};

export default Layout;
