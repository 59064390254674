import React from "react";
import "./DisplayValue.scss";

// Format Options
interface FormatOptions {
    isBold?: boolean;
    isItalic?: boolean;
    isUnderlined?: boolean;
}

// Display Value interface
interface DisplayValueProps {
    value?: string | string[] | number | boolean | any[] | any;
    prefix?: string;
    suffix?: string;
    message?: string;
    format?: FormatOptions;
    messageElement?: keyof React.ReactHTML;
    renderer?: (_value?: any) => React.ReactElement;
}

const DisplayValue: React.FC<DisplayValueProps> = ({ value, prefix = '', suffix = '', message = 'Not Provided', format = {}, messageElement = 'span', renderer }) => {

    // Handle default formatting logic
    const { isItalic = true } = format;

    const messageStyle: React.CSSProperties = {
        fontWeight: format.isBold ? 'bold' : 'normal',
        fontStyle: format.isItalic ? 'italic' : 'normal',
        textDecoration: format.isUnderlined ? 'underline' : 'none'
    };

    const errorMessageStyle: React.CSSProperties = {...messageStyle, fontStyle: isItalic ? 'italic' : 'normal'};

    let new_value = (prefix || '').concat(value?.toString()).concat(suffix || '');

    // If value is an array, check the length but if it's not an array, check if it's a valid value
    let hasValue = Array.isArray(value) ? value.length > 0 : value !== undefined && value !== null && value != '';

    const MessageElement = messageElement;

    return (
        <>
            {hasValue ? (renderer ? <MessageElement style={messageStyle}>{renderer(new_value)}</MessageElement> : <MessageElement style={messageStyle}>{new_value}</MessageElement> ) : <MessageElement style={errorMessageStyle}>{message}</MessageElement>}
        </>
    );
}

export default DisplayValue;