import { NaicsProfile } from "types/profile.types";
import { create } from "zustand";

type Results = {
  // Define the shape of the results data
  // ...
};

export type BusinessProfile = {
  // Define the shape of the profile data
  profile?: {
    name?: string;
    uei?: string;
    parent_uei?: string;
    associated_ueis?: string[];
    pronet_id?: string;
    last_updated?: string;
    contact_person?: string;
    capabilities_narrative?: string;
    capabilities_narrative_link?: string;
    email?: string;
    phone?: string;
    phone_ext?: string;
    fax?: string;
    full_address?: {
      line1?: string;
      line2?: string;
      city?: string;
      state?: string;
      zip?: string;
    };
    address?: string;
    trade_name?: string;
    cage_code?: string[];
    website?: string;
    county_code?: string;
    accepts_government_credit_card: string;
    congressional_district?: string;
    gsa_contract?: string;
    metropolitan_area?: string;
    user_id?: string; // This is the same as the pronet_id
    year_established?: string;
    e_commerce_website?: string;
  };
  organization_ownership?: {
    legal_structure?: string;
    current_principals?: {
      title?: string;
      name?: string;
    }[];
    bd_servicing_office?: string;
  };
  certifications?: {
    sba_certifications?: {
      name?: string;
      status?: string;
      date?: string;
      graduation_date?: string;
      graduation_status?: string;
      case_number?: string;
      pending?: string;
    }[];
    // sba_certifications?: {
    //   "8a"?: {
    //     status?: string;
    //     date?: string;
    //     graduation_date?: string;
    //     graduation_status?: string;
    //   };
    // };
    ownership_certifications?: {
      code?: string;
      description?: string;
      sam_uei?: string;
    }[];
    non_federal_certifications?: string[];
  };
  naics?: NaicsProfile[];
  service_information?: {
    special_equipment?: string;
    business_percentages?: string[];
    bonding_levels?: {
      construction_per_contract?: string;
      construction_aggregate?: string;
      service_per_contract?: string;
      service_aggregate?: string;
    };
    keywords?: string;
    miscellaneous?: string;
    quality_assurance?: string[];
    electronic_data?: string;
  };
  export_profile?: {
    status?: string;
    business_activities?: string[];
    exporting_to?: string[];
    desired_export?: string;
    description_export?: string[];
  };
  performance_history?: {
    name?: string;
    contract?: string;
    value?: string;
    start_date?: string;
    end_date?: string;
    contact?: string;
    phone?: string;
  }[];
} & Record<string, {
  status?: string;
  date?: string;
  graduation_date?: string;
  graduation_status?: string;
}>;

type QuickResults = {
  // Define the shape of the quick results data
  // ...
};

interface SearchState {
  // Convert the profile type below into it's own type and use it here
  business_profile: BusinessProfile;
  results: {}[];
  quickResults: {}[];

  setProfile: (profile: {}) => void;
  setResults: (results: {}[]) => void;
  setQuickResults: (quickResults: {}[]) => void;
}

export const useSearchStore = create<SearchState>((set) => ({
  business_profile: {},
  results: [],
  quickResults: [],
  setProfile: (_profile) => set({ business_profile: _profile }),
  setResults: (_results) => set({ results: _results }),
  setQuickResults: (_quickResults) => set({ quickResults: _quickResults }),
}));
