import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

// types
import { Generic } from "types/filters-store.types";

const fetchZipCodes = async () => {
  const response = await fetch("/zip-codes.json"); // Path to your JSON file in the public directory
  if (!response.ok) {
    throw new Error("Failed to fetch JSON data");
  }
  return response.json();
};

export type ZipCode = {
  zip: string;
  county_name: string;
  state_name: string;
  county_fips: string;
};

export type FilterTerm = Pick<Generic, "label" | "value"> & {
  originalLabel: string;
  originalValue: string;
  stateName?: string;
};

export default function useZipCodes() {
  const { isLoading, error, data } = useQuery<ZipCode[]>({
    queryKey: ["zip-codes"],
    queryFn: fetchZipCodes,

    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const refineZipCodes: FilterTerm[] = useMemo(() => {
    return !data
      ? []
      : data.map((item: ZipCode) => ({
          label: item.zip,
          value: item.zip,
          originalLabel: item.zip,
          originalValue: item.zip,
        }));
  }, [data]);

  const refineCounties: FilterTerm[] = useMemo(() => {
    if (!data) return [];

    const duplicate = new Set();

    // mapped data
    const counties: FilterTerm[] = data.map((item: ZipCode) => ({
      label: item.county_name,
      // Set the value as `${countyName}-${stateName}-${countyFips}`, to avoid duplicate rendering in list, and get the original value
      value: `${item.county_name}-${item.state_name}-${item.county_fips}`,
      originalLabel: item.county_name,
      originalValue: item.county_name,
      stateName: item.state_name,
    }));

    // remove duplicate entries if there are multiple counties in the same state
    const uniqueCounties = counties.filter((item) => {
      const key = `${item.stateName}-${item.label}`;
      if (duplicate.has(key)) {
        return false;
      }
      duplicate.add(key);
      return true;
    });
    return uniqueCounties;
  }, [data]);

  return {
    isLoading,
    error,
    zipCodes: refineZipCodes,
    counties: refineCounties,
  };
}
