export const searchBarMockData = [
  "Fishing Vessel Alitak | Z743WCDUYDR4",
  "D & G Fishing | Z743WCDUYDR4",
  "Sagaka Fishing, Inc | Z743WCDUYDR4",
  "Alaska Back Country Fishing Lodge | Z743WCDUYDR4",
];

export const states = [
  "Alaska",
  "Alabama",
  "Arkansas",
  "American Samoa",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  " North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Virgin Islands",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
];

export const statesCodes = {
  Alaska: "AK",
  Alabama: "AL",
  Arkansas: "AR",
  "American Samoa": "AS",
  Arizona: "AZ",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  "District of Columbia": "DC",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Iowa: "IA",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Massachusetts: "MA",
  Maryland: "MD",
  Maine: "ME",
  Michigan: "MI",
  Minnesota: "MN",
  Missouri: "MO",
  Mississippi: "MS",
  Montana: "MT",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Nebraska: "NE",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  Nevada: "NV",
  "New York": "NY",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Virginia: "VA",
  "Virgin Islands": "VI",
  Vermont: "VT",
  Washington: "WA",
  Wisconsin: "WI",
  "West Virginia": "WV",
  Wyoming: "WY",
};

export const stateAndTerritories = [
  "AL - Alabama",
  "AK - Alaska",
  // "AA - American Atlantic (APO/FPO)",
  // "AE - American Europe (APO/FPO)",
  // "AP - American Pacific (APO/FPO)",
  "AS - American Samoa",
  "AZ - Arizona",
  "AR - Arkansas",
  "CA - California",
  "CO - Colorado",
  "CT - Connecticut",
  "DE - Delaware",
  "DC - District of Columbia",
  "FM - Federated States of Micronesia",
  "FL - Florida",
  "GA - Georgia",
  "GU - Guam",
  "HI - Hawaii",
  "ID - Idaho",
  "IL - Illinois",
  "IN - Indiana",
  "IA - Iowa",
  "KS - Kansas",
  "KY - Kentucky",
  "LA - Louisiana",
  "MH - Marshall Islands",
  "MA - Massachusetts",
  "ME - Maine",
  "MD - Maryland",
  "MS - Mississippi",
  "MI - Michigan",
  "MN - Minnesota",
  "MO - Missouri",
  "MT - Montana",
  "NE - Nebraska",
  "NV - Nevada",
  "NH - New Hampshire",
  "NJ - New Jersey",
  "NM - New Mexico",
  "NY - New York",
  "NC - North Carolina",
  "ND - North Dakota",
  "MP - Northern Mariana Islands",
  "OH - Ohio",
  "OK - Oklahoma",
  "OR - Oregon",
  "PW - Palau ",
  "PA - Pennsylvania ",
  "PR - Puerto Rico ",
  "RI - Rhode Island ",
  "SC - South Carolina",
  "SD - South Dakota",
  "TN - Tennessee",
  "TX - Texas",
  "UT - Utah",
  "VT - Vermont",
  "VA - Virginia",
  "VI - Virgin Islands",
  "WA - Washington",
  "WV - West Virginia",
  "WI - Wisconsin",
  "WY - Wyoming",
];

function County(strState, strCountyName) {
  this.state = strState;
  this.countyName = strCountyName;
}

export const arrCounties = new Array(
  new County("AL", "Autauga County"),
  new County("AL", "Baldwin County"),
  new County("AL", "Barbour County"),
  new County("AL", "Bibb County"),
  new County("AL", "Blount County"),
  new County("AL", "Bullock County"),
  new County("AL", "Butler County"),
  new County("AL", "Calhoun County"),
  new County("AL", "Chambers County"),
  new County("AL", "Cherokee County"),
  new County("AL", "Chilton County"),
  new County("AL", "Choctaw County"),
  new County("AL", "Clarke County"),
  new County("AL", "Clay County"),
  new County("AL", "Cleburne County"),
  new County("AL", "Coffee County"),
  new County("AL", "Colbert County"),
  new County("AL", "Conecuh County"),
  new County("AL", "Coosa County"),
  new County("AL", "Covington County"),
  new County("AL", "Crenshaw County"),
  new County("AL", "Cullman County"),
  new County("AL", "Dale County"),
  new County("AL", "Dallas County"),
  new County("AL", "DeKalb County"),
  new County("AL", "Elmore County"),
  new County("AL", "Escambia County"),
  new County("AL", "Etowah County"),
  new County("AL", "Fayette County"),
  new County("AL", "Franklin County"),
  new County("AL", "Geneva County"),
  new County("AL", "Greene County"),
  new County("AL", "Hale County"),
  new County("AL", "Henry County"),
  new County("AL", "Houston County"),
  new County("AL", "Jackson County"),
  new County("AL", "Jefferson County"),
  new County("AL", "Lamar County"),
  new County("AL", "Lauderdale County"),
  new County("AL", "Lawrence County"),
  new County("AL", "Lee County"),
  new County("AL", "Limestone County"),
  new County("AL", "Lowndes County"),
  new County("AL", "Macon County"),
  new County("AL", "Madison County"),
  new County("AL", "Marengo County"),
  new County("AL", "Marion County"),
  new County("AL", "Marshall County"),
  new County("AL", "Mobile County"),
  new County("AL", "Monroe County"),
  new County("AL", "Montgomery County"),
  new County("AL", "Morgan County"),
  new County("AL", "Perry County"),
  new County("AL", "Pickens County"),
  new County("AL", "Pike County"),
  new County("AL", "Randolph County"),
  new County("AL", "Russell County"),
  new County("AL", "St. Clair County"),
  new County("AL", "Shelby County"),
  new County("AL", "Sumter County"),
  new County("AL", "Talladega County"),
  new County("AL", "Tallapoosa County"),
  new County("AL", "Tuscaloosa County"),
  new County("AL", "Walker County"),
  new County("AL", "Washington County"),
  new County("AL", "Wilcox County"),
  new County("AL", "Winston County"),
  new County("AK", "Aleutians East Borough"),
  new County("AK", "Aleutians West Census Area"),
  new County("AK", "Anchorage Municipality"),
  new County("AK", "Bethel Census Area"),
  new County("AK", "Bristol Bay Borough"),
  new County("AK", "Denali Borough"),
  new County("AK", "Dillingham Census Area"),
  new County("AK", "Fairbanks North Star Borough"),
  new County("AK", "Haines Borough"),
  new County("AK", "Hoonah-Angoon Census Area"),
  new County("AK", "Juneau City and Borough"),
  new County("AK", "Kenai Peninsula Borough"),
  new County("AK", "Ketchikan Gateway Borough"),
  new County("AK", "Kodiak Island Borough"),
  new County("AK", "Lake and Peninsula Borough"),
  new County("AK", "Matanuska-Susitna Borough"),
  new County("AK", "Nome Census Area"),
  new County("AK", "North Slope Borough"),
  new County("AK", "Northwest Arctic Borough"),
  new County("AK", "Petersburg Census Area"),
  new County("AK", "Prince of Wales-Hyder Census Area"),
  new County("AK", "Sitka City and Borough"),
  new County("AK", "Skagway Municipality"),
  new County("AK", "Southeast Fairbanks Census Area"),
  new County("AK", "Valdez-Cordova Census Area"),
  new County("AK", "Wade Hampton Census Area"),
  new County("AK", "Wrangell City and Borough"),
  new County("AK", "Yakutat City and Borough"),
  new County("AK", "Yukon-Koyukuk Census Area"),
  new County("AZ", "Apache County"),
  new County("AZ", "Cochise County"),
  new County("AZ", "Coconino County"),
  new County("AZ", "Gila County"),
  new County("AZ", "Graham County"),
  new County("AZ", "Greenlee County"),
  new County("AZ", "La Paz County"),
  new County("AZ", "Maricopa County"),
  new County("AZ", "Mohave County"),
  new County("AZ", "Navajo County"),
  new County("AZ", "Pima County"),
  new County("AZ", "Pinal County"),
  new County("AZ", "Santa Cruz County"),
  new County("AZ", "Yavapai County"),
  new County("AZ", "Yuma County"),
  new County("AR", "Arkansas County"),
  new County("AR", "Ashley County"),
  new County("AR", "Baxter County"),
  new County("AR", "Benton County"),
  new County("AR", "Boone County"),
  new County("AR", "Bradley County"),
  new County("AR", "Calhoun County"),
  new County("AR", "Carroll County"),
  new County("AR", "Chicot County"),
  new County("AR", "Clark County"),
  new County("AR", "Clay County"),
  new County("AR", "Cleburne County"),
  new County("AR", "Cleveland County"),
  new County("AR", "Columbia County"),
  new County("AR", "Conway County"),
  new County("AR", "Craighead County"),
  new County("AR", "Crawford County"),
  new County("AR", "Crittenden County"),
  new County("AR", "Cross County"),
  new County("AR", "Dallas County"),
  new County("AR", "Desha County"),
  new County("AR", "Drew County"),
  new County("AR", "Faulkner County"),
  new County("AR", "Franklin County"),
  new County("AR", "Fulton County"),
  new County("AR", "Garland County"),
  new County("AR", "Grant County"),
  new County("AR", "Greene County"),
  new County("AR", "Hempstead County"),
  new County("AR", "Hot Spring County"),
  new County("AR", "Howard County"),
  new County("AR", "Independence County"),
  new County("AR", "Izard County"),
  new County("AR", "Jackson County"),
  new County("AR", "Jefferson County"),
  new County("AR", "Johnson County"),
  new County("AR", "Lafayette County"),
  new County("AR", "Lawrence County"),
  new County("AR", "Lee County"),
  new County("AR", "Lincoln County"),
  new County("AR", "Little River County"),
  new County("AR", "Logan County"),
  new County("AR", "Lonoke County"),
  new County("AR", "Madison County"),
  new County("AR", "Marion County"),
  new County("AR", "Miller County"),
  new County("AR", "Mississippi County"),
  new County("AR", "Monroe County"),
  new County("AR", "Montgomery County"),
  new County("AR", "Nevada County"),
  new County("AR", "Newton County"),
  new County("AR", "Ouachita County"),
  new County("AR", "Perry County"),
  new County("AR", "Phillips County"),
  new County("AR", "Pike County"),
  new County("AR", "Poinsett County"),
  new County("AR", "Polk County"),
  new County("AR", "Pope County"),
  new County("AR", "Prairie County"),
  new County("AR", "Pulaski County"),
  new County("AR", "Randolph County"),
  new County("AR", "St. Francis County"),
  new County("AR", "Saline County"),
  new County("AR", "Scott County"),
  new County("AR", "Searcy County"),
  new County("AR", "Sebastian County"),
  new County("AR", "Sevier County"),
  new County("AR", "Sharp County"),
  new County("AR", "Stone County"),
  new County("AR", "Union County"),
  new County("AR", "Van Buren County"),
  new County("AR", "Washington County"),
  new County("AR", "White County"),
  new County("AR", "Woodruff County"),
  new County("AR", "Yell County"),
  new County("CA", "Alameda County"),
  new County("CA", "Alpine County"),
  new County("CA", "Amador County"),
  new County("CA", "Butte County"),
  new County("CA", "Calaveras County"),
  new County("CA", "Colusa County"),
  new County("CA", "Contra Costa County"),
  new County("CA", "Del Norte County"),
  new County("CA", "El Dorado County"),
  new County("CA", "Fresno County"),
  new County("CA", "Glenn County"),
  new County("CA", "Humboldt County"),
  new County("CA", "Imperial County"),
  new County("CA", "Inyo County"),
  new County("CA", "Kern County"),
  new County("CA", "Kings County"),
  new County("CA", "Lake County"),
  new County("CA", "Lassen County"),
  new County("CA", "Los Angeles County"),
  new County("CA", "Madera County"),
  new County("CA", "Marin County"),
  new County("CA", "Mariposa County"),
  new County("CA", "Mendocino County"),
  new County("CA", "Merced County"),
  new County("CA", "Modoc County"),
  new County("CA", "Mono County"),
  new County("CA", "Monterey County"),
  new County("CA", "Napa County"),
  new County("CA", "Nevada County"),
  new County("CA", "Orange County"),
  new County("CA", "Placer County"),
  new County("CA", "Plumas County"),
  new County("CA", "Riverside County"),
  new County("CA", "Sacramento County"),
  new County("CA", "San Benito County"),
  new County("CA", "San Bernardino County"),
  new County("CA", "San Diego County"),
  new County("CA", "San Francisco County"),
  new County("CA", "San Joaquin County"),
  new County("CA", "San Luis Obispo County"),
  new County("CA", "San Mateo County"),
  new County("CA", "Santa Barbara County"),
  new County("CA", "Santa Clara County"),
  new County("CA", "Santa Cruz County"),
  new County("CA", "Shasta County"),
  new County("CA", "Sierra County"),
  new County("CA", "Siskiyou County"),
  new County("CA", "Solano County"),
  new County("CA", "Sonoma County"),
  new County("CA", "Stanislaus County"),
  new County("CA", "Sutter County"),
  new County("CA", "Tehama County"),
  new County("CA", "Trinity County"),
  new County("CA", "Tulare County"),
  new County("CA", "Tuolumne County"),
  new County("CA", "Ventura County"),
  new County("CA", "Yolo County"),
  new County("CA", "Yuba County"),
  new County("CO", "Adams County"),
  new County("CO", "Alamosa County"),
  new County("CO", "Arapahoe County"),
  new County("CO", "Archuleta County"),
  new County("CO", "Baca County"),
  new County("CO", "Bent County"),
  new County("CO", "Boulder County"),
  new County("CO", "Broomfield County"),
  new County("CO", "Chaffee County"),
  new County("CO", "Cheyenne County"),
  new County("CO", "Clear Creek County"),
  new County("CO", "Conejos County"),
  new County("CO", "Costilla County"),
  new County("CO", "Crowley County"),
  new County("CO", "Custer County"),
  new County("CO", "Delta County"),
  new County("CO", "Denver County"),
  new County("CO", "Dolores County"),
  new County("CO", "Douglas County"),
  new County("CO", "Eagle County"),
  new County("CO", "Elbert County"),
  new County("CO", "El Paso County"),
  new County("CO", "Fremont County"),
  new County("CO", "Garfield County"),
  new County("CO", "Gilpin County"),
  new County("CO", "Grand County"),
  new County("CO", "Gunnison County"),
  new County("CO", "Hinsdale County"),
  new County("CO", "Huerfano County"),
  new County("CO", "Jackson County"),
  new County("CO", "Jefferson County"),
  new County("CO", "Kiowa County"),
  new County("CO", "Kit Carson County"),
  new County("CO", "Lake County"),
  new County("CO", "La Plata County"),
  new County("CO", "Larimer County"),
  new County("CO", "Las Animas County"),
  new County("CO", "Lincoln County"),
  new County("CO", "Logan County"),
  new County("CO", "Mesa County"),
  new County("CO", "Mineral County"),
  new County("CO", "Moffat County"),
  new County("CO", "Montezuma County"),
  new County("CO", "Montrose County"),
  new County("CO", "Morgan County"),
  new County("CO", "Otero County"),
  new County("CO", "Ouray County"),
  new County("CO", "Park County"),
  new County("CO", "Phillips County"),
  new County("CO", "Pitkin County"),
  new County("CO", "Prowers County"),
  new County("CO", "Pueblo County"),
  new County("CO", "Rio Blanco County"),
  new County("CO", "Rio Grande County"),
  new County("CO", "Routt County"),
  new County("CO", "Saguache County"),
  new County("CO", "San Juan County"),
  new County("CO", "San Miguel County"),
  new County("CO", "Sedgwick County"),
  new County("CO", "Summit County"),
  new County("CO", "Teller County"),
  new County("CO", "Washington County"),
  new County("CO", "Weld County"),
  new County("CO", "Yuma County"),
  new County("CT", "Fairfield County"),
  new County("CT", "Hartford County"),
  new County("CT", "Litchfield County"),
  new County("CT", "Middlesex County"),
  new County("CT", "New Haven County"),
  new County("CT", "New London County"),
  new County("CT", "Tolland County"),
  new County("CT", "Windham County"),
  new County("DE", "Kent County"),
  new County("DE", "New Castle County"),
  new County("DE", "Sussex County"),
  new County("DC", "District of Columbia"),
  new County("FL", "Alachua County"),
  new County("FL", "Baker County"),
  new County("FL", "Bay County"),
  new County("FL", "Bradford County"),
  new County("FL", "Brevard County"),
  new County("FL", "Broward County"),
  new County("FL", "Calhoun County"),
  new County("FL", "Charlotte County"),
  new County("FL", "Citrus County"),
  new County("FL", "Clay County"),
  new County("FL", "Collier County"),
  new County("FL", "Columbia County"),
  new County("FL", "DeSoto County"),
  new County("FL", "Dixie County"),
  new County("FL", "Duval County"),
  new County("FL", "Escambia County"),
  new County("FL", "Flagler County"),
  new County("FL", "Franklin County"),
  new County("FL", "Gadsden County"),
  new County("FL", "Gilchrist County"),
  new County("FL", "Glades County"),
  new County("FL", "Gulf County"),
  new County("FL", "Hamilton County"),
  new County("FL", "Hardee County"),
  new County("FL", "Hendry County"),
  new County("FL", "Hernando County"),
  new County("FL", "Highlands County"),
  new County("FL", "Hillsborough County"),
  new County("FL", "Holmes County"),
  new County("FL", "Indian River County"),
  new County("FL", "Jackson County"),
  new County("FL", "Jefferson County"),
  new County("FL", "Lafayette County"),
  new County("FL", "Lake County"),
  new County("FL", "Lee County"),
  new County("FL", "Leon County"),
  new County("FL", "Levy County"),
  new County("FL", "Liberty County"),
  new County("FL", "Madison County"),
  new County("FL", "Manatee County"),
  new County("FL", "Marion County"),
  new County("FL", "Martin County"),
  new County("FL", "Miami-Dade County"),
  new County("FL", "Monroe County"),
  new County("FL", "Nassau County"),
  new County("FL", "Okaloosa County"),
  new County("FL", "Okeechobee County"),
  new County("FL", "Orange County"),
  new County("FL", "Osceola County"),
  new County("FL", "Palm Beach County"),
  new County("FL", "Pasco County"),
  new County("FL", "Pinellas County"),
  new County("FL", "Polk County"),
  new County("FL", "Putnam County"),
  new County("FL", "St. Johns County"),
  new County("FL", "St. Lucie County"),
  new County("FL", "Santa Rosa County"),
  new County("FL", "Sarasota County"),
  new County("FL", "Seminole County"),
  new County("FL", "Sumter County"),
  new County("FL", "Suwannee County"),
  new County("FL", "Taylor County"),
  new County("FL", "Union County"),
  new County("FL", "Volusia County"),
  new County("FL", "Wakulla County"),
  new County("FL", "Walton County"),
  new County("FL", "Washington County"),
  new County("GA", "Appling County"),
  new County("GA", "Atkinson County"),
  new County("GA", "Bacon County"),
  new County("GA", "Baker County"),
  new County("GA", "Baldwin County"),
  new County("GA", "Banks County"),
  new County("GA", "Barrow County"),
  new County("GA", "Bartow County"),
  new County("GA", "Ben Hill County"),
  new County("GA", "Berrien County"),
  new County("GA", "Bibb County"),
  new County("GA", "Bleckley County"),
  new County("GA", "Brantley County"),
  new County("GA", "Brooks County"),
  new County("GA", "Bryan County"),
  new County("GA", "Bulloch County"),
  new County("GA", "Burke County"),
  new County("GA", "Butts County"),
  new County("GA", "Calhoun County"),
  new County("GA", "Camden County"),
  new County("GA", "Candler County"),
  new County("GA", "Carroll County"),
  new County("GA", "Catoosa County"),
  new County("GA", "Charlton County"),
  new County("GA", "Chatham County"),
  new County("GA", "Chattahoochee County"),
  new County("GA", "Chattooga County"),
  new County("GA", "Cherokee County"),
  new County("GA", "Clarke County"),
  new County("GA", "Clay County"),
  new County("GA", "Clayton County"),
  new County("GA", "Clinch County"),
  new County("GA", "Cobb County"),
  new County("GA", "Coffee County"),
  new County("GA", "Colquitt County"),
  new County("GA", "Columbia County"),
  new County("GA", "Cook County"),
  new County("GA", "Coweta County"),
  new County("GA", "Crawford County"),
  new County("GA", "Crisp County"),
  new County("GA", "Dade County"),
  new County("GA", "Dawson County"),
  new County("GA", "Decatur County"),
  new County("GA", "DeKalb County"),
  new County("GA", "Dodge County"),
  new County("GA", "Dooly County"),
  new County("GA", "Dougherty County"),
  new County("GA", "Douglas County"),
  new County("GA", "Early County"),
  new County("GA", "Echols County"),
  new County("GA", "Effingham County"),
  new County("GA", "Elbert County"),
  new County("GA", "Emanuel County"),
  new County("GA", "Evans County"),
  new County("GA", "Fannin County"),
  new County("GA", "Fayette County"),
  new County("GA", "Floyd County"),
  new County("GA", "Forsyth County"),
  new County("GA", "Franklin County"),
  new County("GA", "Fulton County"),
  new County("GA", "Gilmer County"),
  new County("GA", "Glascock County"),
  new County("GA", "Glynn County"),
  new County("GA", "Gordon County"),
  new County("GA", "Grady County"),
  new County("GA", "Greene County"),
  new County("GA", "Gwinnett County"),
  new County("GA", "Habersham County"),
  new County("GA", "Hall County"),
  new County("GA", "Hancock County"),
  new County("GA", "Haralson County"),
  new County("GA", "Harris County"),
  new County("GA", "Hart County"),
  new County("GA", "Heard County"),
  new County("GA", "Henry County"),
  new County("GA", "Houston County"),
  new County("GA", "Irwin County"),
  new County("GA", "Jackson County"),
  new County("GA", "Jasper County"),
  new County("GA", "Jeff Davis County"),
  new County("GA", "Jefferson County"),
  new County("GA", "Jenkins County"),
  new County("GA", "Johnson County"),
  new County("GA", "Jones County"),
  new County("GA", "Lamar County"),
  new County("GA", "Lanier County"),
  new County("GA", "Laurens County"),
  new County("GA", "Lee County"),
  new County("GA", "Liberty County"),
  new County("GA", "Lincoln County"),
  new County("GA", "Long County"),
  new County("GA", "Lowndes County"),
  new County("GA", "Lumpkin County"),
  new County("GA", "McDuffie County"),
  new County("GA", "McIntosh County"),
  new County("GA", "Macon County"),
  new County("GA", "Madison County"),
  new County("GA", "Marion County"),
  new County("GA", "Meriwether County"),
  new County("GA", "Miller County"),
  new County("GA", "Mitchell County"),
  new County("GA", "Monroe County"),
  new County("GA", "Montgomery County"),
  new County("GA", "Morgan County"),
  new County("GA", "Murray County"),
  new County("GA", "Muscogee County"),
  new County("GA", "Newton County"),
  new County("GA", "Oconee County"),
  new County("GA", "Oglethorpe County"),
  new County("GA", "Paulding County"),
  new County("GA", "Peach County"),
  new County("GA", "Pickens County"),
  new County("GA", "Pierce County"),
  new County("GA", "Pike County"),
  new County("GA", "Polk County"),
  new County("GA", "Pulaski County"),
  new County("GA", "Putnam County"),
  new County("GA", "Quitman County"),
  new County("GA", "Rabun County"),
  new County("GA", "Randolph County"),
  new County("GA", "Richmond County"),
  new County("GA", "Rockdale County"),
  new County("GA", "Schley County"),
  new County("GA", "Screven County"),
  new County("GA", "Seminole County"),
  new County("GA", "Spalding County"),
  new County("GA", "Stephens County"),
  new County("GA", "Stewart County"),
  new County("GA", "Sumter County"),
  new County("GA", "Talbot County"),
  new County("GA", "Taliaferro County"),
  new County("GA", "Tattnall County"),
  new County("GA", "Taylor County"),
  new County("GA", "Telfair County"),
  new County("GA", "Terrell County"),
  new County("GA", "Thomas County"),
  new County("GA", "Tift County"),
  new County("GA", "Toombs County"),
  new County("GA", "Towns County"),
  new County("GA", "Treutlen County"),
  new County("GA", "Troup County"),
  new County("GA", "Turner County"),
  new County("GA", "Twiggs County"),
  new County("GA", "Union County"),
  new County("GA", "Upson County"),
  new County("GA", "Walker County"),
  new County("GA", "Walton County"),
  new County("GA", "Ware County"),
  new County("GA", "Warren County"),
  new County("GA", "Washington County"),
  new County("GA", "Wayne County"),
  new County("GA", "Webster County"),
  new County("GA", "Wheeler County"),
  new County("GA", "White County"),
  new County("GA", "Whitfield County"),
  new County("GA", "Wilcox County"),
  new County("GA", "Wilkes County"),
  new County("GA", "Wilkinson County"),
  new County("GA", "Worth County"),
  new County("HI", "Hawaii County"),
  new County("HI", "Honolulu County"),
  new County("HI", "Kalawao County"),
  new County("HI", "Kauai County"),
  new County("HI", "Maui County"),
  new County("ID", "Ada County"),
  new County("ID", "Adams County"),
  new County("ID", "Bannock County"),
  new County("ID", "Bear Lake County"),
  new County("ID", "Benewah County"),
  new County("ID", "Bingham County"),
  new County("ID", "Blaine County"),
  new County("ID", "Boise County"),
  new County("ID", "Bonner County"),
  new County("ID", "Bonneville County"),
  new County("ID", "Boundary County"),
  new County("ID", "Butte County"),
  new County("ID", "Camas County"),
  new County("ID", "Canyon County"),
  new County("ID", "Caribou County"),
  new County("ID", "Cassia County"),
  new County("ID", "Clark County"),
  new County("ID", "Clearwater County"),
  new County("ID", "Custer County"),
  new County("ID", "Elmore County"),
  new County("ID", "Franklin County"),
  new County("ID", "Fremont County"),
  new County("ID", "Gem County"),
  new County("ID", "Gooding County"),
  new County("ID", "Idaho County"),
  new County("ID", "Jefferson County"),
  new County("ID", "Jerome County"),
  new County("ID", "Kootenai County"),
  new County("ID", "Latah County"),
  new County("ID", "Lemhi County"),
  new County("ID", "Lewis County"),
  new County("ID", "Lincoln County"),
  new County("ID", "Madison County"),
  new County("ID", "Minidoka County"),
  new County("ID", "Nez Perce County"),
  new County("ID", "Oneida County"),
  new County("ID", "Owyhee County"),
  new County("ID", "Payette County"),
  new County("ID", "Power County"),
  new County("ID", "Shoshone County"),
  new County("ID", "Teton County"),
  new County("ID", "Twin Falls County"),
  new County("ID", "Valley County"),
  new County("ID", "Washington County"),
  new County("IL", "Adams County"),
  new County("IL", "Alexander County"),
  new County("IL", "Bond County"),
  new County("IL", "Boone County"),
  new County("IL", "Brown County"),
  new County("IL", "Bureau County"),
  new County("IL", "Calhoun County"),
  new County("IL", "Carroll County"),
  new County("IL", "Cass County"),
  new County("IL", "Champaign County"),
  new County("IL", "Christian County"),
  new County("IL", "Clark County"),
  new County("IL", "Clay County"),
  new County("IL", "Clinton County"),
  new County("IL", "Coles County"),
  new County("IL", "Cook County"),
  new County("IL", "Crawford County"),
  new County("IL", "Cumberland County"),
  new County("IL", "DeKalb County"),
  new County("IL", "De Witt County"),
  new County("IL", "Douglas County"),
  new County("IL", "DuPage County"),
  new County("IL", "Edgar County"),
  new County("IL", "Edwards County"),
  new County("IL", "Effingham County"),
  new County("IL", "Fayette County"),
  new County("IL", "Ford County"),
  new County("IL", "Franklin County"),
  new County("IL", "Fulton County"),
  new County("IL", "Gallatin County"),
  new County("IL", "Greene County"),
  new County("IL", "Grundy County"),
  new County("IL", "Hamilton County"),
  new County("IL", "Hancock County"),
  new County("IL", "Hardin County"),
  new County("IL", "Henderson County"),
  new County("IL", "Henry County"),
  new County("IL", "Iroquois County"),
  new County("IL", "Jackson County"),
  new County("IL", "Jasper County"),
  new County("IL", "Jefferson County"),
  new County("IL", "Jersey County"),
  new County("IL", "Jo Daviess County"),
  new County("IL", "Johnson County"),
  new County("IL", "Kane County"),
  new County("IL", "Kankakee County"),
  new County("IL", "Kendall County"),
  new County("IL", "Knox County"),
  new County("IL", "Lake County"),
  new County("IL", "LaSalle County"),
  new County("IL", "Lawrence County"),
  new County("IL", "Lee County"),
  new County("IL", "Livingston County"),
  new County("IL", "Logan County"),
  new County("IL", "McDonough County"),
  new County("IL", "McHenry County"),
  new County("IL", "McLean County"),
  new County("IL", "Macon County"),
  new County("IL", "Macoupin County"),
  new County("IL", "Madison County"),
  new County("IL", "Marion County"),
  new County("IL", "Marshall County"),
  new County("IL", "Mason County"),
  new County("IL", "Massac County"),
  new County("IL", "Menard County"),
  new County("IL", "Mercer County"),
  new County("IL", "Monroe County"),
  new County("IL", "Montgomery County"),
  new County("IL", "Morgan County"),
  new County("IL", "Moultrie County"),
  new County("IL", "Ogle County"),
  new County("IL", "Peoria County"),
  new County("IL", "Perry County"),
  new County("IL", "Piatt County"),
  new County("IL", "Pike County"),
  new County("IL", "Pope County"),
  new County("IL", "Pulaski County"),
  new County("IL", "Putnam County"),
  new County("IL", "Randolph County"),
  new County("IL", "Richland County"),
  new County("IL", "Rock Island County"),
  new County("IL", "St. Clair County"),
  new County("IL", "Saline County"),
  new County("IL", "Sangamon County"),
  new County("IL", "Schuyler County"),
  new County("IL", "Scott County"),
  new County("IL", "Shelby County"),
  new County("IL", "Stark County"),
  new County("IL", "Stephenson County"),
  new County("IL", "Tazewell County"),
  new County("IL", "Union County"),
  new County("IL", "Vermilion County"),
  new County("IL", "Wabash County"),
  new County("IL", "Warren County"),
  new County("IL", "Washington County"),
  new County("IL", "Wayne County"),
  new County("IL", "White County"),
  new County("IL", "Whiteside County"),
  new County("IL", "Will County"),
  new County("IL", "Williamson County"),
  new County("IL", "Winnebago County"),
  new County("IL", "Woodford County"),
  new County("IN", "Adams County"),
  new County("IN", "Allen County"),
  new County("IN", "Bartholomew County"),
  new County("IN", "Benton County"),
  new County("IN", "Blackford County"),
  new County("IN", "Boone County"),
  new County("IN", "Brown County"),
  new County("IN", "Carroll County"),
  new County("IN", "Cass County"),
  new County("IN", "Clark County"),
  new County("IN", "Clay County"),
  new County("IN", "Clinton County"),
  new County("IN", "Crawford County"),
  new County("IN", "Daviess County"),
  new County("IN", "Dearborn County"),
  new County("IN", "Decatur County"),
  new County("IN", "DeKalb County"),
  new County("IN", "Delaware County"),
  new County("IN", "Dubois County"),
  new County("IN", "Elkhart County"),
  new County("IN", "Fayette County"),
  new County("IN", "Floyd County"),
  new County("IN", "Fountain County"),
  new County("IN", "Franklin County"),
  new County("IN", "Fulton County"),
  new County("IN", "Gibson County"),
  new County("IN", "Grant County"),
  new County("IN", "Greene County"),
  new County("IN", "Hamilton County"),
  new County("IN", "Hancock County"),
  new County("IN", "Harrison County"),
  new County("IN", "Hendricks County"),
  new County("IN", "Henry County"),
  new County("IN", "Howard County"),
  new County("IN", "Huntington County"),
  new County("IN", "Jackson County"),
  new County("IN", "Jasper County"),
  new County("IN", "Jay County"),
  new County("IN", "Jefferson County"),
  new County("IN", "Jennings County"),
  new County("IN", "Johnson County"),
  new County("IN", "Knox County"),
  new County("IN", "Kosciusko County"),
  new County("IN", "LaGrange County"),
  new County("IN", "Lake County"),
  new County("IN", "LaPorte County"),
  new County("IN", "Lawrence County"),
  new County("IN", "Madison County"),
  new County("IN", "Marion County"),
  new County("IN", "Marshall County"),
  new County("IN", "Martin County"),
  new County("IN", "Miami County"),
  new County("IN", "Monroe County"),
  new County("IN", "Montgomery County"),
  new County("IN", "Morgan County"),
  new County("IN", "Newton County"),
  new County("IN", "Noble County"),
  new County("IN", "Ohio County"),
  new County("IN", "Orange County"),
  new County("IN", "Owen County"),
  new County("IN", "Parke County"),
  new County("IN", "Perry County"),
  new County("IN", "Pike County"),
  new County("IN", "Porter County"),
  new County("IN", "Posey County"),
  new County("IN", "Pulaski County"),
  new County("IN", "Putnam County"),
  new County("IN", "Randolph County"),
  new County("IN", "Ripley County"),
  new County("IN", "Rush County"),
  new County("IN", "St. Joseph County"),
  new County("IN", "Scott County"),
  new County("IN", "Shelby County"),
  new County("IN", "Spencer County"),
  new County("IN", "Starke County"),
  new County("IN", "Steuben County"),
  new County("IN", "Sullivan County"),
  new County("IN", "Switzerland County"),
  new County("IN", "Tippecanoe County"),
  new County("IN", "Tipton County"),
  new County("IN", "Union County"),
  new County("IN", "Vanderburgh County"),
  new County("IN", "Vermillion County"),
  new County("IN", "Vigo County"),
  new County("IN", "Wabash County"),
  new County("IN", "Warren County"),
  new County("IN", "Warrick County"),
  new County("IN", "Washington County"),
  new County("IN", "Wayne County"),
  new County("IN", "Wells County"),
  new County("IN", "White County"),
  new County("IN", "Whitley County"),
  new County("IA", "Adair County"),
  new County("IA", "Adams County"),
  new County("IA", "Allamakee County"),
  new County("IA", "Appanoose County"),
  new County("IA", "Audubon County"),
  new County("IA", "Benton County"),
  new County("IA", "Black Hawk County"),
  new County("IA", "Boone County"),
  new County("IA", "Bremer County"),
  new County("IA", "Buchanan County"),
  new County("IA", "Buena Vista County"),
  new County("IA", "Butler County"),
  new County("IA", "Calhoun County"),
  new County("IA", "Carroll County"),
  new County("IA", "Cass County"),
  new County("IA", "Cedar County"),
  new County("IA", "Cerro Gordo County"),
  new County("IA", "Cherokee County"),
  new County("IA", "Chickasaw County"),
  new County("IA", "Clarke County"),
  new County("IA", "Clay County"),
  new County("IA", "Clayton County"),
  new County("IA", "Clinton County"),
  new County("IA", "Crawford County"),
  new County("IA", "Dallas County"),
  new County("IA", "Davis County"),
  new County("IA", "Decatur County"),
  new County("IA", "Delaware County"),
  new County("IA", "Des Moines County"),
  new County("IA", "Dickinson County"),
  new County("IA", "Dubuque County"),
  new County("IA", "Emmet County"),
  new County("IA", "Fayette County"),
  new County("IA", "Floyd County"),
  new County("IA", "Franklin County"),
  new County("IA", "Fremont County"),
  new County("IA", "Greene County"),
  new County("IA", "Grundy County"),
  new County("IA", "Guthrie County"),
  new County("IA", "Hamilton County"),
  new County("IA", "Hancock County"),
  new County("IA", "Hardin County"),
  new County("IA", "Harrison County"),
  new County("IA", "Henry County"),
  new County("IA", "Howard County"),
  new County("IA", "Humboldt County"),
  new County("IA", "Ida County"),
  new County("IA", "Iowa County"),
  new County("IA", "Jackson County"),
  new County("IA", "Jasper County"),
  new County("IA", "Jefferson County"),
  new County("IA", "Johnson County"),
  new County("IA", "Jones County"),
  new County("IA", "Keokuk County"),
  new County("IA", "Kossuth County"),
  new County("IA", "Lee County"),
  new County("IA", "Linn County"),
  new County("IA", "Louisa County"),
  new County("IA", "Lucas County"),
  new County("IA", "Lyon County"),
  new County("IA", "Madison County"),
  new County("IA", "Mahaska County"),
  new County("IA", "Marion County"),
  new County("IA", "Marshall County"),
  new County("IA", "Mills County"),
  new County("IA", "Mitchell County"),
  new County("IA", "Monona County"),
  new County("IA", "Monroe County"),
  new County("IA", "Montgomery County"),
  new County("IA", "Muscatine County"),
  new County("IA", "O'Brien County"),
  new County("IA", "Osceola County"),
  new County("IA", "Page County"),
  new County("IA", "Palo Alto County"),
  new County("IA", "Plymouth County"),
  new County("IA", "Pocahontas County"),
  new County("IA", "Polk County"),
  new County("IA", "Pottawattamie County"),
  new County("IA", "Poweshiek County"),
  new County("IA", "Ringgold County"),
  new County("IA", "Sac County"),
  new County("IA", "Scott County"),
  new County("IA", "Shelby County"),
  new County("IA", "Sioux County"),
  new County("IA", "Story County"),
  new County("IA", "Tama County"),
  new County("IA", "Taylor County"),
  new County("IA", "Union County"),
  new County("IA", "Van Buren County"),
  new County("IA", "Wapello County"),
  new County("IA", "Warren County"),
  new County("IA", "Washington County"),
  new County("IA", "Wayne County"),
  new County("IA", "Webster County"),
  new County("IA", "Winnebago County"),
  new County("IA", "Winneshiek County"),
  new County("IA", "Woodbury County"),
  new County("IA", "Worth County"),
  new County("IA", "Wright County"),
  new County("KS", "Allen County"),
  new County("KS", "Anderson County"),
  new County("KS", "Atchison County"),
  new County("KS", "Barber County"),
  new County("KS", "Barton County"),
  new County("KS", "Bourbon County"),
  new County("KS", "Brown County"),
  new County("KS", "Butler County"),
  new County("KS", "Chase County"),
  new County("KS", "Chautauqua County"),
  new County("KS", "Cherokee County"),
  new County("KS", "Cheyenne County"),
  new County("KS", "Clark County"),
  new County("KS", "Clay County"),
  new County("KS", "Cloud County"),
  new County("KS", "Coffey County"),
  new County("KS", "Comanche County"),
  new County("KS", "Cowley County"),
  new County("KS", "Crawford County"),
  new County("KS", "Decatur County"),
  new County("KS", "Dickinson County"),
  new County("KS", "Doniphan County"),
  new County("KS", "Douglas County"),
  new County("KS", "Edwards County"),
  new County("KS", "Elk County"),
  new County("KS", "Ellis County"),
  new County("KS", "Ellsworth County"),
  new County("KS", "Finney County"),
  new County("KS", "Ford County"),
  new County("KS", "Franklin County"),
  new County("KS", "Geary County"),
  new County("KS", "Gove County"),
  new County("KS", "Graham County"),
  new County("KS", "Grant County"),
  new County("KS", "Gray County"),
  new County("KS", "Greeley County"),
  new County("KS", "Greenwood County"),
  new County("KS", "Hamilton County"),
  new County("KS", "Harper County"),
  new County("KS", "Harvey County"),
  new County("KS", "Haskell County"),
  new County("KS", "Hodgeman County"),
  new County("KS", "Jackson County"),
  new County("KS", "Jefferson County"),
  new County("KS", "Jewell County"),
  new County("KS", "Johnson County"),
  new County("KS", "Kearny County"),
  new County("KS", "Kingman County"),
  new County("KS", "Kiowa County"),
  new County("KS", "Labette County"),
  new County("KS", "Lane County"),
  new County("KS", "Leavenworth County"),
  new County("KS", "Lincoln County"),
  new County("KS", "Linn County"),
  new County("KS", "Logan County"),
  new County("KS", "Lyon County"),
  new County("KS", "McPherson County"),
  new County("KS", "Marion County"),
  new County("KS", "Marshall County"),
  new County("KS", "Meade County"),
  new County("KS", "Miami County"),
  new County("KS", "Mitchell County"),
  new County("KS", "Montgomery County"),
  new County("KS", "Morris County"),
  new County("KS", "Morton County"),
  new County("KS", "Nemaha County"),
  new County("KS", "Neosho County"),
  new County("KS", "Ness County"),
  new County("KS", "Norton County"),
  new County("KS", "Osage County"),
  new County("KS", "Osborne County"),
  new County("KS", "Ottawa County"),
  new County("KS", "Pawnee County"),
  new County("KS", "Phillips County"),
  new County("KS", "Pottawatomie County"),
  new County("KS", "Pratt County"),
  new County("KS", "Rawlins County"),
  new County("KS", "Reno County"),
  new County("KS", "Republic County"),
  new County("KS", "Rice County"),
  new County("KS", "Riley County"),
  new County("KS", "Rooks County"),
  new County("KS", "Rush County"),
  new County("KS", "Russell County"),
  new County("KS", "Saline County"),
  new County("KS", "Scott County"),
  new County("KS", "Sedgwick County"),
  new County("KS", "Seward County"),
  new County("KS", "Shawnee County"),
  new County("KS", "Sheridan County"),
  new County("KS", "Sherman County"),
  new County("KS", "Smith County"),
  new County("KS", "Stafford County"),
  new County("KS", "Stanton County"),
  new County("KS", "Stevens County"),
  new County("KS", "Sumner County"),
  new County("KS", "Thomas County"),
  new County("KS", "Trego County"),
  new County("KS", "Wabaunsee County"),
  new County("KS", "Wallace County"),
  new County("KS", "Washington County"),
  new County("KS", "Wichita County"),
  new County("KS", "Wilson County"),
  new County("KS", "Woodson County"),
  new County("KS", "Wyandotte County"),
  new County("KY", "Adair County"),
  new County("KY", "Allen County"),
  new County("KY", "Anderson County"),
  new County("KY", "Ballard County"),
  new County("KY", "Barren County"),
  new County("KY", "Bath County"),
  new County("KY", "Bell County"),
  new County("KY", "Boone County"),
  new County("KY", "Bourbon County"),
  new County("KY", "Boyd County"),
  new County("KY", "Boyle County"),
  new County("KY", "Bracken County"),
  new County("KY", "Breathitt County"),
  new County("KY", "Breckinridge County"),
  new County("KY", "Bullitt County"),
  new County("KY", "Butler County"),
  new County("KY", "Caldwell County"),
  new County("KY", "Calloway County"),
  new County("KY", "Campbell County"),
  new County("KY", "Carlisle County"),
  new County("KY", "Carroll County"),
  new County("KY", "Carter County"),
  new County("KY", "Casey County"),
  new County("KY", "Christian County"),
  new County("KY", "Clark County"),
  new County("KY", "Clay County"),
  new County("KY", "Clinton County"),
  new County("KY", "Crittenden County"),
  new County("KY", "Cumberland County"),
  new County("KY", "Daviess County"),
  new County("KY", "Edmonson County"),
  new County("KY", "Elliott County"),
  new County("KY", "Estill County"),
  new County("KY", "Fayette County"),
  new County("KY", "Fleming County"),
  new County("KY", "Floyd County"),
  new County("KY", "Franklin County"),
  new County("KY", "Fulton County"),
  new County("KY", "Gallatin County"),
  new County("KY", "Garrard County"),
  new County("KY", "Grant County"),
  new County("KY", "Graves County"),
  new County("KY", "Grayson County"),
  new County("KY", "Green County"),
  new County("KY", "Greenup County"),
  new County("KY", "Hancock County"),
  new County("KY", "Hardin County"),
  new County("KY", "Harlan County"),
  new County("KY", "Harrison County"),
  new County("KY", "Hart County"),
  new County("KY", "Henderson County"),
  new County("KY", "Henry County"),
  new County("KY", "Hickman County"),
  new County("KY", "Hopkins County"),
  new County("KY", "Jackson County"),
  new County("KY", "Jefferson County"),
  new County("KY", "Jessamine County"),
  new County("KY", "Johnson County"),
  new County("KY", "Kenton County"),
  new County("KY", "Knott County"),
  new County("KY", "Knox County"),
  new County("KY", "Larue County"),
  new County("KY", "Laurel County"),
  new County("KY", "Lawrence County"),
  new County("KY", "Lee County"),
  new County("KY", "Leslie County"),
  new County("KY", "Letcher County"),
  new County("KY", "Lewis County"),
  new County("KY", "Lincoln County"),
  new County("KY", "Livingston County"),
  new County("KY", "Logan County"),
  new County("KY", "Lyon County"),
  new County("KY", "McCracken County"),
  new County("KY", "McCreary County"),
  new County("KY", "McLean County"),
  new County("KY", "Madison County"),
  new County("KY", "Magoffin County"),
  new County("KY", "Marion County"),
  new County("KY", "Marshall County"),
  new County("KY", "Martin County"),
  new County("KY", "Mason County"),
  new County("KY", "Meade County"),
  new County("KY", "Menifee County"),
  new County("KY", "Mercer County"),
  new County("KY", "Metcalfe County"),
  new County("KY", "Monroe County"),
  new County("KY", "Montgomery County"),
  new County("KY", "Morgan County"),
  new County("KY", "Muhlenberg County"),
  new County("KY", "Nelson County"),
  new County("KY", "Nicholas County"),
  new County("KY", "Ohio County"),
  new County("KY", "Oldham County"),
  new County("KY", "Owen County"),
  new County("KY", "Owsley County"),
  new County("KY", "Pendleton County"),
  new County("KY", "Perry County"),
  new County("KY", "Pike County"),
  new County("KY", "Powell County"),
  new County("KY", "Pulaski County"),
  new County("KY", "Robertson County"),
  new County("KY", "Rockcastle County"),
  new County("KY", "Rowan County"),
  new County("KY", "Russell County"),
  new County("KY", "Scott County"),
  new County("KY", "Shelby County"),
  new County("KY", "Simpson County"),
  new County("KY", "Spencer County"),
  new County("KY", "Taylor County"),
  new County("KY", "Todd County"),
  new County("KY", "Trigg County"),
  new County("KY", "Trimble County"),
  new County("KY", "Union County"),
  new County("KY", "Warren County"),
  new County("KY", "Washington County"),
  new County("KY", "Wayne County"),
  new County("KY", "Webster County"),
  new County("KY", "Whitley County"),
  new County("KY", "Wolfe County"),
  new County("KY", "Woodford County"),
  new County("LA", "Acadia Parish"),
  new County("LA", "Allen Parish"),
  new County("LA", "Ascension Parish"),
  new County("LA", "Assumption Parish"),
  new County("LA", "Avoyelles Parish"),
  new County("LA", "Beauregard Parish"),
  new County("LA", "Bienville Parish"),
  new County("LA", "Bossier Parish"),
  new County("LA", "Caddo Parish"),
  new County("LA", "Calcasieu Parish"),
  new County("LA", "Caldwell Parish"),
  new County("LA", "Cameron Parish"),
  new County("LA", "Catahoula Parish"),
  new County("LA", "Claiborne Parish"),
  new County("LA", "Concordia Parish"),
  new County("LA", "De Soto Parish"),
  new County("LA", "East Baton Rouge Parish"),
  new County("LA", "East Carroll Parish"),
  new County("LA", "East Feliciana Parish"),
  new County("LA", "Evangeline Parish"),
  new County("LA", "Franklin Parish"),
  new County("LA", "Grant Parish"),
  new County("LA", "Iberia Parish"),
  new County("LA", "Iberville Parish"),
  new County("LA", "Jackson Parish"),
  new County("LA", "Jefferson Parish"),
  new County("LA", "Jefferson Davis Parish"),
  new County("LA", "Lafayette Parish"),
  new County("LA", "Lafourche Parish"),
  new County("LA", "La Salle Parish"),
  new County("LA", "Lincoln Parish"),
  new County("LA", "Livingston Parish"),
  new County("LA", "Madison Parish"),
  new County("LA", "Morehouse Parish"),
  new County("LA", "Natchitoches Parish"),
  new County("LA", "Orleans Parish"),
  new County("LA", "Ouachita Parish"),
  new County("LA", "Plaquemines Parish"),
  new County("LA", "Pointe Coupee Parish"),
  new County("LA", "Rapides Parish"),
  new County("LA", "Red River Parish"),
  new County("LA", "Richland Parish"),
  new County("LA", "Sabine Parish"),
  new County("LA", "St. Bernard Parish"),
  new County("LA", "St. Charles Parish"),
  new County("LA", "St. Helena Parish"),
  new County("LA", "St. James Parish"),
  new County("LA", "St. John the Baptist Parish"),
  new County("LA", "St. Landry Parish"),
  new County("LA", "St. Martin Parish"),
  new County("LA", "St. Mary Parish"),
  new County("LA", "St. Tammany Parish"),
  new County("LA", "Tangipahoa Parish"),
  new County("LA", "Tensas Parish"),
  new County("LA", "Terrebonne Parish"),
  new County("LA", "Union Parish"),
  new County("LA", "Vermilion Parish"),
  new County("LA", "Vernon Parish"),
  new County("LA", "Washington Parish"),
  new County("LA", "Webster Parish"),
  new County("LA", "West Baton Rouge Parish"),
  new County("LA", "West Carroll Parish"),
  new County("LA", "West Feliciana Parish"),
  new County("LA", "Winn Parish"),
  new County("ME", "Androscoggin County"),
  new County("ME", "Aroostook County"),
  new County("ME", "Cumberland County"),
  new County("ME", "Franklin County"),
  new County("ME", "Hancock County"),
  new County("ME", "Kennebec County"),
  new County("ME", "Knox County"),
  new County("ME", "Lincoln County"),
  new County("ME", "Oxford County"),
  new County("ME", "Penobscot County"),
  new County("ME", "Piscataquis County"),
  new County("ME", "Sagadahoc County"),
  new County("ME", "Somerset County"),
  new County("ME", "Waldo County"),
  new County("ME", "Washington County"),
  new County("ME", "York County"),
  new County("MD", "Allegany County"),
  new County("MD", "Anne Arundel County"),
  new County("MD", "Baltimore County"),
  new County("MD", "Calvert County"),
  new County("MD", "Caroline County"),
  new County("MD", "Carroll County"),
  new County("MD", "Cecil County"),
  new County("MD", "Charles County"),
  new County("MD", "Dorchester County"),
  new County("MD", "Frederick County"),
  new County("MD", "Garrett County"),
  new County("MD", "Harford County"),
  new County("MD", "Howard County"),
  new County("MD", "Kent County"),
  new County("MD", "Montgomery County"),
  new County("MD", "Prince George's County"),
  new County("MD", "Queen Anne's County"),
  new County("MD", "St. Mary's County"),
  new County("MD", "Somerset County"),
  new County("MD", "Talbot County"),
  new County("MD", "Washington County"),
  new County("MD", "Wicomico County"),
  new County("MD", "Worcester County"),
  new County("MD", "Baltimore city"),
  new County("MA", "Barnstable County"),
  new County("MA", "Berkshire County"),
  new County("MA", "Bristol County"),
  new County("MA", "Dukes County"),
  new County("MA", "Essex County"),
  new County("MA", "Franklin County"),
  new County("MA", "Hampden County"),
  new County("MA", "Hampshire County"),
  new County("MA", "Middlesex County"),
  new County("MA", "Nantucket County"),
  new County("MA", "Norfolk County"),
  new County("MA", "Plymouth County"),
  new County("MA", "Suffolk County"),
  new County("MA", "Worcester County"),
  new County("MI", "Alcona County"),
  new County("MI", "Alger County"),
  new County("MI", "Allegan County"),
  new County("MI", "Alpena County"),
  new County("MI", "Antrim County"),
  new County("MI", "Arenac County"),
  new County("MI", "Baraga County"),
  new County("MI", "Barry County"),
  new County("MI", "Bay County"),
  new County("MI", "Benzie County"),
  new County("MI", "Berrien County"),
  new County("MI", "Branch County"),
  new County("MI", "Calhoun County"),
  new County("MI", "Cass County"),
  new County("MI", "Charlevoix County"),
  new County("MI", "Cheboygan County"),
  new County("MI", "Chippewa County"),
  new County("MI", "Clare County"),
  new County("MI", "Clinton County"),
  new County("MI", "Crawford County"),
  new County("MI", "Delta County"),
  new County("MI", "Dickinson County"),
  new County("MI", "Eaton County"),
  new County("MI", "Emmet County"),
  new County("MI", "Genesee County"),
  new County("MI", "Gladwin County"),
  new County("MI", "Gogebic County"),
  new County("MI", "Grand Traverse County"),
  new County("MI", "Gratiot County"),
  new County("MI", "Hillsdale County"),
  new County("MI", "Houghton County"),
  new County("MI", "Huron County"),
  new County("MI", "Ingham County"),
  new County("MI", "Ionia County"),
  new County("MI", "Iosco County"),
  new County("MI", "Iron County"),
  new County("MI", "Isabella County"),
  new County("MI", "Jackson County"),
  new County("MI", "Kalamazoo County"),
  new County("MI", "Kalkaska County"),
  new County("MI", "Kent County"),
  new County("MI", "Keweenaw County"),
  new County("MI", "Lake County"),
  new County("MI", "Lapeer County"),
  new County("MI", "Leelanau County"),
  new County("MI", "Lenawee County"),
  new County("MI", "Livingston County"),
  new County("MI", "Luce County"),
  new County("MI", "Mackinac County"),
  new County("MI", "Macomb County"),
  new County("MI", "Manistee County"),
  new County("MI", "Marquette County"),
  new County("MI", "Mason County"),
  new County("MI", "Mecosta County"),
  new County("MI", "Menominee County"),
  new County("MI", "Midland County"),
  new County("MI", "Missaukee County"),
  new County("MI", "Monroe County"),
  new County("MI", "Montcalm County"),
  new County("MI", "Montmorency County"),
  new County("MI", "Muskegon County"),
  new County("MI", "Newaygo County"),
  new County("MI", "Oakland County"),
  new County("MI", "Oceana County"),
  new County("MI", "Ogemaw County"),
  new County("MI", "Ontonagon County"),
  new County("MI", "Osceola County"),
  new County("MI", "Oscoda County"),
  new County("MI", "Otsego County"),
  new County("MI", "Ottawa County"),
  new County("MI", "Presque Isle County"),
  new County("MI", "Roscommon County"),
  new County("MI", "Saginaw County"),
  new County("MI", "St. Clair County"),
  new County("MI", "St. Joseph County"),
  new County("MI", "Sanilac County"),
  new County("MI", "Schoolcraft County"),
  new County("MI", "Shiawassee County"),
  new County("MI", "Tuscola County"),
  new County("MI", "Van Buren County"),
  new County("MI", "Washtenaw County"),
  new County("MI", "Wayne County"),
  new County("MI", "Wexford County"),
  new County("MN", "Aitkin County"),
  new County("MN", "Anoka County"),
  new County("MN", "Becker County"),
  new County("MN", "Beltrami County"),
  new County("MN", "Benton County"),
  new County("MN", "Big Stone County"),
  new County("MN", "Blue Earth County"),
  new County("MN", "Brown County"),
  new County("MN", "Carlton County"),
  new County("MN", "Carver County"),
  new County("MN", "Cass County"),
  new County("MN", "Chippewa County"),
  new County("MN", "Chisago County"),
  new County("MN", "Clay County"),
  new County("MN", "Clearwater County"),
  new County("MN", "Cook County"),
  new County("MN", "Cottonwood County"),
  new County("MN", "Crow Wing County"),
  new County("MN", "Dakota County"),
  new County("MN", "Dodge County"),
  new County("MN", "Douglas County"),
  new County("MN", "Faribault County"),
  new County("MN", "Fillmore County"),
  new County("MN", "Freeborn County"),
  new County("MN", "Goodhue County"),
  new County("MN", "Grant County"),
  new County("MN", "Hennepin County"),
  new County("MN", "Houston County"),
  new County("MN", "Hubbard County"),
  new County("MN", "Isanti County"),
  new County("MN", "Itasca County"),
  new County("MN", "Jackson County"),
  new County("MN", "Kanabec County"),
  new County("MN", "Kandiyohi County"),
  new County("MN", "Kittson County"),
  new County("MN", "Koochiching County"),
  new County("MN", "Lac qui Parle County"),
  new County("MN", "Lake County"),
  new County("MN", "Lake of the Woods County"),
  new County("MN", "Le Sueur County"),
  new County("MN", "Lincoln County"),
  new County("MN", "Lyon County"),
  new County("MN", "McLeod County"),
  new County("MN", "Mahnomen County"),
  new County("MN", "Marshall County"),
  new County("MN", "Martin County"),
  new County("MN", "Meeker County"),
  new County("MN", "Mille Lacs County"),
  new County("MN", "Morrison County"),
  new County("MN", "Mower County"),
  new County("MN", "Murray County"),
  new County("MN", "Nicollet County"),
  new County("MN", "Nobles County"),
  new County("MN", "Norman County"),
  new County("MN", "Olmsted County"),
  new County("MN", "Otter Tail County"),
  new County("MN", "Pennington County"),
  new County("MN", "Pine County"),
  new County("MN", "Pipestone County"),
  new County("MN", "Polk County"),
  new County("MN", "Pope County"),
  new County("MN", "Ramsey County"),
  new County("MN", "Red Lake County"),
  new County("MN", "Redwood County"),
  new County("MN", "Renville County"),
  new County("MN", "Rice County"),
  new County("MN", "Rock County"),
  new County("MN", "Roseau County"),
  new County("MN", "St. Louis County"),
  new County("MN", "Scott County"),
  new County("MN", "Sherburne County"),
  new County("MN", "Sibley County"),
  new County("MN", "Stearns County"),
  new County("MN", "Steele County"),
  new County("MN", "Stevens County"),
  new County("MN", "Swift County"),
  new County("MN", "Todd County"),
  new County("MN", "Traverse County"),
  new County("MN", "Wabasha County"),
  new County("MN", "Wadena County"),
  new County("MN", "Waseca County"),
  new County("MN", "Washington County"),
  new County("MN", "Watonwan County"),
  new County("MN", "Wilkin County"),
  new County("MN", "Winona County"),
  new County("MN", "Wright County"),
  new County("MN", "Yellow Medicine County"),
  new County("MS", "Adams County"),
  new County("MS", "Alcorn County"),
  new County("MS", "Amite County"),
  new County("MS", "Attala County"),
  new County("MS", "Benton County"),
  new County("MS", "Bolivar County"),
  new County("MS", "Calhoun County"),
  new County("MS", "Carroll County"),
  new County("MS", "Chickasaw County"),
  new County("MS", "Choctaw County"),
  new County("MS", "Claiborne County"),
  new County("MS", "Clarke County"),
  new County("MS", "Clay County"),
  new County("MS", "Coahoma County"),
  new County("MS", "Copiah County"),
  new County("MS", "Covington County"),
  new County("MS", "DeSoto County"),
  new County("MS", "Forrest County"),
  new County("MS", "Franklin County"),
  new County("MS", "George County"),
  new County("MS", "Greene County"),
  new County("MS", "Grenada County"),
  new County("MS", "Hancock County"),
  new County("MS", "Harrison County"),
  new County("MS", "Hinds County"),
  new County("MS", "Holmes County"),
  new County("MS", "Humphreys County"),
  new County("MS", "Issaquena County"),
  new County("MS", "Itawamba County"),
  new County("MS", "Jackson County"),
  new County("MS", "Jasper County"),
  new County("MS", "Jefferson County"),
  new County("MS", "Jefferson Davis County"),
  new County("MS", "Jones County"),
  new County("MS", "Kemper County"),
  new County("MS", "Lafayette County"),
  new County("MS", "Lamar County"),
  new County("MS", "Lauderdale County"),
  new County("MS", "Lawrence County"),
  new County("MS", "Leake County"),
  new County("MS", "Lee County"),
  new County("MS", "Leflore County"),
  new County("MS", "Lincoln County"),
  new County("MS", "Lowndes County"),
  new County("MS", "Madison County"),
  new County("MS", "Marion County"),
  new County("MS", "Marshall County"),
  new County("MS", "Monroe County"),
  new County("MS", "Montgomery County"),
  new County("MS", "Neshoba County"),
  new County("MS", "Newton County"),
  new County("MS", "Noxubee County"),
  new County("MS", "Oktibbeha County"),
  new County("MS", "Panola County"),
  new County("MS", "Pearl River County"),
  new County("MS", "Perry County"),
  new County("MS", "Pike County"),
  new County("MS", "Pontotoc County"),
  new County("MS", "Prentiss County"),
  new County("MS", "Quitman County"),
  new County("MS", "Rankin County"),
  new County("MS", "Scott County"),
  new County("MS", "Sharkey County"),
  new County("MS", "Simpson County"),
  new County("MS", "Smith County"),
  new County("MS", "Stone County"),
  new County("MS", "Sunflower County"),
  new County("MS", "Tallahatchie County"),
  new County("MS", "Tate County"),
  new County("MS", "Tippah County"),
  new County("MS", "Tishomingo County"),
  new County("MS", "Tunica County"),
  new County("MS", "Union County"),
  new County("MS", "Walthall County"),
  new County("MS", "Warren County"),
  new County("MS", "Washington County"),
  new County("MS", "Wayne County"),
  new County("MS", "Webster County"),
  new County("MS", "Wilkinson County"),
  new County("MS", "Winston County"),
  new County("MS", "Yalobusha County"),
  new County("MS", "Yazoo County"),
  new County("MO", "Adair County"),
  new County("MO", "Andrew County"),
  new County("MO", "Atchison County"),
  new County("MO", "Audrain County"),
  new County("MO", "Barry County"),
  new County("MO", "Barton County"),
  new County("MO", "Bates County"),
  new County("MO", "Benton County"),
  new County("MO", "Bollinger County"),
  new County("MO", "Boone County"),
  new County("MO", "Buchanan County"),
  new County("MO", "Butler County"),
  new County("MO", "Caldwell County"),
  new County("MO", "Callaway County"),
  new County("MO", "Camden County"),
  new County("MO", "Cape Girardeau County"),
  new County("MO", "Carroll County"),
  new County("MO", "Carter County"),
  new County("MO", "Cass County"),
  new County("MO", "Cedar County"),
  new County("MO", "Chariton County"),
  new County("MO", "Christian County"),
  new County("MO", "Clark County"),
  new County("MO", "Clay County"),
  new County("MO", "Clinton County"),
  new County("MO", "Cole County"),
  new County("MO", "Cooper County"),
  new County("MO", "Crawford County"),
  new County("MO", "Dade County"),
  new County("MO", "Dallas County"),
  new County("MO", "Daviess County"),
  new County("MO", "DeKalb County"),
  new County("MO", "Dent County"),
  new County("MO", "Douglas County"),
  new County("MO", "Dunklin County"),
  new County("MO", "Franklin County"),
  new County("MO", "Gasconade County"),
  new County("MO", "Gentry County"),
  new County("MO", "Greene County"),
  new County("MO", "Grundy County"),
  new County("MO", "Harrison County"),
  new County("MO", "Henry County"),
  new County("MO", "Hickory County"),
  new County("MO", "Holt County"),
  new County("MO", "Howard County"),
  new County("MO", "Howell County"),
  new County("MO", "Iron County"),
  new County("MO", "Jackson County"),
  new County("MO", "Jasper County"),
  new County("MO", "Jefferson County"),
  new County("MO", "Johnson County"),
  new County("MO", "Knox County"),
  new County("MO", "Laclede County"),
  new County("MO", "Lafayette County"),
  new County("MO", "Lawrence County"),
  new County("MO", "Lewis County"),
  new County("MO", "Lincoln County"),
  new County("MO", "Linn County"),
  new County("MO", "Livingston County"),
  new County("MO", "McDonald County"),
  new County("MO", "Macon County"),
  new County("MO", "Madison County"),
  new County("MO", "Maries County"),
  new County("MO", "Marion County"),
  new County("MO", "Mercer County"),
  new County("MO", "Miller County"),
  new County("MO", "Mississippi County"),
  new County("MO", "Moniteau County"),
  new County("MO", "Monroe County"),
  new County("MO", "Montgomery County"),
  new County("MO", "Morgan County"),
  new County("MO", "New Madrid County"),
  new County("MO", "Newton County"),
  new County("MO", "Nodaway County"),
  new County("MO", "Oregon County"),
  new County("MO", "Osage County"),
  new County("MO", "Ozark County"),
  new County("MO", "Pemiscot County"),
  new County("MO", "Perry County"),
  new County("MO", "Pettis County"),
  new County("MO", "Phelps County"),
  new County("MO", "Pike County"),
  new County("MO", "Platte County"),
  new County("MO", "Polk County"),
  new County("MO", "Pulaski County"),
  new County("MO", "Putnam County"),
  new County("MO", "Ralls County"),
  new County("MO", "Randolph County"),
  new County("MO", "Ray County"),
  new County("MO", "Reynolds County"),
  new County("MO", "Ripley County"),
  new County("MO", "St. Charles County"),
  new County("MO", "St. Clair County"),
  new County("MO", "Ste. Genevieve County"),
  new County("MO", "St. Francois County"),
  new County("MO", "St. Louis County"),
  new County("MO", "Saline County"),
  new County("MO", "Schuyler County"),
  new County("MO", "Scotland County"),
  new County("MO", "Scott County"),
  new County("MO", "Shannon County"),
  new County("MO", "Shelby County"),
  new County("MO", "Stoddard County"),
  new County("MO", "Stone County"),
  new County("MO", "Sullivan County"),
  new County("MO", "Taney County"),
  new County("MO", "Texas County"),
  new County("MO", "Vernon County"),
  new County("MO", "Warren County"),
  new County("MO", "Washington County"),
  new County("MO", "Wayne County"),
  new County("MO", "Webster County"),
  new County("MO", "Worth County"),
  new County("MO", "Wright County"),
  new County("MO", "St. Louis city"),
  new County("MT", "Beaverhead County"),
  new County("MT", "Big Horn County"),
  new County("MT", "Blaine County"),
  new County("MT", "Broadwater County"),
  new County("MT", "Carbon County"),
  new County("MT", "Carter County"),
  new County("MT", "Cascade County"),
  new County("MT", "Chouteau County"),
  new County("MT", "Custer County"),
  new County("MT", "Daniels County"),
  new County("MT", "Dawson County"),
  new County("MT", "Deer Lodge County"),
  new County("MT", "Fallon County"),
  new County("MT", "Fergus County"),
  new County("MT", "Flathead County"),
  new County("MT", "Gallatin County"),
  new County("MT", "Garfield County"),
  new County("MT", "Glacier County"),
  new County("MT", "Golden Valley County"),
  new County("MT", "Granite County"),
  new County("MT", "Hill County"),
  new County("MT", "Jefferson County"),
  new County("MT", "Judith Basin County"),
  new County("MT", "Lake County"),
  new County("MT", "Lewis and Clark County"),
  new County("MT", "Liberty County"),
  new County("MT", "Lincoln County"),
  new County("MT", "McCone County"),
  new County("MT", "Madison County"),
  new County("MT", "Meagher County"),
  new County("MT", "Mineral County"),
  new County("MT", "Missoula County"),
  new County("MT", "Musselshell County"),
  new County("MT", "Park County"),
  new County("MT", "Petroleum County"),
  new County("MT", "Phillips County"),
  new County("MT", "Pondera County"),
  new County("MT", "Powder River County"),
  new County("MT", "Powell County"),
  new County("MT", "Prairie County"),
  new County("MT", "Ravalli County"),
  new County("MT", "Richland County"),
  new County("MT", "Roosevelt County"),
  new County("MT", "Rosebud County"),
  new County("MT", "Sanders County"),
  new County("MT", "Sheridan County"),
  new County("MT", "Silver Bow County"),
  new County("MT", "Stillwater County"),
  new County("MT", "Sweet Grass County"),
  new County("MT", "Teton County"),
  new County("MT", "Toole County"),
  new County("MT", "Treasure County"),
  new County("MT", "Valley County"),
  new County("MT", "Wheatland County"),
  new County("MT", "Wibaux County"),
  new County("MT", "Yellowstone County"),
  new County("NE", "Adams County"),
  new County("NE", "Antelope County"),
  new County("NE", "Arthur County"),
  new County("NE", "Banner County"),
  new County("NE", "Blaine County"),
  new County("NE", "Boone County"),
  new County("NE", "Box Butte County"),
  new County("NE", "Boyd County"),
  new County("NE", "Brown County"),
  new County("NE", "Buffalo County"),
  new County("NE", "Burt County"),
  new County("NE", "Butler County"),
  new County("NE", "Cass County"),
  new County("NE", "Cedar County"),
  new County("NE", "Chase County"),
  new County("NE", "Cherry County"),
  new County("NE", "Cheyenne County"),
  new County("NE", "Clay County"),
  new County("NE", "Colfax County"),
  new County("NE", "Cuming County"),
  new County("NE", "Custer County"),
  new County("NE", "Dakota County"),
  new County("NE", "Dawes County"),
  new County("NE", "Dawson County"),
  new County("NE", "Deuel County"),
  new County("NE", "Dixon County"),
  new County("NE", "Dodge County"),
  new County("NE", "Douglas County"),
  new County("NE", "Dundy County"),
  new County("NE", "Fillmore County"),
  new County("NE", "Franklin County"),
  new County("NE", "Frontier County"),
  new County("NE", "Furnas County"),
  new County("NE", "Gage County"),
  new County("NE", "Garden County"),
  new County("NE", "Garfield County"),
  new County("NE", "Gosper County"),
  new County("NE", "Grant County"),
  new County("NE", "Greeley County"),
  new County("NE", "Hall County"),
  new County("NE", "Hamilton County"),
  new County("NE", "Harlan County"),
  new County("NE", "Hayes County"),
  new County("NE", "Hitchcock County"),
  new County("NE", "Holt County"),
  new County("NE", "Hooker County"),
  new County("NE", "Howard County"),
  new County("NE", "Jefferson County"),
  new County("NE", "Johnson County"),
  new County("NE", "Kearney County"),
  new County("NE", "Keith County"),
  new County("NE", "Keya Paha County"),
  new County("NE", "Kimball County"),
  new County("NE", "Knox County"),
  new County("NE", "Lancaster County"),
  new County("NE", "Lincoln County"),
  new County("NE", "Logan County"),
  new County("NE", "Loup County"),
  new County("NE", "McPherson County"),
  new County("NE", "Madison County"),
  new County("NE", "Merrick County"),
  new County("NE", "Morrill County"),
  new County("NE", "Nance County"),
  new County("NE", "Nemaha County"),
  new County("NE", "Nuckolls County"),
  new County("NE", "Otoe County"),
  new County("NE", "Pawnee County"),
  new County("NE", "Perkins County"),
  new County("NE", "Phelps County"),
  new County("NE", "Pierce County"),
  new County("NE", "Platte County"),
  new County("NE", "Polk County"),
  new County("NE", "Red Willow County"),
  new County("NE", "Richardson County"),
  new County("NE", "Rock County"),
  new County("NE", "Saline County"),
  new County("NE", "Sarpy County"),
  new County("NE", "Saunders County"),
  new County("NE", "Scotts Bluff County"),
  new County("NE", "Seward County"),
  new County("NE", "Sheridan County"),
  new County("NE", "Sherman County"),
  new County("NE", "Sioux County"),
  new County("NE", "Stanton County"),
  new County("NE", "Thayer County"),
  new County("NE", "Thomas County"),
  new County("NE", "Thurston County"),
  new County("NE", "Valley County"),
  new County("NE", "Washington County"),
  new County("NE", "Wayne County"),
  new County("NE", "Webster County"),
  new County("NE", "Wheeler County"),
  new County("NE", "York County"),
  new County("NV", "Churchill County"),
  new County("NV", "Clark County"),
  new County("NV", "Douglas County"),
  new County("NV", "Elko County"),
  new County("NV", "Esmeralda County"),
  new County("NV", "Eureka County"),
  new County("NV", "Humboldt County"),
  new County("NV", "Lander County"),
  new County("NV", "Lincoln County"),
  new County("NV", "Lyon County"),
  new County("NV", "Mineral County"),
  new County("NV", "Nye County"),
  new County("NV", "Pershing County"),
  new County("NV", "Storey County"),
  new County("NV", "Washoe County"),
  new County("NV", "White Pine County"),
  new County("NV", "Carson City"),
  new County("NH", "Belknap County"),
  new County("NH", "Carroll County"),
  new County("NH", "Cheshire County"),
  new County("NH", "Coos County"),
  new County("NH", "Grafton County"),
  new County("NH", "Hillsborough County"),
  new County("NH", "Merrimack County"),
  new County("NH", "Rockingham County"),
  new County("NH", "Strafford County"),
  new County("NH", "Sullivan County"),
  new County("NJ", "Atlantic County"),
  new County("NJ", "Bergen County"),
  new County("NJ", "Burlington County"),
  new County("NJ", "Camden County"),
  new County("NJ", "Cape May County"),
  new County("NJ", "Cumberland County"),
  new County("NJ", "Essex County"),
  new County("NJ", "Gloucester County"),
  new County("NJ", "Hudson County"),
  new County("NJ", "Hunterdon County"),
  new County("NJ", "Mercer County"),
  new County("NJ", "Middlesex County"),
  new County("NJ", "Monmouth County"),
  new County("NJ", "Morris County"),
  new County("NJ", "Ocean County"),
  new County("NJ", "Passaic County"),
  new County("NJ", "Salem County"),
  new County("NJ", "Somerset County"),
  new County("NJ", "Sussex County"),
  new County("NJ", "Union County"),
  new County("NJ", "Warren County"),
  new County("NM", "Bernalillo County"),
  new County("NM", "Catron County"),
  new County("NM", "Chaves County"),
  new County("NM", "Cibola County"),
  new County("NM", "Colfax County"),
  new County("NM", "Curry County"),
  new County("NM", "De Baca County"),
  new County("NM", "Dona Ana County"),
  new County("NM", "Eddy County"),
  new County("NM", "Grant County"),
  new County("NM", "Guadalupe County"),
  new County("NM", "Harding County"),
  new County("NM", "Hidalgo County"),
  new County("NM", "Lea County"),
  new County("NM", "Lincoln County"),
  new County("NM", "Los Alamos County"),
  new County("NM", "Luna County"),
  new County("NM", "McKinley County"),
  new County("NM", "Mora County"),
  new County("NM", "Otero County"),
  new County("NM", "Quay County"),
  new County("NM", "Rio Arriba County"),
  new County("NM", "Roosevelt County"),
  new County("NM", "Sandoval County"),
  new County("NM", "San Juan County"),
  new County("NM", "San Miguel County"),
  new County("NM", "Santa Fe County"),
  new County("NM", "Sierra County"),
  new County("NM", "Socorro County"),
  new County("NM", "Taos County"),
  new County("NM", "Torrance County"),
  new County("NM", "Union County"),
  new County("NM", "Valencia County"),
  new County("NY", "Albany County"),
  new County("NY", "Allegany County"),
  new County("NY", "Bronx County"),
  new County("NY", "Broome County"),
  new County("NY", "Cattaraugus County"),
  new County("NY", "Cayuga County"),
  new County("NY", "Chautauqua County"),
  new County("NY", "Chemung County"),
  new County("NY", "Chenango County"),
  new County("NY", "Clinton County"),
  new County("NY", "Columbia County"),
  new County("NY", "Cortland County"),
  new County("NY", "Delaware County"),
  new County("NY", "Dutchess County"),
  new County("NY", "Erie County"),
  new County("NY", "Essex County"),
  new County("NY", "Franklin County"),
  new County("NY", "Fulton County"),
  new County("NY", "Genesee County"),
  new County("NY", "Greene County"),
  new County("NY", "Hamilton County"),
  new County("NY", "Herkimer County"),
  new County("NY", "Jefferson County"),
  new County("NY", "Kings County"),
  new County("NY", "Lewis County"),
  new County("NY", "Livingston County"),
  new County("NY", "Madison County"),
  new County("NY", "Monroe County"),
  new County("NY", "Montgomery County"),
  new County("NY", "Nassau County"),
  new County("NY", "New York County"),
  new County("NY", "Niagara County"),
  new County("NY", "Oneida County"),
  new County("NY", "Onondaga County"),
  new County("NY", "Ontario County"),
  new County("NY", "Orange County"),
  new County("NY", "Orleans County"),
  new County("NY", "Oswego County"),
  new County("NY", "Otsego County"),
  new County("NY", "Putnam County"),
  new County("NY", "Queens County"),
  new County("NY", "Rensselaer County"),
  new County("NY", "Richmond County"),
  new County("NY", "Rockland County"),
  new County("NY", "St. Lawrence County"),
  new County("NY", "Saratoga County"),
  new County("NY", "Schenectady County"),
  new County("NY", "Schoharie County"),
  new County("NY", "Schuyler County"),
  new County("NY", "Seneca County"),
  new County("NY", "Steuben County"),
  new County("NY", "Suffolk County"),
  new County("NY", "Sullivan County"),
  new County("NY", "Tioga County"),
  new County("NY", "Tompkins County"),
  new County("NY", "Ulster County"),
  new County("NY", "Warren County"),
  new County("NY", "Washington County"),
  new County("NY", "Wayne County"),
  new County("NY", "Westchester County"),
  new County("NY", "Wyoming County"),
  new County("NY", "Yates County"),
  new County("NC", "Alamance County"),
  new County("NC", "Alexander County"),
  new County("NC", "Alleghany County"),
  new County("NC", "Anson County"),
  new County("NC", "Ashe County"),
  new County("NC", "Avery County"),
  new County("NC", "Beaufort County"),
  new County("NC", "Bertie County"),
  new County("NC", "Bladen County"),
  new County("NC", "Brunswick County"),
  new County("NC", "Buncombe County"),
  new County("NC", "Burke County"),
  new County("NC", "Cabarrus County"),
  new County("NC", "Caldwell County"),
  new County("NC", "Camden County"),
  new County("NC", "Carteret County"),
  new County("NC", "Caswell County"),
  new County("NC", "Catawba County"),
  new County("NC", "Chatham County"),
  new County("NC", "Cherokee County"),
  new County("NC", "Chowan County"),
  new County("NC", "Clay County"),
  new County("NC", "Cleveland County"),
  new County("NC", "Columbus County"),
  new County("NC", "Craven County"),
  new County("NC", "Cumberland County"),
  new County("NC", "Currituck County"),
  new County("NC", "Dare County"),
  new County("NC", "Davidson County"),
  new County("NC", "Davie County"),
  new County("NC", "Duplin County"),
  new County("NC", "Durham County"),
  new County("NC", "Edgecombe County"),
  new County("NC", "Forsyth County"),
  new County("NC", "Franklin County"),
  new County("NC", "Gaston County"),
  new County("NC", "Gates County"),
  new County("NC", "Graham County"),
  new County("NC", "Granville County"),
  new County("NC", "Greene County"),
  new County("NC", "Guilford County"),
  new County("NC", "Halifax County"),
  new County("NC", "Harnett County"),
  new County("NC", "Haywood County"),
  new County("NC", "Henderson County"),
  new County("NC", "Hertford County"),
  new County("NC", "Hoke County"),
  new County("NC", "Hyde County"),
  new County("NC", "Iredell County"),
  new County("NC", "Jackson County"),
  new County("NC", "Johnston County"),
  new County("NC", "Jones County"),
  new County("NC", "Lee County"),
  new County("NC", "Lenoir County"),
  new County("NC", "Lincoln County"),
  new County("NC", "McDowell County"),
  new County("NC", "Macon County"),
  new County("NC", "Madison County"),
  new County("NC", "Martin County"),
  new County("NC", "Mecklenburg County"),
  new County("NC", "Mitchell County"),
  new County("NC", "Montgomery County"),
  new County("NC", "Moore County"),
  new County("NC", "Nash County"),
  new County("NC", "New Hanover County"),
  new County("NC", "Northampton County"),
  new County("NC", "Onslow County"),
  new County("NC", "Orange County"),
  new County("NC", "Pamlico County"),
  new County("NC", "Pasquotank County"),
  new County("NC", "Pender County"),
  new County("NC", "Perquimans County"),
  new County("NC", "Person County"),
  new County("NC", "Pitt County"),
  new County("NC", "Polk County"),
  new County("NC", "Randolph County"),
  new County("NC", "Richmond County"),
  new County("NC", "Robeson County"),
  new County("NC", "Rockingham County"),
  new County("NC", "Rowan County"),
  new County("NC", "Rutherford County"),
  new County("NC", "Sampson County"),
  new County("NC", "Scotland County"),
  new County("NC", "Stanly County"),
  new County("NC", "Stokes County"),
  new County("NC", "Surry County"),
  new County("NC", "Swain County"),
  new County("NC", "Transylvania County"),
  new County("NC", "Tyrrell County"),
  new County("NC", "Union County"),
  new County("NC", "Vance County"),
  new County("NC", "Wake County"),
  new County("NC", "Warren County"),
  new County("NC", "Washington County"),
  new County("NC", "Watauga County"),
  new County("NC", "Wayne County"),
  new County("NC", "Wilkes County"),
  new County("NC", "Wilson County"),
  new County("NC", "Yadkin County"),
  new County("NC", "Yancey County"),
  new County("ND", "Adams County"),
  new County("ND", "Barnes County"),
  new County("ND", "Benson County"),
  new County("ND", "Billings County"),
  new County("ND", "Bottineau County"),
  new County("ND", "Bowman County"),
  new County("ND", "Burke County"),
  new County("ND", "Burleigh County"),
  new County("ND", "Cass County"),
  new County("ND", "Cavalier County"),
  new County("ND", "Dickey County"),
  new County("ND", "Divide County"),
  new County("ND", "Dunn County"),
  new County("ND", "Eddy County"),
  new County("ND", "Emmons County"),
  new County("ND", "Foster County"),
  new County("ND", "Golden Valley County"),
  new County("ND", "Grand Forks County"),
  new County("ND", "Grant County"),
  new County("ND", "Griggs County"),
  new County("ND", "Hettinger County"),
  new County("ND", "Kidder County"),
  new County("ND", "LaMoure County"),
  new County("ND", "Logan County"),
  new County("ND", "McHenry County"),
  new County("ND", "McIntosh County"),
  new County("ND", "McKenzie County"),
  new County("ND", "McLean County"),
  new County("ND", "Mercer County"),
  new County("ND", "Morton County"),
  new County("ND", "Mountrail County"),
  new County("ND", "Nelson County"),
  new County("ND", "Oliver County"),
  new County("ND", "Pembina County"),
  new County("ND", "Pierce County"),
  new County("ND", "Ramsey County"),
  new County("ND", "Ransom County"),
  new County("ND", "Renville County"),
  new County("ND", "Richland County"),
  new County("ND", "Rolette County"),
  new County("ND", "Sargent County"),
  new County("ND", "Sheridan County"),
  new County("ND", "Sioux County"),
  new County("ND", "Slope County"),
  new County("ND", "Stark County"),
  new County("ND", "Steele County"),
  new County("ND", "Stutsman County"),
  new County("ND", "Towner County"),
  new County("ND", "Traill County"),
  new County("ND", "Walsh County"),
  new County("ND", "Ward County"),
  new County("ND", "Wells County"),
  new County("ND", "Williams County"),
  new County("OH", "Adams County"),
  new County("OH", "Allen County"),
  new County("OH", "Ashland County"),
  new County("OH", "Ashtabula County"),
  new County("OH", "Athens County"),
  new County("OH", "Auglaize County"),
  new County("OH", "Belmont County"),
  new County("OH", "Brown County"),
  new County("OH", "Butler County"),
  new County("OH", "Carroll County"),
  new County("OH", "Champaign County"),
  new County("OH", "Clark County"),
  new County("OH", "Clermont County"),
  new County("OH", "Clinton County"),
  new County("OH", "Columbiana County"),
  new County("OH", "Coshocton County"),
  new County("OH", "Crawford County"),
  new County("OH", "Cuyahoga County"),
  new County("OH", "Darke County"),
  new County("OH", "Defiance County"),
  new County("OH", "Delaware County"),
  new County("OH", "Erie County"),
  new County("OH", "Fairfield County"),
  new County("OH", "Fayette County"),
  new County("OH", "Franklin County"),
  new County("OH", "Fulton County"),
  new County("OH", "Gallia County"),
  new County("OH", "Geauga County"),
  new County("OH", "Greene County"),
  new County("OH", "Guernsey County"),
  new County("OH", "Hamilton County"),
  new County("OH", "Hancock County"),
  new County("OH", "Hardin County"),
  new County("OH", "Harrison County"),
  new County("OH", "Henry County"),
  new County("OH", "Highland County"),
  new County("OH", "Hocking County"),
  new County("OH", "Holmes County"),
  new County("OH", "Huron County"),
  new County("OH", "Jackson County"),
  new County("OH", "Jefferson County"),
  new County("OH", "Knox County"),
  new County("OH", "Lake County"),
  new County("OH", "Lawrence County"),
  new County("OH", "Licking County"),
  new County("OH", "Logan County"),
  new County("OH", "Lorain County"),
  new County("OH", "Lucas County"),
  new County("OH", "Madison County"),
  new County("OH", "Mahoning County"),
  new County("OH", "Marion County"),
  new County("OH", "Medina County"),
  new County("OH", "Meigs County"),
  new County("OH", "Mercer County"),
  new County("OH", "Miami County"),
  new County("OH", "Monroe County"),
  new County("OH", "Montgomery County"),
  new County("OH", "Morgan County"),
  new County("OH", "Morrow County"),
  new County("OH", "Muskingum County"),
  new County("OH", "Noble County"),
  new County("OH", "Ottawa County"),
  new County("OH", "Paulding County"),
  new County("OH", "Perry County"),
  new County("OH", "Pickaway County"),
  new County("OH", "Pike County"),
  new County("OH", "Portage County"),
  new County("OH", "Preble County"),
  new County("OH", "Putnam County"),
  new County("OH", "Richland County"),
  new County("OH", "Ross County"),
  new County("OH", "Sandusky County"),
  new County("OH", "Scioto County"),
  new County("OH", "Seneca County"),
  new County("OH", "Shelby County"),
  new County("OH", "Stark County"),
  new County("OH", "Summit County"),
  new County("OH", "Trumbull County"),
  new County("OH", "Tuscarawas County"),
  new County("OH", "Union County"),
  new County("OH", "Van Wert County"),
  new County("OH", "Vinton County"),
  new County("OH", "Warren County"),
  new County("OH", "Washington County"),
  new County("OH", "Wayne County"),
  new County("OH", "Williams County"),
  new County("OH", "Wood County"),
  new County("OH", "Wyandot County"),
  new County("OK", "Adair County"),
  new County("OK", "Alfalfa County"),
  new County("OK", "Atoka County"),
  new County("OK", "Beaver County"),
  new County("OK", "Beckham County"),
  new County("OK", "Blaine County"),
  new County("OK", "Bryan County"),
  new County("OK", "Caddo County"),
  new County("OK", "Canadian County"),
  new County("OK", "Carter County"),
  new County("OK", "Cherokee County"),
  new County("OK", "Choctaw County"),
  new County("OK", "Cimarron County"),
  new County("OK", "Cleveland County"),
  new County("OK", "Coal County"),
  new County("OK", "Comanche County"),
  new County("OK", "Cotton County"),
  new County("OK", "Craig County"),
  new County("OK", "Creek County"),
  new County("OK", "Custer County"),
  new County("OK", "Delaware County"),
  new County("OK", "Dewey County"),
  new County("OK", "Ellis County"),
  new County("OK", "Garfield County"),
  new County("OK", "Garvin County"),
  new County("OK", "Grady County"),
  new County("OK", "Grant County"),
  new County("OK", "Greer County"),
  new County("OK", "Harmon County"),
  new County("OK", "Harper County"),
  new County("OK", "Haskell County"),
  new County("OK", "Hughes County"),
  new County("OK", "Jackson County"),
  new County("OK", "Jefferson County"),
  new County("OK", "Johnston County"),
  new County("OK", "Kay County"),
  new County("OK", "Kingfisher County"),
  new County("OK", "Kiowa County"),
  new County("OK", "Latimer County"),
  new County("OK", "Le Flore County"),
  new County("OK", "Lincoln County"),
  new County("OK", "Logan County"),
  new County("OK", "Love County"),
  new County("OK", "McClain County"),
  new County("OK", "McCurtain County"),
  new County("OK", "McIntosh County"),
  new County("OK", "Major County"),
  new County("OK", "Marshall County"),
  new County("OK", "Mayes County"),
  new County("OK", "Murray County"),
  new County("OK", "Muskogee County"),
  new County("OK", "Noble County"),
  new County("OK", "Nowata County"),
  new County("OK", "Okfuskee County"),
  new County("OK", "Oklahoma County"),
  new County("OK", "Okmulgee County"),
  new County("OK", "Osage County"),
  new County("OK", "Ottawa County"),
  new County("OK", "Pawnee County"),
  new County("OK", "Payne County"),
  new County("OK", "Pittsburg County"),
  new County("OK", "Pontotoc County"),
  new County("OK", "Pottawatomie County"),
  new County("OK", "Pushmataha County"),
  new County("OK", "Roger Mills County"),
  new County("OK", "Rogers County"),
  new County("OK", "Seminole County"),
  new County("OK", "Sequoyah County"),
  new County("OK", "Stephens County"),
  new County("OK", "Texas County"),
  new County("OK", "Tillman County"),
  new County("OK", "Tulsa County"),
  new County("OK", "Wagoner County"),
  new County("OK", "Washington County"),
  new County("OK", "Washita County"),
  new County("OK", "Woods County"),
  new County("OK", "Woodward County"),
  new County("OR", "Baker County"),
  new County("OR", "Benton County"),
  new County("OR", "Clackamas County"),
  new County("OR", "Clatsop County"),
  new County("OR", "Columbia County"),
  new County("OR", "Coos County"),
  new County("OR", "Crook County"),
  new County("OR", "Curry County"),
  new County("OR", "Deschutes County"),
  new County("OR", "Douglas County"),
  new County("OR", "Gilliam County"),
  new County("OR", "Grant County"),
  new County("OR", "Harney County"),
  new County("OR", "Hood River County"),
  new County("OR", "Jackson County"),
  new County("OR", "Jefferson County"),
  new County("OR", "Josephine County"),
  new County("OR", "Klamath County"),
  new County("OR", "Lake County"),
  new County("OR", "Lane County"),
  new County("OR", "Lincoln County"),
  new County("OR", "Linn County"),
  new County("OR", "Malheur County"),
  new County("OR", "Marion County"),
  new County("OR", "Morrow County"),
  new County("OR", "Multnomah County"),
  new County("OR", "Polk County"),
  new County("OR", "Sherman County"),
  new County("OR", "Tillamook County"),
  new County("OR", "Umatilla County"),
  new County("OR", "Union County"),
  new County("OR", "Wallowa County"),
  new County("OR", "Wasco County"),
  new County("OR", "Washington County"),
  new County("OR", "Wheeler County"),
  new County("OR", "Yamhill County"),
  new County("PA", "Adams County"),
  new County("PA", "Allegheny County"),
  new County("PA", "Armstrong County"),
  new County("PA", "Beaver County"),
  new County("PA", "Bedford County"),
  new County("PA", "Berks County"),
  new County("PA", "Blair County"),
  new County("PA", "Bradford County"),
  new County("PA", "Bucks County"),
  new County("PA", "Butler County"),
  new County("PA", "Cambria County"),
  new County("PA", "Cameron County"),
  new County("PA", "Carbon County"),
  new County("PA", "Centre County"),
  new County("PA", "Chester County"),
  new County("PA", "Clarion County"),
  new County("PA", "Clearfield County"),
  new County("PA", "Clinton County"),
  new County("PA", "Columbia County"),
  new County("PA", "Crawford County"),
  new County("PA", "Cumberland County"),
  new County("PA", "Dauphin County"),
  new County("PA", "Delaware County"),
  new County("PA", "Elk County"),
  new County("PA", "Erie County"),
  new County("PA", "Fayette County"),
  new County("PA", "Forest County"),
  new County("PA", "Franklin County"),
  new County("PA", "Fulton County"),
  new County("PA", "Greene County"),
  new County("PA", "Huntingdon County"),
  new County("PA", "Indiana County"),
  new County("PA", "Jefferson County"),
  new County("PA", "Juniata County"),
  new County("PA", "Lackawanna County"),
  new County("PA", "Lancaster County"),
  new County("PA", "Lawrence County"),
  new County("PA", "Lebanon County"),
  new County("PA", "Lehigh County"),
  new County("PA", "Luzerne County"),
  new County("PA", "Lycoming County"),
  new County("PA", "McKean County"),
  new County("PA", "Mercer County"),
  new County("PA", "Mifflin County"),
  new County("PA", "Monroe County"),
  new County("PA", "Montgomery County"),
  new County("PA", "Montour County"),
  new County("PA", "Northampton County"),
  new County("PA", "Northumberland County"),
  new County("PA", "Perry County"),
  new County("PA", "Philadelphia County"),
  new County("PA", "Pike County"),
  new County("PA", "Potter County"),
  new County("PA", "Schuylkill County"),
  new County("PA", "Snyder County"),
  new County("PA", "Somerset County"),
  new County("PA", "Sullivan County"),
  new County("PA", "Susquehanna County"),
  new County("PA", "Tioga County"),
  new County("PA", "Union County"),
  new County("PA", "Venango County"),
  new County("PA", "Warren County"),
  new County("PA", "Washington County"),
  new County("PA", "Wayne County"),
  new County("PA", "Westmoreland County"),
  new County("PA", "Wyoming County"),
  new County("PA", "York County"),
  new County("RI", "Bristol County"),
  new County("RI", "Kent County"),
  new County("RI", "Newport County"),
  new County("RI", "Providence County"),
  new County("RI", "Washington County"),
  new County("SC", "Abbeville County"),
  new County("SC", "Aiken County"),
  new County("SC", "Allendale County"),
  new County("SC", "Anderson County"),
  new County("SC", "Bamberg County"),
  new County("SC", "Barnwell County"),
  new County("SC", "Beaufort County"),
  new County("SC", "Berkeley County"),
  new County("SC", "Calhoun County"),
  new County("SC", "Charleston County"),
  new County("SC", "Cherokee County"),
  new County("SC", "Chester County"),
  new County("SC", "Chesterfield County"),
  new County("SC", "Clarendon County"),
  new County("SC", "Colleton County"),
  new County("SC", "Darlington County"),
  new County("SC", "Dillon County"),
  new County("SC", "Dorchester County"),
  new County("SC", "Edgefield County"),
  new County("SC", "Fairfield County"),
  new County("SC", "Florence County"),
  new County("SC", "Georgetown County"),
  new County("SC", "Greenville County"),
  new County("SC", "Greenwood County"),
  new County("SC", "Hampton County"),
  new County("SC", "Horry County"),
  new County("SC", "Jasper County"),
  new County("SC", "Kershaw County"),
  new County("SC", "Lancaster County"),
  new County("SC", "Laurens County"),
  new County("SC", "Lee County"),
  new County("SC", "Lexington County"),
  new County("SC", "McCormick County"),
  new County("SC", "Marion County"),
  new County("SC", "Marlboro County"),
  new County("SC", "Newberry County"),
  new County("SC", "Oconee County"),
  new County("SC", "Orangeburg County"),
  new County("SC", "Pickens County"),
  new County("SC", "Richland County"),
  new County("SC", "Saluda County"),
  new County("SC", "Spartanburg County"),
  new County("SC", "Sumter County"),
  new County("SC", "Union County"),
  new County("SC", "Williamsburg County"),
  new County("SC", "York County"),
  new County("SD", "Aurora County"),
  new County("SD", "Beadle County"),
  new County("SD", "Bennett County"),
  new County("SD", "Bon Homme County"),
  new County("SD", "Brookings County"),
  new County("SD", "Brown County"),
  new County("SD", "Brule County"),
  new County("SD", "Buffalo County"),
  new County("SD", "Butte County"),
  new County("SD", "Campbell County"),
  new County("SD", "Charles Mix County"),
  new County("SD", "Clark County"),
  new County("SD", "Clay County"),
  new County("SD", "Codington County"),
  new County("SD", "Corson County"),
  new County("SD", "Custer County"),
  new County("SD", "Davison County"),
  new County("SD", "Day County"),
  new County("SD", "Deuel County"),
  new County("SD", "Dewey County"),
  new County("SD", "Douglas County"),
  new County("SD", "Edmunds County"),
  new County("SD", "Fall River County"),
  new County("SD", "Faulk County"),
  new County("SD", "Grant County"),
  new County("SD", "Gregory County"),
  new County("SD", "Haakon County"),
  new County("SD", "Hamlin County"),
  new County("SD", "Hand County"),
  new County("SD", "Hanson County"),
  new County("SD", "Harding County"),
  new County("SD", "Hughes County"),
  new County("SD", "Hutchinson County"),
  new County("SD", "Hyde County"),
  new County("SD", "Jackson County"),
  new County("SD", "Jerauld County"),
  new County("SD", "Jones County"),
  new County("SD", "Kingsbury County"),
  new County("SD", "Lake County"),
  new County("SD", "Lawrence County"),
  new County("SD", "Lincoln County"),
  new County("SD", "Lyman County"),
  new County("SD", "McCook County"),
  new County("SD", "McPherson County"),
  new County("SD", "Marshall County"),
  new County("SD", "Meade County"),
  new County("SD", "Mellette County"),
  new County("SD", "Miner County"),
  new County("SD", "Minnehaha County"),
  new County("SD", "Moody County"),
  new County("SD", "Pennington County"),
  new County("SD", "Perkins County"),
  new County("SD", "Potter County"),
  new County("SD", "Roberts County"),
  new County("SD", "Sanborn County"),
  new County("SD", "Shannon County"),
  new County("SD", "Spink County"),
  new County("SD", "Stanley County"),
  new County("SD", "Sully County"),
  new County("SD", "Todd County"),
  new County("SD", "Tripp County"),
  new County("SD", "Turner County"),
  new County("SD", "Union County"),
  new County("SD", "Walworth County"),
  new County("SD", "Yankton County"),
  new County("SD", "Ziebach County"),
  new County("TN", "Anderson County"),
  new County("TN", "Bedford County"),
  new County("TN", "Benton County"),
  new County("TN", "Bledsoe County"),
  new County("TN", "Blount County"),
  new County("TN", "Bradley County"),
  new County("TN", "Campbell County"),
  new County("TN", "Cannon County"),
  new County("TN", "Carroll County"),
  new County("TN", "Carter County"),
  new County("TN", "Cheatham County"),
  new County("TN", "Chester County"),
  new County("TN", "Claiborne County"),
  new County("TN", "Clay County"),
  new County("TN", "Cocke County"),
  new County("TN", "Coffee County"),
  new County("TN", "Crockett County"),
  new County("TN", "Cumberland County"),
  new County("TN", "Davidson County"),
  new County("TN", "Decatur County"),
  new County("TN", "DeKalb County"),
  new County("TN", "Dickson County"),
  new County("TN", "Dyer County"),
  new County("TN", "Fayette County"),
  new County("TN", "Fentress County"),
  new County("TN", "Franklin County"),
  new County("TN", "Gibson County"),
  new County("TN", "Giles County"),
  new County("TN", "Grainger County"),
  new County("TN", "Greene County"),
  new County("TN", "Grundy County"),
  new County("TN", "Hamblen County"),
  new County("TN", "Hamilton County"),
  new County("TN", "Hancock County"),
  new County("TN", "Hardeman County"),
  new County("TN", "Hardin County"),
  new County("TN", "Hawkins County"),
  new County("TN", "Haywood County"),
  new County("TN", "Henderson County"),
  new County("TN", "Henry County"),
  new County("TN", "Hickman County"),
  new County("TN", "Houston County"),
  new County("TN", "Humphreys County"),
  new County("TN", "Jackson County"),
  new County("TN", "Jefferson County"),
  new County("TN", "Johnson County"),
  new County("TN", "Knox County"),
  new County("TN", "Lake County"),
  new County("TN", "Lauderdale County"),
  new County("TN", "Lawrence County"),
  new County("TN", "Lewis County"),
  new County("TN", "Lincoln County"),
  new County("TN", "Loudon County"),
  new County("TN", "McMinn County"),
  new County("TN", "McNairy County"),
  new County("TN", "Macon County"),
  new County("TN", "Madison County"),
  new County("TN", "Marion County"),
  new County("TN", "Marshall County"),
  new County("TN", "Maury County"),
  new County("TN", "Meigs County"),
  new County("TN", "Monroe County"),
  new County("TN", "Montgomery County"),
  new County("TN", "Moore County"),
  new County("TN", "Morgan County"),
  new County("TN", "Obion County"),
  new County("TN", "Overton County"),
  new County("TN", "Perry County"),
  new County("TN", "Pickett County"),
  new County("TN", "Polk County"),
  new County("TN", "Putnam County"),
  new County("TN", "Rhea County"),
  new County("TN", "Roane County"),
  new County("TN", "Robertson County"),
  new County("TN", "Rutherford County"),
  new County("TN", "Scott County"),
  new County("TN", "Sequatchie County"),
  new County("TN", "Sevier County"),
  new County("TN", "Shelby County"),
  new County("TN", "Smith County"),
  new County("TN", "Stewart County"),
  new County("TN", "Sullivan County"),
  new County("TN", "Sumner County"),
  new County("TN", "Tipton County"),
  new County("TN", "Trousdale County"),
  new County("TN", "Unicoi County"),
  new County("TN", "Union County"),
  new County("TN", "Van Buren County"),
  new County("TN", "Warren County"),
  new County("TN", "Washington County"),
  new County("TN", "Wayne County"),
  new County("TN", "Weakley County"),
  new County("TN", "White County"),
  new County("TN", "Williamson County"),
  new County("TN", "Wilson County"),
  new County("TX", "Anderson County"),
  new County("TX", "Andrews County"),
  new County("TX", "Angelina County"),
  new County("TX", "Aransas County"),
  new County("TX", "Archer County"),
  new County("TX", "Armstrong County"),
  new County("TX", "Atascosa County"),
  new County("TX", "Austin County"),
  new County("TX", "Bailey County"),
  new County("TX", "Bandera County"),
  new County("TX", "Bastrop County"),
  new County("TX", "Baylor County"),
  new County("TX", "Bee County"),
  new County("TX", "Bell County"),
  new County("TX", "Bexar County"),
  new County("TX", "Blanco County"),
  new County("TX", "Borden County"),
  new County("TX", "Bosque County"),
  new County("TX", "Bowie County"),
  new County("TX", "Brazoria County"),
  new County("TX", "Brazos County"),
  new County("TX", "Brewster County"),
  new County("TX", "Briscoe County"),
  new County("TX", "Brooks County"),
  new County("TX", "Brown County"),
  new County("TX", "Burleson County"),
  new County("TX", "Burnet County"),
  new County("TX", "Caldwell County"),
  new County("TX", "Calhoun County"),
  new County("TX", "Callahan County"),
  new County("TX", "Cameron County"),
  new County("TX", "Camp County"),
  new County("TX", "Carson County"),
  new County("TX", "Cass County"),
  new County("TX", "Castro County"),
  new County("TX", "Chambers County"),
  new County("TX", "Cherokee County"),
  new County("TX", "Childress County"),
  new County("TX", "Clay County"),
  new County("TX", "Cochran County"),
  new County("TX", "Coke County"),
  new County("TX", "Coleman County"),
  new County("TX", "Collin County"),
  new County("TX", "Collingsworth County"),
  new County("TX", "Colorado County"),
  new County("TX", "Comal County"),
  new County("TX", "Comanche County"),
  new County("TX", "Concho County"),
  new County("TX", "Cooke County"),
  new County("TX", "Coryell County"),
  new County("TX", "Cottle County"),
  new County("TX", "Crane County"),
  new County("TX", "Crockett County"),
  new County("TX", "Crosby County"),
  new County("TX", "Culberson County"),
  new County("TX", "Dallam County"),
  new County("TX", "Dallas County"),
  new County("TX", "Dawson County"),
  new County("TX", "Deaf Smith County"),
  new County("TX", "Delta County"),
  new County("TX", "Denton County"),
  new County("TX", "DeWitt County"),
  new County("TX", "Dickens County"),
  new County("TX", "Dimmit County"),
  new County("TX", "Donley County"),
  new County("TX", "Duval County"),
  new County("TX", "Eastland County"),
  new County("TX", "Ector County"),
  new County("TX", "Edwards County"),
  new County("TX", "Ellis County"),
  new County("TX", "El Paso County"),
  new County("TX", "Erath County"),
  new County("TX", "Falls County"),
  new County("TX", "Fannin County"),
  new County("TX", "Fayette County"),
  new County("TX", "Fisher County"),
  new County("TX", "Floyd County"),
  new County("TX", "Foard County"),
  new County("TX", "Fort Bend County"),
  new County("TX", "Franklin County"),
  new County("TX", "Freestone County"),
  new County("TX", "Frio County"),
  new County("TX", "Gaines County"),
  new County("TX", "Galveston County"),
  new County("TX", "Garza County"),
  new County("TX", "Gillespie County"),
  new County("TX", "Glasscock County"),
  new County("TX", "Goliad County"),
  new County("TX", "Gonzales County"),
  new County("TX", "Gray County"),
  new County("TX", "Grayson County"),
  new County("TX", "Gregg County"),
  new County("TX", "Grimes County"),
  new County("TX", "Guadalupe County"),
  new County("TX", "Hale County"),
  new County("TX", "Hall County"),
  new County("TX", "Hamilton County"),
  new County("TX", "Hansford County"),
  new County("TX", "Hardeman County"),
  new County("TX", "Hardin County"),
  new County("TX", "Harris County"),
  new County("TX", "Harrison County"),
  new County("TX", "Hartley County"),
  new County("TX", "Haskell County"),
  new County("TX", "Hays County"),
  new County("TX", "Hemphill County"),
  new County("TX", "Henderson County"),
  new County("TX", "Hidalgo County"),
  new County("TX", "Hill County"),
  new County("TX", "Hockley County"),
  new County("TX", "Hood County"),
  new County("TX", "Hopkins County"),
  new County("TX", "Houston County"),
  new County("TX", "Howard County"),
  new County("TX", "Hudspeth County"),
  new County("TX", "Hunt County"),
  new County("TX", "Hutchinson County"),
  new County("TX", "Irion County"),
  new County("TX", "Jack County"),
  new County("TX", "Jackson County"),
  new County("TX", "Jasper County"),
  new County("TX", "Jeff Davis County"),
  new County("TX", "Jefferson County"),
  new County("TX", "Jim Hogg County"),
  new County("TX", "Jim Wells County"),
  new County("TX", "Johnson County"),
  new County("TX", "Jones County"),
  new County("TX", "Karnes County"),
  new County("TX", "Kaufman County"),
  new County("TX", "Kendall County"),
  new County("TX", "Kenedy County"),
  new County("TX", "Kent County"),
  new County("TX", "Kerr County"),
  new County("TX", "Kimble County"),
  new County("TX", "King County"),
  new County("TX", "Kinney County"),
  new County("TX", "Kleberg County"),
  new County("TX", "Knox County"),
  new County("TX", "Lamar County"),
  new County("TX", "Lamb County"),
  new County("TX", "Lampasas County"),
  new County("TX", "La Salle County"),
  new County("TX", "Lavaca County"),
  new County("TX", "Lee County"),
  new County("TX", "Leon County"),
  new County("TX", "Liberty County"),
  new County("TX", "Limestone County"),
  new County("TX", "Lipscomb County"),
  new County("TX", "Live Oak County"),
  new County("TX", "Llano County"),
  new County("TX", "Loving County"),
  new County("TX", "Lubbock County"),
  new County("TX", "Lynn County"),
  new County("TX", "McCulloch County"),
  new County("TX", "McLennan County"),
  new County("TX", "McMullen County"),
  new County("TX", "Madison County"),
  new County("TX", "Marion County"),
  new County("TX", "Martin County"),
  new County("TX", "Mason County"),
  new County("TX", "Matagorda County"),
  new County("TX", "Maverick County"),
  new County("TX", "Medina County"),
  new County("TX", "Menard County"),
  new County("TX", "Midland County"),
  new County("TX", "Milam County"),
  new County("TX", "Mills County"),
  new County("TX", "Mitchell County"),
  new County("TX", "Montague County"),
  new County("TX", "Montgomery County"),
  new County("TX", "Moore County"),
  new County("TX", "Morris County"),
  new County("TX", "Motley County"),
  new County("TX", "Nacogdoches County"),
  new County("TX", "Navarro County"),
  new County("TX", "Newton County"),
  new County("TX", "Nolan County"),
  new County("TX", "Nueces County"),
  new County("TX", "Ochiltree County"),
  new County("TX", "Oldham County"),
  new County("TX", "Orange County"),
  new County("TX", "Palo Pinto County"),
  new County("TX", "Panola County"),
  new County("TX", "Parker County"),
  new County("TX", "Parmer County"),
  new County("TX", "Pecos County"),
  new County("TX", "Polk County"),
  new County("TX", "Potter County"),
  new County("TX", "Presidio County"),
  new County("TX", "Rains County"),
  new County("TX", "Randall County"),
  new County("TX", "Reagan County"),
  new County("TX", "Real County"),
  new County("TX", "Red River County"),
  new County("TX", "Reeves County"),
  new County("TX", "Refugio County"),
  new County("TX", "Roberts County"),
  new County("TX", "Robertson County"),
  new County("TX", "Rockwall County"),
  new County("TX", "Runnels County"),
  new County("TX", "Rusk County"),
  new County("TX", "Sabine County"),
  new County("TX", "San Augustine County"),
  new County("TX", "San Jacinto County"),
  new County("TX", "San Patricio County"),
  new County("TX", "San Saba County"),
  new County("TX", "Schleicher County"),
  new County("TX", "Scurry County"),
  new County("TX", "Shackelford County"),
  new County("TX", "Shelby County"),
  new County("TX", "Sherman County"),
  new County("TX", "Smith County"),
  new County("TX", "Somervell County"),
  new County("TX", "Starr County"),
  new County("TX", "Stephens County"),
  new County("TX", "Sterling County"),
  new County("TX", "Stonewall County"),
  new County("TX", "Sutton County"),
  new County("TX", "Swisher County"),
  new County("TX", "Tarrant County"),
  new County("TX", "Taylor County"),
  new County("TX", "Terrell County"),
  new County("TX", "Terry County"),
  new County("TX", "Throckmorton County"),
  new County("TX", "Titus County"),
  new County("TX", "Tom Green County"),
  new County("TX", "Travis County"),
  new County("TX", "Trinity County"),
  new County("TX", "Tyler County"),
  new County("TX", "Upshur County"),
  new County("TX", "Upton County"),
  new County("TX", "Uvalde County"),
  new County("TX", "Val Verde County"),
  new County("TX", "Van Zandt County"),
  new County("TX", "Victoria County"),
  new County("TX", "Walker County"),
  new County("TX", "Waller County"),
  new County("TX", "Ward County"),
  new County("TX", "Washington County"),
  new County("TX", "Webb County"),
  new County("TX", "Wharton County"),
  new County("TX", "Wheeler County"),
  new County("TX", "Wichita County"),
  new County("TX", "Wilbarger County"),
  new County("TX", "Willacy County"),
  new County("TX", "Williamson County"),
  new County("TX", "Wilson County"),
  new County("TX", "Winkler County"),
  new County("TX", "Wise County"),
  new County("TX", "Wood County"),
  new County("TX", "Yoakum County"),
  new County("TX", "Young County"),
  new County("TX", "Zapata County"),
  new County("TX", "Zavala County"),
  new County("UT", "Beaver County"),
  new County("UT", "Box Elder County"),
  new County("UT", "Cache County"),
  new County("UT", "Carbon County"),
  new County("UT", "Daggett County"),
  new County("UT", "Davis County"),
  new County("UT", "Duchesne County"),
  new County("UT", "Emery County"),
  new County("UT", "Garfield County"),
  new County("UT", "Grand County"),
  new County("UT", "Iron County"),
  new County("UT", "Juab County"),
  new County("UT", "Kane County"),
  new County("UT", "Millard County"),
  new County("UT", "Morgan County"),
  new County("UT", "Piute County"),
  new County("UT", "Rich County"),
  new County("UT", "Salt Lake County"),
  new County("UT", "San Juan County"),
  new County("UT", "Sanpete County"),
  new County("UT", "Sevier County"),
  new County("UT", "Summit County"),
  new County("UT", "Tooele County"),
  new County("UT", "Uintah County"),
  new County("UT", "Utah County"),
  new County("UT", "Wasatch County"),
  new County("UT", "Washington County"),
  new County("UT", "Wayne County"),
  new County("UT", "Weber County"),
  new County("VT", "Addison County"),
  new County("VT", "Bennington County"),
  new County("VT", "Caledonia County"),
  new County("VT", "Chittenden County"),
  new County("VT", "Essex County"),
  new County("VT", "Franklin County"),
  new County("VT", "Grand Isle County"),
  new County("VT", "Lamoille County"),
  new County("VT", "Orange County"),
  new County("VT", "Orleans County"),
  new County("VT", "Rutland County"),
  new County("VT", "Washington County"),
  new County("VT", "Windham County"),
  new County("VT", "Windsor County"),
  new County("VA", "Accomack County"),
  new County("VA", "Albemarle County"),
  new County("VA", "Alleghany County"),
  new County("VA", "Amelia County"),
  new County("VA", "Amherst County"),
  new County("VA", "Appomattox County"),
  new County("VA", "Arlington County"),
  new County("VA", "Augusta County"),
  new County("VA", "Bath County"),
  new County("VA", "Bedford County"),
  new County("VA", "Bland County"),
  new County("VA", "Botetourt County"),
  new County("VA", "Brunswick County"),
  new County("VA", "Buchanan County"),
  new County("VA", "Buckingham County"),
  new County("VA", "Campbell County"),
  new County("VA", "Caroline County"),
  new County("VA", "Carroll County"),
  new County("VA", "Charles City County"),
  new County("VA", "Charlotte County"),
  new County("VA", "Chesterfield County"),
  new County("VA", "Clarke County"),
  new County("VA", "Craig County"),
  new County("VA", "Culpeper County"),
  new County("VA", "Cumberland County"),
  new County("VA", "Dickenson County"),
  new County("VA", "Dinwiddie County"),
  new County("VA", "Essex County"),
  new County("VA", "Fairfax County"),
  new County("VA", "Fauquier County"),
  new County("VA", "Floyd County"),
  new County("VA", "Fluvanna County"),
  new County("VA", "Franklin County"),
  new County("VA", "Frederick County"),
  new County("VA", "Giles County"),
  new County("VA", "Gloucester County"),
  new County("VA", "Goochland County"),
  new County("VA", "Grayson County"),
  new County("VA", "Greene County"),
  new County("VA", "Greensville County"),
  new County("VA", "Halifax County"),
  new County("VA", "Hanover County"),
  new County("VA", "Henrico County"),
  new County("VA", "Henry County"),
  new County("VA", "Highland County"),
  new County("VA", "Isle of Wight County"),
  new County("VA", "James City County"),
  new County("VA", "King and Queen County"),
  new County("VA", "King George County"),
  new County("VA", "King William County"),
  new County("VA", "Lancaster County"),
  new County("VA", "Lee County"),
  new County("VA", "Loudoun County"),
  new County("VA", "Louisa County"),
  new County("VA", "Lunenburg County"),
  new County("VA", "Madison County"),
  new County("VA", "Mathews County"),
  new County("VA", "Mecklenburg County"),
  new County("VA", "Middlesex County"),
  new County("VA", "Montgomery County"),
  new County("VA", "Nelson County"),
  new County("VA", "New Kent County"),
  new County("VA", "Northampton County"),
  new County("VA", "Northumberland County"),
  new County("VA", "Nottoway County"),
  new County("VA", "Orange County"),
  new County("VA", "Page County"),
  new County("VA", "Patrick County"),
  new County("VA", "Pittsylvania County"),
  new County("VA", "Powhatan County"),
  new County("VA", "Prince Edward County"),
  new County("VA", "Prince George County"),
  new County("VA", "Prince William County"),
  new County("VA", "Pulaski County"),
  new County("VA", "Rappahannock County"),
  new County("VA", "Richmond County"),
  new County("VA", "Roanoke County"),
  new County("VA", "Rockbridge County"),
  new County("VA", "Rockingham County"),
  new County("VA", "Russell County"),
  new County("VA", "Scott County"),
  new County("VA", "Shenandoah County"),
  new County("VA", "Smyth County"),
  new County("VA", "Southampton County"),
  new County("VA", "Spotsylvania County"),
  new County("VA", "Stafford County"),
  new County("VA", "Surry County"),
  new County("VA", "Sussex County"),
  new County("VA", "Tazewell County"),
  new County("VA", "Warren County"),
  new County("VA", "Washington County"),
  new County("VA", "Westmoreland County"),
  new County("VA", "Wise County"),
  new County("VA", "Wythe County"),
  new County("VA", "York County"),
  new County("VA", "Alexandria city"),
  new County("VA", "Bedford city"),
  new County("VA", "Bristol city"),
  new County("VA", "Buena Vista city"),
  new County("VA", "Charlottesville city"),
  new County("VA", "Chesapeake city"),
  new County("VA", "Colonial Heights city"),
  new County("VA", "Covington city"),
  new County("VA", "Danville city"),
  new County("VA", "Emporia city"),
  new County("VA", "Fairfax city"),
  new County("VA", "Falls Church city"),
  new County("VA", "Franklin city"),
  new County("VA", "Fredericksburg city"),
  new County("VA", "Galax city"),
  new County("VA", "Hampton city"),
  new County("VA", "Harrisonburg city"),
  new County("VA", "Hopewell city"),
  new County("VA", "Lexington city"),
  new County("VA", "Lynchburg city"),
  new County("VA", "Manassas city"),
  new County("VA", "Manassas Park city"),
  new County("VA", "Martinsville city"),
  new County("VA", "Newport News city"),
  new County("VA", "Norfolk city"),
  new County("VA", "Norton city"),
  new County("VA", "Petersburg city"),
  new County("VA", "Poquoson city"),
  new County("VA", "Portsmouth city"),
  new County("VA", "Radford city"),
  new County("VA", "Richmond city"),
  new County("VA", "Roanoke city"),
  new County("VA", "Salem city"),
  new County("VA", "Staunton city"),
  new County("VA", "Suffolk city"),
  new County("VA", "Virginia Beach city"),
  new County("VA", "Waynesboro city"),
  new County("VA", "Williamsburg city"),
  new County("VA", "Winchester city"),
  new County("WA", "Adams County"),
  new County("WA", "Asotin County"),
  new County("WA", "Benton County"),
  new County("WA", "Chelan County"),
  new County("WA", "Clallam County"),
  new County("WA", "Clark County"),
  new County("WA", "Columbia County"),
  new County("WA", "Cowlitz County"),
  new County("WA", "Douglas County"),
  new County("WA", "Ferry County"),
  new County("WA", "Franklin County"),
  new County("WA", "Garfield County"),
  new County("WA", "Grant County"),
  new County("WA", "Grays Harbor County"),
  new County("WA", "Island County"),
  new County("WA", "Jefferson County"),
  new County("WA", "King County"),
  new County("WA", "Kitsap County"),
  new County("WA", "Kittitas County"),
  new County("WA", "Klickitat County"),
  new County("WA", "Lewis County"),
  new County("WA", "Lincoln County"),
  new County("WA", "Mason County"),
  new County("WA", "Okanogan County"),
  new County("WA", "Pacific County"),
  new County("WA", "Pend Oreille County"),
  new County("WA", "Pierce County"),
  new County("WA", "San Juan County"),
  new County("WA", "Skagit County"),
  new County("WA", "Skamania County"),
  new County("WA", "Snohomish County"),
  new County("WA", "Spokane County"),
  new County("WA", "Stevens County"),
  new County("WA", "Thurston County"),
  new County("WA", "Wahkiakum County"),
  new County("WA", "Walla Walla County"),
  new County("WA", "Whatcom County"),
  new County("WA", "Whitman County"),
  new County("WA", "Yakima County"),
  new County("WV", "Barbour County"),
  new County("WV", "Berkeley County"),
  new County("WV", "Boone County"),
  new County("WV", "Braxton County"),
  new County("WV", "Brooke County"),
  new County("WV", "Cabell County"),
  new County("WV", "Calhoun County"),
  new County("WV", "Clay County"),
  new County("WV", "Doddridge County"),
  new County("WV", "Fayette County"),
  new County("WV", "Gilmer County"),
  new County("WV", "Grant County"),
  new County("WV", "Greenbrier County"),
  new County("WV", "Hampshire County"),
  new County("WV", "Hancock County"),
  new County("WV", "Hardy County"),
  new County("WV", "Harrison County"),
  new County("WV", "Jackson County"),
  new County("WV", "Jefferson County"),
  new County("WV", "Kanawha County"),
  new County("WV", "Lewis County"),
  new County("WV", "Lincoln County"),
  new County("WV", "Logan County"),
  new County("WV", "McDowell County"),
  new County("WV", "Marion County"),
  new County("WV", "Marshall County"),
  new County("WV", "Mason County"),
  new County("WV", "Mercer County"),
  new County("WV", "Mineral County"),
  new County("WV", "Mingo County"),
  new County("WV", "Monongalia County"),
  new County("WV", "Monroe County"),
  new County("WV", "Morgan County"),
  new County("WV", "Nicholas County"),
  new County("WV", "Ohio County"),
  new County("WV", "Pendleton County"),
  new County("WV", "Pleasants County"),
  new County("WV", "Pocahontas County"),
  new County("WV", "Preston County"),
  new County("WV", "Putnam County"),
  new County("WV", "Raleigh County"),
  new County("WV", "Randolph County"),
  new County("WV", "Ritchie County"),
  new County("WV", "Roane County"),
  new County("WV", "Summers County"),
  new County("WV", "Taylor County"),
  new County("WV", "Tucker County"),
  new County("WV", "Tyler County"),
  new County("WV", "Upshur County"),
  new County("WV", "Wayne County"),
  new County("WV", "Webster County"),
  new County("WV", "Wetzel County"),
  new County("WV", "Wirt County"),
  new County("WV", "Wood County"),
  new County("WV", "Wyoming County"),
  new County("WI", "Adams County"),
  new County("WI", "Ashland County"),
  new County("WI", "Barron County"),
  new County("WI", "Bayfield County"),
  new County("WI", "Brown County"),
  new County("WI", "Buffalo County"),
  new County("WI", "Burnett County"),
  new County("WI", "Calumet County"),
  new County("WI", "Chippewa County"),
  new County("WI", "Clark County"),
  new County("WI", "Columbia County"),
  new County("WI", "Crawford County"),
  new County("WI", "Dane County"),
  new County("WI", "Dodge County"),
  new County("WI", "Door County"),
  new County("WI", "Douglas County"),
  new County("WI", "Dunn County"),
  new County("WI", "Eau Claire County"),
  new County("WI", "Florence County"),
  new County("WI", "Fond du Lac County"),
  new County("WI", "Forest County"),
  new County("WI", "Grant County"),
  new County("WI", "Green County"),
  new County("WI", "Green Lake County"),
  new County("WI", "Iowa County"),
  new County("WI", "Iron County"),
  new County("WI", "Jackson County"),
  new County("WI", "Jefferson County"),
  new County("WI", "Juneau County"),
  new County("WI", "Kenosha County"),
  new County("WI", "Kewaunee County"),
  new County("WI", "La Crosse County"),
  new County("WI", "Lafayette County"),
  new County("WI", "Langlade County"),
  new County("WI", "Lincoln County"),
  new County("WI", "Manitowoc County"),
  new County("WI", "Marathon County"),
  new County("WI", "Marinette County"),
  new County("WI", "Marquette County"),
  new County("WI", "Menominee County"),
  new County("WI", "Milwaukee County"),
  new County("WI", "Monroe County"),
  new County("WI", "Oconto County"),
  new County("WI", "Oneida County"),
  new County("WI", "Outagamie County"),
  new County("WI", "Ozaukee County"),
  new County("WI", "Pepin County"),
  new County("WI", "Pierce County"),
  new County("WI", "Polk County"),
  new County("WI", "Portage County"),
  new County("WI", "Price County"),
  new County("WI", "Racine County"),
  new County("WI", "Richland County"),
  new County("WI", "Rock County"),
  new County("WI", "Rusk County"),
  new County("WI", "St. Croix County"),
  new County("WI", "Sauk County"),
  new County("WI", "Sawyer County"),
  new County("WI", "Shawano County"),
  new County("WI", "Sheboygan County"),
  new County("WI", "Taylor County"),
  new County("WI", "Trempealeau County"),
  new County("WI", "Vernon County"),
  new County("WI", "Vilas County"),
  new County("WI", "Walworth County"),
  new County("WI", "Washburn County"),
  new County("WI", "Washington County"),
  new County("WI", "Waukesha County"),
  new County("WI", "Waupaca County"),
  new County("WI", "Waushara County"),
  new County("WI", "Winnebago County"),
  new County("WI", "Wood County"),
  new County("WY", "Albany County"),
  new County("WY", "Big Horn County"),
  new County("WY", "Campbell County"),
  new County("WY", "Carbon County"),
  new County("WY", "Converse County"),
  new County("WY", "Crook County"),
  new County("WY", "Fremont County"),
  new County("WY", "Goshen County"),
  new County("WY", "Hot Springs County"),
  new County("WY", "Johnson County"),
  new County("WY", "Laramie County"),
  new County("WY", "Lincoln County"),
  new County("WY", "Natrona County"),
  new County("WY", "Niobrara County"),
  new County("WY", "Park County"),
  new County("WY", "Platte County"),
  new County("WY", "Sheridan County"),
  new County("WY", "Sublette County"),
  new County("WY", "Sweetwater County"),
  new County("WY", "Teton County"),
  new County("WY", "Uinta County"),
  new County("WY", "Washakie County"),
  new County("WY", "Weston County")
);
