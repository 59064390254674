import * as React from "react";

function SelfCert(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 3a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V5a2 2 0 012-2h16zm-7 3H4v10h9V6zm7 8h-5v2h5v-2zM8.5 12c.83 0 2.5.38 2.5 1.14V14H6v-.86C6 12.38 7.67 12 8.5 12zM20 10h-5v2h5v-2zM8.5 8a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM20 6h-5v2h5V6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SelfCert;
