import styles from "./FilterFlyoutBar.module.scss";

// components
import { RoundedButton } from "components/LibraryComponents/Components";
import {
  LocationFilterButton,
  NaicsFilterButton,
  SbaFilterButton,
  SelfFilterButton,
  KeywordFilterButton,
  LastUpdatedFilterButton,
  MoreFilterButton,
} from "components/FilterButtons";

// store
import useFiltersStore from "store/useFiltersStoreNew";

export default function FilterFlyoutBar() {
  const { areFiltersApplied, clearFilters } = useFiltersStore((state) => ({
    areFiltersApplied: state.areFiltersApplied(),
    clearFilters: state.clearFilters,
  }));

  return (
    <div className={styles["filter-flyout-bar-root"]}>
      <LocationFilterButton />
      <SbaFilterButton />
      <NaicsFilterButton />
      <KeywordFilterButton />
      <SelfFilterButton />
      <LastUpdatedFilterButton />
      <MoreFilterButton />
      <RoundedButton
        variant="ghost"
        disabled={!areFiltersApplied}
        onClick={clearFilters}
      >
        Clear filters
      </RoundedButton>
    </div>
  );
}
