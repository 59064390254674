export type ProfileParam = {
  profile?: {
    name?: string;
    uei?: string;
    parent_uei?: string;
    associated_ueis?: string[];
    pronet_id?: string;
    last_updated?: string;
    contact_person?: string;
    capabilities_narrative?: string;
    capabilities_narrative_link?: string;
    email?: string;
    phone?: string;
    fax?: string;
    address?: string;
    trade_name?: string;
    cage_code?: string[];
    website?: string;
    country_code?: string;
    accepts_government_credit_card: string;
    congressional_district?: string;
    gsa_contract?: string;
    metropolitan_area?: string;
    user_id?: string; // This is the same as the pronet_id
  };
  organization_ownership?: {
    legal_structure?: string;
    current_principals?: string[];
    bd_servicing_office?: string;
  };
  certifications?: {
    sba_certifications?: {
      // _comment?: string;
      "8a"?: {
        status?: string;
        date?: string;
        graduation_date?: string;
        graduation_status?: string;
      };
    };
    ownership_certifications?: string[];
    non_federal_certifications?: string[];
  };
  naics?: {
    code?: string;
    description?: string[];
    buy_green?: boolean;
    small?: boolean;
  }[];
  service_information?: {
    special_equipment?: string;
    business_percentages?: string[];
    bonding_levels?: {
      construction_per_contract?: string;
      construction_aggregate?: string;
      service_per_contract?: string;
      service_aggregate?: string;
    };
    keywords?: string;
    miscellaneous?: string;
    quality_assurance?: string[];
    electronic_data?: string;
  };
  export_profile?: {
    status?: string;
    business_activities?: string[];
    exporting_to?: string[];
    desired_export?: string;
    description_export?: string[];
  };
  performance_history?: {
    name?: string;
    contract?: string;
    value?: string;
    start_date?: string;
    end_date?: string;
    contact?: string;
    phone?: string;
  }[];
};

export type ResultsParam = {
  UEI: string;
  NAICS: string;
  PSC: string;
  state: string;
  keyword: string;
  size: string;
  bondingLevel: string;
  qualityAssuranceStandards: string;
  natureOfBusiness: string;
  profileStatus: string;
  location: string;
  sbacertifications: string;
  ownershipAndSelfCertifications: string;
  capabilityKeywords: string;
  privilegedSearch: string;
}[];

/**
 *
 * @returns Business profile data from DSBS
 */
export async function getProfile<ProfileParam>(pronetId): Promise<any> {
  // const proId = params.queryKey[0];
  try {
    const response = await fetch(`/_api/firms/${pronetId}`);
    if (!response.ok) {
      throw new Error("Failed to fetch profile data");
    }
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getResults<ResultsParam>(params): Promise<any> {
  try {
    const response = await fetch("/_api/firms", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch results data");
    }

    const data = await response.json();
    return data as any[];
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getBusinessResults(params): Promise<any> {
  try {
    const response = await fetch("/_api/getbusiness", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch results data");
    }

    const data = await response.json();
    console.log("=======158***", data);
    return data as any;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getQuickResults(): Promise<any> {
  try {
    const response = await fetch("/_api/quick-results");
    if (!response.ok) {
      throw new Error("Failed to fetch quick results data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getTotalActiveBusinesses(): Promise<any> {
  try {
    const response = await fetch("/_api/total_active");
    if (!response.ok) {
      throw new Error("Failed to fetch quick results data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function sendFeedback(feedbackData: any): Promise<any> {
  try {
    const response = await fetch("/_api/feedback/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(feedbackData),
    });

    if (!response.ok) {
      throw new Error("Failed to send feedback.");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getbusinessByUEI(params): Promise<any> {
  try {
    const response = await fetch("/_api/getbusinessByUEI", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch results data");
    }

    const data = await response.json();
    return data as any;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

