import { useQuery } from "@tanstack/react-query";

// types
import { BusinessItem } from "types/results-panel.types";

// store
import useFiltersStore from "store/useFiltersStoreNew";

// utils
import { fetchAllFilterData, refineFilterOptions } from "utils/advanced-search";
import { formatUpdateAtDate } from "utils/helper";

export default function useResultQuery() {
  const { filters } = useFiltersStore((state) => ({
    filters: state.filters,
  }));

  const refinedOptions = refineFilterOptions(filters);
  // console.log("filter options", refinedOptions);

  const {
    isLoading,
    error,
    data,
    refetch,
    dataUpdatedAt,
    isSuccess
  } = useQuery({
    queryKey: ["results", refinedOptions],

    queryFn: () => fetchAllFilterData(refinedOptions),

    enabled: refinedOptions.length > 0,
    staleTime: 1000 * 60,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const refactorData: BusinessItem[] =
    data?.results?.map((result) => ({
      ...result,
      pronetID: result.pronet_id,
      businessName: result.name,
      business_trade_name: result.business_trade_name,
      capabilitiesNarrative: result.capabilities,
      capabilitiesStatement: result.capabilities_link,
      active_certifications: result.active_certifications || [],
      contact: result.contact,
      email: result.email,
      address: result.address,
      ein: result.ein,
      uei: result.uei,
      last_updated: result.last_updated,
    })) ?? [];

  return {
    data: refactorData,
    totalCount: data?.total?.toLocaleString("en-US") ?? 0,
    totalPages: data?.totalPages ?? 0,
    isLoading,
    isError: Boolean(error),
    updateAt: isSuccess
      ? formatUpdateAtDate(dataUpdatedAt)
      : null,
    refetch,
  };
}
