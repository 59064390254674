import { create } from "zustand";
import { persist } from "zustand/middleware";

export type GenericFilter = {
  isApplied: boolean;
  [key: string]: any;
  label?: string;
  value?: string;
};

type QualityAssuranceStandards = {
  standards: GenericFilter[];
  isRefelected: boolean;
};

type NatureOfBusiness = {
  business: GenericFilter[];
  isRefelected: boolean;
};

type Level = {
  construction_individual: GenericFilter;
  construction_aggregate: GenericFilter;
  service_individual: GenericFilter;
  service_aggregate: GenericFilter;
};

type BondingLevel = {
  level: Level;
  isRefelected: boolean;
};

type PrivilegedSearch = {
  min_employee: GenericFilter;
  max_employee: GenericFilter;
  min_revenue: GenericFilter;
  max_revenue: GenericFilter;
  ein: GenericFilter;
};

type ProfileStatus = {
  lastUpdatedOn: GenericFilter;
  status: GenericFilter;
  isRefelected: boolean;
};

type CapabilityKeywords = {
  keywords: GenericFilter[];
  keywordRelated: GenericFilter;
  isRefelected: boolean;
};

type SmallBusinessProfile = GenericFilter[];

type Location = {
  states: GenericFilter[];
  isRefelected: boolean;
};

type SBACertifications = {
  activeCertification: GenericFilter[];
  previousCertification: GenericFilter[];
  certificationRelate: GenericFilter;
  isRefelected: boolean;
};

type OwnershipAndSelfCertifications = {
  selfCertifications: GenericFilter[];
  selfCertifiedWomanOwned: GenericFilter[];
  anyMinorityOwned: {
    otherMinorityOwned: GenericFilter;
    anyNativeAmericanOwner: GenericFilter[];
  };
  matchType: GenericFilter;
  isRefelected: boolean;
};

export type NAICS = {
  [codeNumber: string]: any;
  code: string;
  id?: string;
  ids: string[];
  name: string;
  isApplied: boolean;
};

type Capabilities = {
  naics: NAICS[];
  matchType: GenericFilter;
  matchlimit: any;
  isRefelected: boolean;
};

type Filters = {
  qualityAssuranceStandards: QualityAssuranceStandards;
  natureOfBusiness: NatureOfBusiness;
  bondingLevels: BondingLevel | null;
  profileStatus: ProfileStatus;
  privilegedSearch: PrivilegedSearch;
  capabilityKeywords: CapabilityKeywords;
  smallBusinessProfile: SmallBusinessProfile;
  location: Location;
  sbaCertifications: SBACertifications;
  ownershipSelfCerts: OwnershipAndSelfCertifications;
  capabilities: Capabilities;
  searchBusiness: GenericFilter
};
const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    console.log(e);
  }
};


const initialData: Filters = {
  qualityAssuranceStandards: {
    standards: [],
    isRefelected: false,
  },
  natureOfBusiness: {
    business: [],
    isRefelected: false,
  },
  bondingLevels: {
    level: {} as Level,
    isRefelected: false,
  },
  profileStatus: {
    lastUpdatedOn: {} as GenericFilter,
    status: {} as GenericFilter,
    isRefelected: false,
  },
  privilegedSearch: {} as PrivilegedSearch,
  capabilityKeywords: {
    keywords: [],
    keywordRelated: {
      isApplied: false,
      value: "keyword-relate-or",
    } as GenericFilter,
    isRefelected: false,
  },
  smallBusinessProfile: [],
  location: {
    states: [],
    isRefelected: false,
  },
  sbaCertifications: {
    activeCertification: [],
    previousCertification: [],
    certificationRelate: {
      value: "certifications-or",
      isApplied: false,
    } as GenericFilter,
    isRefelected: false,
  },
  ownershipSelfCerts: {
    selfCertifications: [],
    selfCertifiedWomanOwned: [],
    anyMinorityOwned: {
      otherMinorityOwned: {} as GenericFilter,
      anyNativeAmericanOwner: [],
    },
    matchType: {
      isApplied: false,
      value: "ownership-or",
    } as GenericFilter,
    isRefelected: false,
  },
  capabilities: {
    naics: [],
    matchType: {
      isApplied: false,
      value: "naics-or",
    } as GenericFilter,
    matchlimit: {
      value: "",
    },
    isRefelected: false,
  },
  searchBusiness:{
    isApplied:false,
    category:"searchBusiness",
    value:""
  }
  // capabilityKeywords: {
  //   keywords: [],
  //   keywordRelated: {} as GenericFilter,
  // },
  // profileStatus: {
  //   lastUpdatedOn: {} as GenericFilter,
  //   status: [],
  // },
  // natureOfBusiness: [],
  // bondingLevels: {} as BondingLevel,
  // qualityAssuranceStandards: [],
  // privilegedSearch: {} as PrivilegedSearch,
  // smallBusinessProfile: [],
};

export interface FilterState {
  filters: Filters;
  appliedFilters: Filters;
  clearCount: number;
  isBtnDisabled: boolean;

  setFilter: <T extends keyof FilterState["filters"]>(
    filterType: T,
    filterItems: FilterState["filters"][T]
  ) => void;

  appliedFilter: () => void;

  clearFilter: () => void;
}

export const useFilterStore = create<FilterState>()(
  persist(
    (set) => ({
      filters: initialData,
      appliedFilters: initialData,
      clearCount: 0,
      isBtnDisabled: true,

      setFilter: (filterType, filterItems) => {
        set((state) => ({
          filters: {
            ...state.filters,
            [filterType]: {
              ...filterItems,
              isRefelected: false,
            },
          },
          isBtnDisabled: state.isBtnDisabled ? false : state.isBtnDisabled,
        }));
      },

      appliedFilter: () => {
        set((state) => {
          const updatedFilters = {
            ...state.filters,

            qualityAssuranceStandards: {
              standards: state.filters.qualityAssuranceStandards.standards.map(
                (item) => ({
                  ...item,
                  isApplied: true,
                })
              ),
              isRefelected: !state.filters.qualityAssuranceStandards
                .isRefelected
                ? true
                : state.filters.qualityAssuranceStandards.isRefelected,
            },

            natureOfBusiness: {
              business: state.filters.natureOfBusiness.business.map((item) => ({
                ...item,
                isApplied: true,
              })),
              isRefelected: !state.filters.natureOfBusiness.isRefelected
                ? true
                : state.filters.natureOfBusiness.isRefelected,
            },

            bondingLevels: {
              level: Object.keys(state.filters.bondingLevels.level).reduce(
                (newBondingLevels, key) => {
                  newBondingLevels[key] = {
                    ...state.filters.bondingLevels.level[key],
                    isApplied: state.filters.bondingLevels.level[key]?.value
                      ? true
                      : state.filters.bondingLevels.level[key]?.isApplied,
                  };
                  return newBondingLevels;
                },
                {} as Level
              ),
              isRefelected: !state.filters.bondingLevels.isRefelected
                ? true
                : state.filters.bondingLevels.isRefelected,
            },

            profileStatus: {
              ...state.filters.profileStatus,
              lastUpdatedOn: {
                ...state.filters.profileStatus.lastUpdatedOn,
                isApplied: state.filters.profileStatus.lastUpdatedOn?.value
                  ? true
                  : state.filters.profileStatus.lastUpdatedOn?.isApplied,
              },
              status: {
                ...state.filters.profileStatus.status,
                isApplied: state.filters.profileStatus.status?.value
                  ? true
                  : state.filters.profileStatus.status?.isApplied,
              },
              isRefelected: !state.filters.profileStatus.isRefelected
                ? true
                : state.filters.profileStatus.isRefelected,
            },

            privilegedSearch: Object.keys(
              state.filters.privilegedSearch
            ).reduce((newPrivilegedSearch, key) => {
              newPrivilegedSearch[key] = {
                ...state.filters.privilegedSearch[key],
                isApplied: true,
              };
              return newPrivilegedSearch;
            }, {} as PrivilegedSearch),

            capabilityKeywords: {
              keywords: state.filters.capabilityKeywords.keywords.map(
                (keyword) => ({
                  ...keyword,
                  isApplied: true,
                })
              ),
              keywordRelated: {
                ...state.filters.capabilityKeywords.keywordRelated,
                isApplied: true,
              },
              isRefelected: !state.filters.capabilityKeywords.isRefelected
                ? true
                : state.filters.capabilityKeywords.isRefelected,
            },

            smallBusinessProfile: state.filters.smallBusinessProfile.map(
              (item) => ({
                ...item,
                isApplied: true,
              })
            ),

            location: {
              ...state.filters.location,
              states: state.filters.location.states.map((item) => ({
                ...item,
                isApplied: true,
              })),
              isRefelected: !state.filters.location.isRefelected
                ? true
                : state.filters.location.isRefelected,
            },

            sbaCertifications: {
              activeCertification:
                state.filters.sbaCertifications.activeCertification.map(
                  (item) => ({
                    ...item,
                    isApplied: true,
                  })
                ),
              previousCertification:
                state.filters.sbaCertifications.previousCertification.map(
                  (item) => ({
                    ...item,
                    isApplied: true,
                  })
                ),
              certificationRelate: {
                ...state.filters.sbaCertifications.certificationRelate,
                isApplied: true,
              },
              isRefelected: !state.filters.sbaCertifications.isRefelected
                ? true
                : state.filters.sbaCertifications.isRefelected,
            },

            ownershipSelfCerts: {
              selfCertifications:
                state.filters.ownershipSelfCerts.selfCertifications.map(
                  (item) => ({
                    ...item,
                    isApplied: true,
                  })
                ),
              selfCertifiedWomanOwned:
                state.filters.ownershipSelfCerts.selfCertifiedWomanOwned.map(
                  (item) => ({
                    ...item,
                    isApplied: true,
                  })
                ),
              anyMinorityOwned: {
                otherMinorityOwned: {
                  ...state.filters.ownershipSelfCerts.anyMinorityOwned
                    .otherMinorityOwned,
                  isApplied: true,
                },
                anyNativeAmericanOwner:
                  state.filters.ownershipSelfCerts.anyMinorityOwned.anyNativeAmericanOwner.map(
                    (item) => ({
                      ...item,
                      isApplied: true,
                    })
                  ),
              },
              matchType: {
                ...state.filters.ownershipSelfCerts.matchType,
                isApplied: true,
              },
              isRefelected: !state.filters.ownershipSelfCerts.isRefelected
                ? true
                : state.filters.ownershipSelfCerts.isRefelected,
            },

            capabilities: {
              ...state.filters.capabilities,
              naics: state.filters.capabilities?.naics?.map((item) => ({
                ...item,
                isApplied: true,
              })),
              matchType: {
                ...state.filters.capabilities?.matchType,
                isApplied: true,
              },
              isRefelected: !state.filters.capabilities?.isRefelected
                ? true
                : state.filters.capabilities.isRefelected,
            },
          };

          return {
            filters: updatedFilters,
            appliedFilters: updatedFilters,
            isBtnDisabled: true,
          };
        });
      },

      clearFilter: () => {
        set((state) => ({
          filters: initialData,
          appliedFilters: initialData,
          isBtnDisabled: true,
          clearCount: state.clearCount + 1,
        }));
      },
    }),
    {
      name: "filter-store",
      getStorage: () => localStorage,
      serialize: (state) => JSON.stringify(state),
      deserialize: (state) => JSON.parse(state),
    }
  )
);

if (initialData) {
  useFilterStore.setState((state) => ({
    ...state,
    ...initialData,
  }));
}

useFilterStore.subscribe(() => {
  saveState("filter-store", useFilterStore.getState());
});
