import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "sonner";

// styles
import "./style.scss";
import "@trussworks/react-uswds/lib/index.css";

// pages component
import AdvancedSearchPage from "./pages/AdvancedSearch";
import BasicSearchPage from "./pages/BasicSearch";
import BusinessProfile from "./pages/BusinessProfile";
import LibraryPage from "./pages/Library";
import AdvancedSearchNewPage from "./pages/AdvancedSearchNew";
import HomePage from "pages/Home/Home";
import NotFoundPage from "pages/NotFoundPage/NotFoundPage";
import MarketInsights from "pages/MarketInsights/MarketInsights";

// layouts
import Layout from "./pages/Layouts";
import MainLayout from "layouts/MainLayout/MainLayout";

const queryClient = new QueryClient();

ReactGA.initialize(
  (process.env.REACT_APP_SBS_TRACKING_ID as string) || "UA-000000-01"
);

export default function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "SBA SBS Search",
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<HomePage />} />

            <Route path={`/profile/:pronetId`} element={<BusinessProfile />} />

            <Route path={`/advanced`} element={<AdvancedSearchNewPage />} />

            <Route path={`/library`} element={<LibraryPage />} />

            {/* <Route path={`/market-insights`} element={<MarketInsights />} /> */}
          </Route>

          <Route path={`/archive`} element={<Layout />}>
            <Route index element={<BasicSearchPage />} />
            <Route
              path={`advanced/:search?`}
              element={<AdvancedSearchPage />}
            />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>

      {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      )}

      <Toaster
        position="top-right"
        richColors
        closeButton
        toastOptions={{
          duration: 2000,
          style: {
            padding: 16,
          },
        }}
      />
    </QueryClientProvider>
  );
}
