import React from "react";
import "./ProfileOverviewSection.scss";

import { Tag } from "@trussworks/react-uswds";
import { useSearchStore } from "../../../store/searchStore";

// icons
import {
  UserIcon,
  AtIcon,
  PhoneIcon,
  FaxIcon,
  PinIcon,
  WorldIcon,
  StoreIcon,
  TimerOutline,
} from "../../icons";

import { refineCertificationTags } from "../../../utils/helper";
import DisplayValue from "../DisplayValue";
import { getCapabilitiesNarrativeLink } from "../../../utils/helper";

const MockTags = [
  {
    title: "8(a) certification",
    isPending: false,
  },
  {
    title: "8(a) joint venture",
    isPending: false,
  },
  {
    title: "wosb certification",
    isPending: true,
  },
  {
    title: "edwosb certification",
    isPending: true,
  },
  {
    title: "hubzone certification",
    isPending: false,
  },
  {
    title: "veteran",
    isPending: false,
  },
  {
    title: "veteran joint venture",
    isPending: false,
  },
  {
    title: "veteran service disabled",
    isPending: false,
  },
  {
    title: "veteran service disabled joint venture",
    isPending: false,
  },
];

export default function ProfileOverviewSection() {
  const { business_profile, business_profile: { certifications } } = useSearchStore();

    const capabilitiesLink = getCapabilitiesNarrativeLink(business_profile);

  return (
    <div className="font-family-sans">
      <div className="space-y-4">
        <h1 className="title">{business_profile?.profile?.name}</h1>
        <p className="uei-number" hidden>
          Parent UEI: <DisplayValue value={business_profile.profile?.parent_uei} messageElement="b" format={{ isBold: true }} message="Not Listed" />
        </p>
        <p className="uei-number">
          UEI: <DisplayValue value={business_profile.profile?.uei} message="This firm has no UEI" />
        </p>
        <p className="uei-number" hidden>
          Associated UEI(s): <DisplayValue value={business_profile.profile?.associated_ueis} messageElement="strong" />
        </p>
        <div className="tags-group">
          {certifications?.sba_certifications?.filter(x => x?.status === 'Active').map((cert, index) => {
            const refineTag = refineCertificationTags(cert.name, cert.pending?.indexOf('Y') > -1 ?? false);
            return (
              <Tag
                key={index}
                background={!refineTag.isPending ? "#538200" : "transparent"}
                className={`tag ${refineTag.isPending ? "pending-tag" : ""}`}
              >
                {refineTag.isPending && <TimerOutline />}
                {refineTag.name}
              </Tag>
            );
          })
          }
        </div>
        <p className="last-update">
          This profile was last updated:{" "}
          {business_profile?.profile?.last_updated
            ? new Date(
              business_profile.profile.last_updated
            ).toLocaleDateString()
            : null}
        </p>
      </div>

      <hr className="break-line" />

      <h1 id="profileOverview" className="title-2">
        Profile overview
      </h1>

      <div className="grid-row desktop:grid-gap-3 margin-top-3 profile-content">
        <div className="grid-col-12 desktop:grid-col-4 space-y-4">
          <div className="display-flex">
            <UserIcon className="text-primary font-ui-lg flex-shrink" />
            <div className="content-group margin-left-2">
              <h5>Contact person</h5>
              <DisplayValue value={business_profile?.profile?.contact_person} />
            </div>
          </div>
          <div className="display-flex">
            <AtIcon className="text-primary font-ui-lg flex-shrink" />
            <div className="content-group margin-left-2">
              <h5>Email address</h5>
              <DisplayValue value={business_profile?.profile?.email} renderer={(_value) => (<>
                <p>
                  <a
                    href={`mailto:${business_profile?.profile?.email}`}
                    className="usa-link usa-link"
                  >
                    {_value}
                  </a>
                </p>
              </>)} />
            </div>
          </div>
          <div className="display-flex">
            <PhoneIcon className="text-primary font-ui-lg flex-shrink" />
            <div className="content-group margin-left-2">
              <h5>Phone number</h5>
              <DisplayValue value={business_profile?.profile?.phone_ext === null || business_profile?.profile?.phone_ext === '' ? business_profile?.profile?.phone : business_profile?.profile?.phone?.concat(` x ${business_profile?.profile?.phone_ext}`)} />
            </div>
          </div>
          <div className="display-flex">
            <FaxIcon className="text-primary font-ui-lg flex-shrink" />
            <div className="content-group margin-left-2">
              <h5>Fax number</h5>
              <DisplayValue value={business_profile?.profile?.fax} />
            </div>
          </div>
          <div className="display-flex">
            <PinIcon className="text-primary font-ui-lg flex-shrink" />
            <div className="content-group margin-left-2">
              <h5>Address</h5>
              <DisplayValue value={business_profile?.profile?.full_address} renderer={(_value) => {
                let address = business_profile?.profile?.full_address;
                return (
                  <>
                    <span style={{ display: "block" }}>{address.line1}</span>
                    <span style={{ display: "block" }}>{address.line2}</span>
                    <span style={{ display: "block" }}>
                      {`${address.city}, ${address.state} ${address.zip}`}
                    </span>
                  </>
                )
              }} />
            </div>
          </div>
          <div className="display-flex">
            <WorldIcon className="text-primary font-ui-lg flex-shrink" />
            <div className="content-group margin-left-2">
              <h5>Website</h5>
              <DisplayValue value={business_profile?.profile?.website} renderer={(_value) => {
                let url = _value;
                if (url.indexOf("http") === -1) {
                  url = "https://" + url;
                }
                return (
                  <>
                    <p>
                      <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        className="usa-link usa-link--external"
                      >
                        {_value}
                      </a>
                    </p>
                  </>
                )
              }} />
            </div>
          </div>
          <div className="display-flex">
            <StoreIcon className="text-primary font-ui-lg flex-shrink" />
            <div className="content-group margin-left-2">
              <h5>E-commerce website</h5>
              <DisplayValue value={business_profile?.profile?.e_commerce_website} renderer={(_value) => {
                let url = _value;
                if (url.indexOf("http") === -1) {
                  url = "https://" + url;
                }

                return (
                  <>
                    <p>
                      <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        className="usa-link usa-link--external"
                      >
                        {_value}
                      </a>
                    </p>
                  </>
                )
              }} />
            </div>
          </div>
        </div>

        <div className="grid-col-12 desktop:grid-col-8 margin-top-3 desktop:margin-top-0">
          <div className="content-group">
            <h4>Capabilities narrative</h4>
            <p className="line-height-150">
              <DisplayValue value={business_profile?.profile?.capabilities_narrative} />
              <br/>
              <DisplayValue value={capabilitiesLink} message={null} renderer={(_value) => (<>
                <a
                  href={capabilitiesLink}
                  target="_blank"
                  rel="noreferrer"
                  className="usa-link usa-link--external"
                >
                  Capabilities statement
                </a>
              </>)} />
            </p>
          </div>

          <div className="grid-row desktop:grid-gap-3 margin-top-3">
            <div className="grid-col-12 desktop:grid-col-6 space-y-4">
              <div className="content-group">
                <div>
                  <h5>Trade name</h5>
                  <span className="quote">“Doing business as...” </span>
                </div>
                <p><DisplayValue value={business_profile?.profile?.trade_name} /></p>
              </div>
              <div className="content-group">
                <h5>County code (3 digits)</h5>
                <DisplayValue value={business_profile?.profile?.county_code} renderer={(_value) => {
                  const FormatCountyCode = (_code) => _code.toString().padStart(3, '0');
                  return (
                    <>
                      {FormatCountyCode(_value)}
                    </>
                  )
                }} message="Unknown" />
              </div>
              <div className="content-group">
                <h5>Congressional district</h5>
                <DisplayValue value={business_profile?.profile?.congressional_district} message="Unknown" />
              </div>
              <div className="content-group">
                <h5>Metropolitan statistical area</h5>
                <DisplayValue value={business_profile?.profile?.metropolitan_area} message="Unknown" />
              </div>
            </div>

            <div className="grid-col-12 desktop:grid-col-6 space-y-4 margin-top-3 desktop:margin-top-0">
              <div className="content-group">
                <h5>CAGE code</h5>
                <DisplayValue value={business_profile?.profile?.cage_code} message="Unknown" />
              </div>
              <div className="content-group">
                <h5>Year established</h5>
                <DisplayValue value={business_profile?.profile?.year_established} />
              </div>
              <div className="content-group">
                <h5>Accepts government credit card?</h5>
                <DisplayValue value={business_profile?.profile?.accepts_government_credit_card} renderer={(_value) => (<>
                  {_value ===
                    "Y"
                    ? "Yes"
                    : "No"}
                </>)} />
              </div>
              <div className="content-group">
                <h5>GSA Advantage contract(s)</h5>
                <DisplayValue value={business_profile?.profile?.gsa_contract} />
              </div>
              <div className="content-group">
                <h5>User ID</h5>
                <DisplayValue value={business_profile?.profile?.user_id} message="Unknown" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
