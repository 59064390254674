import "./ControlAccordion.scss";

import React, { useState } from "react";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

// store
import { useFilterStore } from "../store/useFilterStore";

// utils
import {
  getUnAppliedFilterOptionsCount,
  getAppliedFilterOptionsCount,
  getTotalFilterOptionsCount,
} from "../utils/helper";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

interface ControlAccordionProps {
  title: string;
  name: string;
  _isExpanded?: boolean;
  children: React.ReactNode;
}

const ControlAccordion: React.FunctionComponent<ControlAccordionProps> = ({
  title,
  name,
  _isExpanded = false, // optional property to set the initial state of the accordion
  children,
}) => {
  const filters = useFilterStore((state) => state.filters);

  const [isExpanded, setIsExpanded] = useState(_isExpanded);

  const handleToggleAccordion = () => {
    setIsExpanded((prevValue) => !prevValue);
  };

  const totalCount = getTotalFilterOptionsCount(filters, name);
  const isRefelected = filters[name]?.isRefelected;

  return (
    <div className="usa-accordion sbs-usa-accordion">
      <div
        className="usa-accordion__button"
        aria-expanded={isExpanded}
        onClick={handleToggleAccordion}
      >
        {title}
        {totalCount > 0 && (
          <div className="applied-content">
            <CustomTooltip
              title={`${
                isRefelected
                  ? "These filters are reflected in the search results"
                  : "This filter has unapplied changes"
              }`}
              placement="top"
            >
              <span className={`${isRefelected ? "applied" : "unapplied"}`}>
                {totalCount}
              </span>
            </CustomTooltip>
          </div>
        )}
      </div>

      {isExpanded && <div className="usa-accordion__content">{children}</div>}
    </div>
  );
};

export default ControlAccordion;
