import { useState, useEffect } from "react";
import { Icon } from "@trussworks/react-uswds";

// component
import {
  FilterFlyout,
  FilterRelationBox,
  MultiSelectCheckbox,
  RoundedButton,
  SearchInput,
} from "components/LibraryComponents/Components";

// types
import { Generic } from "types/filters-store.types";

// constants
import { FilterKeys, FilterTitles, OperatorType } from "constants/filters";

// store
import useFiltersStore from "store/useFiltersStoreNew";

// styles
import styles from "./KeywordFilterButton.module.scss";

export default function KeywordFilterButton() {
  const { keywords, setFilter } = useFiltersStore((state) => ({
    keywords: state.filters.keywords,
    setFilter: state.setFilter,
  }));

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  // hold user entered keywords either checked/unchecked.
  const [list, setList] = useState<Generic[]>(keywords.list);
  // This state only control if filter is open,
  // and has more than one value apply max-width to filter button.
  const [isHalt, setIsHalt] = useState<boolean>(true);

  const selectedKeywordValues = keywords.list.map((item) => item.value);
  const isDisabled = list.length === 0;

  useEffect(() => {
    if (keywords.list.length === 0) {
      if (!isHalt) setIsHalt(true);
    }
  }, [keywords.list]);

  const handleChange = (items: Generic[]) => {
    setFilter(FilterKeys.KEYWORDS, {
      ...keywords,
      list: items,
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    if (showError) {
      setShowError(false);
    }
  };

  const handleOperatorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setFilter(FilterKeys.KEYWORDS, {
      ...keywords,
      operatorType: value,
    });
  };

  const handleAddKeyword = () => {
    if (value.trim() !== "") {
      const isExist = keywords.list.find((item) => item.value === value);

      if (isExist) {
        setShowError(true);
        return;
      }

      if (showError) {
        setShowError(false);
      }

      const newKeyword = { value: value.trim(), label: value.trim() };

      setFilter(FilterKeys.KEYWORDS, {
        ...keywords,
        list: [...keywords.list, newKeyword],
      });

      setList([...list, newKeyword]);
      setValue("");
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleAddKeyword();
    }
  };

  const handleSearchClear = () => {
    setValue("");
  };

  const handleClear = () => {
    setFilter(FilterKeys.KEYWORDS, {
      list: [],
      operatorType: OperatorType.OR,
    });

    setList([]);
    setValue("");

    if (showError) {
      setShowError(false);
    }

    setIsHalt(true);
  };

  const handleCallback = (open: boolean) => {
    if (!open) {
      if (value) {
        setValue("");
      }
    }

    if (!open && selectedKeywordValues.length > 0) {
      setIsHalt(false);
    }
  };

  return (
    <FilterFlyout
      id={FilterKeys.KEYWORDS}
      filterName={FilterTitles[FilterKeys.KEYWORDS]}
      title={FilterTitles[FilterKeys.KEYWORDS]}
      FilterIcon={<Icon.List />}
      options={selectedKeywordValues}
      operator={keywords.operatorType}
      isOpen={isOpen}
      isHalt={isHalt}
      setIsOpen={setIsOpen}
      callback={handleCallback}
    >
      <div className={styles["keyword-filter-content"]}>
        <p className={styles["description"]}>
          Search for one or more terms included in a small business’s{" "}
          <b>capabilities narrative</b> or <b>capability keywords</b>.
        </p>

        <div className={styles["search-input-wrapper"]}>
          <SearchInput
            id="keyword-search"
            name="keyowrd-search"
            placeholder="Type to search keywords"
            inputSize="small"
            value={value}
            onChange={handleSearch}
            handleClear={handleSearchClear}
            onKeyDown={handleKeyPress}
          />

          {showError && (
            <span className={styles["error"]}>Keyword already exist</span>
          )}
        </div>

        <div className={styles["keywords-list-wrapper"]}>
          <p hidden={isDisabled}>SELECTED</p>
          <div hidden={isDisabled} className={styles["list"]}>
            <MultiSelectCheckbox
              items={list}
              checkedItems={keywords.list}
              handleChange={handleChange}
              hideOnly
            />
          </div>
        </div>

        <div
          hidden={selectedKeywordValues.length <= 1}
          className={styles["relation-box"]}
        >
          <FilterRelationBox
            filterName={FilterKeys.KEYWORDS}
            value={`${FilterKeys.KEYWORDS}-${keywords.operatorType}`}
            onChange={handleOperatorChange}
          />
        </div>

        <div className={styles["clear-filter"]}>
          <RoundedButton
            variant="ghost"
            onClick={handleClear}
            disabled={isDisabled}
          >
            Clear filter selections
          </RoundedButton>
        </div>
      </div>
    </FilterFlyout>
  );
}
