import { useEffect, useState } from "react";

// component
import {
  Checkbox,
  FilterFlyout,
  FilterRelationBox,
  MultiSelectCheckbox,
  RoundedButton,
} from "components/LibraryComponents/Components";
import { SbaCertify } from "components/icons";

// types
import { Generic } from "types/filters-store.types";

// constant
import {
  ActiveSBACertifications,
  OperatorType,
  FilterKeys,
  FilterTitles,
} from "constants/filters";

// store
import useFiltersStore from "store/useFiltersStoreNew";

// styles
import styles from "./SbaFilterButton.module.scss";

export default function SbaFilterButton() {
  const { sbaCertifications, setFilter } = useFiltersStore((state) => ({
    sbaCertifications: state.filters.sbaCertifications,
    setFilter: state.setFilter,
  }));

  const [isOpen, setIsOpen] = useState(false);
  // This state only control if filter is open,
  // and has more than one value apply max-width to filter button.
  const [isHalt, setIsHalt] = useState<boolean>(true);

  const selectedSbaValues = sbaCertifications.activeCerts.map(
    (item) => item.label
  );
  const isDisabled = selectedSbaValues.length === 0;

  useEffect(() => {
    if (sbaCertifications.activeCerts.length === 0) {
      if (!isHalt) setIsHalt(true);
    }
  }, [sbaCertifications.activeCerts]);

  const handleChange = (items: Generic[]) => {
    setFilter(FilterKeys.SBA_CERTIFICATIONS, {
      ...sbaCertifications,
      activeCerts: items,
    });
  };

  const handlePreviousChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setFilter(FilterKeys.SBA_CERTIFICATIONS, {
      ...sbaCertifications,
      isPreviousCert: checked,
    });
  };

  const handleOperatorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setFilter(FilterKeys.SBA_CERTIFICATIONS, {
      ...sbaCertifications,
      operatorType: value,
    });
  };

  const handleClear = () => {
    setFilter(FilterKeys.SBA_CERTIFICATIONS, {
      activeCerts: [],
      isPreviousCert: false,
      operatorType: OperatorType.OR,
    });

    setIsHalt(true);
  };

  const handleCallback = (open: boolean) => {
    if (!open && selectedSbaValues.length > 0) {
      setIsHalt(false);
    }
  };

  return (
    <FilterFlyout
      id={FilterKeys.SBA_CERTIFICATIONS}
      filterName={FilterTitles[FilterKeys.SBA_CERTIFICATIONS]}
      title={FilterTitles[FilterKeys.SBA_CERTIFICATIONS]}
      FilterIcon={<SbaCertify />}
      options={selectedSbaValues}
      operator={sbaCertifications.operatorType}
      isOpen={isOpen}
      isHalt={isHalt}
      setIsOpen={setIsOpen}
      callback={handleCallback}
    >
      <div className={styles["sba-filter-content"]}>
        <p className={styles["description"]}>
          SBA Certified Small Businesses have gone through the certification
          process for an SBA program.{" "}
          <a href="https://certify.sba.gov" target="_blank" rel="noreferrer">
            Learn more about the SBA certification programs here.
          </a>
        </p>

        <div className={styles["active-certs-list"]}>
          <MultiSelectCheckbox
            className={styles["list-wrapper"]}
            items={ActiveSBACertifications}
            checkedItems={sbaCertifications.activeCerts}
            handleChange={handleChange}
            hideOnly
          />
        </div>

        <div
          hidden={selectedSbaValues.length <= 1}
          className={styles["relation-box"]}
        >
          <FilterRelationBox
            filterName={"certifications"}
            value={`certifications-${sbaCertifications.operatorType}`}
            onChange={handleOperatorChange}
          />
        </div>

        <div className={styles["divider"]}>
          <hr />
        </div>

        {/* previous cert checkbox */}
        <Checkbox
          id="sba-previous"
          name="sba-previous"
          label="Include previous certifications"
          labelDescription="Include businesses that were previously certified with any of the above certifications."
          checked={sbaCertifications.isPreviousCert}
          onChange={handlePreviousChange}
        />

        <div className={styles["clear-filter"]}>
          <RoundedButton
            variant="ghost"
            onClick={handleClear}
            disabled={isDisabled}
          >
            Clear filter selections
          </RoundedButton>
        </div>
      </div>
    </FilterFlyout>
  );
}
