import "./CapabilityKeywordsFilter.scss";

import { useState } from "react";

import { Search, Radio, Icon } from "@trussworks/react-uswds";

import { useFilterStore } from "../../../store/useFilterStore";

export default function CapabilityKeywordsFilter() {
  const store = useFilterStore();

  const [showError, setShowError] = useState<boolean>(false);
  const [showCloseIcon, setShowCloseIcon] = useState<boolean>(false);

  const handleRelatedKeywords = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;

    store.setFilter("capabilityKeywords", {
      ...store.filters.capabilityKeywords,
      keywordRelated: {
        value: id,
        isApplied: false,
      },
    });
  };

  const handleAddKeyword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const inputEl = document.getElementById("capability") as HTMLInputElement;
    const value = inputEl.value;

    if (value.trim() !== "") {
      const isExist = store.filters.capabilityKeywords.keywords.find(
        (item) => item.value === value
      );

      if (isExist) {
        setShowError(true);
        return;
      }

      if (showError) {
        setShowError(false);
      }

      store.setFilter("capabilityKeywords", {
        ...store.filters.capabilityKeywords,
        keywords: [
          ...store.filters.capabilityKeywords.keywords,
          { value: value.trim(), isApplied: false },
        ],
      });

      inputEl.value = "";
      setShowCloseIcon(false);
    }
  };

  const handleRemoveKeyword = (index: number) => {
    store.setFilter("capabilityKeywords", {
      ...store.filters.capabilityKeywords,
      keywords: store.filters.capabilityKeywords.keywords.filter(
        (_, i) => i !== index
      ),
    });
  };

  const handleClearInput = () => {
    const inputEl = document.getElementById("capability") as HTMLInputElement;
    inputEl.value = "";
    setShowCloseIcon(false);
  };

  return (
    <div className="capability-keywords-root font-family-sans">
      <h5>
        Search capabilities narrative and service information for specific
        keywords:
      </h5>

      <div className="search-box-wrapper">
        <Search
          inputId="capability"
          size="small"
          inputMode="search"
          placeholder="Type Keywords"
          className="search-wrapper"
          onSubmit={handleAddKeyword}
          onChange={(e: React.ChangeEvent<HTMLFormElement>) => {
            const { value } = e.target;

            if (value !== "" && !showCloseIcon) {
              setShowCloseIcon(true);
            } else if (value === "" && showCloseIcon) {
              setShowCloseIcon(false);
            }
          }}
        />

        {showCloseIcon && <Icon.Cancel onClick={handleClearInput} />}
      </div>

      {showError && <span className="error">Keyword already exist</span>}

      {store.filters.capabilityKeywords.keywords.length > 0 && (
        <div className="pills-content">
          {store.filters.capabilityKeywords.keywords.map((item, index) => (
            <div key={index} className="pill">
              <span>{item.value}</span>
              <span
                className="icon-x-wrapper"
                onClick={() => handleRemoveKeyword(index)}
              >
                <Icon.Close />
              </span>
            </div>
          ))}
        </div>
      )}

      <hr className="divider" />

      <h5>How should these keywords relate?</h5>

      <div className="radio-group">
        <Radio
          id="keyword-relate-or"
          name="keyword-relate-or"
          label={
            <>
              OR: results can include <b>any</b> of these
            </>
          }
          checked={
            store.filters.capabilityKeywords.keywordRelated?.value ===
            "keyword-relate-or"
          }
          onChange={handleRelatedKeywords}
        />
        <Radio
          id="keyword-relate-and"
          name="keyword-relate-and"
          label={
            <>
              AND: results must include <b>all</b> of these
            </>
          }
          checked={
            store.filters.capabilityKeywords.keywordRelated?.value ===
            "keyword-relate-and"
          }
          onChange={handleRelatedKeywords}
        />
      </div>
    </div>
  );
}
