// components
import { Heading, Subheading, Checkbox } from "../../Components";

// simulation components
import {
  AutoFocusCheckbox,
  AutoHoverCheckbox,
} from "../../Components/Checkbox";

// styles
import styles from "./CheckboxBlock.module.scss";

export default function CheckboxBlock() {
  return (
    <div className={styles["checkbox-block-root"]}>
      <Heading title="Checkboxes" />

      <div style={{ marginTop: "24px" }} />

      {/* Selected Checkboxes */}
      <Subheading title="Selected" />

      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default</span>
          <Checkbox
            id="default-checked"
            name="default-checked"
            label="Checkbox item"
            defaultChecked
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Hover</span>
          <AutoHoverCheckbox
            id="hover-checked"
            name="hover-checked"
            label="Checkbox item"
            checked
            readOnly
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Focus</span>
          <AutoFocusCheckbox
            id="focus-checked"
            name="focus-checked"
            label="Checkbox item"
            checked
            readOnly
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Disabled</span>
          <Checkbox
            id="disabled-checked"
            name="disabled-checked"
            label="Checkbox item"
            disabled
          />
        </div>
      </div>

      {/* Selected Checkboxes with descripition */}
      <div style={{ marginTop: "32px" }} />
      <Subheading
        title="With description"
        className={styles["subheading-sm"]}
      />

      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default</span>
          <Checkbox
            id="default-des-checked"
            name="default-des-checked"
            label="Checkbox item"
            labelDescription="Checkbox description"
            defaultChecked
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Hover</span>
          <AutoHoverCheckbox
            id="hover-des-checked"
            name="hover-des-checked"
            label="Checkbox item"
            labelDescription="Checkbox description"
            checked
            readOnly
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Focus</span>
          <AutoFocusCheckbox
            id="focus-des-checked"
            name="focus-des-checked"
            label="Checkbox item"
            labelDescription="Checkbox description"
            checked
            readOnly
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Disabled</span>
          <Checkbox
            id="disabled-des-checked"
            name="disabled-des-checked"
            label="Checkbox item"
            labelDescription="Checkbox description"
            disabled
          />
        </div>
      </div>

      {/* Unselected Checkboxes */}
      <div style={{ marginTop: "48px" }} />
      <Subheading title="Unselected" />

      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default</span>
          <Checkbox
            id="default-un-checked"
            name="default-un-checked"
            label="Checkbox item"
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Hover</span>
          <AutoHoverCheckbox
            id="hover-un-checked"
            name="hover-un-checked"
            label="Checkbox item"
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Focus</span>
          <AutoFocusCheckbox
            id="focus-un-checked"
            name="focus-un-checked"
            label="Checkbox item"
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Disabled</span>
          <Checkbox
            id="disabled-un-checked"
            name="disabled-un-checked"
            label="Checkbox item"
            disabled
          />
        </div>
      </div>

      {/* Unselected Checkboxes with description*/}
      <div style={{ marginTop: "32px" }} />
      <Subheading
        title="With description"
        className={styles["subheading-sm"]}
      />

      <div className={styles["grid-toggle-cols"]}>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Default</span>
          <Checkbox
            id="default-un-des-checked"
            name="default-un-des-checked"
            label="Checkbox item"
            labelDescription="Checkbox description"
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Hover</span>
          <AutoHoverCheckbox
            id="hover-un-des-checked"
            name="hover-un-des-checked"
            label="Checkbox item"
            labelDescription="Checkbox description"
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Focus</span>
          <AutoFocusCheckbox
            id="focus-un-des-checked"
            name="focus-un-des-checked"
            label="Checkbox item"
            labelDescription="Checkbox description"
          />
        </div>
        <div className={styles["toggle-col"]}>
          <span className={styles["label"]}>Disabled</span>
          <Checkbox
            id="disabled-un-des-checked"
            name="disabled-un-des-checked"
            label="Checkbox item"
            labelDescription="Checkbox description"
            disabled
          />
        </div>
      </div>
    </div>
  );
}
