import { Skeleton } from "@mui/material";
import styles from "./ResultTable.module.scss";

export default function TableSkeleton() {
  return (
    <div className={styles["table-skeleton-root"]}>
      {/* table header row */}
      <div className={styles["group"]}>
        {new Array(5).fill(5).map((_, idx) => (
          <Skeleton
            variant="rectangular"
            key={idx}
            height={28}
            animation="wave"
          />
        ))}
      </div>

      <div style={{ marginTop: 16 }} />

      {/* table body rows */}
      <div className={styles["group"]}>
        {new Array(30).fill(30).map((_, idx) => (
          <Skeleton
            variant="rectangular"
            key={idx}
            height={60}
            animation="wave"
          />
        ))}
      </div>
    </div>
  );
}
