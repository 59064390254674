import { create } from "zustand";
import { persist } from "zustand/middleware";

// types
import { Filters } from "types/filters-store.types";

// utils
import { getFilterCount } from "utils/advanced-search";

// constants
import { LastUpdatedList, OperatorType } from "constants/filters";

const initialState: Filters = {
  searchProfiles: {
    searchTerm: "",
  },
  location: {
    states: [],
    zipCodes: [],
    counties: [],
  },
  sbaCertifications: {
    activeCerts: [],
    isPreviousCert: false,
    operatorType: OperatorType.OR,
  },
  naics: {
    codes: [],
    isPrimary: false,
    operatorType: OperatorType.OR,
  },
  selfCertifications: {
    certifications: [],
    operatorType: OperatorType.OR,
  },
  keywords: {
    list: [],
    operatorType: OperatorType.OR,
  },
  lastUpdated: {
    date: LastUpdatedList[0],
  },
  samStatus: {
    isExpiredSAM: false,
  },
  qualityAssuranceStandards: {
    qas: [],
  },
  bondingLevels: {
    constructionIndividual: "",
    constructionAggregate: "",
    serviceIndividual: "",
    serviceAggregate: "",
  },
};

type FiltersStore = {
  filters: Filters;

  setFilter: <T extends keyof Filters>(
    filterType: T,
    filterItems: Filters[T]
  ) => void;

  areFiltersApplied: () => boolean;

  clearFilters: () => void;
};

const useFiltersStore = create(
  persist<FiltersStore>(
    (set, get) => ({
      filters: initialState,
      currentPage: 1,
      setFilter: (filterType, filterItems) =>
        set((state) => ({
          filters: {
            ...state.filters,
            [filterType]: filterItems,
          },
        })),

      clearFilters: () =>
        set((_prevValue) => ({
          filters: initialState,
        })),

      areFiltersApplied: () => {
        const filters = get().filters;
        return Object.keys(filters).some((filterType) => {
          return getFilterCount(filters, filterType) > 0;
        });
      },
    }),
    {
      name: "filters-store-v1.3",
    }
  )
);
export default useFiltersStore;
